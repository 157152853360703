import { PAGE_TEMPLATES_API } from 'modules/funnels/funnel/api/page-templates-api'
import { PageTemplateLimitType } from 'modules/funnels/funnel/components/page-template-selector/page-template-pagination'
import { FunnelPageTypeEnum } from 'modules/funnels/funnel/enums/funnel-page-type-enum'
import { FunnelPageTemplatesApiInterface } from 'modules/funnels/funnel/types/funnel-page-template-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { PaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'

interface UsePageTemplatesProps {
  type?: FunnelPageTypeEnum
  pagination: PaginationInterface
  limit?: PageTemplateLimitType
}
export function usePageTemplates({ type, limit, pagination }: UsePageTemplatesProps) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelPageTemplatesApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: pageTemplates } = useSWR(() => {
    if (user && type && pagination.startFromId === undefined && limit) {
      return `${PAGE_TEMPLATES_API}/${type}?pagination[limit]=${limit}`
    } else if (user && type && pagination.startFromId && limit) {
      return `${PAGE_TEMPLATES_API}/${type}?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}`
    }
    return null
  }, fetcher)

  return { pageTemplates }
}
