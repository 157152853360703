import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useControlledSwr } from 'shared/hooks/use-controlled-swr'
import useUser from 'shared/hooks/use-user'
import { OFFER_API } from '../api'
import { DigitalProductId } from '../types/digital-product-interface'

export function useOfferDigitalProductsCount(digitalProductId?: DigitalProductId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, { count: number }>({
    method: RequestMethodsEnum.get,
  })

  const {
    data: offerDigitalProductsCount,
    isLoading,
    isValidating,
  } = useControlledSwr(
    () => user && digitalProductId && `${OFFER_API}/count-by-digital-product/${digitalProductId}`,
    fetcher,
  )

  return { offerDigitalProductsCount, isLoading: isLoading || isValidating }
}
