import {
  PAGE_TEMPLATES_API,
  selectPageTemplate,
} from 'modules/funnels/funnel/api/page-templates-api'
import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import PageTemplatePagination, {
  PageTemplateLimitType,
} from 'modules/funnels/funnel/components/page-template-selector/page-template-pagination'
import TemplateCard from 'modules/funnels/funnel/components/page-template-selector/template-card'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { usePageTemplates } from 'modules/funnels/funnel/hooks/use-page-templates'
import { FunnelPageTemplateId } from 'modules/funnels/funnel/types/funnel-page-template-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getPageTypeFromFunnelStepType } from 'modules/funnels/funnel/utils/get-page-type-from-funnel-step-type'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import useUser from 'shared/hooks/use-user'
import { usePagination } from 'shared/route-query/hooks/use-pagination'

interface PageTemplateSelectorProps {
  funnelStep: FunnelStepInterface | null
  funnelId: FunnelId
}

function PageTemplateSelector({ funnelStep, funnelId }: PageTemplateSelectorProps) {
  const [limit, setLimit] = useState<PageTemplateLimitType>(9)
  const { pagination, setPagination } = usePagination(true)
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { pageTemplates } = usePageTemplates({
    type: getPageTypeFromFunnelStepType(funnelStep?.type),
    limit,
    pagination,
  })
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const limiter = { limit, setLimit }
  const paginator = { pagination, setPagination }

  const updateStepTemplatesData = useUpdateData({
    api: PAGE_TEMPLATES_API,
  })

  const [selectedPageTemplate, setSelectedPageTemplate] = useState<
    FunnelPageTemplateId | undefined
  >()

  const onSelect = async (pageTemplateId: FunnelPageTemplateId) => {
    try {
      if (funnelStep) {
        setSelectedPageTemplate(pageTemplateId)
        const response = await selectPageTemplate(funnelStep.id, pageTemplateId)
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(dataFunnelStep => {
              if (dataFunnelStep.id === funnelStep.id) {
                return response.data
              }
              return dataFunnelStep
            })
          }
        }, false)
        setSelectedPageTemplate(undefined)
      }
    } catch (error) {
      setSelectedPageTemplate(undefined)
      toast.error(t('global.error'))
    }
  }

  useEffect(() => {
    const revalidateStepTemplates = async () => await updateStepTemplatesData(false, pageTemplates)
    if (pageTemplates) {
      revalidateStepTemplates()
    }
  }, [user?.dashboardLocale])

  return (
    <div data-test-table-is-loaded={!!pageTemplates}>
      {!pageTemplates || !funnelStep ? (
        <div className={'h-full flex items-center justify-center py-12'}>
          <Loader className={'scale-150'} />
        </div>
      ) : (
        <div className="flex flex-col gap-5 mb-10 lg:px-[15px] px-[15px]">
          <MobileSettingsHeader type={FunnelStepSettingTabsEnum.stepConfiguration} />
          <div
            className={`grid justify-between 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 w-full gap-x-8 gap-y-14`}
          >
            {pageTemplates.items?.map(template => {
              return (
                <TemplateCard
                  key={template.id}
                  template={template}
                  onSelect={onSelect}
                  selectedPageTemplate={selectedPageTemplate}
                />
              )
            })}
          </div>
          <PageTemplatePagination data={pageTemplates} limiter={limiter} paginator={paginator} />
        </div>
      )}
    </div>
  )
}

export default PageTemplateSelector
