import { EmbeddedFormFieldsInterface } from 'modules/funnels/funnel/types/remote-form-interface'

export const getScriptTagHtmlCode = (
  domainName: string,
  pageHash: string,
  funnelStepId: number,
) => {
  return `<script id="form-script-tag-${funnelStepId}" src="${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/public/remote/page/${pageHash}.js"></script>`
}

export const getEmbeddedFormHtmlCode = (
  embeddedFormFields: EmbeddedFormFieldsInterface,
  pageId: number,
) => {
  const fieldsHtml = Object.keys(embeddedFormFields)
    .map(
      key => `<input type=${embeddedFormFields[key].type} name=${embeddedFormFields[key].slug} />`,
    )
    .join('\n')

  return `<form method="post" action="${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${process.env.NEXT_PUBLIC_SIO_HOST_NAME}/embedded/${pageId}/subscription"> ${fieldsHtml}
  <div class="f-row">
    <button type="submit" class="btn">Subscribe</button>
  </div>
  </form>
  `
}

export const getScriptLinkHtmlCode = (
  domainName: string,
  pageHash: string,
  funnelStepId: number,
  linkText: string,
) => {
  return `<a href="#" class="systeme-show-popup-${funnelStepId}">${linkText}</a><script id="form-script-tag-${funnelStepId}" src="${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/public/remote/page/${pageHash}.js"></script>`
}
