import { FunnelPageTypeEnum } from 'modules/funnels/funnel/enums/funnel-page-type-enum'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

export const getPageTypeFromFunnelStepType = (funnelStepType?: FunnelStepTypeEnum) => {
  switch (funnelStepType) {
    case FunnelStepTypeEnum.OrderThankYouPage:
      return FunnelPageTypeEnum.offer_thank_you_page
    case FunnelStepTypeEnum.OfferForm:
      return FunnelPageTypeEnum.offer_page
    case FunnelStepTypeEnum.OptInThankYouPage:
      return FunnelPageTypeEnum.opt_in_thank_you
    case FunnelStepTypeEnum.Upsell:
    case FunnelStepTypeEnum.Downsell:
      return FunnelPageTypeEnum.offer_upsell_page
    case FunnelStepTypeEnum.Squeeze:
      return FunnelPageTypeEnum.squeeze
    case FunnelStepTypeEnum.SalesPage:
      return FunnelPageTypeEnum.sales_page
    case FunnelStepTypeEnum.InfoPage:
      return FunnelPageTypeEnum.info_page
    case FunnelStepTypeEnum.Inline:
      return FunnelPageTypeEnum.inline
    case FunnelStepTypeEnum.Popup:
      return FunnelPageTypeEnum.popup
    case FunnelStepTypeEnum.WebinarRegistration:
      return FunnelPageTypeEnum.webinar_registration
    case FunnelStepTypeEnum.WebinarThankYou:
      return FunnelPageTypeEnum.webinar_thank_you_page
    case FunnelStepTypeEnum.WebinarSession:
      return FunnelPageTypeEnum.webinar_session
    case FunnelStepTypeEnum.ContactUsPage:
      return FunnelPageTypeEnum.funnel_contact_us
    case FunnelStepTypeEnum.LinkInBio:
      return FunnelPageTypeEnum.link_in_bio
    default:
      return
  }
}
