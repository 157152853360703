import toast from 'react-hot-toast'
import { ErrorResponseContentInterface } from 'shared/types/error-response-content-interface'

export const handleOfferPricePlansErrors = (
  errors: ErrorResponseContentInterface<{ offerPricePlans?: string[] }>,
) => {
  if (errors.fields.offerPricePlans) {
    toast.error(errors.fields.offerPricePlans.join(', '))
  }
}
