import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { OfferFormViewInterface } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useMutationUpdate } from 'shared/hooks/use-mutation-update'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

const getOfferSettingsApiKey = (id: FunnelStepId) => `${FUNNELS_API}/steps/${id}/offer-form`

export function useOfferFormSettings(id: FunnelStepId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    OfferFormViewInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: offerFormSettings, mutate: mutateOfferFormSettings } = useSWR(
    () => user && getOfferSettingsApiKey(id),
    fetcher,
  )

  return { offerFormSettings, mutateOfferFormSettings }
}

export const useOfferFormSettingsMutation = () => {
  const mutate = useMutationUpdate()

  const clearCurrencyRelatedFields = async (funnelStepIds?: number[]) => {
    if (!funnelStepIds) return
    for await (const id of funnelStepIds) {
      await mutate<OfferFormViewInterface>(
        getOfferSettingsApiKey(id),
        data =>
          data && {
            ...data,
            offer: { ...data.offer, bumps: [], coupons: [], product: null, digitalProduct: null },
          },
      )
    }
  }

  return { clearCurrencyRelatedFields }
}
