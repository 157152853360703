import DigitalProduct from 'modules/digital-product'
import { DigitalProductSourceEnum } from 'modules/digital-product/enums/digital-product-source-enum'
import ProductWrapper from 'modules/funnels/funnel/components/offer-settings/product-wrapper'
import { useFunnelStepData } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/hooks/use-funnel-step-data'
import { DigitalOfferViewInterface } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/digital-offer-interface'
import {
  OfferFormEditType,
  OfferFormViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'
import PhysicalProductSelector from 'modules/funnels/funnel/components/step-configuration-tab/physical-product-selector/components/physical-product-selector'
import { OfferTypeEnum } from 'modules/funnels/funnel/enums/offer-type-enum'
import DeliveredBoxIcon from 'modules/funnels/funnel/icons/delivered-box-icon'
import TrashIcon from 'modules/funnels/funnel/icons/trash-icon'
import { FunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import Confirmation from 'shared/components/confirmation-modal'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { Tooltip } from 'shared/components/tooltip'
import { PaymentMethodEnum } from 'shared/enums/payment-method-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'

interface OfferBumpSettingsProps {
  funnel: FunnelInterface
  offerBumps: OfferFormViewInterface['offer']['bumps']
  onSaveBump: (bumps: OfferFormEditType['bumps']) => Promise<void>
  paymentMethods: PaymentMethodEnum[]
}

function OfferBumpSettings({
  offerBumps,
  onSaveBump,
  funnel,
  paymentMethods,
}: OfferBumpSettingsProps) {
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false)

  const [bump, setBump] = useState<Pick<
    OfferFormViewInterface['offer']['bumps'][0],
    'product' | 'digitalProduct'
  > | null>(null)

  const { funnelStepData, mutateFunnelStepData } = useFunnelStepData()

  const hasUserAvailableBumps = funnelStepData && funnelStepData.bumpsCount > 0

  const hasUserPaymentMethods = paymentMethods.length > 0

  const isAddOrderBumpVisible = hasUserAvailableBumps && hasUserPaymentMethods

  const getDisableAddOrderBumpButtonReason = () => {
    if (!hasUserAvailableBumps)
      return t('dashboard.funnel.configuration.offer.order_bump_limit_description')

    if (!hasUserPaymentMethods)
      return (
        <>
          {t('dashboard.funnel.configuration.offer.need_payment_methods_description')}
          <br />
          <LinkWithoutPrefetch
            target="_blank"
            className="primary-link w-full"
            href={`/profile/payment-gateways`}
          >
            {t('dashboard.funnel.configuration.offer.go_to_payment_gateway_link_description')}
          </LinkWithoutPrefetch>
        </>
      )
  }

  useEffect(() => {
    if (offerBumps.length) {
      const bumpView = offerBumps[0]
      if (bumpView.product) {
        setBump({ product: bumpView.product, digitalProduct: null })
      } else if (bumpView.digitalProduct) {
        setBump({ product: null, digitalProduct: bumpView.digitalProduct })
      }
    } else {
      setBump(null)
    }
  }, [offerBumps])

  const handleRemoveBump = async () => {
    await onSaveBump([])
    await mutateFunnelStepData(data => {
      if (data) {
        return { ...data, bumpsCount: data.bumpsCount + 1 }
      }
    }, false)
  }

  const handleSaveBump = (data: OfferFormEditType['bumps']) => onSaveBump(data)

  const { t } = useLocoTranslation()
  return (
    <>
      {!bump ? (
        <div className="flex justify-center mt-10">
          <Tooltip
            disabled={isAddOrderBumpVisible}
            mode={'hover'}
            label={getDisableAddOrderBumpButtonReason() ?? ' '}
            stayOnHover
          >
            <PrimaryButton
              type={'button'}
              onClick={() => setBump({ product: null, digitalProduct: null })}
              disabled={!isAddOrderBumpVisible}
            >
              <AddIcon />
              {t('dashboard.funnel.configuration.offer.order_bump_add')}
            </PrimaryButton>
          </Tooltip>
        </div>
      ) : (
        <div className="flex flex-col gap-3 bo-color-[#F8FAFC] mt-10 lg:px-0 px-4">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-3 bo-color-[#F8FAFC]">
              <div className="flex flex-row gap-1 font-normal lg:text-[15px] text-sm text-blue items-center">
                <DeliveredBoxIcon />
                <span>{t('dashboard.funnel.configuration.offer.order_bump')}</span>
              </div>
              <span className="text-sm font-normal">
                {t('dashboard.funnel.configuration.offer.order_bump_description')}
              </span>
            </div>
            <TrashIcon
              className={`cursor-pointer flex-shrink-0`}
              onClick={() => setIsRemoveConfirmationOpen(true)}
            />
          </div>
          <ProductWrapper digitalProduct={bump.digitalProduct} product={bump.product}>
            {type => (
              <>
                {type === OfferTypeEnum.physical && (
                  <PhysicalProductSelector
                    product={bump.product?.id || null}
                    onSave={data => handleSaveBump([{ product: data, digitalProduct: null }])}
                    funnel={funnel}
                  />
                )}
                {type === OfferTypeEnum.digital && (
                  <DigitalProduct
                    source={DigitalProductSourceEnum.Funnel}
                    currency={funnel.currency}
                    paymentMethods={paymentMethods}
                    product={bump.digitalProduct}
                    onSave={data => handleSaveBump([{ product: null, digitalProduct: data }])}
                    isBump={true}
                  />
                )}
              </>
            )}
          </ProductWrapper>
        </div>
      )}
      <Confirmation
        opened={isRemoveConfirmationOpen}
        onClose={() => setIsRemoveConfirmationOpen(false)}
        onConfirm={handleRemoveBump}
        confirmationText={t('dashboard.funnel.configuration.offer.order_bump_confirmation')}
      />
    </>
  )
}

export default OfferBumpSettings
