import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'

type DeadlineSettingsRadioGroupProps<T extends number> = FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    options: DeadlineSettingsRadioGroupDataInterface<T>[]
    selected: T[]
    className?: string
    disabled?: boolean
    onChange: (value: T) => void
  }

export interface DeadlineSettingsRadioGroupDataInterface<T> {
  id: T
  caption: string
}

const DeadlineSettingsRadioGroup = <T extends number>({
  options,
  selected,
  onChange,
  disabled,
  error,
  label,
  required,
  labelClassName,
}: DeadlineSettingsRadioGroupProps<T>) => {
  return (
    <div className="flex flex-col gap-1">
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      <ul className="flex gap-5 items-center flex-wrap">
        {options.map(option => (
          <li key={option.id}>
            <label
              className={`relative r flex gap-2 items-center ${
                !disabled && 'cursor-pointer'
              } w-fit `}
            >
              <input
                className={`outline-none border-2 ${
                  disabled ? 'border-gray-100/70' : 'border-gray-100'
                } w-[20px] h-[20px] rounded-full ${
                  selected.includes(option.id) ? 'bg-white' : 'bg-transparent'
                } appearance-none main-transition-colors focus-visible:ring-2 ring-blue ring-offset-1`}
                disabled={disabled}
                checked={selected.includes(option.id)}
                value={option.caption}
                onClick={() => onChange(option.id)}
                onChange={() => {}}
                type="radio"
              />
              <svg
                width={20}
                height={20}
                className={`absolute main-transition-colors ${
                  selected.includes(option.id) ? 'fill-green' : 'fill-transparent'
                } ${disabled && 'opacity-70'}`}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.084 10.042C20.084 15.588 15.588 20.084 10.042 20.084C4.49594 20.084 0 15.588 0 10.042C0 4.49594 4.49594 0 10.042 0C15.588 0 20.084 4.49594 20.084 10.042ZM8.88044 15.3591L16.3309 7.90864C16.5839 7.65565 16.5839 7.24542 16.3309 6.99243L15.4147 6.07622C15.1618 5.82319 14.7515 5.82319 14.4985 6.07622L8.42231 12.1523L5.58549 9.31553C5.3325 9.06254 4.92227 9.06254 4.66924 9.31553L3.75303 10.2317C3.50004 10.4847 3.50004 10.895 3.75303 11.1479L7.96419 15.3591C8.21722 15.6121 8.62741 15.6121 8.88044 15.3591Z"
                />
              </svg>
              <span
                className={`main-transition-colors ${
                  selected.includes(option.id) ? 'text-darkblue' : 'text-darkblue/50'
                } ${disabled && 'opacity-70'}`}
              >
                {option.caption}
              </span>
            </label>
          </li>
        ))}
      </ul>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

export default DeadlineSettingsRadioGroup
