import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import { useDeviceMode } from 'modules/funnels/funnel/context/device-mode-context'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import ChangeIconNew from 'modules/funnels/funnel/icons/change-icon-new'
import React, { useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useWindowSize from 'shared/hooks/use-window-size'
import EyeIcon from 'shared/icons/eye-icon'
import EditIcon from '../../icons/edit-icon'

interface StepConfigurationTabLayoutProps {
  children: JSX.Element
  viewLinkUrl: string
  editLinkUrl: string
  onChangeTemplate: () => Promise<void>
  additionalAction?: JSX.Element
  customEditButton?: JSX.Element
  customViewButton?: JSX.Element
}

function StepConfigurationTabLayout({
  children,
  viewLinkUrl,
  editLinkUrl,
  onChangeTemplate,
  additionalAction,
  customEditButton,
  customViewButton,
}: StepConfigurationTabLayoutProps) {
  const { t } = useLocoTranslation()
  const [changeTemplateConfirmationOpen, setChangeTemplateConfirmationOpen] = useState(false)
  const { isMobile } = useDeviceMode()
  const { width } = useWindowSize()
  const isDesktopMode = width > 1170

  return (
    <>
      <div
        className={`flex ${
          isDesktopMode ? 'flex-row' : 'flex-col-reverse'
        } lg:gap-5 xl:gap-10 gap-7 lg:px-[15px] px-[15px] lg:pb-7 pb-5 ${
          isMobile ? 'bg-white rounded-lg' : ''
        }`}
      >
        <div className="lg:w-4/5 w-full">{children}</div>
        <div className="flex flex-col gap-4 xl:ml-auto">
          {customViewButton ? (
            customViewButton
          ) : (
            <PrimaryButton
              className="!justify-start"
              href={viewLinkUrl}
              target={'_blank'}
              data-test-element={'view-funnel-step-button'}
            >
              <EyeIcon className={'w-[20px] h-[20px] fill-white'} />
              <span className="text-start">
                {t('dashboard.funnel.configuration.common.view_funnel_step')}
              </span>
            </PrimaryButton>
          )}
          {customEditButton ? (
            customEditButton
          ) : (
            <PrimaryButton
              className="!justify-start"
              href={editLinkUrl}
              target={'_self'}
              data-test-element={'edit-page-funnel-step-button'}
            >
              <EditIcon className={'w-[18px] h-[18px] fill-white'} />
              <span className="text-start">
                {t('dashboard.funnel.configuration.common.edit_page')}
              </span>
            </PrimaryButton>
          )}
          <PrimaryButton
            className="!justify-start"
            onClick={() => setChangeTemplateConfirmationOpen(true)}
            data-test-element={'change-page-template-funnel-step-button'}
          >
            <ChangeIconNew className={'w-[18px] h-[18px] fill-white'} />
            <span className="text-start">
              {t('dashboard.funnel.configuration.common.change_page_template')}
            </span>
          </PrimaryButton>
          {additionalAction}
        </div>
        <MobileSettingsHeader type={FunnelStepSettingTabsEnum.stepConfiguration} />
      </div>
      <ConfirmationModal
        confirmationText={t('dashboard.funnel.configuration.common.change_page_template_confirm')}
        opened={changeTemplateConfirmationOpen}
        onClose={() => setChangeTemplateConfirmationOpen(false)}
        onConfirm={() => onChangeTemplate()}
      />
    </>
  )
}

export default StepConfigurationTabLayout
