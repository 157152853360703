import { chooseSplitTestVariant } from 'modules/funnels/funnel/api/funnel-api'
import { useBTestFunnelSteps } from 'modules/funnels/funnel/hooks/use-b-test-funnel-step'
import { useFunnelStepTests } from 'modules/funnels/funnel/hooks/use-funnel-step-tests'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useGoalTestFunnelSteps } from 'modules/funnels/funnel/hooks/use-goal-test-funnel-step'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  ChooseSplitTestVariantErrorsInterface,
  ChooseSplitTestVariantInterface,
} from 'modules/funnels/funnel/types/funnel-tests-interface'
import {
  getAllowedStepsAsGoal,
  getAllowedStepsAsVariant,
} from 'modules/funnels/funnel/utils/split-tests-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import CreateModal from 'shared/components/create-modal'
import FormSelect from 'shared/components/form-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'

const defaultValues: ChooseSplitTestVariantInterface = {
  goalFunnelStep: undefined,
  funnelStepB: undefined,
}

const defaultErrors: ChooseSplitTestVariantErrorsInterface = {
  fields: {
    goalFunnelStep: '',
    funnelStepB: '',
  },
}
interface ChooseTestVariantModalProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface
}

function ChooseTestVariantModal({ funnelId, funnelStep }: ChooseTestVariantModalProps) {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { mutateSplitTest } = useFunnelStepTests(funnelStep.id)

  const [shouldFetchFieldsData, setShouldFetchFieldsData] = useState(false)
  const { bFunnelStepsData } = useBTestFunnelSteps(funnelStep?.id, shouldFetchFieldsData)
  const { goalFunnelStepsData } = useGoalTestFunnelSteps(funnelStep?.id, shouldFetchFieldsData)

  const onCreate = async (data: ChooseSplitTestVariantInterface) => {
    await fetchWithFieldErrors(async () => {
      if (funnelStep) {
        const response = await chooseSplitTestVariant(funnelStep.id, data)
        await mutateSplitTest(response.data, false)
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(funnelStep => {
              const isFunnelStepAOrB =
                funnelStep.id === response.data.funnelStepA.id ||
                funnelStep.id === response.data.funnelStepB.id
              const isGoalFunnelStep = funnelStep.id === response.data.goalFunnelStep.id

              const currentSplitTest = isFunnelStepAOrB
                ? {
                    id: response.data.id,
                    funnelStepA: {
                      id: response.data.funnelStepA.id,
                      page: response.data.funnelStepA.page,
                    },
                    funnelStepB: {
                      id: response.data.funnelStepB.id,
                      page: response.data.funnelStepB.page,
                    },
                  }
                : null
              if (
                funnelStep.id === response.data.funnelStepA.id ||
                funnelStep.id === response.data.funnelStepB.id
              ) {
                return {
                  ...funnelStep,
                  currentSplitTest: {
                    id: response.data.id,
                    funnelStepA: {
                      id: response.data.funnelStepA.id,
                      page: response.data.funnelStepA.page,
                    },
                    funnelStepB: {
                      id: response.data.funnelStepB.id,
                      page: response.data.funnelStepB.page,
                    },
                  },
                }
              }
              return {
                ...funnelStep,
                currentSplitTest,
                goalFunnelStepOfAnySplitTest: isGoalFunnelStep,
              }
            })
          }
        }, false)
      }
    }, setErrors)
  }
  return (
    <>
      <PrimaryButton
        onClick={e => {
          e.preventDefault()
          setOpened(true)
          setShouldFetchFieldsData(true)
        }}
      >
        {t('dashboard.funnel.split_tests.choose_variant')}
      </PrimaryButton>
      <CreateModal
        fullCaption={t('dashboard.funnel.split_tests.choose_variant_modal_title')}
        opened={opened}
        onClose={() => setOpened(false)}
        defaultValues={defaultValues}
        needToast={false}
        creatable={[
          {
            field: 'funnelStepB',
            onRender: (value, onChange) => (
              <FormSelect
                key="funnel-step-b"
                className={'sm:min-w-0'}
                data={getAllowedStepsAsVariant(bFunnelStepsData)}
                label={t('dashboard.funnel.split_tests.variant_funnel_step')}
                value={value}
                onChange={value => onChange(value)}
                placeholder={t('dashboard.funnel.split_tests.choose_page')}
                required
                withoutCloseIcon
                error={errors.fields.funnelStepB}
              />
            ),
          },
          {
            field: 'goalFunnelStep',
            onRender: (value, onChange) => (
              <FormSelect
                key="funnel-step-goal-page"
                className={'sm:min-w-0'}
                data={getAllowedStepsAsGoal(goalFunnelStepsData)}
                label={t('dashboard.funnel.split_tests.goal_funnel_step')}
                value={value}
                onChange={value => onChange(value)}
                placeholder={t('dashboard.funnel.split_tests.choose_page')}
                required
                withoutCloseIcon
                error={errors.fields.goalFunnelStep}
              />
            ),
          },
        ]}
        onCreate={onCreate}
        requiredFields={['funnelStepB', 'goalFunnelStep']}
      />
    </>
  )
}

export default ChooseTestVariantModal
