import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRulesTriggersType } from 'modules/automation-rules/triggers/types/automation-rule-trigger-interface'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { FunnelTriggerDataInterface } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'

export const splitRelatedAndUnrelatedTriggers = <
  T extends AutomationRulesTriggersEditType | AutomationRulesTriggersType,
>(
  triggers: Array<T>,
  funnelStepId: FunnelTriggerDataInterface['funnelStepId'],
) => {
  const relatedTriggers: Array<T> = []
  const unrelatedTriggers: Array<T> = []
  triggers.forEach(trigger => {
    switch (trigger.type) {
      case AutomationRuleTriggersEnum.form_subscribed:
      case AutomationRuleTriggersEnum.new_sale:
      case AutomationRuleTriggersEnum.sale_canceled:
      case AutomationRuleTriggersEnum.page_visited:
      case AutomationRuleTriggersEnum.subscription_payment_failed: {
        trigger.funnelStep.id === funnelStepId
          ? relatedTriggers.push(trigger)
          : unrelatedTriggers.push(trigger)
        break
      }
      case AutomationRuleTriggersEnum.registered_to_webinar:
        trigger.funnelStep?.id === funnelStepId
          ? relatedTriggers.push(trigger)
          : unrelatedTriggers.push(trigger)
        break
      default:
        unrelatedTriggers.push(trigger)
    }
  })

  return { relatedTriggers, unrelatedTriggers }
}
