import React, { FC, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import SaveIcon from 'shared/icons/save-icon'

type Props = {
  onSave: () => void
  disabled: boolean
  isFetching: boolean
  isCreate: boolean
}

export const DigitalProductSave: FC<Props> = ({ onSave, disabled, isFetching, isCreate }) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)

  return (
    <>
      <PrimaryButton
        width={'large'}
        className={'w-fit'}
        isFetching={isFetching}
        onClick={isCreate ? onSave : () => setOpened(true)}
        disabled={disabled}
      >
        <SaveIcon />
        {t('global.save')}
      </PrimaryButton>
      <ConfirmationModal
        confirmationText={t('dashboard.offer.digital_product.edit_warning')}
        opened={opened}
        onClose={() => setOpened(false)}
        onConfirm={onSave}
      />
    </>
  )
}
