import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { RemoteFormInterface } from 'modules/funnels/funnel/types/remote-form-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useRemoteFormSettings(id: FunnelStepId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, RemoteFormInterface>(
    { method: RequestMethodsEnum.get },
  )

  const { data: remoteFormSettings, mutate: mutateRemoteFormSettings } = useSWR(() => {
    if (user) {
      return `${FUNNELS_API}/step-remote-form/${id}`
    }
    return null
  }, fetcher)

  return { remoteFormSettings, mutateRemoteFormSettings }
}
