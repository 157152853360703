import { Float } from '@headlessui-float/react'
import { Menu } from '@headlessui/react'
import { nonDraggableProps } from 'modules/courses/courses/curriculum/utils/non-draggable-props'
import {
  duplicateFunnelStep,
  moveFunnelStep,
  removeFunnelStep,
} from 'modules/funnels/funnel/api/funnel-api'
import { useClearFunnelCache } from 'modules/funnels/funnel/hooks/use-clear-funnel-cache'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useFunnels } from 'modules/funnels/funnel/hooks/use-funnels'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getAvailableFunnelsData } from 'modules/funnels/funnel/utils/funnel-actions-utils'
import { getFunnelStepUrl } from 'modules/funnels/funnel/utils/get-funnel-step-url'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import FormSelect from 'shared/components/form-select'
import { ActionMenuItem } from 'shared/components/table/components/action-menu-item'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import DotsIcon from 'shared/icons/dots-icon'

interface FunnelStepActionsProps {
  funnelStep: FunnelStepInterface
  selectFunnelStep?: (funnelId: FunnelStepInterface) => void
}

const defaultFunnelState: {
  funnel?: number
} = {
  funnel: undefined,
}

function FunnelStepActions({ funnelStep, selectFunnelStep }: FunnelStepActionsProps) {
  const { t } = useLocoTranslation()
  const router = useRouter()
  const funnelId = parseInt(router.query.funnelId as string) as FunnelId
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const hash = router.asPath.split('#step')[1]

  const [shouldFetchFunnelsList, setShouldFetchFunnelsList] = useState(false)
  const [isMoveOpen, setIsMoveOpen] = useState(false)
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false)

  const caption = t('dashboard.funnel.funnel_step')

  const { funnelsList } = useFunnels(funnelId, shouldFetchFunnelsList)

  const funnelListData = getAvailableFunnelsData(funnelsList)
  const clearFunnelCache = useClearFunnelCache()

  const onRemoveFunnelStep = async (funnelStepId: number) => {
    try {
      await removeFunnelStep(funnelStepId)
      await mutateFunnelStepsList(data => {
        if (data) {
          return data.filter(funnelStep => funnelStep.id !== funnelStepId)
        }
      }, false)
    } catch (e) {
      throw e
    }
  }

  const onDuplicateFunnel = async (funnelId: number) => {
    try {
      const response = await duplicateFunnelStep(funnelId)
      await mutateFunnelStepsList(data => {
        if (data) {
          const initialFunnelStepIndex = data.findIndex(funnelStep => funnelStep.id === funnelId)
          const newData = [...data]
          newData.splice(initialFunnelStepIndex + 1, 0, response.data)
          return newData
        }
      }, false)
      selectFunnelStep && selectFunnelStep(response.data)
    } catch (e) {
      throw e
    }
  }

  const onMoveFunnelStep = async (moveData: { funnel?: number }) => {
    try {
      await moveFunnelStep(funnelStep.id, moveData)
      await mutateFunnelStepsList(data => {
        if (data) {
          return data.filter(dataFunnelStep => dataFunnelStep.id !== funnelStep.id)
        }
      }, false)
      await clearFunnelCache(moveData.funnel, funnelStep.id)
      if (moveData.funnel) {
        await router.push(getFunnelStepUrl(moveData.funnel, Number(hash)))
      }
    } catch (e) {
      throw e
    }
  }

  const { show, delayClose, delayOpen, close } = useFloatHover()

  return (
    <div className={`flex items-start justify-end`}>
      <div {...nonDraggableProps}>
        <Menu>
          {({ open }) => (
            <Float
              show={show || open}
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              portal
              placement="left-start"
              offset={4}
              tailwindcssOriginClass={true}
              className={'flex'}
            >
              <Menu.Button
                onMouseEnter={delayOpen}
                onMouseLeave={delayClose}
                onClick={() => (show ? delayClose() : delayOpen())}
                className={`h-[30px] lg:h-full outline-none main-transition-colors hover:bg-blue group focus-visible:ring-2 ring-blue ring-offset-1 rounded-lg px-3 lg:py-2 py-1 ${
                  show || open ? 'bg-blue' : ''
                }`}
              >
                <DotsIcon className={`group-hover:fill-white ${(show || open) && 'fill-white'}`} />
              </Menu.Button>
              <Menu.Items
                onMouseEnter={delayOpen}
                onMouseLeave={delayClose}
                className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden focus:outline-none"
              >
                <ActionMenuItem
                  label={t('global.duplicate')}
                  onClick={e => {
                    e.stopPropagation()
                    setIsDuplicateOpen(true)
                    close()
                  }}
                />
                <ActionMenuItem
                  label={t('dashboard.funnel.move_funnel_step')}
                  onClick={e => {
                    e.stopPropagation()
                    setIsMoveOpen(true)
                    setShouldFetchFunnelsList(true)
                    close()
                  }}
                />
                <ActionMenuItem
                  label={t('global.remove')}
                  onClick={e => {
                    e.stopPropagation()
                    setIsRemoveOpen(true)
                    close()
                  }}
                />
              </Menu.Items>
            </Float>
          )}
        </Menu>
        <Confirmation
          opened={isRemoveOpen}
          onClose={() => setIsRemoveOpen(false)}
          onConfirm={() => onRemoveFunnelStep(funnelStep.id)}
          confirmationText={t('dashboard.actions.remove_confirmation', {
            module: caption?.toLowerCase(),
          })}
          toastCaption={t('dashboard.actions.removed', { module: caption?.toLowerCase() })}
        />
        <EditModal
          fullCaption={t('dashboard.funnel.move_funnel_step_modal_title')}
          opened={isMoveOpen}
          onClose={() => {
            setIsMoveOpen(false)
          }}
          instance={defaultFunnelState}
          editable={[
            {
              field: 'funnel',
              onRender: (value, onChange, _, __, isPreFetching) => (
                <FormSelect
                  isPreFetching={isPreFetching}
                  key={'funnel-step-move-select'}
                  label={t('dashboard.funnel.move_funnel_step_title')}
                  data={funnelListData}
                  onChange={data => onChange(data)}
                  value={value}
                  required
                />
              ),
            },
          ]}
          onEdit={data => onMoveFunnelStep(data)}
        />
        <Confirmation
          opened={isDuplicateOpen}
          onClose={() => setIsDuplicateOpen(false)}
          onConfirm={() => onDuplicateFunnel(funnelStep.id)}
          confirmationText={t('dashboard.funnel.confirm_duplicate')}
          toastCaption={t('global.duplicated')}
        />
      </div>
    </div>
  )
}

export default FunnelStepActions
