export enum FunnelStepSettingTabsEnum {
  stepConfiguration = 'stepConfiguration',
  automationRules = 'automationRules',
  tests = 'tests',
  stats = 'stats',
  leads = 'leads',
  sales = 'sales',
  deadlineSettings = 'deadlineSettings',
  emails = 'emails',
}
