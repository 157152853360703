import React from 'react'

function OptInThankYouFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_209_11)">
        <path
          d="M13.7812 2.25H12.5437C12.1725 0.95625 10.98 0 9.5625 0C8.145 0 6.9525 0.95625 6.58125 2.25H5.34375C4.8825 2.25 4.5 2.6325 4.5 3.09375V4.78125C4.5 5.86125 5.38875 6.75 6.46875 6.75H12.6562C13.7362 6.75 14.625 5.86125 14.625 4.78125V3.09375C14.625 2.6325 14.2425 2.25 13.7812 2.25Z"
          fill="#CBD5E1"
        />
        <path
          d="M16.0313 3.375H15.75V4.78125C15.75 6.49125 14.3663 7.875 12.6563 7.875H6.46875C4.75875 7.875 3.375 6.49125 3.375 4.78125V3.375H3.09375C1.38375 3.375 0 4.75875 0 6.46875V20.5312C0 22.2412 1.38375 23.625 3.09375 23.625H10.5075C9.945 22.4212 9.6075 21.0938 9.57375 19.6875H4.21875C3.7575 19.6875 3.375 19.305 3.375 18.8438C3.375 18.3825 3.7575 18 4.21875 18H9.66375C9.765 17.3137 9.93375 16.6613 10.1588 16.0312H4.21875C3.7575 16.0312 3.375 15.6487 3.375 15.1875C3.375 14.7263 3.7575 14.3438 4.21875 14.3438H10.9688C11.4075 13.6238 11.925 12.96 12.5325 12.375H4.21875C3.7575 12.375 3.375 11.9925 3.375 11.5312C3.375 11.07 3.7575 10.6875 4.21875 10.6875H14.8388C16.1325 10.0125 17.5838 9.6075 19.125 9.57375V6.46875C19.125 4.75875 17.7413 3.375 16.0313 3.375Z"
          fill="#CBD5E1"
        />
        <path
          d="M19.4062 11.8125C15.219 11.8125 11.8125 15.219 11.8125 19.4062C11.8125 23.5935 15.219 27 19.4062 27C23.5935 27 27 23.5935 27 19.4062C27 15.219 23.5935 11.8125 19.4062 11.8125ZM23.0648 17.8976L19.1273 22.3976C18.9225 22.6316 18.63 22.7711 18.3184 22.7812C18.306 22.7812 18.2936 22.7812 18.2812 22.7812C17.9831 22.7812 17.6974 22.6631 17.4859 22.4516L15.2359 20.2016C14.796 19.7618 14.796 19.0507 15.2359 18.6109C15.6758 18.171 16.3867 18.171 16.8266 18.6109L18.2261 20.0104L21.3716 16.4149C21.7811 15.9491 22.491 15.8996 22.959 16.3091C23.427 16.7186 23.4743 17.4296 23.0648 17.8976Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_11">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OptInThankYouFunnelStepIcon
