import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { FUNNEL_STEP_API } from 'modules/funnels/funnel/api/funnel-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const useFunnelStepTriggers = (funnelStepId?: FunnelStepId) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    AutomationRuleTriggersEnum[]
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (!user || !funnelStepId) return
    return `${FUNNEL_STEP_API}/${funnelStepId}/automation-rule-triggers`
  }, fetcher)
}
