import { updateFunnelEmail } from 'modules/funnels/funnel/components/email-tab/api/funnel-email-api'
import { EventTypeEnum } from 'modules/funnels/funnel/components/email-tab/enums/funnel-emails-enum'
import { useFunnelEmail } from 'modules/funnels/funnel/components/email-tab/hooks/use-funnel-emails'
import { FunnelEmailsErrorInterface } from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-error-itnerface'
import {
  FunnelEmailEditInterface,
  FunnelEmailViewInterface,
  FunnelEmailsApiInterface,
  FunnelEmailsInterface,
} from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { SecondaryButton } from 'shared/components/button'
import DashboardMailEditor from 'shared/components/dashboard-mail-editor'
import { updateAttachments } from 'shared/components/dashboard-mail-editor/api/email-api'
import { useUnsavedEmail } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import {
  AttachmentFileInterface,
  EmailBaseInterface,
  EmailErrorType,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { getStyledBody } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import TimeDelayPicker, {
  TimeDelayPickerProps,
} from 'shared/components/date-picker/time-delay-picker'
import { TimeType, TimeTypeState } from 'shared/components/date-picker/time-delay-picker/constants'
import {
  convertSecondsToTimeValue,
  convertTimeValueToSeconds,
} from 'shared/components/date-picker/time-delay-picker/utils'
import { TimePicker } from 'shared/components/date-picker/time-picker'
import FormSelect from 'shared/components/form-select'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { isValidEmail } from 'shared/utils/email-validation'
import { KeyedMutator } from 'swr'

const defaultErrors: EmailErrorType = {
  body: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  common: [],
  delay: '',
  attachments: '',
}

interface FunnelEmailUpdateProps {
  opened: boolean
  onClose: () => void
  instance: FunnelEmailsInterface
  funnelStepId?: FunnelStepId
  mutateEmails: KeyedMutator<FunnelEmailsApiInterface>
}

const FunnelEmailUpdate = ({
  opened,
  onClose,
  instance,
  funnelStepId,
  mutateEmails,
}: FunnelEmailUpdateProps) => {
  const { t } = useLocoTranslation()
  const [isFetching, setIsFetching] = useState(false)
  const [selectedEventType, setSelectedEventType] = useState<EventTypeEnum>(
    EventTypeEnum.registration_to_webinar,
  )
  const [chosenTimeDelay, setChosenTimeDelay] = useState<number>(0)
  const [chosenSendingEmailTime, setChosenSendingEmailTime] = useState<number | null>(null)
  const [selectedTimeBeforeAfter, setSelectedTimeBeforeAfter] = useState<TimeTypeState | undefined>(
    TimeTypeState.after,
  )
  const [selectedTimeType, setSelectedTimeType] = useState<TimeType>(TimeType.MINUTES)
  const [tempState, setTempState] = useState<EmailBaseInterface>()
  const [errors, setErrors] = useState<EmailErrorType>(defaultErrors)
  const [attachmentsTempState, setAttachmentsTempState] = useState<
    (AttachmentFileInterface | File)[]
  >([])
  const { emailData, mutateEmailData } = useFunnelEmail(
    funnelStepId,
    instance.webinarNotification.id,
    opened,
  )

  const eventSelector = [
    {
      caption: t('dashboard.funnel.emails_registration_to_webinar'),
      id: EventTypeEnum.registration_to_webinar,
    },
    {
      caption: t('dashboard.funnel.emails_webinar_start'),
      id: EventTypeEnum.webinar_start,
    },
  ]

  useEffect(() => {
    if (emailData) {
      const delay = convertSecondsToTimeValue(emailData.delay)

      setTempState(prev => ({
        ...prev,
        body: emailData.mailing.currentEmail.body,
        subject: emailData.mailing.currentEmail.subject,
        previewText: emailData.mailing.currentEmail.previewText,
        fromEmail: emailData.mailing.currentEmail.fromEmail,
        fromName: emailData.mailing.currentEmail.fromName,
      }))
      if (delay) {
        setSelectedTimeBeforeAfter(delay.beforeAfter)
        setSelectedTimeType(delay.timeType)
        setChosenTimeDelay(delay.timeValue)
      }
      if (emailData.mailing.currentEmail.attachments) {
        setAttachmentsTempState(emailData.mailing.currentEmail.attachments)
      }
      setSelectedEventType(emailData.event)
      setChosenSendingEmailTime(emailData.certainTime)
    }
  }, [emailData, opened])

  const onUpdate = async () => {
    if (!emailData) return
    try {
      if (tempState && funnelStepId) {
        setErrors(defaultErrors)
        if (!isValidEmail(tempState.fromEmail)) {
          return setErrors(prev => ({
            ...prev,
            fromEmail: t('dashboard.validation.email_not_valid'),
          }))
        }
        setIsFetching(true)
        const newData: FunnelEmailEditInterface = {
          mailing: { currentEmail: { ...tempState, body: getStyledBody(tempState.body) } },
          event: selectedEventType,
          delay: convertTimeValueToSeconds(
            chosenTimeDelay,
            selectedTimeType,
            selectedTimeBeforeAfter,
          ),
          certainTime: chosenSendingEmailTime,
        }
        const emailResponse = await updateFunnelEmail(
          funnelStepId,
          instance.webinarNotification.id,
          newData,
        )
        if (emailResponse.data) {
          const response = await updateAttachments(
            emailResponse.data.mailing.currentEmail.id,
            attachmentsTempState,
          )
          const updatedData: FunnelEmailViewInterface = {
            ...newData,
            mailing: {
              id: emailData.mailing.id,
              currentEmail: {
                id: emailData?.mailing.currentEmail.id,
                ...tempState,
                body: getStyledBody(tempState.body),
                attachments: response.data.attachments,
              },
            },
          }
          await mutateEmailData(data => {
            if (data) {
              return { ...data, ...updatedData }
            }
          }, false)
          await mutateEmails(
            data =>
              data && {
                ...data,
                items: data.items.map(el => {
                  if (el.webinarNotification.mailing.id === emailResponse.data.mailing.id) {
                    el.webinarNotification.mailing.currentEmail.subject =
                      emailResponse.data.mailing.currentEmail.subject
                  }
                  return el
                }),
              },
            false,
          )
        }
        await removeDbRecord()
        toast.success(t('global.changes_saved'))
        setIsFetching(false)
        onClosed()
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const funnelEmailSettingsErrors = e.errors as unknown as FunnelEmailsErrorInterface
        if (funnelEmailSettingsErrors) {
          const fieldErrors = !Array.isArray(funnelEmailSettingsErrors.fields)
            ? funnelEmailSettingsErrors.fields
            : undefined
          if (fieldErrors) {
            setErrors(prev => ({
              ...prev,
              body: fieldErrors.mailing?.currentEmail?.body?.join(''),
              fromEmail: fieldErrors.mailing?.currentEmail?.fromEmail?.join(''),
              fromName: fieldErrors.mailing?.currentEmail?.fromName?.join(''),
              subject: fieldErrors.mailing?.currentEmail?.subject?.join(''),
              previewText: fieldErrors.mailing?.currentEmail?.previewText?.join(''),
              delay: fieldErrors?.delay?.join(''),
            }))
          }
        }
        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const renderTime = () => {
    return (
      <>
        <FormSelect
          withoutCloseIcon
          label={t('global.event_caption')}
          data={eventSelector}
          value={selectedEventType}
          onChange={data => {
            if (data !== selectedEventType) {
              setSelectedTimeBeforeAfter(TimeTypeState.after)
              setChosenSendingEmailTime(null)
              setChosenTimeDelay(0)
            }
            setSelectedEventType(data)
          }}
          isPreFetching={!emailData}
        />
        <TimeDelayPicker
          required
          selectedTimeType={selectedTimeType}
          selectedTimeBeforeAfter={selectedTimeBeforeAfter}
          selectedTimeValue={chosenTimeDelay}
          className={'pt-5'}
          classNameInputInteger={'lg:min-w-[40px]'}
          label={t('dashboard.funnel.emails_webinar.delay')}
          onChange={onChooseTimeDelay}
          error={errors.delay}
          withTimeAfterBeforeSelect={selectedEventType === EventTypeEnum.webinar_start}
          isPreFetching={!emailData}
        />
        {(selectedEventType === EventTypeEnum.registration_to_webinar ||
          selectedTimeBeforeAfter === TimeTypeState.after) && (
          <TimePicker
            className={'pt-5'}
            label={t('dashboard.funnel.emails_webinar.send_at_certain_time')}
            seconds={chosenSendingEmailTime}
            onChangeSeconds={setChosenSendingEmailTime}
            isPreFetching={!emailData}
          />
        )}
      </>
    )
  }

  const onChooseTimeDelay = useCallback<TimeDelayPickerProps['onChange']>(
    (timeDelay, timeType, timeBeforeAfter) => {
      setChosenTimeDelay(timeDelay)
      setSelectedTimeType(timeType)
      setSelectedTimeBeforeAfter(timeBeforeAfter)
      if (timeBeforeAfter !== selectedTimeBeforeAfter) {
        setChosenSendingEmailTime(null)
      }
    },
    [],
  )

  const { setCkeditorRef, CkeditorUnsavedEmail, removeDbRecord, setNeedToShowModal } =
    useUnsavedEmail({
      currentBody: tempState?.body,
      initialBody: emailData?.mailing.currentEmail.body,
      mailingId: emailData?.mailing.id,
    })
  const onChangeEmail = (value: EmailBaseInterface) => setTempState(value)

  const onClosed = () => {
    onClose()
    setErrors(defaultErrors)
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClosed}
        size={ModalSizeEnum.big}
        title={t('dashboard.funnel.emails_edit.send.email')}
        isFetching={isFetching}
        afterEnter={() => setNeedToShowModal(true)}
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <DashboardMailEditor
            sidebarItemBlock={{
              title: t('global.settings'),
              Component: renderTime(),
            }}
            inModal={true}
            isPreFetching={!emailData}
            tempState={tempState}
            onChangeEmail={onChangeEmail}
            attachmentsTempState={attachmentsTempState}
            setAttachmentsTempState={setAttachmentsTempState}
            errors={errors}
            setCkeditorRef={setCkeditorRef}
            additionalSubstitutes={[
              {
                name: t('dashboard.funnel.emails_edit.send.email_webinar.replay.link'),
                slug: 'webinar_show_link',
              },
              {
                name: t('dashboard.funnel.emails_edit.send.email_webinar.replay.link'),
                slug: 'webinar_replay_link',
              },
            ]}
          />
          <div className="flex justify-center gap-4">
            <SecondaryButton width="large" onClick={onClose}>
              {t('confirmation_modal.cancel')}
            </SecondaryButton>
            <PrimaryButton
              width="large"
              onClick={e => {
                e.preventDefault()
                onUpdate()
              }}
              isFetching={isFetching}
              disabled={isFetching}
              type={'submit'}
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
        </form>
        {CkeditorUnsavedEmail}
      </Modal>
    </>
  )
}

export default FunnelEmailUpdate
