import React from 'react'

function BoxIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="#00A0FF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2505 0.456055L19.5374 3.60469L10.7068 6.77941L2.83755 3.65222L11.2505 0.456055ZM1.72305 4.28464L9.99009 7.57033L8.83806 9.69065L0.5 6.48277L1.72305 4.28464ZM2.24525 8.21085L8.90716 10.7799C8.96625 10.8025 9.01829 10.813 9.07738 10.813C9.25659 10.813 9.47687 10.7178 9.56552 10.5515L10.3072 9.2786V18.8893L2.2452 15.6045L2.24525 8.21085ZM20.1598 15.5903L11.2022 18.9163V8.906L12.5145 10.7952C12.6085 10.9314 12.7633 11.0085 12.923 11.0085C12.9821 11.0085 13.0302 10.9975 13.0886 10.9759L20.1605 8.25098L20.1598 15.5903ZM13.1075 9.90308L11.4807 7.56424L20.7486 4.2319L22 6.48301L13.1075 9.90308Z"
      />
    </svg>
  )
}

export default BoxIcon
