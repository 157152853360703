import { FUNNELS_API, updateFunnel } from 'modules/funnels/api/funnels-api'
import { useOfferFormSettingsMutation } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/hooks/use-offer-form-settings'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import {
  FunnelApiInterface,
  FunnelEditableErrorsInterface,
  FunnelEditableInterface,
  FunnelId,
} from 'modules/funnels/types/funnel-interface'
import PaymentMethods from 'modules/payment-methods/components/payment-methods'
import { useCurrency } from 'modules/payment-methods/hooks/use-currency'
import { usePaymentMethods } from 'modules/payment-methods/hooks/use-payment-methods'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import EditModal from 'shared/components/edit-modal'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import Popover from 'shared/components/popover'
import TransLoco from 'shared/components/trans-loco'
import { useCurrencyData } from 'shared/hooks/use-currency-data'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useMutationUpdate } from 'shared/hooks/use-mutation-update'
import { useUpdateData } from 'shared/hooks/use-update-data'
import useUser from 'shared/hooks/use-user'
import useUserDomains from 'shared/hooks/use-user-domains'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { getDomainInfoLink } from 'shared/utils/get-domain-info-link'

const defaultErrors: FunnelEditableErrorsInterface = {
  fields: {
    name: '',
    domain: '',
    currency: '',
    paymentMethods: '',
  },
}

const defaultValues: FunnelEditableInterface = {
  name: '',
  domain: undefined,
  currency: undefined,
  paymentMethods: undefined,
}

function EditFunnelModal() {
  const router = useRouter()
  const [errors, setErrors] = useState(defaultErrors)
  const [tempState, setTempState] = useState(defaultValues)
  const [opened, setOpened] = useState(false)

  const { t } = useLocoTranslation()
  const funnelId = parseInt(router.query.funnelId as string) as FunnelId

  const { funnelStepsList } = useFunnelStepsList(funnelId)

  const { clearCurrencyRelatedFields } = useOfferFormSettingsMutation()

  const { currentCurrency, setCurrentCurrency, showToast } = useCurrency({
    currencyChangeMessage: t('dashboard.funnel.currency_change_info'),
  })
  const { data: paymentMethodsData } = usePaymentMethods(currentCurrency)
  const { user } = useUser()
  const { userDomainsSelect } = useUserDomains()
  const { funnel, mutateFunnel } = useFunnel(funnelId)
  const currencyData = useCurrencyData()
  const mutationUpdate = useMutationUpdate()

  const updateData = useUpdateData({
    api: `${FUNNELS_API}/${funnelId}`,
    //TODO maybe need to add more constant api's later
    constantApi: [
      `${FUNNELS_API}/list`,
      `${FUNNELS_API}/get-payment-methods`,
      `${FUNNELS_API}/step-remote-form`,
      `${FUNNELS_API}/${funnelId}/steps/list`,
      `${FUNNELS_API}/${funnelId}/steps/`,
      `${FUNNELS_API}/${funnelId}/stats/`,
      `${FUNNELS_API}/${funnelId}/leads/`,
    ],
  })

  const onUpdateFunnel = async (data: FunnelEditableInterface) => {
    await fetchWithFieldErrors(async () => {
      const newData: FunnelEditableInterface = {
        name: data.name,
        paymentMethods: data.paymentMethods,
        domain: data.domain,
        currency: data.currency,
      }
      const response = await updateFunnel(Number(funnelId), newData)
      await updateData(
        false,
        undefined,
        {
          mutationData: funnel,
          onMutate: async () => {
            await mutateFunnel(data => {
              if (data) {
                return {
                  ...data,
                  name: response.data.name,
                  domain: response.data.domain,
                  paymentMethods: response.data.paymentMethods,
                  currency: response.data.currency,
                }
              }
            }, false)
          },
        },
        {
          id: funnel?.id,
          onMutate: async apiKey => {
            await mutationUpdate<FunnelApiInterface>(
              apiKey,
              data =>
                data && {
                  hasMore: data.hasMore,
                  items: data.items.map(item => {
                    if (item.id === response.data.id)
                      item = {
                        ...item,
                        name: response.data.name,
                      }
                    return item
                  }),
                },
            )
          },
        },
      )
      if (funnel?.currency !== response.data.currency) {
        if (!funnelStepsList) return
        await clearCurrencyRelatedFields(funnelStepsList.map(step => step.id))
      }
    }, setErrors)
  }

  useEffect(() => {
    if (funnel) {
      setTempState(prev => {
        return {
          ...prev,
          name: funnel.name,
          currency: funnel.currency,
          domain: funnel.domain.id,
          paymentMethods: funnel.paymentMethods,
        }
      })
      setCurrentCurrency(funnel.currency)
    }
  }, [funnel, setCurrentCurrency])

  return (
    <>
      <PrimaryButton
        onClick={() => {
          setOpened(true)
        }}
        disabled={!funnel}
        data-test-element={'funnel-settings-button'}
      >
        <SettingsIcon className={'fill-white'} />
        <span className={'hidden lg:flex'}>{t('dashboard.funnel.funnel_settings')}</span>
      </PrimaryButton>
      <EditModal
        fullCaption={t('dashboard.funnel.edit_funnel')}
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        onOpen={() => setCurrentCurrency(funnel?.currency)}
        instance={tempState}
        isPreFetching={!funnel || !paymentMethodsData}
        editable={[
          {
            field: 'name',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormInput
                key="funnel-edit-name"
                isPreFetching={isPreFetching}
                value={value}
                onChange={e => onChange(e.target.value)}
                label={t('global.name')}
                error={errors.fields.name}
              />
            ),
          },
          {
            field: 'domain',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormSelect
                isPreFetching={isPreFetching}
                key={'funnel-domain-edit'}
                label={
                  <div className={'flex gap-1 w-fit items-center'}>
                    {t('dashboard.funnel.funnel_domain')}
                    <Popover
                      label={
                        <TransLoco
                          t={t}
                          i18nKey={'dashboard.funnel.domain_tooltip'}
                          components={{
                            a: (
                              <a
                                rel="noreferrer"
                                target={'_blank'}
                                className="primary-link w-full"
                                href={getDomainInfoLink(user?.dashboardLocale)}
                              />
                            ),
                          }}
                        />
                      }
                      popoverClassName={'w-[200px] sm:w-[350px]'}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                }
                data={userDomainsSelect}
                onChange={data => onChange(data)}
                value={value}
                error={errors.fields.domain}
                withoutCloseIcon
              />
            ),
          },
          {
            field: 'currency',
            onRender: (value, _, state, __, isPreFetching, onChangeState) => (
              <FormSelect
                isPreFetching={isPreFetching}
                key={'funnel-currency-edit'}
                label={
                  <Hint label={t('dashboard.funnel.currency_change_info')}>
                    {t('global.currency')}
                  </Hint>
                }
                data={currencyData}
                onChange={data => {
                  onChangeState({
                    ...state,
                    paymentMethods: [],
                    currency: data,
                  })
                  setCurrentCurrency(data)
                  showToast()
                }}
                value={value}
                error={errors.fields.currency}
                withoutCloseIcon
              />
            ),
          },
          {
            field: 'paymentMethods',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <PaymentMethods
                isPreFetching={isPreFetching}
                key={'funnel-payment-methods-edit'}
                value={value}
                onChange={onChange}
                data={paymentMethodsData?.paymentMethods}
                error={errors.fields.paymentMethods}
              />
            ),
          },
        ]}
        onEdit={data => onUpdateFunnel(data)}
      />
    </>
  )
}

export default EditFunnelModal
