import en from 'date-fns/locale/en-US'
import React, { forwardRef, useEffect, useState } from 'react'
import { default as ReactDatePicker } from 'react-datepicker'
import { DateModeEnum } from 'shared/components/date-picker/enum/date-mode-enum'
import { setDateTill } from 'shared/components/date-picker/utills/set-date-till'
import useUser from 'shared/hooks/use-user'
import ArrowIonicLeftIcon from 'shared/icons/arrow-ionic-left-icon'
import ArrowIonicRightIcon from 'shared/icons/arrow-ionic-right-icon'
import CalendarIcon from 'shared/icons/calendar-icon'
import { setDateWithTimezone, toISOStringWithoutTimezone } from 'shared/utils/date-time-format'
import { getDate } from 'shared/utils/get-dates'
import { getLanguageCodeByLocale } from '../../enums/language-code-enum'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from '../form/field-error-and-description'

interface DatePickerProps {
  value: string | null
  onChange: (date: string | null) => void
  error?: string
  label?: string
  required?: boolean
  placeholder?: string
  className?: string
  description?: string
  maxDate?: Date
  minDate?: Date
  disabled?: boolean
  mode?: DateModeEnum
}

const DatePicker = ({
  value,
  onChange,
  error,
  label,
  required,
  placeholder,
  className,
  description,
  maxDate,
  minDate,
  disabled,
  mode = DateModeEnum.dateSince,
}: DatePickerProps) => {
  const { user } = useUser()
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    (value && setDateWithTimezone(value, user?.timezone)) || null,
  )
  const [selectedDateRaw, setSelectedDateRaw] = useState<string | null>()
  const [locale, setLocale] = useState<Locale>(en)

  const userMinDate = user ? getDate(user.createdAt, user.timezone) : null

  useEffect(() => {
    // We need it to fix this https://rollbar.com/systemeio/dashboard-frontend/items/1370/?item_page=0&#instances
    if (user && user.dashboardLocale) {
      if (user.dashboardLocale === 'en') {
        setLocale(require(`date-fns/locale/en-US/index.js`))
      } else if (user.dashboardLocale === 'zh') {
        setLocale(require(`date-fns/locale/zh-CN/index.js`))
      } else if (user.dashboardLocale === 'ua') {
        setLocale(require(`date-fns/locale/uk/index.js`))
      } else if (user.dashboardLocale === 'dk') {
        setLocale(require(`date-fns/locale/da/index.js`))
      } else if (user.dashboardLocale === 'no') {
        setLocale(require(`date-fns/locale/nb/index.js`))
      } else {
        setLocale(
          require(`date-fns/locale/${getLanguageCodeByLocale(user.dashboardLocale)}/index.js`),
        )
      }
    } else {
      setLocale(require(`date-fns/locale/en-US/index.js`))
    }
  }, [user])

  useEffect(() => {
    if (!user) return
    const valueWithTimezone = value ? setDateWithTimezone(value, user.timezone) : null
    if (!selectedDate || valueWithTimezone?.toISOString() !== selectedDate.toISOString()) {
      setSelectedDate(valueWithTimezone)
    }
  }, [value, user, selectedDate])
  return (
    <div>
      <ReactDatePicker
        selected={selectedDate}
        dateFormat="MMMM d, yyyy"
        onChange={date => {
          if (date) {
            const isSameDate = selectedDate
              ? date.toDateString() === selectedDate.toDateString()
              : false
            if (isSameDate) {
              setSelectedDate(null)
              onChange(null)
            } else {
              setSelectedDate(date)
              if (mode === DateModeEnum.dateTill) {
                setDateTill(date)
              }
              onChange(toISOStringWithoutTimezone(date))
            }
          }
        }}
        onChangeRaw={e => {
          if (selectedDateRaw === e.target.ariaLabel) {
            setSelectedDate(null)
            onChange(null)
          } else {
            setSelectedDateRaw(e.target.ariaLabel)
          }
        }}
        locale={locale}
        minDate={minDate || userMinDate}
        maxDate={maxDate}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-bottom"
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        customInput={
          <ButtonInput
            description={description}
            className={className}
            error={error}
            label={label}
            isRequired={required}
            placeholderText={placeholder}
            isDisabled={disabled}
          />
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`${
                prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'
              } rounded-full outline-none group`}
            >
              <ArrowIonicLeftIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
            </button>
            <span className="text-base text-gray-900">
              {new Intl.DateTimeFormat(locale.code, { month: 'long', year: 'numeric' }).format(
                date,
              )}
            </span>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`${
                nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'
              } rounded-full outline-none group`}
            >
              <ArrowIonicRightIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
            </button>
          </div>
        )}
        disabled={disabled}
      />
    </div>
  )
}

export default DatePicker

interface ButtonInputProps extends FieldErrorAndDescriptionProps {
  value?: string
  onClick?: () => void
  label?: string
  isRequired?: boolean
  placeholderText?: string
  className?: string
  isDisabled?: boolean
}

const ButtonInput = forwardRef<HTMLButtonElement, ButtonInputProps>(
  (
    {
      value,
      onClick,
      error,
      label,
      isRequired,
      placeholderText,
      className,
      description,
      isDisabled,
    },
    ref,
  ) => (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      {(label || isRequired) && (
        <div className="font-medium text-sm flex gap-0.5">
          {label && <span>{label}</span>}
          {isRequired && <span className="text-danger">*</span>}
        </div>
      )}
      <button
        onClick={onClick}
        ref={ref}
        type="button"
        className={`flex group justify-between min-w-[200px] w-full py-2.5 px-4 text-sm font-medium text-darkblue border ${
          isDisabled ? 'bg-gray-200/40' : 'bg-white focus:border-blue'
        } ${
          error ? 'border-danger' : 'border-gray/30'
        } rounded-lg bg-white focus:outline-none focus:border-blue main-transition-colors`}
      >
        <span className="text-start">
          {value ? value : <span className={`text-gray-300/70`}>{placeholderText}</span>}
        </span>
        <CalendarIcon
          className="ml-3 fill-gray-100 h-[20px] w-[20px] ${
              isDisabled ? '' : 'group-focus:fill-blue'
            } main-transition-colors"
        />
      </button>
      <FieldErrorAndDescription error={error} description={description} />
    </div>
  ),
)

ButtonInput.displayName = 'ButtonInput'
