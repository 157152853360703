import React from 'react'

interface AutomationRulesIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function AutomationRulesIcon({ active }: AutomationRulesIconProps) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.99904"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4522 20.9043C16.2249 20.9043 20.9043 16.2249 20.9043 10.4522C20.9043 4.67943 16.2249 0 10.4522 0C4.67943 0 0 4.67943 0 10.4522C0 16.2249 4.67943 20.9043 10.4522 20.9043Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98125 10.3575L8.70585 10.786L7.7129 14.7996C7.48295 15.7403 7.94285 16.0539 8.73721 15.4999L14.1514 11.7476C14.8099 11.2877 14.7158 10.7547 13.9319 10.5561L12.2073 10.1275L13.2003 6.11388C13.4302 5.17319 12.9703 4.85962 12.176 5.41359L6.76175 9.16591C6.10327 9.6258 6.19733 10.1589 6.98125 10.3575Z"
        fill={active ? 'white' : '#64748B'}
      />
    </svg>
  )
}

export default AutomationRulesIcon
