import React from 'react'

function WebsiteIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="171"
      height="147"
      viewBox="0 0 171 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.018 1e-05H14.55C6.52981 1e-05 0 6.52348 0 14.55V131.473C0 139.494 6.52347 146.023 14.55 146.023H156.018C164.038 146.023 170.561 139.5 170.561 131.473V14.55C170.553 6.5239 164.038 0 156.018 0V1e-05ZM55.486 12.8539H67.9584V23.6443H55.486V12.8539ZM36.5104 12.8539H48.9827V23.6443H36.5104V12.8539ZM17.5347 12.8539H30.0071V23.6443H17.5347V12.8539ZM160.866 131.467C160.866 134.14 158.699 136.315 156.018 136.315H14.5508C11.8779 136.315 9.70289 134.14 9.70289 131.467V38.8782H160.866V131.467Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9648 49.9609H148.883V59.6567H77.9648L77.9648 49.9609Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9648 71.3389H148.883V81.0283H77.9648L77.9648 71.3389Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9648 92.7129H148.883V102.409H77.9648L77.9648 92.7129Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9648 114.091H148.883V123.78H77.9648L77.9648 114.091Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7227 49.9609H66.8773V123.78H21.7227L21.7227 49.9609Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default WebsiteIcon
