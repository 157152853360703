import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  NotStartedSplitTestInterface,
  SplitTestInterface,
} from 'modules/funnels/funnel/types/funnel-tests-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnelStepTests(funnelStepId?: FunnelStepId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SplitTestInterface | NotStartedSplitTestInterface | []
  >({ method: RequestMethodsEnum.get })

  const { data: splitTestData, mutate: mutateSplitTest } = useSWR(() => {
    if (user && funnelStepId) {
      return `${FUNNELS_API}/steps/${funnelStepId}/split-test`
    }
    return null
  }, fetcher)

  return { splitTestData, mutateSplitTest }
}
