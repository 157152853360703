import RuleSave from 'modules/automation-rules/components/rule-save'
import { useAutomationRule } from 'modules/automation-rules/hooks/use-automation-rule'
import { useRuleState } from 'modules/automation-rules/hooks/use-rule-state'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import {
  AutomationRuleApiInterface,
  AutomationRuleViewInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import { FunnelStepRule } from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule'
import { FunnelTriggerDataInterface } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'
import React, { useEffect, useRef, useState } from 'react'
import { NeutralButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowIonicLeftIcon from 'shared/icons/arrow-ionic-left-icon'
import { KeyedMutator } from 'swr'

interface FunnelStepRuleEditProps {
  ruleId: number
  clearSelectedRule: () => void
  funnelTriggerData: FunnelTriggerDataInterface
  availableTriggers?: AutomationRuleTriggersEnum[]
  mutateRules: KeyedMutator<AutomationRuleApiInterface>
  mutateAvailableTriggers: KeyedMutator<AutomationRuleTriggersEnum[]>
}

const FunnelStepRuleEdit = ({
  ruleId,
  clearSelectedRule,
  funnelTriggerData,
  mutateRules,
  mutateAvailableTriggers,
  availableTriggers,
}: FunnelStepRuleEditProps) => {
  const { t } = useLocoTranslation()

  const { data: rule, mutate } = useAutomationRule(ruleId)

  const [isFetching, setIsFetching] = useState(false)

  const { tempState, setTempState, errors, setErrors } = useRuleState()

  const maxTriggerId = useRef(0)
  const maxActionId = useRef(0)

  useEffect(() => {
    if (rule) {
      setTempState(prev => ({ ...prev, ...rule }))
      if (rule.actions.length !== 0) {
        maxActionId.current = Math.max(...rule.actions.map(el => el.id))
      }
    }
  }, [rule, setTempState])

  const afterSave = async (rule: AutomationRuleViewInterface) => {
    await mutate(data => ({ ...data, ...rule }))
    await mutateRules()
    await mutateAvailableTriggers()
    clearSelectedRule()
  }

  return (
    <div className={'lg:px-8 px-6 py-4 flex flex-col gap-5'}>
      <div className={'grid grid-cols-2 grid-flow-col-dense'}>
        <NeutralButton disabled={isFetching} className={'w-fit'} onClick={clearSelectedRule}>
          <ArrowIonicLeftIcon
            className={'lg:hidden group-hover:fill-blue main-transition-colors'}
          />
          <span className={'hidden lg:inline'}>{t('dashboard.funnel.automation_rules.back')}</span>
        </NeutralButton>
        <div className={'flex justify-end'}>
          <RuleSave
            afterSave={afterSave}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            rule={tempState}
            ruleId={ruleId}
            setErrors={setErrors}
          />
        </div>
      </div>
      <FunnelStepRule
        ruleId={ruleId}
        isFetching={isFetching}
        availableTriggers={availableTriggers}
        funnelTriggerData={funnelTriggerData}
        tempState={tempState}
        setTempState={setTempState}
        errors={errors}
        maxTriggerId={maxTriggerId}
        maxActionId={maxActionId}
        isPreFetching={!rule}
        savedTriggers={rule?.triggers}
      />
    </div>
  )
}

export default FunnelStepRuleEdit
