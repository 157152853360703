import FunnelStepRuleCreate from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule-create'
import FunnelStepRuleEdit from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule-edit'
import FunnelStepRuleError from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule-error'
import FunnelStepRulesTable from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rules-table'
import { useFunnelStepRuleData } from 'modules/funnels/funnel/components/funnel-rules/hooks/use-funnel-step-rule-data'
import { useFunnelStepRules } from 'modules/funnels/funnel/components/funnel-rules/hooks/use-funnel-step-rules'
import { useFunnelStepTriggers } from 'modules/funnels/funnel/components/funnel-rules/hooks/use-funnel-step-triggers'
import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BadRequest } from 'shared/errors/bad-request'

interface AutomationRulesSectionProps {
  funnelStepId?: FunnelStepId
  funnelId: FunnelId
}

function AutomationRulesTab({ funnelId, funnelStepId }: AutomationRulesSectionProps) {
  const [commonError, setCommonError] = useState<string>('')

  const { data: rules, mutate: mutateRules, error: ruleError } = useFunnelStepRules(funnelStepId)

  const {
    data: availableTriggers,
    error: triggerError,
    mutate: mutateAvailableTriggers,
  } = useFunnelStepTriggers(funnelStepId)

  const { funnelTriggerData } = useFunnelStepRuleData(funnelId, funnelStepId)

  const [selectedRuleId, setSelectedRuleId] = useState<number>()

  const clearSelectedRule = () => setSelectedRuleId(undefined)

  useEffect(() => {
    if (ruleError && ruleError instanceof BadRequest && ruleError.errors.common) {
      setCommonError(ruleError.errors.common.join(''))
    }
  }, [ruleError])

  useEffect(() => {
    if (triggerError && triggerError instanceof BadRequest && triggerError.errors.common) {
      toast.error(triggerError.errors.common.join(''))
    }
  }, [triggerError])

  return (
    <>
      <div className="lg:hidden px-4">
        <MobileSettingsHeader type={FunnelStepSettingTabsEnum.automationRules} />
      </div>
      <FunnelStepRuleError error={commonError} />
      {funnelTriggerData && selectedRuleId ? (
        <FunnelStepRuleEdit
          mutateRules={mutateRules}
          mutateAvailableTriggers={mutateAvailableTriggers}
          clearSelectedRule={clearSelectedRule}
          ruleId={selectedRuleId}
          funnelTriggerData={funnelTriggerData}
          availableTriggers={availableTriggers}
        />
      ) : (
        <>
          {!ruleError && funnelTriggerData && (
            <FunnelStepRulesTable
              funnelStepId={funnelTriggerData.funnelStepId}
              mutateAvailableTriggers={mutateAvailableTriggers}
              setSelectedRuleId={setSelectedRuleId}
              mutateRules={mutateRules}
              rules={rules}
            />
          )}
          {funnelTriggerData && (
            <FunnelStepRuleCreate
              availableTriggers={availableTriggers}
              mutateRules={mutateRules}
              mutateAvailableTriggers={mutateAvailableTriggers}
              funnelTriggerData={funnelTriggerData}
            />
          )}
        </>
      )}
    </>
  )
}

export default AutomationRulesTab
