import { SubscriptionPlanInterface } from 'modules/funnels/funnel/components/sales-tab/types/funnel-sales-interface'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface SubscriptionDescriptionProps {
  subscriptionPlan: SubscriptionPlanInterface
  amountWithCurrency: string
  quantity: number
  pricePlanName: string
  coupon: {
    innerName: string
  } | null
}
function SubscriptionDescription({
  amountWithCurrency,
  subscriptionPlan,
  quantity,
  pricePlanName,
  coupon,
}: SubscriptionDescriptionProps) {
  const { t } = useLocoTranslation()

  return (
    <div className="flex flex-col gap-1">
      <div className="truncate">
        {`${amountWithCurrency} ${t(
          'dashboard.funnel.configuration.common.price_plan.subscription_plan.interval_label',
        )} ${t(
          `dashboard.funnel.configuration.common.price_plan.period.${subscriptionPlan.interval}`,
          {
            count: subscriptionPlan.intervalCount,
          },
        )}`}
      </div>
      {subscriptionPlan.limitOfPayments && (
        <div className="truncate">
          {`${t(
            'dashboard.funnel.configuration.common.price_plan.subscription_plan.limit_of_payments',
          )} : ${subscriptionPlan.limitOfPayments}`}
        </div>
      )}
      {subscriptionPlan.trialPeriod > 0 && subscriptionPlan.trialInterval && (
        <div className="truncate">{`${t(
          'dashboard.funnel.configuration.common.price_plan.subscription_plan.trial_period',
        )} : ${t(
          `dashboard.funnel.configuration.common.price_plan.period.${subscriptionPlan.trialInterval}`,
          {
            count: subscriptionPlan.trialPeriod,
          },
        )}`}</div>
      )}
      {quantity > 1 ? (
        <div className="truncate">{`${t(
          'dashboard.funnel.configuration.common.for_label',
        )} ${quantity} x ${pricePlanName}`}</div>
      ) : (
        <div className="truncate">{pricePlanName}</div>
      )}
      {coupon && (
        <div className="truncate">
          {`${t('dashboard.funnel.configuration.common.with_the_coupon_label')} `}
          <span className="font-bold">{coupon.innerName}</span>
        </div>
      )}
    </div>
  )
}

export default SubscriptionDescription
