import en from 'date-fns/locale/en-US'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker'
import { PrimaryButton } from 'shared/components/button'
import { DangerButton } from 'shared/components/button'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ArrowIonicLeftIcon from 'shared/icons/arrow-ionic-left-icon'
import ArrowIonicRightIcon from 'shared/icons/arrow-ionic-right-icon'
import CalendarIcon from 'shared/icons/calendar-icon'
import { setDateWithTimezone, toISOStringWithoutTimezone } from 'shared/utils/date-time-format'
import { getLanguageCodeByLocale } from '../../enums/language-code-enum'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from '../form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from '../form/field-label'

interface DateTimePickerProps {
  value: string | null
  onChange: (date: string | null) => void
  error?: string | null
  label?: string
  required?: boolean
  placeholder?: string
  className?: string
  description?: string
  customProps?: Partial<ReactDatePickerProps>
  labelClassName?: string
}

const DateTimePicker = ({
  value,
  onChange,
  error,
  label,
  required,
  placeholder,
  className,
  description,
  customProps,
  labelClassName,
}: DateTimePickerProps) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    (value && setDateWithTimezone(value, user?.timezone)) || null,
  )
  const [appliedDate, setAppliedDate] = useState<Date | null>(
    (value && setDateWithTimezone(value, user?.timezone)) || null,
  )
  const [locale, setLocale] = useState<Locale>(en)
  const datePickerRef = useRef<ReactDatePicker>(null)

  useEffect(() => {
    if (value && !selectedDate && !appliedDate && user) {
      setSelectedDate(setDateWithTimezone(value, user.timezone))
      setAppliedDate(setDateWithTimezone(value, user.timezone))
    }
  }, [value, user, selectedDate, appliedDate])
  useEffect(() => {
    // We need it to fix this https://rollbar.com/systemeio/dashboard-frontend/items/1370/?item_page=0&#instances
    if (user && user.dashboardLocale) {
      if (user.dashboardLocale === 'en') {
        setLocale(require(`date-fns/locale/en-US/index.js`))
      } else if (user.dashboardLocale === 'zh') {
        setLocale(require(`date-fns/locale/zh-CN/index.js`))
      } else if (user.dashboardLocale === 'ua') {
        setLocale(require(`date-fns/locale/uk/index.js`))
      } else if (user.dashboardLocale === 'dk') {
        setLocale(require(`date-fns/locale/da/index.js`))
      } else if (user.dashboardLocale === 'no') {
        setLocale(require(`date-fns/locale/nb/index.js`))
      } else {
        setLocale(
          require(`date-fns/locale/${getLanguageCodeByLocale(user.dashboardLocale)}/index.js`),
        )
      }
    } else {
      setLocale(require(`date-fns/locale/en-US/index.js`))
    }
  }, [user])

  return (
    <div>
      <ReactDatePicker
        ref={datePickerRef}
        selected={selectedDate}
        showTimeSelect
        dayClassName={date =>
          selectedDate &&
          appliedDate &&
          date.toDateString() === appliedDate.toDateString() &&
          selectedDate.toDateString() !== appliedDate.toDateString()
            ? 'text-blue/50'
            : ''
        }
        timeClassName={date =>
          selectedDate &&
          appliedDate &&
          date.getTime() === appliedDate.getTime() &&
          selectedDate.getTime() !== appliedDate.getTime()
            ? 'text-blue/50'
            : '.'
        }
        shouldCloseOnSelect={false}
        onCalendarClose={() => {
          setSelectedDate(appliedDate)
        }}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption={t('global.time')}
        timeInputLabel={t('global.time')}
        onChange={date => {
          if (date) {
            setSelectedDate(date)
          }
        }}
        locale={locale}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-bottom"
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: false,
              altAxis: true,
            },
          },
        ]}
        customInput={
          <ButtonInput
            date={appliedDate}
            locale={user?.dashboardLocale}
            error={error}
            label={label}
            required={required}
            placeholderText={placeholder}
            className={className}
            description={description}
            labelClassName={labelClassName}
          />
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={`${
                prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'
              } rounded-full outline-none group`}
            >
              <ArrowIonicLeftIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
            </button>
            <span className="text-base text-gray-900">
              {new Intl.DateTimeFormat(locale.code, {
                month: 'long',
                year: 'numeric',
              }).format(date)}
            </span>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={`${
                nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'
              } rounded-full outline-none group`}
            >
              <ArrowIonicRightIcon className="fill-gray-900 group-focus-visible:fill-blue group-active:fill-blue" />
            </button>
          </div>
        )}
        {...customProps}
      >
        <div className="sm:col-span-2 flex w-full justify-center gap-5">
          <PrimaryButton
            disabled={!selectedDate}
            onClick={() => {
              if (selectedDate) {
                datePickerRef.current?.setOpen(false)
                setAppliedDate(selectedDate)
                onChange(toISOStringWithoutTimezone(selectedDate))
              }
            }}
            width="large"
          >
            {t('global.apply')}
          </PrimaryButton>
          <DangerButton
            onClick={() => {
              datePickerRef.current?.setOpen(false)
              setAppliedDate(null)
              onChange(null)
            }}
            width="large"
          >
            {t('global.clear')}
          </DangerButton>
        </div>
      </ReactDatePicker>
    </div>
  )
}

export default DateTimePicker

type ButtonInputProps = FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    value?: string
    date: Date | null
    onClick?: () => void
    locale?: LocaleEnum
    placeholderText?: string
    className?: string
  }

const ButtonInput = forwardRef<HTMLButtonElement, ButtonInputProps>(
  (
    {
      value,
      date,
      onClick,
      locale,
      error,
      label,
      required,
      placeholderText,
      className,
      description,
      labelClassName,
    },
    ref,
  ) => {
    return (
      <div className={`flex flex-col gap-1 ${className || ''}`}>
        <FieldLabel label={label} labelClassName={labelClassName} required={required} />

        <button
          onClick={onClick}
          ref={ref}
          type="button"
          className={`flex group justify-between min-w-[250px] w-full py-2.5 px-4 text-sm font-medium text-darkblue border ${
            error ? 'border-danger' : 'border-gray/30'
          } rounded-lg bg-white focus:outline-none focus:border-blue main-transition-colors`}
        >
          <span>
            {date ? (
              new Intl.DateTimeFormat(locale, {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                hourCycle: 'h23',
                minute: '2-digit',
              }).format(date)
            ) : (
              <span className={`text-gray-300/70`}>{placeholderText}</span>
            )}
          </span>
          <CalendarIcon className="ml-3 fill-gray-100 h-[20px] w-[20px] group-focus:fill-blue main-transition-colors" />
        </button>
        <FieldErrorAndDescription error={error} description={description} />
      </div>
    )
  },
)

ButtonInput.displayName = 'ButtonInput'
