import { useDeviceMode } from 'modules/funnels/funnel/context/device-mode-context'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { getFunnelStepSettingsProperties } from 'modules/funnels/funnel/utils/get-funnel-step-settings-properties'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowLeftIcon from 'shared/icons/arrow-left-icon'

interface MobileSettingsHeaderProps {
  type: FunnelStepSettingTabsEnum
}

function MobileSettingsHeader({ type }: MobileSettingsHeaderProps) {
  const { isMobile, resetSelectedMobileTab } = useDeviceMode()
  const { t } = useLocoTranslation()
  const { iconRenderer, caption } = getFunnelStepSettingsProperties(type)
  return (
    <>
      {isMobile && (
        <div className="flex justify-between pt-5">
          <div className="flex gap-1">
            <div>{iconRenderer(true)}</div>
            <div className="font-inter font-bold text-base text-blue">{t(caption)}</div>
          </div>
          <div
            onClick={() => resetSelectedMobileTab && resetSelectedMobileTab()}
            className="text-[#64748B] font-inter font-bold text-base"
          >
            <div className="flex gap-1 items-center">
              <ArrowLeftIcon className="stroke-[#64748B]" />
              <span>{t('dashboard.funnel.configuration.common.back')}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileSettingsHeader
