import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import AutomationRulesIcon from 'modules/funnels/funnel/icons/automation-rules-icon'
import ClockIcon from 'modules/funnels/funnel/icons/clock-icon'
import ConfigurationIcon from 'modules/funnels/funnel/icons/configuration-icon'
import LeadsIcon from 'modules/funnels/funnel/icons/leads-icon'
import LetterIcon from 'modules/funnels/funnel/icons/letter-icon'
import SalesIcon from 'modules/funnels/funnel/icons/sales-icon'
import StatsIcon from 'modules/funnels/funnel/icons/stats-icon'
import TestsIcon from 'modules/funnels/funnel/icons/tests-icon'

export interface SettingsTabInterface {
  type: FunnelStepSettingTabsEnum
  caption: string
  iconRenderer: (active: boolean) => JSX.Element
}

export const funnelSettingTabsList: SettingsTabInterface[] = [
  {
    type: FunnelStepSettingTabsEnum.stepConfiguration,
    caption: 'dashboard.funnel.step_configuration',
    iconRenderer: (active: boolean) => <ConfigurationIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.automationRules,
    caption: 'dashboard.funnel.automation_rules.title',
    iconRenderer: (active: boolean) => <AutomationRulesIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.tests,
    caption: 'dashboard.funnel.split_tests_title',
    iconRenderer: (active: boolean) => <TestsIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.stats,
    caption: 'dashboard.funnel.stats',
    iconRenderer: (active: boolean) => <StatsIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.leads,
    caption: 'dashboard.funnel.leads',
    iconRenderer: (active: boolean) => <LeadsIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.sales,
    caption: 'dashboard.funnel.sales',
    iconRenderer: (active: boolean) => <SalesIcon active={active} />,
  },
  {
    type: FunnelStepSettingTabsEnum.deadlineSettings,
    caption: 'dashboard.funnel.deadline_settings',
    iconRenderer: (active: boolean) => <ClockIcon active={active} />,
  },
]

export const funnelStepSettingsEmailsTab = {
  type: FunnelStepSettingTabsEnum.emails,
  caption: 'dashboard.funnel.emails',
  iconRenderer: (active: boolean) => <LetterIcon active={active} />,
}

export const funnelSettingTabsListWithEmails = [
  ...funnelSettingTabsList,
  funnelStepSettingsEmailsTab,
]
