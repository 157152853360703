import ResourcesBase from 'modules/digital-product/resources/components/resources-base'
import { useDigitalResourceContent } from 'modules/digital-product/resources/hooks/use-digital-resource-content'
import { DigitalResourceType } from 'modules/digital-product/resources/types/digital-resource-interface'
import React, { FC } from 'react'

interface Props {
  resources: DigitalResourceType[]
}

export const ResourcesReadOnly: FC<Props> = ({ resources }) => {
  const existingResourcesData = useDigitalResourceContent(resources)
  return <ResourcesBase>{existingResourcesData}</ResourcesBase>
}
