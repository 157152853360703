import BaseConfigurationTab from 'modules/funnels/funnel/components/step-configuration-tab/base-configuration-tab/base-configuration-tab'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'
import OfferFormConfigurationTab from '../components/step-configuration-tab/offer-form-configuration-tab/components/offer-form-configuration-tab'
import RemoteFormConfigurationTab from '../components/step-configuration-tab/remote-form-configuration-tab'
import UpsellConfigurationTab from '../components/step-configuration-tab/upsell-configuration-tab/components/upsell-configuration-tab'
import WebinarRegistrationConfigurationTab from '../components/step-configuration-tab/webinar-registration-configuration-tab'
import { FunnelStepInterface } from '../types/funnel-step-interface'

export const getFunnelStepConfigurationTabContent = (
  funnelStep: FunnelStepInterface,
  funnelId: FunnelId,
): JSX.Element => {
  switch (funnelStep.type) {
    case FunnelStepTypeEnum.WebinarRegistration: {
      return <WebinarRegistrationConfigurationTab funnelStep={funnelStep} funnelId={funnelId} />
    }
    case FunnelStepTypeEnum.Inline:
    case FunnelStepTypeEnum.Popup: {
      return <RemoteFormConfigurationTab funnelStep={funnelStep} funnelId={funnelId} />
    }
    case FunnelStepTypeEnum.OrderThankYouPage:
    case FunnelStepTypeEnum.Squeeze:
    case FunnelStepTypeEnum.SalesPage:
    case FunnelStepTypeEnum.InfoPage:
    case FunnelStepTypeEnum.OptInThankYouPage:
    case FunnelStepTypeEnum.ContactUsPage:
    case FunnelStepTypeEnum.LinkInBio: {
      return <BaseConfigurationTab funnelStep={funnelStep} funnelId={funnelId} />
    }
    case FunnelStepTypeEnum.WebinarThankYou: {
      return <BaseConfigurationTab funnelStep={funnelStep} funnelId={funnelId} showPreview />
    }
    case FunnelStepTypeEnum.WebinarSession: {
      return <BaseConfigurationTab funnelStep={funnelStep} funnelId={funnelId} showPreview />
    }
    case FunnelStepTypeEnum.OfferForm:
      return <OfferFormConfigurationTab funnelStep={funnelStep} funnelId={funnelId} />

    case FunnelStepTypeEnum.Upsell:
    case FunnelStepTypeEnum.Downsell:
      return <UpsellConfigurationTab funnelStep={funnelStep} funnelId={funnelId} />
    default: {
      return <></>
    }
  }
}
