import ContactUsFunnelStepIcon from 'modules/funnels/funnel/icons/contact-use-funnel-step-icon'
import DownsellFunnelStepIcon from 'modules/funnels/funnel/icons/downsell-funnel-step-icon'
import InfoFunnelStepIcon from 'modules/funnels/funnel/icons/info-funnel-step-icon'
import InlineFunnelStepIcon from 'modules/funnels/funnel/icons/inline-funnel-step-icon'
import OfferThankYouFunnelStepIcon from 'modules/funnels/funnel/icons/offer-thank-you-funnel-step-icon'
import OptInThankYouFunnelStepIcon from 'modules/funnels/funnel/icons/opt-in-thank-you-funnel-step-icon'
import OrderFormFunnelStepIcon from 'modules/funnels/funnel/icons/order-form-funnel-step-icon'
import PopupFunnelStepIcon from 'modules/funnels/funnel/icons/popup-funnel-step-icon'
import SalesFunnelStepIcon from 'modules/funnels/funnel/icons/sales-funnel-step-icon'
import SqueezeFunnelStepIcon from 'modules/funnels/funnel/icons/squeeze-funnel-step-icon'
import UpsellFunnelStepIcon from 'modules/funnels/funnel/icons/upsell-funnel-step-icon'
import WebinarBroadcastFunnelStepIcon from 'modules/funnels/funnel/icons/webinar-broadcast-funnel-step-icon'
import WebinarRegistrationFunnelStepIcon from 'modules/funnels/funnel/icons/webinar-registration-funnel-step-icon'
import WebinarThankYouFunnelStepIcon from 'modules/funnels/funnel/icons/webinar-thank-you-funnel-step-icon'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'
import LinkInBioIcon from '../icons/link-in-bio-icon'

export const getFunnelStepTypeIcon = (funnelStepType: FunnelStepTypeEnum) => {
  switch (funnelStepType) {
    case FunnelStepTypeEnum.OrderThankYouPage:
      return <OfferThankYouFunnelStepIcon />
    case FunnelStepTypeEnum.OfferForm:
      return <OrderFormFunnelStepIcon />
    case FunnelStepTypeEnum.Upsell:
      return <UpsellFunnelStepIcon />
    case FunnelStepTypeEnum.Downsell:
      return <DownsellFunnelStepIcon />
    case FunnelStepTypeEnum.Squeeze:
      return <SqueezeFunnelStepIcon />
    case FunnelStepTypeEnum.SalesPage:
      return <SalesFunnelStepIcon />
    case FunnelStepTypeEnum.InfoPage:
      return <InfoFunnelStepIcon />
    case FunnelStepTypeEnum.OptInThankYouPage:
      return <OptInThankYouFunnelStepIcon />
    case FunnelStepTypeEnum.Inline:
      return <InlineFunnelStepIcon />
    case FunnelStepTypeEnum.Popup:
      return <PopupFunnelStepIcon />
    case FunnelStepTypeEnum.WebinarRegistration:
      return <WebinarRegistrationFunnelStepIcon />
    case FunnelStepTypeEnum.WebinarThankYou:
      return <WebinarThankYouFunnelStepIcon />
    case FunnelStepTypeEnum.WebinarSession:
      return <WebinarBroadcastFunnelStepIcon />
    case FunnelStepTypeEnum.ContactUsPage:
      return <ContactUsFunnelStepIcon />
    case FunnelStepTypeEnum.LinkInBio:
      return <LinkInBioIcon />
    default:
      return
  }
}
