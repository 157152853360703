import React from 'react'

function PlayIcon() {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 353 392"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M327.365 152.203L75.5712 6.82404C41.9767 -12.5661 0 11.6734 0 50.4611V341.219C0 379.984 41.9913 404.246 75.5858 384.856L327.365 239.477C360.96 220.087 360.96 171.593 327.365 152.203Z"
        fill="white"
      />
    </svg>
  )
}

export default PlayIcon
