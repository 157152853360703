import { FunnelTriggerDataInterface } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useWebinarRegistrationSettings } from 'modules/funnels/funnel/hooks/use-webinar-registration-settings'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

export const useFunnelStepRuleData = (funnelId: FunnelId, funnelStepId?: FunnelStepId) => {
  const { funnel } = useFunnel(funnelId)

  const { funnelStepsList } = useFunnelStepsList(funnelId)

  const funnelStep = funnelStepsList?.find(step => step.id === funnelStepId)

  const { webinarRegistrationSettings } = useWebinarRegistrationSettings(funnelStepId, {
    shouldFetch: funnelStep?.type === FunnelStepTypeEnum.WebinarRegistration,
  })

  const funnelTriggerData: FunnelTriggerDataInterface | undefined =
    funnel && funnelStep
      ? {
          funnelId,
          funnelStepId: funnelStep.id,
          funnelStepName: funnelStep.name,
          funnelName: funnel.name,
          webinarId: webinarRegistrationSettings?.webinar.id,
        }
      : undefined

  return { funnelTriggerData }
}
