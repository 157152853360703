import {
  OfferFormEditType,
  OfferFormViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'

export const getOfferSettingsApiData = (
  offerState: OfferFormViewInterface['offer'],
): OfferFormEditType => {
  const saleLimit: OfferFormEditType['saleLimit'] = offerState.saleLimit

  const affiliateCommission: OfferFormEditType['affiliateCommission'] =
    offerState.affiliateCommission

  const payoutDelay: OfferFormEditType['payoutDelay'] = offerState.payoutDelay

  const saleLimitFunnelStepRedirect: OfferFormEditType['saleLimitFunnelStepRedirect'] =
    offerState.saleLimitFunnelStepRedirect?.id

  const coupons: OfferFormEditType['coupons'] = offerState.coupons?.map(coupon => coupon.id) || []

  const bumpView = offerState.bumps.length !== 0 ? offerState.bumps[0] : undefined

  const digitalProductBump: OfferFormEditType['digitalProduct'] =
    bumpView?.digitalProduct?.id || null

  const productBump: OfferFormEditType['product'] = bumpView?.product?.id || null

  const bumps: OfferFormEditType['bumps'] = digitalProductBump
    ? [{ digitalProduct: digitalProductBump, product: null }]
    : productBump
    ? [{ product: productBump, digitalProduct: null }]
    : []

  const digitalProduct: OfferFormEditType['digitalProduct'] = offerState.digitalProduct?.id || null

  const product: OfferFormEditType['product'] = offerState.product?.id || null

  const baseOffer = {
    saleLimit,
    payoutDelay,
    affiliateCommission,
    saleLimitFunnelStepRedirect,
    coupons,
    bumps,
  }

  if (digitalProduct) {
    return {
      ...baseOffer,
      digitalProduct,
      product: null,
    }
  } else {
    return {
      ...baseOffer,
      digitalProduct: null,
      product,
    }
  }
}
