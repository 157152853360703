import React from 'react'

interface SalesIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function SalesIcon({ active }: SalesIconProps) {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.964844 3.61889C0.964844 1.96204 2.30799 0.618896 3.96484 0.618896H24.9377C26.5945 0.618896 27.9377 1.96204 27.9377 3.6189V16.3793C27.9377 18.0362 26.5945 19.3793 24.9377 19.3793H3.96484C2.30799 19.3793 0.964844 18.0362 0.964844 16.3793V3.61889Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9376 12.3643C17.945 11.3547 17.5459 10.5564 16.7499 9.99252C16.1351 9.55841 15.3891 9.33196 14.6675 9.11436C13.1642 8.65864 12.7282 8.41861 12.7282 7.69974C12.7282 6.89901 13.7688 6.6141 14.6596 6.6141C15.3084 6.6141 16.0548 6.81582 16.5184 7.11476L17.4374 5.69149C16.8315 5.29875 16.0024 5.03086 15.1836 4.94771V3.61865H13.4884V5.04091C11.9814 5.37241 11.0321 6.35732 11.0321 7.69974C11.0321 8.63989 11.4228 9.38548 12.1911 9.91136C12.7754 10.3128 13.4881 10.5284 14.1765 10.7371C15.6509 11.1828 16.2497 11.4544 16.2429 12.3538L16.2428 12.3604C16.2428 13.1153 15.2425 13.3836 14.3854 13.3836C13.5758 13.3836 12.6955 13.0288 12.1964 12.5013L10.9648 13.6662C11.5968 14.3346 12.515 14.8072 13.4883 14.9924V16.3791H15.1836V15.0223C16.8582 14.7826 17.9359 13.7745 17.9376 12.3643L17.9376 12.3643Z"
        fill={active ? 'white' : '#64748B'}
      />
    </svg>
  )
}

export default SalesIcon
