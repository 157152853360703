import {
  FunnelPageTemplateId,
  FunnelPageTemplateInterface,
} from 'modules/funnels/funnel/types/funnel-page-template-interface'
import { FunnelStepPageId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { PrimaryButton } from 'shared/components/button'
import { ABSOLUTE_PATH_PREFIX } from 'shared/constants/base-path-url'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface TemplateCardProps {
  template: FunnelPageTemplateInterface
  onSelect: (id: FunnelPageTemplateId) => Promise<void>
  selectedPageTemplate?: FunnelStepPageId
}

function TemplateCard({ template, onSelect, selectedPageTemplate }: TemplateCardProps) {
  const { t } = useLocoTranslation()
  const isTemplateSelected = template.id === selectedPageTemplate
  return (
    <div className="bg-white rounded-xl overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="overflow-hidden aspect-video m-0 p-0 group relative">
        <div
          className={`w-full h-full z-1 ${
            isTemplateSelected ? 'flex' : 'hidden'
          } group-hover:flex gap-5 justify-center items-center absolute bg-darkblue bg-opacity-50 `}
        >
          <PrimaryButton
            type={'button'}
            isFetching={isTemplateSelected}
            data-test-element={`select-template-${template.id}`}
            onClick={() => onSelect(template.id)}
          >
            <span className={'flex'}>{t('global.select')}</span>
          </PrimaryButton>
          <PrimaryButton
            type={'button'}
            href={`${ABSOLUTE_PATH_PREFIX}/dashboard/page/template/${template.id}/show`}
            target={'_blank'}
            data-test-element={`preview-template-${template.id}`}
          >
            <span className={'flex'}>
              {t('dashboard.funnel.configuration.templates.preview_template')}
            </span>
          </PrimaryButton>
        </div>
        <img
          alt={template.previewImage.filename}
          src={template.previewImage.path}
          data-test-element={template.name}
          className="w-[-webkit-fill-available]"
        />
      </div>
    </div>
  )
}

export default TemplateCard
