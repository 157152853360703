import { MARKET_PLACE_API } from 'modules/funnels/api/market-place-api'
import { MarketPlaceOfferInterface } from 'modules/funnels/funnel/types/marketplace-offer-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useMarketplaceOffer(id?: number, shouldFetch?: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MarketPlaceOfferInterface
  >({ method: RequestMethodsEnum.get })

  const { data: marketPlaceOffer, mutate } = useSWR(() => {
    if (user && id) {
      return `${MARKET_PLACE_API}/offer/${id}`
    }
    return null
  }, fetcher)

  return { marketPlaceOffer, mutate }
}
