import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { BaseFunnelStepConfigurationInterface } from 'modules/funnels/funnel/types/base-funnel-step-configuration-settings-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

export function useBaseFunnelStepConfigurationSettings(funnelStepId: FunnelStepId) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    BaseFunnelStepConfigurationInterface
  >({ method: RequestMethodsEnum.get })

  const { data: funnelStepConfigurationSettings, mutate: mutateFunnelStepConfigurationSettings } =
    useSWR(() => {
      return `${FUNNELS_API}/steps/${funnelStepId}`
    }, fetcher)

  return { funnelStepConfigurationSettings, mutateFunnelStepConfigurationSettings }
}
