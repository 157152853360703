import ContactInfoTooltip from 'modules/contacts/components/contact-info-tooltip'
import { useFunnelSales } from 'modules/funnels/funnel/components/sales-tab/hooks/use-funnel-sales'
import {
  FunnelSalesFilterInterface,
  FunnelSalesInterface,
} from 'modules/funnels/funnel/components/sales-tab/types/funnel-sales-interface'
import { getSalePriceDescription } from 'modules/funnels/funnel/components/sales-tab/utils/get-sale-price-description'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import DateRangePicker from 'shared/components/date-picker/date-range-picker'
import ListingLayout from 'shared/components/listing-layout'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import CloseCircleIcon from 'shared/icons/close-circle-icon'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { getDateRangeWithDaysDifference, getToday } from 'shared/utils/get-dates'

interface SalesTabProps {
  funnelId: FunnelId
}

const defaultFilters: FunnelSalesFilterInterface = { date: [null, null] }

function SalesTab({ funnelId }: SalesTabProps) {
  const { t } = useLocoTranslation()
  const { user, isWsAssistant } = useUser()

  const { limit, setLimit, pagination, setPagination } = usePagination(true)
  const [filter, setFilter] = useState<FunnelSalesFilterInterface>(defaultFilters)
  const { sales } = useFunnelSales({
    limit,
    pagination,
    filter,
    funnelId: funnelId,
  })

  const columns: ColumnDefinitionInterface<FunnelSalesInterface>[] = [
    {
      key: 'createdAt',
      header: t('dashboard.funnel.sales_date'),
      widthPercent: 25,
      onRender: sale => (
        <span>
          {dateTimeFormat(sale.createdAt, {
            timeFormat: user?.timeFormat,
            dateFormat: user?.dateFormat,
            timeZone: user?.timezone,
          })}
        </span>
      ),
    },
    {
      key: 'offer',
      header: t('dashboard.funnel.sales_step'),
      widthPercent: 30,
      onRender: sale => <div className="truncate">{sale.offer.funnelStep.name}</div>,
    },
    {
      key: 'productOrderItem',
      header: t('dashboard.funnel.sales_price'),
      widthPercent: 35,
      onRender: sale => {
        return <>{getSalePriceDescription(sale, user)}</>
      },
    },
    {
      key: 'order',
      header: t('dashboard.funnel.sales_customer'),
      widthPercent: 30,
      onRender: sale => {
        return (
          <>
            {!isWsAssistant ? (
              <ContactInfoTooltip
                fields={sale.order.customer.fieldValues}
                referredByEmail={sale.order?.customer?.referredBy?.email}
                type={sale.order.customer.type}
                contactId={sale.order.customer.contact.id}
              >
                <span className={'truncate w-full'}>{sale.order.customer.email}</span>
              </ContactInfoTooltip>
            ) : (
              <span>{t('global.hidden')}</span>
            )}
          </>
        )
      },
    },
    {
      key: 'cancelledAt',
      header: t('dashboard.funnel.sales_status'),
      widthPercent: 10,
      onRender: sale => (
        <div className="lg:ml-4 flex items-center">
          <Tooltip
            label={
              !sale.cancelledAt
                ? t('dashboard.funnel.sales_status_received')
                : t('dashboard.funnel.sales_status_revoked')
            }
            mode="hover"
            key="status-tooltip"
          >
            {sale.cancelledAt ? (
              <CloseCircleIcon
                className={`w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] ${
                  sale.cancelledAt && 'fill-danger'
                }`}
              />
            ) : (
              <CheckCircleIcon
                className={`w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] ${
                  !sale.cancelledAt && 'fill-green'
                } `}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <div className="flex flex-col mb-5 lg:px-[15px] px-[15px]">
      <ListingLayout
        counter={false}
        data={sales}
        title={''}
        type={'compact'}
        filterData={{
          limiter: { limit, setLimit },
          paginator: { pagination, setPagination },
          filterer: { filter, setFilter, defaultFilters },
          filterable: [
            {
              field: 'date',
              onRender: (value, onChange) => (
                <DateRangePicker
                  key={'funnel-sales-date-filter'}
                  maxDate={getToday(user?.timezone)}
                  getDefaultDate={() => getDateRangeWithDaysDifference(57, user?.timezone)}
                  date={value}
                  onChange={date => onChange(date)}
                />
              ),
            },
          ],
        }}
      >
        <Table
          data={sales}
          columns={columns}
          limiter={{ limit, setLimit }}
          paginator={{ pagination, setPagination }}
          actions={{}}
          withBorders
        />
      </ListingLayout>
    </div>
  )
}

export default SalesTab
