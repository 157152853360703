import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnelStepsList(id?: FunnelId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelStepInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: funnelStepsList, mutate: mutateFunnelStepsList } = useSWR(() => {
    if (user && id) {
      return `${FUNNELS_API}/${id}/steps/list`
    }
    return null
  }, fetcher)

  return { funnelStepsList, mutateFunnelStepsList }
}
