import {
  AffiliateCommissionErrorsInterface,
  AffiliateCommissionInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/affiliate-commission/types/affiliate-commission-interface'
import RemoveIcon from 'modules/funnels/funnel/icons/remove-icon'
import { getFunnelSettingsFieldStateStatusIcon } from 'modules/funnels/funnel/utils/get-funnel-settings-field-status-icon'
import React, { useEffect, useState } from 'react'
import FormInput, { handleNumberInput } from 'shared/components/form-input'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'

interface AffiliateCommissionProps extends AffiliateCommissionInterface {
  onSave: (data: AffiliateCommissionInterface) => Promise<void>
}

const defaultValues: AffiliateCommissionInterface = { affiliateCommission: 0, payoutDelay: 0 }
const defaultErrors: AffiliateCommissionErrorsInterface = {
  affiliateCommission: '',
  payoutDelay: '',
}
function AffiliateCommission({
  affiliateCommission,
  payoutDelay,
  onSave,
}: AffiliateCommissionProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useLocoTranslation()
  const [isCommissionFetching, setIsCommissionFetching] = useState(false)
  const [isPayoutDelayFetching, setIsPayoutDelayFetching] = useState(false)

  const [tempState, setTempState] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)

  useEffect(() => {
    if (affiliateCommission || payoutDelay) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    setTempState({
      affiliateCommission,
      payoutDelay,
    })
  }, [affiliateCommission, payoutDelay])

  const onCommissionSave = async () => {
    try {
      setErrors(defaultErrors)
      await onSave(tempState)
    } catch (error) {
      if (error instanceof BadRequest) {
        if (error.errors.fields) {
          const errors = error.errors.fields as unknown as any
          setErrors({
            affiliateCommission: errors.affiliateCommission?.join(', '),
            payoutDelay: errors.payoutDelay?.join(', '),
          })
        }
      }
    }
  }

  return (
    <>
      <div className="flex items-center">
        {isOpen ? (
          <RemoveIcon
            className={`fill-blue w-[18px] h-[18px] cursor-pointer mr-3`}
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <AddIcon
            className={`fill-blue w-[18px] h-[18px] cursor-pointer mr-3`}
            onClick={() => setIsOpen(true)}
          />
        )}
        <span className={'lg:text-[15px] text-sm text-darkblue font-normal'}>
          {t('dashboard.funnel.configuration.offer.affiliate_commission')}
        </span>
        {isOpen && <span className="text-danger">*</span>}
      </div>
      {isOpen && (
        <div className="flex xl:flex-row flex-col xl:gap-10 gap-4">
          <FormInput
            label={t('dashboard.funnel.configuration.offer.affiliate_commission')}
            labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
            onChange={e =>
              setTempState(prev => ({
                ...prev,
                affiliateCommission: handleNumberInput(e.target.value, 100),
              }))
            }
            addonAfter={
              !isCommissionFetching
                ? '%'
                : getFunnelSettingsFieldStateStatusIcon(isCommissionFetching)
            }
            value={tempState.affiliateCommission}
            required
            onBlur={async () => {
              setIsCommissionFetching(true)
              await onCommissionSave()
              setIsCommissionFetching(false)
            }}
            error={errors.affiliateCommission}
          />
          <FormInput
            label={t('dashboard.funnel.configuration.offer.payout_delay')}
            labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
            onChange={e =>
              setTempState(prev => ({
                ...prev,
                payoutDelay: handleNumberInput(e.target.value),
              }))
            }
            addonAfter={
              !isPayoutDelayFetching
                ? tempState.payoutDelay || 0 > 1
                  ? t('dashboard.transactions.sub_interval.days')
                  : t('dashboard.transactions.sub_interval.day')
                : getFunnelSettingsFieldStateStatusIcon(isPayoutDelayFetching)
            }
            value={tempState.payoutDelay}
            required
            onBlur={async () => {
              setIsPayoutDelayFetching(true)
              await onCommissionSave()
              setIsPayoutDelayFetching(false)
            }}
            error={errors.payoutDelay}
          />
        </div>
      )}
    </>
  )
}

export default AffiliateCommission
