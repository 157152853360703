import { COUPONS_API } from 'modules/coupons/api/coupon-api'
import { CouponInterface } from 'modules/coupons/types/coupon-interface'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

export function useCustomerCoupons(currency: CurrencyEnum, shouldFetch?: boolean) {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, CouponInterface[]>({
    method: RequestMethodsEnum.get,
  })

  const { data: coupons, mutate: mutateCoupons } = useSWR(() => {
    if (shouldFetch) {
      return `${COUPONS_API}/list-by-currency/${currency}`
    }
    return null
  }, fetcher)

  return { coupons, mutateCoupons }
}
