import { useEffect, useRef, useState } from 'react'

const CREATE_BUTTON_HEIGHT = 59

function useContentScrollingSize(isMobile: boolean) {
  const listRef = useRef<HTMLUListElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [listHeight, setListHeight] = useState<number | string>('')
  const [contentHeight, setContentHeight] = useState<number | string>('')
  const listContainerHeight = listRef?.current?.getBoundingClientRect().height
  const isBothRefsDefined = listRef && listRef.current && contentRef && contentRef.current

  useEffect(() => {
    if (!contentRef.current || !listRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      if (isBothRefsDefined && !isMobile) {
        // case where listing have enough items to expend
        if (listRef.current?.scrollHeight > window.innerHeight) {
          // case where listing can expand and we need to make list height according to content box height else window size
          if (
            listContainerHeight &&
            document.body.scrollHeight > window.innerHeight &&
            listRef.current?.scrollHeight > contentRef.current.getBoundingClientRect().height
          ) {
            setListHeight(contentRef?.current.getBoundingClientRect().height - CREATE_BUTTON_HEIGHT)
          } else {
            setListHeight('calc(100vh - 15rem)')
          }
        }

        // case where listing cannot expand and we need to add scroll to the content box
        if (
          listContainerHeight &&
          contentRef.current.getBoundingClientRect().height > window.innerHeight &&
          listRef.current?.scrollHeight < contentRef.current.getBoundingClientRect().height
        ) {
          setContentHeight(`calc(100vh - 15rem + ${CREATE_BUTTON_HEIGHT}px)`)
        } else {
          setContentHeight('')
        }
      }
    })
    resizeObserver.observe(contentRef.current)
    return () => resizeObserver.disconnect()
  }, [listRef.current, contentRef.current])

  return { listHeight, listRef, contentRef, contentHeight }
}

export default useContentScrollingSize
