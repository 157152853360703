import { DigitalProductBase } from 'modules/digital-product/components/digital-product-base'
import { DigitalProductTabEnum } from 'modules/digital-product/enums/digital-product-tab-enum'
import { DigitalProductProps } from 'modules/digital-product/index'
import { PricePlansReadOnly } from 'modules/digital-product/price-plans/components/price-plans-read-only'
import { handleOfferPricePlansErrors } from 'modules/digital-product/price-plans/utils/handle-offer-price-plans-errors'
import { ResourcesReadOnly } from 'modules/digital-product/resources/components/resources-read-only'
import {
  DigitalProductChangeInterface,
  DigitalProductId,
  DigitalProductInterface,
} from 'modules/digital-product/types/digital-product-interface'
import { getDigitalProductsData } from 'modules/digital-product/utils/get-digital-products-data'
import { AddItemIcon } from 'modules/funnels/funnel/components/offer-settings/add-item-icon'
import React, { FC, useEffect, useRef, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import FormSelect, { FormSelectProps } from 'shared/components/form-select'
import { Tooltip } from 'shared/components/tooltip'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BinIcon from 'shared/icons/bin-icon'
import PenIcon from 'shared/icons/pen-icon'
import SaveIcon from 'shared/icons/save-icon'

interface DigitalProductSelectProps
  extends Omit<
    DigitalProductProps,
    'paymentMethods' | 'mutateDigitalProduct' | 'currency' | 'product' | 'source'
  > {
  digitalProductList: DigitalProductInterface[]
  setTab: (tab: DigitalProductTabEnum) => void
  digitalProductId?: DigitalProductId
  onDelete: (id: DigitalProductId) => void
}

const DigitalProductSelect: FC<DigitalProductSelectProps> = ({
  digitalProductId,
  onSave,
  isBump,
  digitalProductList,
  setTab,
  onDelete,
}) => {
  const formSelectRef = useRef<NonNullable<FormSelectProps['formSelectRef']>['current']>(null)
  const [id, setId] = useState<DigitalProductChangeInterface['id']>(digitalProductId ?? null)
  const { t } = useLocoTranslation()
  const [hasChanged, setHasChanged] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    setId(digitalProductId ?? null)
  }, [digitalProductId])

  const selectedProduct = digitalProductList.find(product => product.id === id)

  const handleSave = async () => {
    setIsFetching(true)
    try {
      await onSave(selectedProduct?.id ?? null)
      setHasChanged(false)
      setIsFetching(false)
    } catch (e) {
      if (e instanceof BadRequest) {
        handleOfferPricePlansErrors(e.errors)
      }
    } finally {
      setIsFetching(false)
    }
  }

  const hasResources = selectedProduct && selectedProduct.resources.length !== 0

  const hasPricePlans = selectedProduct && selectedProduct.pricePlans.length !== 0

  const showDetails = hasResources || hasPricePlans

  return (
    <>
      <DigitalProductBase
        additionalChild={
          <div className={'flex items-center gap-3'}>
            {!hasChanged && digitalProductId && (
              <Tooltip
                mode={'hover'}
                label={t('dashboard.funnel.configuration.offer.edit_digital_product')}
              >
                <button
                  onClick={() => setTab(DigitalProductTabEnum.edit)}
                  className={'outline-none group'}
                >
                  <PenIcon
                    className={'group-hover:fill-blue main-transition-colors w-[20px] h-[20px]'}
                  />
                </button>
              </Tooltip>
            )}
            <AddItemIcon onClick={() => setTab(DigitalProductTabEnum.create)} />
          </div>
        }
      />
      <FormSelect
        formSelectRef={formSelectRef}
        data={getDigitalProductsData(digitalProductList, isBump)}
        className={'sm:min-w-0'}
        value={id || undefined}
        onChange={data => {
          setId(data)
          setHasChanged(data !== digitalProductId)
        }}
        optionIconRenderer={data => {
          if (data) {
            return (
              <BinIcon
                className="w-4 h-4 group hover:fill-red cursor-pointer"
                onClick={e => {
                  e.stopPropagation()
                  formSelectRef.current?.buttonRef?.current?.click()
                  onDelete(data.id)
                }}
              />
            )
          }
        }}
      />
      {hasChanged && (
        <PrimaryButton
          width="large"
          onClick={handleSave}
          isFetching={isFetching}
          type="submit"
          className="font-inter text-sm font-bold mr-auto"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      )}
      {showDetails && (
        <div className={'flex flex-col gap-5 ml-5'}>
          <ResourcesReadOnly resources={selectedProduct.resources} />
          <hr className={'border-t-1 border-solid border-gray/30'} />
          <PricePlansReadOnly pricePlans={selectedProduct.pricePlans} />
        </div>
      )}
    </>
  )
}

export default DigitalProductSelect
