import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

interface FunnelStepDataInterface {
  id: number
  caption: string
}

const availableFunnelStepTypes = [FunnelStepTypeEnum.OfferForm, FunnelStepTypeEnum.SalesPage]

export const getFunnelStepsToRedirectData = (funnelStepsList?: FunnelStepInterface[]) => {
  return (
    funnelStepsList?.reduce((acc, curr) => {
      if (curr.page) {
        acc.push({ id: curr.id, caption: curr.name })
      }
      return acc
    }, [] as FunnelStepDataInterface[]) || []
  )
}

export const getAvailableFunnelStepsData = (funnelStepsList?: FunnelStepInterface[]) => {
  return (
    funnelStepsList?.reduce((acc, curr) => {
      if (availableFunnelStepTypes.includes(curr.type)) {
        acc.push({ id: curr.id, caption: curr.name })
      }
      return acc
    }, [] as FunnelStepDataInterface[]) || []
  )
}
