import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

export function useSaleLimit(id: FunnelStepId, shouldFetch?: boolean) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    { salesCount: number }
  >({ method: RequestMethodsEnum.get })

  const { data: saleLimitData } = useSWR(() => {
    if (!shouldFetch) return null
    return `${FUNNELS_API}/step/${id}/sales-count`
  }, fetcher)

  return { saleLimitData }
}
