import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import {
  SplitTestComponentTypeEnum,
  SplitTestComponentTypeTitles,
} from '../../enums/split-tests-component-type-enum'
import { useUpdateFunnelStepHash } from '../../hooks/use-update-funnel-step-hash'
import WebsiteIcon from '../../icons/website-icon'
import { FunnelStepId } from '../../types/funnel-step-interface'

interface SplitTestItemProps {
  type: SplitTestComponentTypeEnum
  funnelStep: { id: FunnelStepId; name: string }
  className?: string
}

function SplitTestItem({ type, funnelStep, className }: SplitTestItemProps) {
  const { t } = useLocoTranslation()
  const { updateFunnelStepHash } = useUpdateFunnelStepHash()

  const handleClick = () => {
    updateFunnelStepHash(`#step${funnelStep.id}`)
  }

  return (
    <div
      className={`flex flex-col gap-4 font-inter text-darkblue text-xl items-center justify-start flex-[33_1_0%] xl:w-[280px] ${className}`}
    >
      <WebsiteIcon
        className="cursor-pointer lg:w-[121px] lg:h-[104px] w-[85px] h-[73px]"
        onClick={handleClick}
      />
      <div className="flex flex-col gap-1 justify-center items-center lg:text-base text-[15px]">
        <div className="opacity-60 text-center">{t(SplitTestComponentTypeTitles[type])}</div>
        <div className="font-bold text-center">{funnelStep.name}</div>
      </div>
    </div>
  )
}

export default SplitTestItem
