import React from 'react'

function WebinarThankYouFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 80 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12_15)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.997H11C4.92487 12.997 0 17.9219 0 23.997V81.997C0 88.0721 4.92487 92.997 11 92.997H69C75.0751 92.997 80 88.0721 80 81.997V23.997C80 17.9219 75.0751 12.997 69 12.997H67V19.997C67 22.2061 65.2091 23.997 63 23.997H16C13.7909 23.997 12 22.2061 12 19.997V12.997ZM62.0683 41.9508C63.3283 43.2048 63.3077 45.2178 62.0223 46.4471L36.2538 71.0881C34.9864 72.3 32.958 72.3 31.6907 71.0881L19.9777 59.8876C18.6923 58.6584 18.6717 56.6454 19.9317 55.3914C21.1918 54.1374 23.2553 54.1173 24.5408 55.3465L33.9722 64.3653L57.4592 41.9059C58.7447 40.6767 60.8082 40.6968 62.0683 41.9508Z"
          fill="#CBD5E1"
        />
        <path
          d="M17 4.99701C17 2.78787 18.7909 0.997009 21 0.997009H58C60.2091 0.997009 62 2.78787 62 4.99701V14.997C62 17.2061 60.2091 18.997 58 18.997H21C18.7909 18.997 17 17.2061 17 14.997V4.99701Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_15">
          <rect width="80" height="93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WebinarThankYouFunnelStepIcon
