import {
  createMarketPlaceOffer,
  updateMarketPlaceOffer,
} from 'modules/funnels/api/market-place-api'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useMarketplaceOfferCategories } from 'modules/funnels/funnel/hooks/use-market-place-offer-categories'
import { useMarketplaceOffer } from 'modules/funnels/funnel/hooks/use-marketplace-offer'
import MarketPlaceIcon from 'modules/funnels/funnel/icons/marketplace-icon'
import { FunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'
import {
  MarketPlaceOfferCreateApiInterface,
  MarketPlaceOfferCreateInterface,
  MarketPlaceOfferErrorsInterface,
} from 'modules/funnels/funnel/types/marketplace-offer-interface'
import {
  getChildCategories,
  getInfoPageData,
  getOptInPageData,
  getSaleFunnelStepData,
  idChildCategory,
  isCategoryHaveChildCategories,
} from 'modules/funnels/funnel/utils/marketplace-offer-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { Checkbox } from 'shared/components/checkbox'
import EditModal from 'shared/components/edit-modal'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import FormTextarea from 'shared/components/form-textarea'
import { Hint } from 'shared/components/hint'
import { ImageUploaderWithPreview } from 'shared/components/image-uploader/image-uploader-with-preview'
import Popover from 'shared/components/popover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { KeyedMutator } from 'swr'

const defaultErrors: MarketPlaceOfferErrorsInterface = {
  fields: {
    parentCategory: '',
    childCategory: '',
    optinFunnelStep: '',
    saleFunnelStep: '',
    infoStep: '',
    name: '',
    description: '',
    image: '',
  },
}

const defaultValues: MarketPlaceOfferCreateInterface = {
  name: '',
  description: '',
  image: null,
  optinFunnelStep: undefined,
  saleFunnelStep: undefined,
  infoStep: undefined,
  active: true,
  category: {
    parentCategory: undefined,
    childCategory: undefined,
  },
}

interface MarketPlaceOfferModalProps {
  funnel: FunnelInterface | undefined
  mutateFunnel: KeyedMutator<FunnelInterface>
}

function MarketplaceOfferModal({ funnel, mutateFunnel }: MarketPlaceOfferModalProps) {
  const haveMarketplaceOffer = !!funnel?.marketplace?.id
  const router = useRouter()
  const funnelId = parseInt(router.query.funnelId as string) as FunnelId
  const { t } = useLocoTranslation()

  const [errors, setErrors] = useState(defaultErrors)
  const [tempState, setTempState] = useState(defaultValues)
  const [opened, setOpened] = useState(false)
  const [shouldFetchMarketPlaceOffer, setShouldFetchMarketPlaceOffer] = useState(false)
  const [shouldFetchMarketPlaceOfferCategories, setShouldFetchMarketPlaceOfferCategories] =
    useState(false)

  const { marketPlaceOffer, mutate } = useMarketplaceOffer(
    funnel?.marketplace?.id,
    shouldFetchMarketPlaceOffer,
  )
  const { funnelStepsList } = useFunnelStepsList(funnelId)
  const { marketPlaceOfferCategories } = useMarketplaceOfferCategories(
    shouldFetchMarketPlaceOfferCategories,
  )

  const onUpdateMarketPlaceOffer = async (data: MarketPlaceOfferCreateInterface) => {
    const requestData: MarketPlaceOfferCreateApiInterface = {
      name: data.name,
      description: data.description,
      image: data.image?.id,
      optinFunnelStep: data.optinFunnelStep,
      saleFunnelStep: data.saleFunnelStep,
      infoStep: data.infoStep,
      active: data.active,
      parentCategory: data.category.parentCategory,
      childCategory: data.category.childCategory,
    }
    if (!haveMarketplaceOffer) {
      await fetchWithFieldErrors(async () => {
        const response = await createMarketPlaceOffer(funnelId, requestData)
        await mutate(data => {
          if (data) {
            return response.data
          }
        }, false)
        await mutateFunnel(data => {
          if (data) {
            return { ...data, marketplace: { id: response.data.id } }
          }
        })
      }, setErrors)
      window.location.assign('/marketplace')
    }
    await fetchWithFieldErrors(async () => {
      if (funnel?.marketplace?.id) {
        const response = await updateMarketPlaceOffer(funnel.marketplace.id, requestData)
        await mutate(data => {
          if (data) {
            return response.data
          }
        }, false)
        toast.success(t('global.changes_saved'))
      }
    }, setErrors)
  }

  useEffect(() => {
    if (marketPlaceOffer) {
      const categoriesProperties = marketPlaceOffer.category.parentCategory
        ? {
            category: {
              parentCategory: marketPlaceOffer.category.parentCategory?.id,
              childCategory: marketPlaceOffer.category.id,
            },
          }
        : { category: { parentCategory: marketPlaceOffer.category.id } }

      setTempState(prev => {
        return {
          ...prev,
          name: marketPlaceOffer.name,
          description: marketPlaceOffer.description,
          image: marketPlaceOffer.image,
          optinFunnelStep: marketPlaceOffer.optinFunnelStep?.id,
          saleFunnelStep: marketPlaceOffer.saleFunnelStep?.id,
          infoStep: marketPlaceOffer.infoStep?.id,
          active: marketPlaceOffer.active,
          ...categoriesProperties,
        }
      })
    }
  }, [marketPlaceOffer])

  const marketPlaceOfferCategoriesData = marketPlaceOfferCategories?.map(category => {
    return {
      id: category.id,
      caption: category.name,
    }
  })

  return (
    <>
      <PrimaryButton
        onClick={() => {
          setOpened(true)
        }}
        disabled={!funnel}
        data-test-element={'funnel-marketplace-offer-button'}
      >
        <MarketPlaceIcon className={'fill-white w-6'} />
        <span className={'hidden lg:flex'}>{t('dashboard.funnel.marketplace_offer')}</span>
      </PrimaryButton>
      <EditModal
        fullCaption={
          haveMarketplaceOffer
            ? t('dashboard.funnel.marketplace_offer_edit_title')
            : t('dashboard.funnel.marketplace_offer_create_title')
        }
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        onOpen={() => {
          if (haveMarketplaceOffer) {
            setShouldFetchMarketPlaceOffer(true)
          }
          setShouldFetchMarketPlaceOfferCategories(true)
        }}
        instance={tempState}
        isPreFetching={(haveMarketplaceOffer && !marketPlaceOffer) || !marketPlaceOfferCategories}
        editable={[
          {
            field: 'name',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormInput
                key="funnel-marketplace-offer-name"
                isPreFetching={isPreFetching}
                value={value}
                onChange={e => onChange(e.target.value)}
                label={t('dashboard.funnel.marketplace_offer_name_title')}
                error={errors.fields.name}
                required
              />
            ),
          },
          {
            field: 'description',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormTextarea
                key="funnel-marketplace-offer-description"
                isLoading={isPreFetching}
                onChange={e => onChange(e.target.value)}
                value={value || ''}
                label={
                  <>
                    <div
                      className={`flex gap-3 items-center [&>svg]:fill-blue [&>svg]:main-transition-colors`}
                    >
                      {t('global.description')}
                      <Popover
                        label={t('dashboard.funnel.marketplace_offer_description_popover')}
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  </>
                }
                error={errors.fields.description}
                className={'min-h-[126px]'}
                required
              />
            ),
          },
          {
            field: 'category',
            onRender: (value, onChange, _, __, isPreFetching) => {
              const shouldRenderChildCategories = isCategoryHaveChildCategories(
                value.parentCategory,
                marketPlaceOfferCategories,
              )
              const childCategoriesSelectData = getChildCategories(
                value.parentCategory,
                marketPlaceOfferCategories,
              )
              return (
                <React.Fragment key="funnel-marketplace-offer-categories">
                  <FormSelect
                    isPreFetching={isPreFetching}
                    key={'funnel-marketplace-offer-parent-category'}
                    label={t('dashboard.funnel.marketplace_offer_category')}
                    data={marketPlaceOfferCategoriesData}
                    onChange={data => {
                      if (!idChildCategory(data, value.childCategory, marketPlaceOfferCategories)) {
                        onChange({ childCategory: undefined, parentCategory: data })
                      } else {
                        onChange({ parentCategory: data, childCategory: value.childCategory })
                      }
                    }}
                    value={value.parentCategory}
                    error={errors.fields.parentCategory}
                    required
                  />
                  {shouldRenderChildCategories && (
                    <FormSelect
                      isPreFetching={isPreFetching || !childCategoriesSelectData}
                      key={'funnel-marketplace-offer-child-category'}
                      data={childCategoriesSelectData}
                      onChange={data =>
                        onChange({ parentCategory: value.parentCategory, childCategory: data })
                      }
                      value={value.childCategory}
                      error={errors.fields.childCategory}
                    />
                  )}
                </React.Fragment>
              )
            },
          },
          {
            field: 'active',
            onRender: (value, onChange) => (
              <Checkbox
                key={'funnel-marketplace-offer-checkbox'}
                label={t('dashboard.funnel.marketplace_offer_show_title')}
                value={value}
                onChange={onChange}
              />
            ),
          },
          {
            field: 'image',
            onRender: (value, onChange) => (
              <ImageUploaderWithPreview
                key={'image-edit'}
                label={
                  <Hint label={t('dashboard.funnel.marketplace_offer_image_popover')}>
                    {t('global.image')}
                  </Hint>
                }
                previewProps={{
                  className: 'h-[300px] w-[300px]',
                  src: value?.path,
                }}
                onRemove={() => onChange(null)}
                imageLoaderProps={{
                  imageCropperProps: { aspectRatio: 10 / 7 },
                  fileSelectorDropZoneClassName: 'w-[200px] h-[30px]',
                  onUploadSuccess: dataFile => onChange(dataFile),
                  source: 'settings',
                  error: errors.fields.image,
                }}
              />
            ),
          },
          {
            field: 'optinFunnelStep',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormSelect
                isPreFetching={isPreFetching}
                key={'funnel-marketplace-offer-opt-in-page'}
                label={t('dashboard.funnel.marketplace_offer_opt_in_page')}
                data={getOptInPageData(funnelStepsList)}
                onChange={data => onChange(data)}
                value={value}
                error={errors.fields.optinFunnelStep}
              />
            ),
          },
          {
            field: 'saleFunnelStep',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormSelect
                isPreFetching={isPreFetching}
                key={'funnel-marketplace-offer-sales-funnel-step'}
                label={t('dashboard.funnel.marketplace_offer_sales_funnel_step')}
                data={getSaleFunnelStepData(funnelStepsList)}
                onChange={data => onChange(data)}
                value={value}
                error={errors.fields.saleFunnelStep}
              />
            ),
          },
          {
            field: 'infoStep',
            onRender: (value, onChange, _, __, isPreFetching) => (
              <FormSelect
                isPreFetching={isPreFetching}
                key={'funnel-marketplace-offer-info-page'}
                label={t('dashboard.funnel.marketplace_offer_info_page')}
                data={getInfoPageData(funnelStepsList)}
                onChange={data => onChange(data)}
                value={value}
                error={errors.fields.infoStep}
              />
            ),
          },
        ]}
        onEdit={data => onUpdateMarketPlaceOffer(data)}
      />
    </>
  )
}

export default MarketplaceOfferModal
