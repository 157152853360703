import React from 'react'

function CouponIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.7326C0.5 10.4968 0.69114 10.3057 0.9269 10.3057C2.10544 10.3057 3.06072 9.35042 3.06072 8.17191C3.06072 6.99337 2.10544 6.03789 0.9269 6.03789C0.69114 6.03789 0.5 5.84679 0.5 5.61119V1.34321C0.5 1.1076 0.69114 0.916504 0.9269 0.916504H20.0731C20.3089 0.916504 20.5 1.1076 20.5 1.34321V5.61119C20.5 5.84679 20.3089 6.03789 20.0731 6.03789C18.8945 6.03789 17.9393 6.99336 17.9393 8.17191C17.9393 9.35042 18.8945 10.3057 20.0731 10.3057C20.3089 10.3057 20.5 10.4968 20.5 10.7326V15.0004C20.5 15.2362 20.3089 15.4271 20.0731 15.4271H0.9269C0.69114 15.4271 0.5 15.2362 0.5 15.0004V10.7326ZM9.88959 5.18407C9.88959 4.24131 9.12528 3.47699 8.18235 3.47699C7.23959 3.47699 6.47527 4.24131 6.47527 5.18407C6.47527 6.12702 7.23959 6.89133 8.18235 6.89133C9.12529 6.89133 9.88959 6.12702 9.88959 5.18407ZM6.56885 12.173C6.4215 12.3571 6.45132 12.6256 6.63549 12.773C6.81947 12.9202 7.08811 12.8903 7.23529 12.7064L14.0637 4.17049C14.2111 3.98649 14.1812 3.71785 13.9971 3.57069C13.8131 3.42331 13.5445 3.45314 13.3973 3.63731L6.56885 12.173ZM14.1572 11.1594C14.1572 10.2166 13.3929 9.45227 12.4502 9.45227C11.5072 9.45227 10.7429 10.2166 10.7429 11.1594C10.7429 12.1021 11.5072 12.8664 12.4502 12.8664C13.3929 12.8664 14.1572 12.1021 14.1572 11.1594Z"
        fill="#00A0FF"
      />
    </svg>
  )
}

export default CouponIcon
