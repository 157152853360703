import EditFunnelModal from 'modules/funnels/funnel/components/edit-funnel-modal'
import MarketplaceOfferModal from 'modules/funnels/funnel/components/marketplace-offer-modal'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React from 'react'
import Breadcrumbs, { BreadcrumbsData } from 'shared/components/breadcrumbs'
import { PrimaryButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useWindowSize from 'shared/hooks/use-window-size'
import EyeIcon from 'shared/icons/eye-icon'

interface FunnelHeaderProps {
  funnelId: FunnelId
  selectedFunnelStep: FunnelStepInterface | null
}

function FunnelHeader({ funnelId, selectedFunnelStep }: FunnelHeaderProps) {
  const { t } = useLocoTranslation()
  const { funnel, mutateFunnel } = useFunnel(funnelId)
  const { width } = useWindowSize()

  const breadcrumbsData: BreadcrumbsData[] = [{ caption: funnel ? funnel.name : '' }]

  if (width > 600) {
    breadcrumbsData.unshift({
      caption: t('dashboard.funnel.breadcrumbs.funnel_list'),
      href: '/funnels',
      isStatic: true,
    })
  } else if (selectedFunnelStep) {
    breadcrumbsData.push({
      caption: selectedFunnelStep.name,
      href: '',
      isStatic: true,
    })
  }
  return (
    <div className="flex justify-between items-center gap-5">
      <Breadcrumbs data={breadcrumbsData} isFetching={!funnel} />
      <div className={'flex lg:gap-5 gap-2 whitespace-nowrap'}>
        <PrimaryButton
          aria-disabled={false}
          href={`/funnel/${funnelId}/show`}
          target={'_blank'}
          data-test-element={'view-funnel-button'}
        >
          <EyeIcon className={'fill-white'} />
          <span className={'hidden lg:flex'}>{t('dashboard.funnel.view_funnel')}</span>
        </PrimaryButton>
        <EditFunnelModal />
        <MarketplaceOfferModal funnel={funnel} mutateFunnel={mutateFunnel} />
      </div>
    </div>
  )
}

export default FunnelHeader
