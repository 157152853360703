import { LocaleEnum } from 'shared/enums/locale-enum'

export const getGrantCourseAccessUrl = (locale?: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.FRENCH:
      return 'https://aide.systeme.io/article/1621-comment-fixer-une-date-specifique-pour-accorder-lacces-a-une-formation'
    case LocaleEnum.DEUTSCH:
      return 'https://help-de.systeme.io/article/1620-so-legen-sie-ein-bestimmtes-datum-fur-den-zugang-zu-einem-kurs-fest'
    case LocaleEnum.ITALIAN:
      return 'https://help-it.systeme.io/article/1622-come-impostare-una-data-specifica-per-dare-accesso-a-un-corso'
    case LocaleEnum.SPANISH:
      return 'https://help-es.systeme.io/article/1624-como-establecer-una-fecha-especifica-para-otorgar-acceso-a-un-curso'
    default:
      return 'https://help.systeme.io/article/1619-how-to-set-a-specific-date-to-grant-access-to-a-course'
  }
}
