import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import EmptyListIcon from 'shared/icons/empty-list-icon'

const EmptyFunnelStepList = () => {
  const { t } = useLocoTranslation()
  return (
    <div
      className={`flex w-full justify-center items-center flex-col gap-3py-20 bg-white rounded-lg pb-10`}
    >
      <EmptyListIcon />
      <div className={`flex flex-col items-center`}>
        <span className={'text-darkblue text-base'}>
          {t('dashboard.funnel.configuration.common.list.empty_title.first_line')}
        </span>
        <span className={'text-darkblue text-base'}>
          {t('dashboard.funnel.configuration.common.list.empty_title.list.empty_list')}
        </span>
      </div>
    </div>
  )
}

export default EmptyFunnelStepList
