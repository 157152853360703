import httpClient from 'shared/utils/http-client'
import { ProductInterface } from 'modules/products/types/product-interface'

export const PRODUCTS_API = '/api/dashboard/customer/products'
export const DEFAULT_PRODUCTS_API = '/api/dashboard/customer/products/default'

export const removeProduct = (id: number) => httpClient.delete(`${PRODUCTS_API}/${id}`)

export const updateProduct = (id: number, data: Partial<ProductInterface>) =>
  httpClient.put(`${PRODUCTS_API}/${id}`, data)

export const createProduct = (data: Partial<ProductInterface>) =>
  httpClient.post(`${PRODUCTS_API}`, data)
