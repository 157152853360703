import { useBaseConfigurationTabFieldState } from 'modules/funnels/funnel/hooks/use-update-base-configuration-tab'
import {
  BaseFunnelStepConfigurationEditErrorsInterface,
  BaseFunnelStepConfigurationEditInterface,
  BaseFunnelStepConfigurationFieldErrorResponseContentInterface,
} from 'modules/funnels/funnel/types/base-funnel-step-configuration-settings-interface'
import { getFunnelSettingsFieldStateStatusIcon } from 'modules/funnels/funnel/utils/get-funnel-settings-field-status-icon'
import React, { FC, useEffect, useState } from 'react'
import FormInput from 'shared/components/form-input'
import FormUrlPathInput from 'shared/components/form-url-path-input'
import { Hint } from 'shared/components/hint'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface BaseConfigurationProps {
  onSave: (data: BaseFunnelStepConfigurationEditInterface) => Promise<void>
  domainName?: string
  pathDisabled?: boolean
  name?: string
  path?: string
}

const defaultValues: BaseFunnelStepConfigurationEditInterface = {
  name: '',
  page: {
    path: '',
  },
}

const defaultErrors: BaseFunnelStepConfigurationEditErrorsInterface = {
  name: '',
  page: {
    path: '',
  },
}

const BaseConfiguration: FC<BaseConfigurationProps> = ({
  onSave,
  domainName,
  pathDisabled,
  name,
  path,
}) => {
  const { t } = useLocoTranslation()
  const [errors, setErrors] = useState(defaultErrors)
  const [tempState, setTempState] = useState(defaultValues)
  const [savedState, setSavedState] = useState(defaultValues)

  const [isFetching, setIsFetching] = useState(false)

  const { isNameChanged, isPagePathChanged, successState, setSuccessState } =
    useBaseConfigurationTabFieldState({ savedState: savedState, currentState: tempState })

  useEffect(() => {
    if (name !== undefined && path !== undefined) {
      const newState = { name, page: { path } }
      setTempState(newState)
      setSavedState(newState)
    }
  }, [name, path])

  const handleSave = async () => {
    try {
      setIsFetching(true)
      setErrors(defaultErrors)
      await onSave(tempState)
      setSuccessState(prev => {
        return {
          ...prev,
          name: isNameChanged,
          path: isPagePathChanged,
        }
      })
      setSavedState({ ...tempState })
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.fields) {
          const errors = e.errors
            .fields as BaseFunnelStepConfigurationFieldErrorResponseContentInterface
          setErrors(prev => ({
            ...prev,
            name: errors.name?.join(''),
            page: { path: errors.page?.path?.join('') },
          }))
        }
      }
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div className="flex flex-col gap-5 ">
      <FormInput
        required
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium '}
        label={t('global.name')}
        value={tempState.name}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, name: e.target.value }
          })
        }
        maxLength={256}
        onBlur={() => isNameChanged && handleSave()}
        error={errors.name}
        success={successState.name}
        statusIcon={getFunnelSettingsFieldStateStatusIcon(
          isFetching && isNameChanged,
          successState.name,
        )}
      />
      <FormUrlPathInput
        required
        copyButtonClassName={`border border-gray/30 hover:border-blue group !bg-white [&>*]:group-hover:!fill-blue ${
          successState.path && 'border-green'
        }`}
        copyIconClassName="fill-gray group-hover:fill-blue main-transition-colors"
        value={tempState.page?.path}
        domainName={domainName}
        label={
          <Hint
            label={t('dashboard.funnel.configuration.webinar.url_path_popover')}
            className={'lg:text-[15px] font-medium text-sm text-darkblue'}
          >
            {t('global.url_path')}
          </Hint>
        }
        onChange={e =>
          setTempState(prev => {
            return { ...prev, page: { path: e.target.value } }
          })
        }
        onBlur={() => isPagePathChanged && handleSave()}
        disabled={pathDisabled}
        error={errors.page.path}
        statusIcon={getFunnelSettingsFieldStateStatusIcon(
          isFetching && isPagePathChanged,
          successState.path,
        )}
        success={successState.path}
      />
    </div>
  )
}

export default BaseConfiguration
