import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { OfferCategoryInterface } from 'modules/funnels/funnel/types/marketplace-offer-interface'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

interface DataItemInterface {
  id: number
  caption: string
}

const optInTypes = [FunnelStepTypeEnum.Squeeze, FunnelStepTypeEnum.WebinarRegistration]
export const getOptInPageData = (funnelStepsList?: FunnelStepInterface[]) => {
  return funnelStepsList?.reduce((acc, cur) => {
    if (optInTypes.includes(cur.type)) {
      acc.push({ id: cur.id, caption: cur.name })
    }
    return acc
  }, [] as DataItemInterface[])
}

const salesFunnelStepTypes = [
  FunnelStepTypeEnum.SalesPage,
  FunnelStepTypeEnum.OfferForm,
  FunnelStepTypeEnum.WebinarThankYou,
  FunnelStepTypeEnum.OptInThankYouPage,
  FunnelStepTypeEnum.OrderThankYouPage,
]

export const getSaleFunnelStepData = (funnelStepsList?: FunnelStepInterface[]) => {
  return funnelStepsList?.reduce((acc, cur) => {
    if (salesFunnelStepTypes.includes(cur.type)) {
      acc.push({ id: cur.id, caption: cur.name })
    }
    return acc
  }, [] as DataItemInterface[])
}

const infoPageTypes = [FunnelStepTypeEnum.InfoPage]

export const getInfoPageData = (funnelStepsList?: FunnelStepInterface[]) => {
  return funnelStepsList?.reduce((acc, cur) => {
    if (infoPageTypes.includes(cur.type)) {
      acc.push({ id: cur.id, caption: cur.name })
    }
    return acc
  }, [] as DataItemInterface[])
}

export const isCategoryHaveChildCategories = (
  categoryId?: number,
  categoryList?: OfferCategoryInterface[],
) => {
  if (!categoryId || !categoryList) return false
  const category = categoryList.find(category => category.id === categoryId)
  return category && category.childCategories.length > 0
}

export const getChildCategories = (
  categoryId?: number,
  categoryList?: OfferCategoryInterface[],
) => {
  const category = categoryList?.find(category => category.id === categoryId)
  return category?.childCategories.map(category => {
    return {
      id: category.id,
      caption: category.name,
    }
  })
}

export const idChildCategory = (
  parentCategoryId: number,
  childCategoryId?: number,
  categoryList?: OfferCategoryInterface[],
) => {
  if (!categoryList || !childCategoryId) return false
  const parentCategory = categoryList?.find(category => category.id === parentCategoryId)
  return parentCategory?.childCategories.map(category => category.id).includes(childCategoryId)
}
