import React from 'react'

function DeadlineSettingsItemLayout({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <div className="flex flex-col">
      {children}
      <hr className="border-solid border-darkblue border-opacity-[0.15] mt-7" />
    </div>
  )
}
export default DeadlineSettingsItemLayout
