import { OfferTypeEnum } from 'modules/funnels/funnel/enums/offer-type-enum'
import { RadioGroupDataInterface } from 'shared/components/radio-group'
import { TLocoType } from 'shared/hooks/use-loco-translation'

export const getOfferTypeData = (t: TLocoType): RadioGroupDataInterface<OfferTypeEnum>[] => [
  {
    id: OfferTypeEnum.digital,
    caption: t('dashboard.funnel.configuration.offer.digital_product'),
  },
  {
    id: OfferTypeEnum.physical,
    caption: t('dashboard.funnel.configuration.offer.physical_product'),
  },
]
