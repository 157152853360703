import {
  CourseBundleAccessTypeEnum,
  getCourseBundleAccessTypeDataOptions,
} from 'modules/courses/bundles/course-bundle/enums/access-type-enum'
import {
  AccessTypeEnum,
  getCourseAccessTypeDataOptions,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import {
  DigitalResourcesTypeEnum,
  DigitalResourcesTypeTitles,
} from 'modules/digital-product/enums/digital-resources-type-enum'
import CourseModulesSkeleton from 'modules/digital-product/resources/components/course-module-skeleton'
import DigitalResourceWrapper from 'modules/digital-product/resources/components/digital-resource-wrapper'
import { DigitalResourceEditType } from 'modules/digital-product/resources/types/digital-resource-edit-interface'
import { getDefaultResourceValue } from 'modules/digital-product/resources/utils/get-default-resource-value'
import { getGrantCourseAccessUrl } from 'modules/digital-product/resources/utils/get-grant-course-access-url'
import { DigitalProductErrorInterface } from 'modules/digital-product/types/digital-product-interface'
import React, { FC } from 'react'
import { Checkbox } from 'shared/components/checkbox'
import CheckBoxSwitcher from 'shared/components/checkbox-switcher'
import DatePicker from 'shared/components/date-picker/date-picker'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { getToday } from 'shared/utils/get-dates'

interface ResourcesSelectProps {
  resources: DigitalResourceEditType[]
  onChangeResources: (resources: DigitalResourceEditType[]) => void
  errors: DigitalProductErrorInterface['resources']
  numberOfExistingResources: number
}

const ResourcesSelect: FC<ResourcesSelectProps> = ({
  resources,
  onChangeResources,
  errors,
  numberOfExistingResources,
}) => {
  const { user, isRootAdmin } = useUser()
  const { t } = useLocoTranslation()

  const courseAccessData = getCourseAccessTypeDataOptions(t)
  const courseBundleAccessData = getCourseBundleAccessTypeDataOptions(t)

  const digitalResourceTypeData = Object.values(DigitalResourcesTypeEnum)
    .filter(el => (isRootAdmin ? true : el !== DigitalResourcesTypeEnum.systemeio_plan))
    .filter(el => el !== DigitalResourcesTypeEnum.downloadable_file)
    ?.map(el => {
      return {
        id: el,
        caption: t(DigitalResourcesTypeTitles[el]),
      }
    })

  const onChangeType = (idx: number, data?: DigitalResourcesTypeEnum) => {
    const updatedResources = resources.map((el, i) => {
      if (i === idx) {
        if (data) {
          return getDefaultResourceValue(data)
        } else {
          return { type: undefined }
        }
      }
      return el
    })
    onChangeResources(updatedResources)
  }

  const onChangeResource = (
    idx: number,
    data: DigitalResourceEditType & { type: Required<DigitalResourceEditType>['type'] },
  ) => {
    const updatedResources = resources.map((el, i) => {
      if (i === idx && el.type === data.type) {
        return { ...el, ...data } as DigitalResourceEditType
      }
      return el
    })
    onChangeResources(updatedResources)
  }

  return (
    <>
      {resources.map((resource, idx) => {
        switch (resource.type) {
          case DigitalResourcesTypeEnum.membership_course: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                      />
                      <div className="flex flex-col gap-3 ml-4">
                        <FormSelect
                          key={`course-selector-${idx}`}
                          placeholder={t(
                            'dashboard.funnel.configuration.common.offer_resources.choose_course',
                          )}
                          value={resource.course}
                          data={data.coursesData}
                          onChange={value => {
                            onChangeResource(idx, {
                              type: resource.type,
                              course: value,
                            })
                          }}
                          error={errors[numberOfExistingResources + idx]?.course?.join(', ')}
                        />
                        {resource.course && (
                          <FormSelect
                            key={`access-type-selector-${idx}`}
                            placeholder={t(
                              'dashboard.funnel.configuration.common.offer_resources.choose_course_access_type',
                            )}
                            value={resource.enrollmentAccessType || undefined}
                            data={courseAccessData}
                            onChange={data =>
                              onChangeResource(idx, {
                                type: resource.type,
                                enrollmentAccessType: data,
                                accessFrom: undefined,
                                expirationPeriod: undefined,
                                modules: [],
                              })
                            }
                            error={errors[
                              numberOfExistingResources + idx
                            ]?.enrollmentAccessType?.join(', ')}
                          />
                        )}
                        {resource.enrollmentAccessType === AccessTypeEnum.partialAccess && (
                          <div
                            key={`modules-selector-${idx}`}
                            className={`text-sm font-medium flex-col gap-1 ${
                              data.courseModulesData && data.courseModulesData.length === 0
                                ? 'hidden'
                                : 'flex'
                            }`}
                          >
                            <ul className={'flex flex-wrap flex-row gap-5'}>
                              {data.courseModulesData ? (
                                data.courseModulesData.map(module => {
                                  const isSelected =
                                    !!resource.modules?.find(el => el === module.id) || false
                                  return (
                                    <li key={module.id}>
                                      <Checkbox
                                        value={isSelected}
                                        label={
                                          <div
                                            className={`flex gap-1.5 items-center ${
                                              isSelected &&
                                              '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                                            }`}
                                          >
                                            {module.caption}
                                          </div>
                                        }
                                        onChange={() =>
                                          onChangeResource(idx, {
                                            type: resource.type,
                                            modules: isSelected
                                              ? resource.modules?.filter(el => el !== module.id)
                                              : [...(resource.modules || []), module.id],
                                          })
                                        }
                                      />
                                    </li>
                                  )
                                })
                              ) : (
                                <CourseModulesSkeleton />
                              )}
                            </ul>
                          </div>
                        )}
                        {resource.enrollmentAccessType && (
                          <>
                            <div
                              className="flex gap-5 items-center min-h-[42px]"
                              key={`access-from-selector-${idx}`}
                            >
                              <CheckBoxSwitcher
                                label={
                                  <Hint
                                    label={
                                      <TransLoco
                                        t={t}
                                        i18nKey={
                                          'dashboard.funnel.configuration.common.offer_resources.course_start_access_on_day_description'
                                        }
                                        components={{
                                          a: (
                                            <a
                                              target={'_blank'}
                                              className={'primary-link'}
                                              href={getGrantCourseAccessUrl(user?.dashboardLocale)}
                                              rel="noreferrer"
                                            />
                                          ),
                                        }}
                                      />
                                    }
                                  >
                                    {t(
                                      'dashboard.funnel.configuration.common.offer_resources.course_start_access_on_day',
                                    )}
                                  </Hint>
                                }
                                selected={resource.accessFrom !== undefined}
                                onChange={value =>
                                  onChangeResource(idx, {
                                    type: resource.type,
                                    accessFrom: value ? null : undefined,
                                  })
                                }
                              />
                              {resource.accessFrom !== undefined && (
                                <DatePicker
                                  value={resource.accessFrom || null}
                                  minDate={getToday(user?.timezone)}
                                  onChange={data =>
                                    onChangeResource(idx, { type: resource.type, accessFrom: data })
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                        {(resource.enrollmentAccessType === AccessTypeEnum.fullAccess ||
                          resource.enrollmentAccessType === AccessTypeEnum.partialAccess) && (
                          <div
                            className="flex gap-5 items-center min-h-[42px]"
                            key={`enrollment-access-type-selector-${idx}`}
                          >
                            <CheckBoxSwitcher
                              label={
                                <Hint
                                  label={t(
                                    'dashboard.funnel.configuration.common.offer_resources.course_expiration_period_popover',
                                  )}
                                >
                                  {t(
                                    'dashboard.funnel.configuration.common.offer_resources.course_expiration_period',
                                  )}
                                </Hint>
                              }
                              selected={resource.expirationPeriod !== undefined}
                              onChange={value =>
                                onChangeResource(idx, {
                                  type: resource.type,
                                  expirationPeriod: value ? 30 : undefined,
                                })
                              }
                            />
                            {resource.expirationPeriod !== undefined && (
                              <FormInput
                                key={`expiration-period-selector-${idx}`}
                                value={resource.expirationPeriod}
                                onChange={e => {
                                  let updatedValue = e.target.valueAsNumber
                                  if (updatedValue < 0 || isNaN(updatedValue)) {
                                    updatedValue = 0
                                  }
                                  onChangeResource(idx, {
                                    type: resource.type,
                                    expirationPeriod: updatedValue,
                                  })
                                }}
                                type={'number'}
                                min={0}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.membership_course_bundle: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                      />
                      <div className="flex flex-col gap-3 ml-4">
                        <FormSelect
                          key={`course-bundle-selector-${idx}`}
                          placeholder={t(
                            'dashboard.funnel.configuration.common.offer_resources.choose_course_bundle',
                          )}
                          value={resource.courseBundle}
                          data={data.courseBundlesData}
                          onChange={value =>
                            onChangeResource(idx, {
                              type: resource.type,
                              courseBundle: value,
                              enrollmentAccessType: null,
                              enrollmentDrippingAccessCourse: undefined,
                            })
                          }
                          error={errors[numberOfExistingResources + idx]?.courseBundle?.join(', ')}
                        />
                        {resource.courseBundle && (
                          <FormSelect
                            key={`course-bundle-access-type-selector-${idx}`}
                            placeholder={t(
                              'dashboard.funnel.configuration.common.offer_resources.choose_course_bundle_access_type',
                            )}
                            value={resource.enrollmentAccessType || undefined}
                            data={courseBundleAccessData}
                            onChange={data =>
                              onChangeResource(idx, {
                                type: resource.type,
                                enrollmentAccessType: data,
                                enrollmentDrippingAccessCourse: undefined,
                              })
                            }
                            error={errors[
                              numberOfExistingResources + idx
                            ]?.enrollmentAccessType?.join(', ')}
                          />
                        )}
                        {resource.courseBundle &&
                          resource.enrollmentAccessType ===
                            CourseBundleAccessTypeEnum.dripping_content && (
                            <FormSelect
                              key={`enrollment-dripping-course-selector-${idx}`}
                              placeholder={t(
                                'dashboard.funnel.configuration.common.offer_resources.choose_dripping_course',
                              )}
                              value={resource.enrollmentDrippingAccessCourse}
                              data={data.courseBundleCoursesData}
                              onChange={value =>
                                onChangeResource(idx, {
                                  type: resource.type,
                                  enrollmentDrippingAccessCourse: value,
                                })
                              }
                              error={errors[
                                numberOfExistingResources + idx
                              ]?.enrollmentDrippingAccessCourse?.join(', ')}
                            />
                          )}
                      </div>
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.systemeio_plan: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                      />
                      <FormSelect
                        key={`systemeio-plan-selector-${idx}`}
                        wrapperClassName="ml-4"
                        value={resource.value}
                        data={data.plansData}
                        onChange={value => onChangeResource(idx, { type: resource.type, value })}
                        error={errors[numberOfExistingResources + idx]?.value?.join(', ')}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.offer_resources.choose_systeme_io_plan',
                        )}
                      />
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.systemeio_tag: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                      />
                      <FormSelect
                        key={`tag-selector-${idx}`}
                        wrapperClassName="ml-4"
                        placeholder={t(
                          'dashboard.funnel.configuration.common.offer_resources.choose_tag',
                        )}
                        value={resource.value}
                        data={data.tagsData}
                        onChange={value => onChangeResource(idx, { type: resource.type, value })}
                        error={errors[numberOfExistingResources + idx]?.value?.join(', ')}
                      />
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.community_access: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                      />
                      <FormSelect
                        key={`community-access-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.offer_resources.choose_community_access',
                        )}
                        wrapperClassName="ml-4"
                        value={resource.value}
                        data={data.communitiesData}
                        onChange={value => onChangeResource(idx, { type: resource.type, value })}
                        error={errors[numberOfExistingResources + idx]?.value?.join(', ')}
                      />
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          default: {
            return (
              <DigitalResourceWrapper
                key={idx}
                resource={resource}
                onDelete={() => {
                  onChangeResources(resources.filter((_, i) => i !== idx))
                }}
              >
                {() => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${idx}`}
                        placeholder={t(
                          'dashboard.funnel.configuration.common.digital_resources.placeholder',
                        )}
                        data={digitalResourceTypeData}
                        onChange={data => onChangeType(idx, data)}
                        value={resource.type}
                        error={errors[numberOfExistingResources + idx]?.type?.join(', ')}
                      />
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
        }
      })}
    </>
  )
}

export default ResourcesSelect
