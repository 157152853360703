import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import FunnelStepActions from 'modules/funnels/funnel/components/funnel-actions'
import TestIconWithoutBg from 'modules/funnels/funnel/icons/test-withot-bg-icon'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelStepTypeIcon } from 'modules/funnels/funnel/utils/get-funnel-step-type-icon'
import React from 'react'
import { Tooltip } from 'shared/components/tooltip'
import { FunnelStepTypeTitlesEnum } from 'shared/enums/funnel-step-type-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CheckIcon from 'shared/icons/check-icon'
import WarningIcon from 'shared/icons/warning-icon'

interface DraggableFunnelStepProps {
  funnelStep: FunnelStepInterface
  selectedFunnelStep: FunnelStepInterface | null
  selectFunnelStep: (funnelId: FunnelStepInterface) => void
}

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

function DraggableFunnelStep({
  funnelStep,
  selectedFunnelStep,
  selectFunnelStep,
}: DraggableFunnelStepProps) {
  const { t } = useLocoTranslation()
  const { attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
    id: funnelStep.id,
    animateLayoutChanges,
  })

  const style: React.CSSProperties = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  }

  const isGoalFunnelStepOfAnySplitTest = funnelStep.goalFunnelStepOfAnySplitTest
  const isNotGoalInSplitTest =
    !funnelStep.goalFunnelStepOfAnySplitTest &&
    (funnelStep.inRunningSplitTest || funnelStep.currentSplitTest?.id)

  return (
    <li
      className={`flex px-[15px] py-[18px] justify-between border-b-[1px] last:border-b-transparent border-[#142D6326] border-opacity-20 lg:hover:bg-blue-06 cursor-pointer relative ${
        selectedFunnelStep?.id === funnelStep.id ? 'bg-blue-06' : ''
      }`}
      ref={setNodeRef}
      style={{ ...style }}
      {...attributes}
      {...listeners}
      onClick={() => selectFunnelStep(funnelStep)}
    >
      <div className="flex gap-3 items-start">
        <div className="mt-1">{getFunnelStepTypeIcon(funnelStep.type)}</div>
        <div className="flex flex-col gap-1 max-w-[200px]">
          <Tooltip
            mode="overflow"
            label={funnelStep.name}
            wrapperClassName="flex w-full h-full"
            className="flex"
            tooltipClassName={'max-w-[250px]'}
            key={funnelStep.name}
          >
            <span className={`font-semibold font-inter text-[15px] truncate leading-5`}>
              {funnelStep.name}
            </span>
          </Tooltip>
          <div className="flex gap-1 items-center">
            {!funnelStep.page && (
              <WarningIcon className="w-[16px] h-[20px] fill-[#CBD5E1] flex-shrink-0" />
            )}
            <span className="font-normal text-sm truncate leading-4 text-gray-300">
              {t(FunnelStepTypeTitlesEnum[funnelStep.type])}
            </span>
          </div>
        </div>
      </div>
      {isGoalFunnelStepOfAnySplitTest && (
        <div className="absolute top-0.5 left-0.5">
          <Tooltip
            label={t('dashboard.funnel.split_tests.goal_page_tooltip')}
            tooltipClassName={'min-w-max'}
            mode="hover"
          >
            <CheckIcon className="stroke-[#CBD5E1] h-4 w-4" />
          </Tooltip>
        </div>
      )}
      {isNotGoalInSplitTest && (
        <div className="absolute top-0.5 left-0.5">
          <Tooltip
            label={
              funnelStep.inRunningSplitTest
                ? t('dashboard.funnel.split_tests.running_tooltip')
                : t('dashboard.funnel.split_tests.selected_tooltip')
            }
            tooltipClassName={'min-w-max'}
            mode="hover"
          >
            <TestIconWithoutBg className="hover:fill-bg-blue-06" />
          </Tooltip>
        </div>
      )}
      <FunnelStepActions funnelStep={funnelStep} selectFunnelStep={selectFunnelStep} />
    </li>
  )
}

export default DraggableFunnelStep
