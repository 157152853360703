import { useRouter } from 'next/router'

export function useUpdateFunnelStepHash() {
  const router = useRouter()

  const updateFunnelStepHash = (funnelStepHash: string) => {
    router
      .replace(
        {
          hash: funnelStepHash,
        },
        undefined,
        {
          shallow: true,
        },
      )
      .catch(e => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
  }
  return { updateFunnelStepHash }
}
