import React from 'react'
import { PrimaryButton } from 'shared/components/button'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface SplitTestPreviewStepModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  stepAPreviewLink: string
  stepBPreviewLink: string
}
function SplitTestPreviewStepModal({
  open,
  setOpen,
  stepAPreviewLink,
  stepBPreviewLink,
}: SplitTestPreviewStepModalProps) {
  const { t } = useLocoTranslation()

  return (
    <Modal
      opened={open}
      size={ModalSizeEnum.medium}
      onClose={() => setOpen(false)}
      title={t('dashboard.funnel.configuration.common.preview_running_split_test_title')}
    >
      <div className="flex justify-center gap-4">
        <PrimaryButton
          className="!justify-start"
          href={stepAPreviewLink}
          target={'_blank'}
          data-test-element={'view-funnel-step-a-button'}
        >
          <span className="text-start">
            {t('dashboard.funnel.configuration.common.preview_running_split_test_page_a')}
          </span>
        </PrimaryButton>
        <PrimaryButton
          className="!justify-start"
          href={stepBPreviewLink}
          target={'_blank'}
          data-test-element={'view-funnel-step-b-button'}
        >
          <span className="text-start">
            {t('dashboard.funnel.configuration.common.preview_running_split_test_page_b')}
          </span>
        </PrimaryButton>
      </div>
    </Modal>
  )
}

export default SplitTestPreviewStepModal
