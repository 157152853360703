import React from 'react'

interface StatsIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function StatsIcon({ active }: StatsIconProps) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0994 0H6.71524C3.07343 0 0.902344 2.17 0.902344 5.81V14.18C0.902344 17.83 3.07342 20 6.71524 20H15.0894C18.7312 20 20.9023 17.83 20.9023 14.19V5.81C20.9123 2.17 18.7412 0 15.0994 0Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3312 5.8099V14.1899C15.3312 14.8299 14.8109 15.3499 14.1706 15.3499C13.5203 15.3499 13 14.8299 13 14.1899V5.8099C13 5.1699 13.5203 4.6499 14.1706 4.6499C14.8109 4.6499 15.3312 5.1699 15.3312 5.8099Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81554 10.93V14.19C8.81554 14.83 8.29527 15.35 7.64496 15.35C7.00464 15.35 6.48438 14.83 6.48438 14.19V10.93C6.48438 10.29 7.00464 9.77002 7.64496 9.77002C8.29528 9.77002 8.81554 10.29 8.81554 10.93Z"
        fill={active ? 'white' : '#64748B'}
      />
    </svg>
  )
}

export default StatsIcon
