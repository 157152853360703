import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelLeadsApiInterface } from 'modules/funnels/funnel/types/funnel-leads-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { CountInterface, SwrPaginationInterface } from 'shared/types/pagination-interface'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

interface useFunnelLeadsProps extends SwrPaginationInterface {
  funnelId: FunnelId
}

export function useFunnelLeads({ funnelId, limit, pagination }: useFunnelLeadsProps) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelLeadsApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: leads } = useSWR(() => {
    if (user && limit) {
      if (pagination.startFromId) {
        return `${FUNNELS_API}/${funnelId}/leads/list?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}`
      } else {
        return `${FUNNELS_API}/${funnelId}/leads/list?pagination[limit]=${limit}`
      }
    }
    return null
  }, fetcher)
  const { data: counter } = useSWR<CountInterface>(() => {
    if (user) {
      return `${FUNNELS_API}/${funnelId}/leads/count?pagination[limit]`
    }
    return null
  }, baseFetcher)

  return { leads, counter }
}
