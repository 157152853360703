import { FUNNEL_EMAIL_API } from 'modules/funnels/funnel/components/email-tab/api/funnel-email-api'
import {
  FunnelEmailViewInterface,
  FunnelEmailsApiInterface,
} from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/pagination-interface'
import { getPaginationQueryString } from 'shared/utils/api/get-pagination-query-string'
import useSWR from 'swr'

interface UseFunnelEmailsInterface extends SwrPaginationInterface {
  funnelStepId?: FunnelStepId
}

export const useFunnelEmails = ({ limit, pagination, funnelStepId }: UseFunnelEmailsInterface) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelEmailsApiInterface
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (!user || !funnelStepId) return
    if (user) {
      return `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails?${getPaginationQueryString({
        pagination,
        limit,
      })}`
    }
    return null
  }, fetcher)
}

export const useFunnelEmail = (funnelStepId?: FunnelStepId, emailId?: number, opened?: boolean) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelEmailViewInterface
  >({ method: RequestMethodsEnum.get })

  const { data: emailData, mutate: mutateEmailData } = useSWR(() => {
    if (!user || !funnelStepId || !opened) return
    return `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails/${emailId}`
  }, fetcher)
  return { emailData, mutateEmailData }
}

export const useFunnelEmailInfoSender = (funnelStepId?: FunnelStepId) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    { senderName: string; senderAddress: string }
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (!user || !funnelStepId) return
    return `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails/sender-info`
  }, fetcher)
}
