import React from 'react'
import { InstanceWithNestedIdType } from 'shared/components/table/types/instance-interface'
import { Tooltip } from 'shared/components/tooltip'

interface TableDataInterface<T> {
  items: T[]
  [key: string]: any
}
type AlignType = 'justify-end' | 'justify-start' | 'justify-center'
export interface TabColumnDefinitionInterface<T, K> {
  key: keyof T | T[keyof T]
  header: string | JSX.Element
  onHeaderRender?: (data?: K) => JSX.Element
  subHeaders: {
    widthPercent?: number
    title: string | JSX.Element
    onRender?: (instance: T) => JSX.Element
    onRenderSmall?: (instance: T) => JSX.Element
    align?: AlignType
  }[]
  subHeaderWidthPercent?: number[]
  widthPercent?: number
  shouldRender?: boolean
}

export interface TableProps<T, K> {
  columns: TabColumnDefinitionInterface<T, K>[]
  data?: K
  passDataToHeader?: boolean
}

function TabTable<T extends { id: number | string }, K extends TableDataInterface<T>>({
  columns,
  data,
  passDataToHeader,
}: TableProps<T, K>) {
  const totalWidth = columns
    .filter(c => c.widthPercent !== undefined)
    .reduce((prev, cur) => prev + (cur.widthPercent || 25), 0)

  const rightBorderStyles = data ? '' : 'last:lg:border-r'

  return (
    <div
      className={`flex flex-col w-full lg:border-t ${
        data ? 'lg:border-r' : ''
      } lg:border-darkblue lg:border-opacity-[0.15] text-darkblue lg:text-sm text-opacity-60 lg:text-opacity-100`}
    >
      <TableHeader
        columns={columns}
        totalWidth={totalWidth}
        rightBorderStyles={rightBorderStyles}
        data={passDataToHeader ? data : undefined}
      />
      <TableSubHeader
        columns={columns}
        totalWidth={totalWidth}
        rightBorderStyles={rightBorderStyles}
      />
      <div className="overflow-auto">
        <TableRows columns={columns} data={data} totalWidth={totalWidth} />
      </div>
    </div>
  )
}

function TableHeader<T extends InstanceWithNestedIdType<T>, K extends TableDataInterface<T>>({
  columns,
  totalWidth,
  rightBorderStyles,
  data,
}: {
  columns: TabColumnDefinitionInterface<T, K>[]
  totalWidth: number
  rightBorderStyles: string
  data?: K
}) {
  const headers = columns.map((column, index) => {
    const widthPercent = column.widthPercent || 25
    const onRender = column.onHeaderRender && data ? column.onHeaderRender(data) : column.header

    return (
      <div
        key={`header-cell-${index}`}
        className={`hidden lg:table-cell truncate lg:border-b lg:border-l lg:border-darkblue lg:border-opacity-[0.15] text-darkblue lg:text-[15px] text-sm font-inter font-bold ${rightBorderStyles}`}
        style={{
          flex: `${(widthPercent / totalWidth) * 100}`,
        }}
      >
        {typeof onRender === 'string' ? (
          <Tooltip
            mode="overflow"
            label={column.header}
            wrapperClassName="h-14 flex w-full h-full justify-center align-center px-3"
            className="flex"
          >
            <span className="truncate inline-block w-full">{onRender}</span>
          </Tooltip>
        ) : (
          <div className="flex w-full min-w-0 h-14 justify-center items-center truncate">
            <div className="truncate">{onRender}</div>
          </div>
        )}
      </div>
    )
  })

  return <div className="flex items-center bg-lightblue bg-opacity-40">{headers}</div>
}

function TableSubHeader<T extends InstanceWithNestedIdType<T>, K extends TableDataInterface<T>>({
  columns,
  totalWidth,
  rightBorderStyles,
}: {
  columns: TabColumnDefinitionInterface<T, K>[]
  totalWidth: number
  rightBorderStyles: string
}) {
  const subHeaders = columns.map((column, index) => {
    const columnWidthPercent = column.widthPercent || 25
    return (
      <div
        key={`sub-header-header-cell-${index}`}
        className={`hidden lg:flex truncate items-center lg:border-l lg:border-darkblue lg:border-opacity-[0.15] text-darkblue lg:text-[15px] text-sm font-inter font-bold  ${rightBorderStyles}`}
        style={{
          flex: `${(columnWidthPercent / totalWidth) * 100}`,
        }}
      >
        {column.subHeaders.map((subHeader, index) => {
          const widthPercent = subHeader.widthPercent || 50
          return (
            <div
              key={`sub-header-cell-${index}`}
              className={`hidden lg:table-cell truncate first:border-none lg:border-l lg:border-darkblue lg:border-opacity-[0.15] px-3`}
              style={{
                flex: `${(widthPercent / totalWidth) * 100}`,
              }}
            >
              <Tooltip
                mode="overflow"
                label={subHeader.title}
                wrapperClassName="h-14 flex w-full h-full justify-center align-center"
                className="flex"
              >
                <span className="truncate font-normal">{subHeader.title}</span>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  })

  return <div className="flex items-center bg-lightblue bg-opacity-40">{subHeaders}</div>
}

function TableRows<T extends { id: number | string }, K extends TableDataInterface<T>>({
  columns,
  data,
  totalWidth,
}: {
  columns: TabColumnDefinitionInterface<T, K>[]
  data?: K
  totalWidth: number
}) {
  if (data === undefined) {
    return <TableSkeleton columns={columns} totalWidth={totalWidth} />
  }

  return (
    <>
      {data.items.map((row, index) => {
        return (
          <div
            className="flex lg:flex-row flex-col flex-shrink-0 gap-2 mb-4 lg:mb-0 lg:gap-0"
            key={`row-${index}`}
          >
            {columns.map((column, columnIndex) => {
              const columnHeaderRender =
                column.onHeaderRender && data ? column.onHeaderRender(data) : column.header
              const columnWidthPercent = column.widthPercent || 25
              return (
                <React.Fragment key={`column-parent-header-${columnIndex}`}>
                  {column.subHeaders.length > 0 && (
                    <div className="lg:hidden text-darkblue font-medium">{columnHeaderRender}</div>
                  )}
                  <div
                    key={`sub-header-header-cell-${index}`}
                    className={`flex lg:flex-row flex-col truncate lg:border-l lg:border-b lg:border-darkblue lg:border-opacity-[0.15]`}
                    style={{
                      flex: `${(columnWidthPercent / totalWidth) * 100}`,
                    }}
                  >
                    {column.subHeaders.map((subHeader, subHeaderIndex) => {
                      const widthPercent = subHeader.widthPercent || 100
                      const onRender = subHeader.onRender
                        ? subHeader.onRender(row)
                        : subHeader.title
                      const onRenderSmall = subHeader.onRenderSmall
                        ? subHeader.onRenderSmall(row)
                        : subHeader.onRender
                        ? subHeader.onRender(row)
                        : subHeader.title
                      const subheaderTitle = subHeader.title ? subHeader.title : column.header
                      const subheaderCalculatedTitle =
                        typeof subheaderTitle === 'string' && !!subheaderTitle.trim()
                          ? `${subheaderTitle} :`
                          : ''

                      const node = (
                        <>
                          <div
                            className={`flex lg:hidden items-center ${
                              !!subheaderCalculatedTitle ? 'gap-1' : ''
                            }`}
                          >
                            {subHeader.onRenderSmall ? (
                              onRenderSmall
                            ) : (
                              <>
                                <span className="text-darkblue text-opacity-60 lg:font-bold whitespace-nowrap">
                                  {subheaderCalculatedTitle}
                                </span>
                                {onRender}
                              </>
                            )}
                          </div>
                          <div
                            className={`hidden lg:flex truncate ${
                              subHeader.align ? subHeader.align : 'justify-center'
                            } `}
                          >
                            {onRender}
                          </div>
                        </>
                      )

                      return (
                        <div
                          key={`sub-header-cell-${subHeaderIndex}`}
                          className={`lg:table-cell flex flex-col truncate first:border-none lg:border-l lg:border-darkblue lg:border-opacity-[0.15] lg:py-3.5 lg:px-3`}
                          style={{
                            flex: `${(widthPercent / totalWidth) * 100}`,
                          }}
                        >
                          {node}
                        </div>
                      )
                    })}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        )
      })}
    </>
  )
}

function TableSkeleton<T extends InstanceWithNestedIdType<T>, K extends TableDataInterface<T>>({
  columns,
  totalWidth,
}: {
  columns: TabColumnDefinitionInterface<T, K>[]
  totalWidth: number
}) {
  return (
    <>
      {Array.from(Array(6).keys()).map((row, index) => (
        <div
          key={`row-${index}`}
          className={`flex animate-pulse flex-col gap-4 border border-transparent lg:gap-0 lg:flex-row items-start lg:items-center even:bg-blue-100 odd:bg-white group px-6 py-4 lg:p-0 ${
            index === 5 && 'rounded-b-lg'
          }`}
        >
          {columns.map((column, index2) => {
            if (!column.widthPercent) column.widthPercent = 25
            const widthPercent = column.widthPercent || 25
            return (
              <div
                className={`lg:pl-2 lg:pr-4 lg:py-4 w-full`}
                key={`cell-${index2}`}
                style={{
                  flex: `${(widthPercent / totalWidth) * 100}`,
                }}
              >
                <div className="bg-gray-600 rounded text-transparent">.</div>
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}

export default TabTable
