import React from 'react'

interface LetterIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function LetterIcon({ active }: LetterIconProps) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
          fill={active ? '#00A0FF' : '#CBD5E1'}
        />
        <path
          d="M317.892 257.944L446 338.935V173.544L317.892 257.944Z"
          fill={active ? 'white' : '#64748B'}
        />
        <path
          d="M66 173.544V338.935L194.108 257.944L66 173.544Z"
          fill={active ? 'white' : '#64748B'}
        />
        <path
          d="M422.252 125.375H89.752C77.898 125.375 68.493 134.21 66.712 145.586L256 270.298L445.287 145.586C443.506 134.21 434.101 125.375 422.252 125.375Z"
          fill={active ? 'white' : '#64748B'}
        />
        <path
          d="M296.136 272.293L262.536 294.428C260.595 295.699 258.325 296.375 256.005 296.375C253.685 296.375 251.415 295.699 249.474 294.428L215.862 272.269L66.76 366.58C68.589 377.861 77.946 386.625 89.75 386.625H422.25C434.05 386.625 443.411 377.861 445.24 366.58L296.136 272.293Z"
          fill={active ? 'white' : '#64748B'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LetterIcon
