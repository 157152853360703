import { TabColumnDefinitionInterface } from 'modules/funnels/funnel/components/tab-table'
import {
  FunnelStepStatTableInterface,
  SplitTestHistoryTableInterface,
} from 'modules/funnels/funnel/types/funnel-tests-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { dateFormat } from 'shared/utils/date-time-format'

interface useSplitTestTableColumnsProps {
  trashIconRenderer?: (data: SplitTestHistoryTableInterface) => JSX.Element
  withEndDate?: boolean
}
export const useSplitTestTableColumns = ({
  trashIconRenderer,
  withEndDate,
}: useSplitTestTableColumnsProps): TabColumnDefinitionInterface<
  FunnelStepStatTableInterface,
  SplitTestHistoryTableInterface
>[] => {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  return [
    {
      key: 'funnelStepName',
      header: ' ',
      onHeaderRender: data => (
        <>
          {data && (
            <div className="flex items-center">
              <span>
                {data.splitTest.startedAt &&
                  `${dateFormat(data.splitTest.startedAt, {
                    dateFormat: user?.dateFormat,
                    timeZone: user?.timezone,
                  })} -`}
              </span>
              {withEndDate && (
                <span>
                  {data.splitTest.finishedAt &&
                    `${dateFormat(data.splitTest.finishedAt, {
                      dateFormat: user?.dateFormat,
                      timeZone: user?.timezone,
                    })}`}
                </span>
              )}
              {trashIconRenderer && trashIconRenderer(data)}
            </div>
          )}
        </>
      ),
      widthPercent: 30,
      subHeaders: [
        {
          widthPercent: 100,
          title: ' ',
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.funnelStepName}</span>
          ),
        },
      ],
    },
    {
      key: 'pageViewsUnique',
      header: t('dashboard.funnel.statistics.page_views'),
      widthPercent: 30,
      subHeaders: [
        {
          widthPercent: 100,
          title: ' ',
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.pageViewsUnique}</span>
          ),
        },
      ],
    },
    {
      key: 'optIns',
      header: t('dashboard.funnel.split_tests.conversions'),
      widthPercent: 20,
      subHeaders: [
        {
          widthPercent: 30,
          title: t('dashboard.funnel.statistics.all'),
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.conversionAll}</span>
          ),
        },
        {
          widthPercent: 70,
          title: t('dashboard.funnel.statistics.conversion_rate'),
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.conversionRate}</span>
          ),
        },
      ],
    },
  ]
}
