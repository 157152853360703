import { CouponInterface } from 'modules/coupons/types/coupon-interface'
import { AddCouponDataInterface } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/types/add-coupon-data-interface'
import {
  getAvailableCouponsData,
  getDiscountTypeData,
} from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/utils/coupons-utils'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import ButtonLikeLink from 'shared/components/button-like-link'
import FormSelect from 'shared/components/form-select'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import SaveIcon from 'shared/icons/save-icon'
import { CouponDefaultErrorsInterface } from '../types/default-errors-interface'

interface AddCouponFormProps {
  coupons?: CouponInterface[]
  onAddCoupon: (value: AddCouponDataInterface) => Promise<void>
  openCreateForm: () => void
  closeForm: () => void
  createdCouponIdRef: React.MutableRefObject<number | undefined>
  couponFieldErrors: CouponDefaultErrorsInterface
}

const defaultValues: AddCouponDataInterface = {
  type: undefined,
  couponId: undefined,
}

function AddCouponForm({
  onAddCoupon,
  openCreateForm,
  coupons,
  closeForm,
  createdCouponIdRef,
  couponFieldErrors,
}: AddCouponFormProps) {
  const { t } = useLocoTranslation()
  const [tempState, setTempState] = useState(defaultValues)
  const [isFetching, setIsFetching] = useState(false)
  const [availableCouponsData, setAvailableCouponsData] = useState(
    getAvailableCouponsData(coupons, tempState.type),
  )

  const handleConfirm = async (data?: AddCouponDataInterface) => {
    try {
      const apiData = data ? data : tempState
      setIsFetching(true)
      await onAddCoupon(apiData)
      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
      if (e instanceof InternalError) {
        toast.error(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const handleChangeCouponType = (data: 'fixed' | 'percent') => {
    const currentSelectData = getAvailableCouponsData(coupons, data)
    const currentCouponIndex = currentSelectData?.findIndex(el => el.id === tempState.couponId)
    const needResetCouponId = tempState.couponId && currentCouponIndex && currentCouponIndex < 0
    setTempState(prev => {
      return {
        ...prev,
        type: data,
        couponId: needResetCouponId ? undefined : prev.couponId,
      }
    })
  }

  useEffect(() => {
    setAvailableCouponsData(getAvailableCouponsData(coupons, tempState.type))
  }, [coupons, tempState.type])

  useEffect(() => {
    if (createdCouponIdRef.current) {
      const createdCouponValue = createdCouponIdRef.current
      const updatedState = { ...tempState, couponId: createdCouponValue }
      setTempState(updatedState)
      handleConfirm(updatedState)
      createdCouponIdRef.current = undefined
    }
  }, [])

  return (
    <div className="flex flex-col gap-5 relative rounded-lg border-gray/30 border py-3 px-5">
      <CloseIcon
        className={`cursor-pointer ${
          isFetching && 'fill-gray pointer-events-none'
        } absolute lg:top-2 top-1 lg:right-2 right-1`}
        onClick={() => closeForm()}
      />
      <FormSelect
        label={t('dashboard.funnel.configuration.offer.coupon_discount_type')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        data={getDiscountTypeData(t)}
        onChange={handleChangeCouponType}
        value={tempState.type}
        required
      />
      <FormSelect
        label={t('dashboard.funnel.configuration.offer.coupon')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        data={availableCouponsData}
        onChange={data =>
          setTempState(prev => {
            return { ...prev, couponId: data }
          })
        }
        value={tempState.couponId}
        error={couponFieldErrors.coupons}
        required
      />
      <div
        className={
          'flex lg:flex-row flex-col w-full lg:text-[15px] text-sm text-darkblue font-medium gap-2'
        }
      >
        <span>{t('dashboard.funnel.configuration.offer.add_coupon_footer')}</span>
        <ButtonLikeLink
          onClick={() => {
            openCreateForm()
          }}
        >
          {t('dashboard.funnel.configuration.offer.add_coupon_footer_link')}
        </ButtonLikeLink>
      </div>
      <div className="flex ">
        <PrimaryButton
          disabled={!tempState.couponId}
          width="large"
          onClick={e => {
            e.preventDefault()
            handleConfirm()
          }}
          isFetching={isFetching}
          type="submit"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      </div>
      <FieldErrorAndDescription
        error={couponFieldErrors.common}
        errorClassName={'mt-2 text-center'}
      />
    </div>
  )
}

export default AddCouponForm
