import { ListingPhysicalProductInterface } from 'modules/funnels/funnel/components/step-configuration-tab/physical-product-selector/types/listing-physical-product-interface'
import {
  FunnelStepId,
  FunnelStepInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

export interface ListingDataInterface {
  id: number
  caption: string
}

export const getProductListData = (productList: ListingPhysicalProductInterface[]) => {
  return productList?.map(funnel => {
    return {
      id: funnel.id,
      caption: funnel.name,
    }
  }, [] as ListingDataInterface[])
}

const saleLimitRedirectTypes = [
  FunnelStepTypeEnum.InfoPage,
  FunnelStepTypeEnum.SalesPage,
  FunnelStepTypeEnum.OrderThankYouPage,
  FunnelStepTypeEnum.OfferForm,
]

export const getAvailableSaleLimitRedirectionFunnelSteps = (
  activeFunnelStepId: FunnelStepId,
  funnelStepsList?: FunnelStepInterface[],
) => {
  return (
    funnelStepsList?.reduce((acc, curr) => {
      if (saleLimitRedirectTypes.includes(curr.type) && activeFunnelStepId !== curr.id) {
        acc.push({ id: curr.id, caption: curr.name })
      }
      return acc
    }, [] as ListingDataInterface[]) || []
  )
}
