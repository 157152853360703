import AutomationRulesTab from 'modules/funnels/funnel/components/funnel-rules/components/automation-rules-tab'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import DeadlineSettingsTab from '../components/deadline-settings-tab/deadline-settings-tab'
import EmailTab from '../components/email-tab/email-tab'
import EmptyFunnelStepList from '../components/empty-funnel-step-list'
import LeadsTab from '../components/leads-tab/leads-tab'
import PageTemplateSelector from '../components/page-template-selector'
import SalesTab from '../components/sales-tab/components/sales-tab'
import SplitTestsTab from '../components/split-tests-tab/split-tests-tab'
import StatisticsTab from '../components/statistics-tab/statistics-tab'
import StepConfigurationTab from '../components/step-configuration-tab'
import { FunnelStepSettingTabsEnum } from '../enums/funnel-step-setting-tabs-enum'
import { FunnelStepInterface } from '../types/funnel-step-interface'

export const getFunnelStepSettingTabContent = (
  funnelId: FunnelId,
  funnelStep: FunnelStepInterface | null,
  selectedTab?: FunnelStepSettingTabsEnum,
  isFunnelListIsEmpty?: boolean,
): JSX.Element => {
  if (isFunnelListIsEmpty) {
    return <EmptyFunnelStepList />
  }

  if (funnelStep && !funnelStep.page) {
    return <PageTemplateSelector funnelId={funnelId} funnelStep={funnelStep} />
  }

  switch (selectedTab) {
    case FunnelStepSettingTabsEnum.stepConfiguration: {
      return <StepConfigurationTab funnelId={funnelId} funnelStep={funnelStep} />
    }
    case FunnelStepSettingTabsEnum.automationRules: {
      return <AutomationRulesTab funnelId={funnelId} funnelStepId={funnelStep?.id} />
    }
    case FunnelStepSettingTabsEnum.tests: {
      return <SplitTestsTab funnelId={funnelId} funnelStep={funnelStep} />
    }
    case FunnelStepSettingTabsEnum.leads: {
      return <LeadsTab funnelId={funnelId} funnelStep={funnelStep} />
    }
    case FunnelStepSettingTabsEnum.stats: {
      return <StatisticsTab funnelId={funnelId} funnelStep={funnelStep} />
    }
    case FunnelStepSettingTabsEnum.sales: {
      return <SalesTab funnelId={funnelId} />
    }
    case FunnelStepSettingTabsEnum.emails: {
      return <EmailTab funnelStepId={funnelStep?.id} />
    }
    case FunnelStepSettingTabsEnum.deadlineSettings: {
      return <DeadlineSettingsTab funnelId={funnelId} funnelStep={funnelStep} />
    }
    default: {
      return <></>
    }
  }
}
