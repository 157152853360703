import React from 'react'

function SqueezeFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.0001 5.42097V4.74614C26.9979 2.88305 25.488 1.3732 23.6249 1.37097H3.37492C1.51183 1.3732 0.00222473 2.88305 0 4.74614V5.42097H27.0001Z"
        fill="#CBD5E1"
      />
      <path
        d="M0 6.77112V22.296C0.00222473 24.1591 1.51183 25.669 3.37492 25.671H23.6249C25.488 25.669 26.9979 24.1591 27.0001 22.296V6.77112H0ZM4.05 11.496C4.05 11.317 4.12094 11.1452 4.24751 11.0186C4.37432 10.8921 4.54587 10.8209 4.72508 10.8211H10.125C10.304 10.8209 10.4758 10.8921 10.6023 11.0186C10.7291 11.1452 10.8001 11.317 10.8001 11.496V15.546C10.8001 15.7252 10.7291 15.8967 10.6023 16.0235C10.4758 16.1501 10.304 16.221 10.125 16.221H4.72508C4.54587 16.221 4.37432 16.1501 4.24751 16.0235C4.12094 15.8967 4.05 15.7252 4.05 15.546V11.496ZM22.275 20.271H4.72508C4.35232 20.271 4.05 19.9687 4.05 19.596C4.05 19.2232 4.35232 18.9211 4.72508 18.9211H22.275C22.6478 18.9211 22.9501 19.2232 22.9501 19.596C22.9501 19.9687 22.6478 20.271 22.275 20.271ZM22.275 16.221H14.175C13.8022 16.221 13.4999 15.9187 13.4999 15.546C13.4999 15.1732 13.8022 14.8711 14.175 14.8711H22.275C22.6478 14.8711 22.9501 15.1732 22.9501 15.546C22.9501 15.9187 22.6478 16.221 22.275 16.221ZM22.275 12.171H14.175C13.8022 12.171 13.4999 11.8687 13.4999 11.496C13.4999 11.1232 13.8022 10.8211 14.175 10.8211H22.275C22.6478 10.8211 22.9501 11.1232 22.9501 11.496C22.9501 11.8687 22.6478 12.171 22.275 12.171Z"
        fill="#CBD5E1"
      />
      <path d="M5.3999 12.1711H9.4499V14.8712H5.3999V12.1711Z" fill="#CBD5E1" />
    </svg>
  )
}

export default SqueezeFunnelStepIcon
