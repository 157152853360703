import {
  FunnelGroupEnum,
  FunnelStepTypeEnum,
  FunnelStepTypeTitlesEnum,
} from 'shared/enums/funnel-step-type-enum'
import { TLocoType } from 'shared/hooks/use-loco-translation'

export function getFunnelStepPageTypeDataOptions(t: TLocoType) {
  return (Object.keys(FunnelStepTypeEnum) as Array<keyof typeof FunnelStepTypeEnum>).map(
    formatKey => {
      return {
        id: FunnelStepTypeEnum[formatKey],
        caption: t(FunnelStepTypeTitlesEnum[FunnelStepTypeEnum[formatKey]]),
        group: getFunnelStepPageGroupType(FunnelStepTypeEnum[formatKey]),
      }
    },
  )
}

export function getFunnelStepPageGroupType(type: FunnelStepTypeEnum) {
  switch (type) {
    case FunnelStepTypeEnum.Squeeze:
    case FunnelStepTypeEnum.Inline:
    case FunnelStepTypeEnum.Popup:
    case FunnelStepTypeEnum.OptInThankYouPage:
    case FunnelStepTypeEnum.LinkInBio:
      return FunnelGroupEnum.OptIn
    case FunnelStepTypeEnum.SalesPage:
    case FunnelStepTypeEnum.OfferForm:
    case FunnelStepTypeEnum.OrderThankYouPage:
    case FunnelStepTypeEnum.Upsell:
    case FunnelStepTypeEnum.Downsell:
      return FunnelGroupEnum.Sales
    case FunnelStepTypeEnum.WebinarRegistration:
    case FunnelStepTypeEnum.WebinarSession:
    case FunnelStepTypeEnum.WebinarThankYou:
      return FunnelGroupEnum.Webinar
    case FunnelStepTypeEnum.InfoPage:
    case FunnelStepTypeEnum.ContactUsPage:
      return FunnelGroupEnum.Misc
    default:
      return FunnelGroupEnum.Misc
  }
}
