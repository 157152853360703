import React from 'react'

function OfferThankYouFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_209_6)">
        <path
          d="M20.25 13.5C19.0647 13.5014 17.9 13.1903 16.8732 12.598C15.8465 12.0057 14.9941 11.1532 14.4018 10.1264C13.8096 9.09967 13.4986 7.93492 13.5 6.74961C13.5015 5.56429 13.8154 4.40031 14.4101 3.375H4.77225L4.725 2.979C4.62805 2.15842 4.23344 1.40192 3.61596 0.852867C2.99848 0.303811 2.20103 0.000351941 1.37475 0L1.125 0C0.826631 0 0.540483 0.118526 0.329505 0.329505C0.118526 0.540483 0 0.826631 0 1.125C0 1.42337 0.118526 1.70952 0.329505 1.9205C0.540483 2.13147 0.826631 2.25 1.125 2.25H1.37475C1.6503 2.25004 1.91625 2.3512 2.12217 2.5343C2.32808 2.71741 2.45964 2.96972 2.49187 3.24337L4.03988 16.4059C4.20058 17.7748 4.85829 19.037 5.88815 19.953C6.91801 20.869 8.24834 21.375 9.62663 21.375H21.375C21.6734 21.375 21.9595 21.2565 22.1705 21.0455C22.3815 20.8345 22.5 20.5484 22.5 20.25C22.5 19.9516 22.3815 19.6655 22.1705 19.4545C21.9595 19.2435 21.6734 19.125 21.375 19.125H9.62663C8.93012 19.1233 8.25121 18.9061 7.68301 18.5033C7.11482 18.1004 6.68517 17.5317 6.453 16.875H19.8641C21.183 16.8751 22.4599 16.4118 23.4718 15.566C24.4837 14.7202 25.1663 13.5458 25.4002 12.2479L25.668 10.764C25.0422 11.6124 24.2259 12.302 23.2848 12.7773C22.3438 13.2525 21.3043 13.5 20.25 13.5Z"
          fill="#CBD5E1"
        />
        <path
          d="M7.875 27C9.11764 27 10.125 25.9926 10.125 24.75C10.125 23.5074 9.11764 22.5 7.875 22.5C6.63236 22.5 5.625 23.5074 5.625 24.75C5.625 25.9926 6.63236 27 7.875 27Z"
          fill="#CBD5E1"
        />
        <path
          d="M19.125 27C20.3676 27 21.375 25.9926 21.375 24.75C21.375 23.5074 20.3676 22.5 19.125 22.5C17.8824 22.5 16.875 23.5074 16.875 24.75C16.875 25.9926 17.8824 27 19.125 27Z"
          fill="#CBD5E1"
        />
        <path
          d="M17.6996 10.0642C17.8931 10.2732 18.1269 10.4407 18.387 10.5568C18.6471 10.6728 18.928 10.7349 19.2127 10.7392H19.2499C19.5289 10.7401 19.8054 10.6856 20.0632 10.5788C20.321 10.472 20.5551 10.3151 20.7517 10.1171L25.5577 5.31109C25.7697 5.09954 25.889 4.81245 25.8893 4.51297C25.8896 4.21348 25.771 3.92614 25.5594 3.71415C25.3479 3.50216 25.0608 3.38289 24.7613 3.38257C24.4618 3.38225 24.1745 3.50092 23.9625 3.71246L19.2521 8.43071L17.8639 6.94121C17.6601 6.72296 17.3779 6.59459 17.0795 6.58436C16.7811 6.57413 16.4908 6.68286 16.2726 6.88665C16.0543 7.09043 15.9259 7.37258 15.9157 7.67101C15.9055 7.96943 16.0142 8.2597 16.218 8.47796L17.6996 10.0642Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_6">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OfferThankYouFunnelStepIcon
