import React, { useEffect, useState } from 'react'
import { LightButton } from 'shared/components/button'
import { BaseTableProps } from 'shared/components/table/components/base-table'
import { InstanceWithIdType } from 'shared/components/table/types/instance-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { OrderEnum } from 'shared/types/pagination-interface'

export type PageTemplateLimitType = 9 | 15 | 30

const limitArray: PageTemplateLimitType[] = [9, 15, 30]

interface PageTemplatePaginationProps<T> extends Pick<BaseTableProps<T>, 'data' | 'paginator'> {
  limiter: {
    limit: PageTemplateLimitType
    setLimit: React.Dispatch<React.SetStateAction<PageTemplateLimitType>>
  }
}

function PageTemplatePagination<T extends { id: number | string }>({
  data,
  limiter,
  paginator,
}: PageTemplatePaginationProps<T>) {
  const { t } = useLocoTranslation()
  const { pagination, setPagination } = paginator!
  const { limit, setLimit } = limiter!
  const [isPaginationShown, setIsPaginationShown] = useState(true)
  const [isFirst, setIsFirst] = useState(true)
  const [isLast, setIsLast] = useState(false)

  useEffect(() => {
    if (!isFirst && data?.items.length === 0) {
      setPagination({ order: OrderEnum.next })
    }
  }, [data?.items.length, isFirst, setPagination])

  useEffect(() => {
    if (data) {
      setIsFirst(true)
      setIsLast(false)

      switch (pagination.order) {
        case 'next': {
          if (pagination.startFromId) {
            setIsFirst(false)
          }
          if (!data.hasMore) {
            setIsLast(true)
          }
          break
        }
        case 'prev': {
          if (data.hasMore) {
            setIsFirst(false)
          }
        }
      }
    }
  }, [data, pagination.order, pagination.startFromId])

  useEffect(() => {
    if (data) {
      setIsPaginationShown(true)

      if (!isFirst || limiter.limit !== 9) return

      if (pagination.order === 'next' && !data.hasMore) {
        setIsPaginationShown(false)
      }
      if (data.items.length === 0) {
        setIsPaginationShown(false)
      }
    } else {
      setIsPaginationShown(false)
    }
  }, [data, isFirst, limiter.limit, pagination.order, pagination.startFromId])

  return (
    <div
      className={`justify-end items-center gap-5 lg:px-0 px-2 ${
        isPaginationShown ? 'flex' : 'hidden'
      }`}
    >
      <div className={`flex gap-2`}>
        {limitArray.map(el => (
          <button
            key={el}
            className={`flex justify-center items-center rounded-full w-9 h-9 text-sm font-bold cursor-pointer outline-none focus-visible:ring-2 ring-blue ring-offset-1 ${
              limit === el ? 'bg-white text-blue' : 'bg-transparent text-darkblue/50 '
            }`}
            disabled={data === undefined || data.items.length === 0}
            onClick={() => {
              setLimit(el)
              setPagination({
                startFromId: undefined,
                order: OrderEnum.next,
              })
            }}
          >
            {el}
          </button>
        ))}
      </div>
      <div className={`flex gap-3`}>
        <LightButton
          disabled={isFirst}
          onClick={() =>
            setPagination({
              startFromId: (data?.items[0] as InstanceWithIdType).id,
              order: OrderEnum.prev,
            })
          }
        >
          {t('global.previous')}
        </LightButton>
        <LightButton
          disabled={isLast}
          onClick={() =>
            setPagination({
              startFromId: (data?.items[data.items.length - 1] as InstanceWithIdType).id,
              order: OrderEnum.next,
            })
          }
        >
          {t('global.next')}
        </LightButton>
      </div>
    </div>
  )
}
export default PageTemplatePagination
