import React from 'react'

function RemoveIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5_2)">
        <path d="M257 0C116.39 0 0 114.39 0 255C0 395.61 116.39 512 257 512C397.61 512 512 395.61 512 255C512 114.39 397.61 0 257 0ZM392 285H120C103.46 285 90 271.54 90 255C90 238.46 103.46 225 120 225H392C408.53 225 422 238.46 422 255C422 271.54 408.53 285 392 285Z" />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RemoveIcon
