import React from 'react'

function StopIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M288 0H32C14.368 0 0 14.368 0 32V288C0 305.632 14.368 320 32 320H288C305.632 320 320 305.632 320 288V32C320 14.368 305.632 0 288 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="320" height="320" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StopIcon
