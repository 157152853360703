import OfferProductTypeSelector from 'modules/funnels/funnel/components/offer-settings/offer-product-type-selector'
import {
  DigitalOfferEditInterface,
  DigitalOfferViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/digital-offer-interface'
import {
  PhysicalOfferEditInterface,
  PhysicalOfferViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/physical-offer-interface'
import { OfferTypeEnum } from 'modules/funnels/funnel/enums/offer-type-enum'
import React, { FC, useEffect, useState } from 'react'
import { SectionMessage } from 'shared/components/section-message'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ProductWrapperProps {
  children: (type: OfferTypeEnum) => JSX.Element
  digitalProduct:
    | DigitalOfferViewInterface['digitalProduct']
    | DigitalOfferEditInterface['digitalProduct']
  product: PhysicalOfferViewInterface['product'] | PhysicalOfferEditInterface['product']
}

const ProductWrapper: FC<ProductWrapperProps> = ({ children, digitalProduct, product }) => {
  const { t } = useLocoTranslation()

  const [type, setType] = useState<OfferTypeEnum>()
  useEffect(() => {
    if (digitalProduct) {
      setType(OfferTypeEnum.digital)
    } else if (product) {
      setType(OfferTypeEnum.physical)
    } else {
      setType(undefined)
    }
  }, [digitalProduct, product])

  return (
    <div className="flex flex-col lg:px-0 px-4">
      <OfferProductTypeSelector
        selected={type}
        onChange={type => {
          setType(type)
        }}
      />
      {type ? (
        <div className="flex flex-col gap-5 w-full flex-grow py-7 px-4 bg-[#F8FAFC]">
          {children(type)}
        </div>
      ) : (
        <SectionMessage>
          {t('dashboard.funnel.configuration.offer.no_product_selected')}
        </SectionMessage>
      )}
    </div>
  )
}

export default ProductWrapper
