import { updateFunnelDeadlineSettings } from 'modules/funnels/funnel/api/funnel-api'
import DeadlineSettingsExpirationInput from 'modules/funnels/funnel/components/deadline-settings-tab/deadline-settings-expiration-input'
import DeadlineSettingsRadioGroup from 'modules/funnels/funnel/components/deadline-settings-tab/deadline-settings-radio-group'
import DeadlineSettingsTabSkeleton from 'modules/funnels/funnel/components/deadline-settings-tab/deadline-settings-tab-skeleton'
import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { useFunnelDeadlineSettings } from 'modules/funnels/funnel/hooks/use-funnel-deadline-settings'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import {
  DeadlineSettingsEditInterface,
  DeadlineSettingsErrorsInterface,
} from 'modules/funnels/funnel/types/deadline-settings-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  getAvailableFunnelStepsData,
  getFunnelStepsToRedirectData,
} from 'modules/funnels/funnel/utils/deadline-settings-tab-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import CheckBoxSwitcher from 'shared/components/checkbox-switcher'
import { TimePicker } from 'shared/components/date-picker/time-picker'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import Popover from 'shared/components/popover'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import SaveIcon from 'shared/icons/save-icon'
import WarningIcon from 'shared/icons/warning-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { getSecondsWithTimeZone, getSecondsWithoutTimeZone } from 'shared/utils/time-utils'
import DeadlineSettingsItemLayout from './deadline-settings-item-layout'

const defaultValues: DeadlineSettingsEditInterface = {
  enabled: false,
  deadlinePageExpirationInDays: undefined,
  expirationFunnelStep: undefined,
  deadlineTime: null,
  deadlineFunnelSteps: [],
}

const defaultErrors: DeadlineSettingsErrorsInterface = {
  fields: {
    enabled: '',
    deadlinePageExpirationInDays: '',
    expirationFunnelStep: '',
    deadlineTime: '',
    deadlineFunnelSteps: '',
  },
}

interface DeadlineSettingsTabProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface | null
}

function DeadlineSettingsTab({ funnelId }: DeadlineSettingsTabProps) {
  const router = useRouter()
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const { funnelStepsList } = useFunnelStepsList(funnelId)
  const { deadlineSettings, mutate } = useFunnelDeadlineSettings(funnelId)
  const [tempState, setTempState] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)
  const [isExpirationTime, setIsExpirationTime] = useState(false)
  const [deadlineSeconds, setDeadlineSeconds] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const funnelStepsToRedirectData = getFunnelStepsToRedirectData(funnelStepsList)

  const availableFunnelStepsData = getAvailableFunnelStepsData(funnelStepsList)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await fetchWithFieldErrors(async () => {
        const newData = {
          ...tempState,
          deadlineTime: getSecondsWithTimeZone(deadlineSeconds, user?.timezone),
        }
        const response = await updateFunnelDeadlineSettings(funnelId, newData)
        await mutate(data => {
          if (data) {
            return response.data
          }
        }, false)
      }, setErrors)
      setIsLoading(false)
      toast.success(t('global.changes_saved'))
    } catch (error) {
      setIsLoading(false)
      if (error instanceof BadRequest) {
        if (error.errors.common) {
          const commonErrors = error.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      router.replace(
        {
          pathname: router.asPath.split('#step')[0],
        },
        undefined,
        {
          shallow: true,
        },
      )
    }, 0)

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (deadlineSettings) {
      setTempState(prev => {
        return {
          ...prev,
          deadlineFunnelSteps: deadlineSettings.deadlineFunnelSteps.map(
            funnelStep => funnelStep.id,
          ),
          deadlinePageExpirationInDays: deadlineSettings.deadlinePageExpirationInDays ?? 0,
          deadlineTime: deadlineSettings.deadlineTime,
          enabled: deadlineSettings.enabled,
          expirationFunnelStep: deadlineSettings.expirationFunnelStep?.id,
        }
      })
      if (deadlineSettings.deadlineTime) {
        setIsExpirationTime(true)
        setDeadlineSeconds(getSecondsWithoutTimeZone(deadlineSettings.deadlineTime, user?.timezone))
      }
    }
  }, [deadlineSettings, user?.timezone])

  return (
    <div className="flex flex-col gap-10 mb-7 lg:px-[15px] px-[15px]">
      <MobileSettingsHeader type={FunnelStepSettingTabsEnum.deadlineSettings} />
      {deadlineSettings && funnelStepsList ? (
        <>
          <DeadlineSettingsItemLayout>
            <div className="flex justify-between items-center ">
              <CheckBoxSwitcher
                label={
                  <Hint label={t('dashboard.funnel.deadline_settings_description')}>
                    {t('dashboard.funnel.deadline_settings_enable')}
                  </Hint>
                }
                labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue leading-6'}
                selected={tempState.enabled}
                onChange={value =>
                  setTempState(prev => {
                    return { ...prev, enabled: value }
                  })
                }
                disabled={!deadlineSettings}
              />
              <PrimaryButton
                disabled={false}
                width="large"
                onClick={handleConfirm}
                isFetching={isLoading}
                type="submit"
                className={'lg:flex hidden'}
              >
                <SaveIcon />
                <span className={'hidden lg:flex'}>
                  {t('dashboard.funnel.deadline_settings_save')}
                </span>
              </PrimaryButton>
            </div>
          </DeadlineSettingsItemLayout>
          <DeadlineSettingsItemLayout>
            <div className="flex gap-10 lg:flex-row flex-col">
              <DeadlineSettingsExpirationInput
                labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
                onChange={value =>
                  setTempState(prev => {
                    return { ...prev, deadlinePageExpirationInDays: value }
                  })
                }
                value={tempState.deadlinePageExpirationInDays || 0}
                isFetching={false}
                label={t('dashboard.funnel.deadline_settings_deadline_expiration_title')}
                required
                wrapperClassName={'flex-[40_1_0%]'}
                error={errors.fields.deadlinePageExpirationInDays}
              />
              <FormSelect
                label={t('dashboard.funnel.deadline_settings_expiration_funnel_step_title')}
                labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
                value={tempState.expirationFunnelStep}
                data={funnelStepsToRedirectData}
                onChange={value =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      expirationFunnelStep: value,
                    }
                  })
                }
                required
                wrapperClassName={'flex-[60_1_0%]'}
                error={errors.fields.expirationFunnelStep}
              />
            </div>
          </DeadlineSettingsItemLayout>
          <DeadlineSettingsItemLayout>
            <div className="flex flex-col gap-5">
              <CheckBoxSwitcher
                label={
                  <div
                    className={
                      'flex gap-1.5 items-center lg:text-[15px] font-medium text-sm text-darkblue leading-6'
                    }
                  >
                    {t('dashboard.funnel.deadline_settings_expiration_time_title')}
                    <Popover
                      label={t('dashboard.funnel.deadline_settings_expiration_time_tooltip')}
                    >
                      {show => (
                        <QuestionMarkIcon
                          className={`${
                            show ? 'fill-blue' : 'fill-gray-100'
                          } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                        />
                      )}
                    </Popover>
                  </div>
                }
                selected={isExpirationTime}
                onChange={value => {
                  if (!value) {
                    setDeadlineSeconds(null)
                  }
                  setIsExpirationTime(value)
                }}
              />
              {isExpirationTime && (
                <TimePicker seconds={deadlineSeconds} onChangeSeconds={setDeadlineSeconds} />
              )}
            </div>
          </DeadlineSettingsItemLayout>
          <div className="flex flex-col gap-5">
            <div className={'flex gap-1.5 items-start'}>
              <span className="lg:text-[15px] font-medium text-sm text-darkblue">
                {t('dashboard.funnel.deadline_settings_available_funnel_steps_title')}
              </span>
              <Popover
                label={t('dashboard.funnel.deadline_settings_available_funnel_steps_tooltip')}
              >
                {() => <WarningIcon className="w-[18px] h-[18px]" />}
              </Popover>
            </div>
            <DeadlineSettingsRadioGroup
              options={availableFunnelStepsData}
              selected={tempState.deadlineFunnelSteps}
              onChange={value => {
                const isSelected = tempState.deadlineFunnelSteps.includes(value)
                if (isSelected) {
                  setTempState(prev => ({
                    ...prev,
                    deadlineFunnelSteps: prev.deadlineFunnelSteps.filter(
                      funnelStepId => funnelStepId !== value,
                    ),
                  }))
                } else {
                  setTempState(prev => ({
                    ...prev,
                    deadlineFunnelSteps: [...prev.deadlineFunnelSteps, value],
                  }))
                }
              }}
              error={errors.fields.deadlineFunnelSteps}
            />
          </div>
          <PrimaryButton
            disabled={false}
            width="small"
            onClick={handleConfirm}
            isFetching={isLoading}
            type="submit"
            className={'lg:hidden max-w-fit'}
          >
            <SaveIcon />
            <span className={'flex'}>{t('dashboard.funnel.deadline_settings_save')}</span>
          </PrimaryButton>
        </>
      ) : (
        <DeadlineSettingsTabSkeleton />
      )}
      {errors.common && (
        <>
          {errors.common.map((error, index) => (
            <p className="text-sm text-danger px-6" key={index}>
              {error}
            </p>
          ))}
        </>
      )}
    </div>
  )
}

export default DeadlineSettingsTab
