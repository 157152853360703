import {
  FUNNEL_EMAIL_API,
  createFunnelEmail,
} from 'modules/funnels/funnel/components/email-tab/api/funnel-email-api'
import { EventTypeEnum } from 'modules/funnels/funnel/components/email-tab/enums/funnel-emails-enum'
import { useFunnelEmailInfoSender } from 'modules/funnels/funnel/components/email-tab/hooks/use-funnel-emails'
import { FunnelEmailsErrorInterface } from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-error-itnerface'
import {
  FunnelEmailEditInterface,
  FunnelEmailsApiInterface,
} from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-interface'
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { SecondaryButton } from 'shared/components/button'
import DashboardMailEditor from 'shared/components/dashboard-mail-editor'
import { updateAttachments } from 'shared/components/dashboard-mail-editor/api/email-api'
import { UnsavedEmailsEnum } from 'shared/components/dashboard-mail-editor/enums/unsaved-emails-enum'
import { useUnsavedEmail } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import {
  AttachmentFileInterface,
  EmailBaseInterface,
  EmailErrorType,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { getStyledBody } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import TimeDelayPicker, {
  TimeDelayPickerProps,
} from 'shared/components/date-picker/time-delay-picker'
import { TimeType, TimeTypeState } from 'shared/components/date-picker/time-delay-picker/constants'
import { convertTimeValueToSeconds } from 'shared/components/date-picker/time-delay-picker/utils'
import { TimePicker } from 'shared/components/date-picker/time-picker'
import FormSelect from 'shared/components/form-select'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import { PaginationInterface } from 'shared/types/pagination-interface'
import { isValidEmail } from 'shared/utils/email-validation'

const defaultValues: EmailBaseInterface = {
  body: '',
  subject: '',
  previewText: '',
  fromName: '',
  fromEmail: '',
}

const defaultErrors: EmailErrorType = {
  body: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  attachments: '',
  common: [],
  delay: '',
}

interface FunnelEmailCreateProps {
  funnelStepId?: number
  emails?: FunnelEmailsApiInterface
  setPagination: React.Dispatch<React.SetStateAction<PaginationInterface>>
}

const FunnelEmailCreate = ({ emails, funnelStepId, setPagination }: FunnelEmailCreateProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState<EventTypeEnum>(
    EventTypeEnum.registration_to_webinar,
  )
  const [chosenTimeDelay, setChosenTimeDelay] = useState<number>(0)
  const [chosenSendingEmailTime, setChosenSendingEmailTime] = useState<number | null>(null)
  const [selectedTimeBeforeAfter, setSelectedTimeBeforeAfter] = useState<TimeTypeState | undefined>(
    TimeTypeState.after,
  )
  const [selectedTimeType, setSelectedTimeType] = useState<TimeType>(TimeType.MINUTES)
  const [tempState, setTempState] = useState<EmailBaseInterface>(defaultValues)
  const [errors, setErrors] = useState<EmailErrorType>(defaultErrors)
  const [attachmentsTempState, setAttachmentsTempState] = useState<
    (AttachmentFileInterface | File)[]
  >([])

  const { data: infoSender } = useFunnelEmailInfoSender(funnelStepId)

  const updateData = useUpdateData({
    api: `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails`,
    customApi: `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails`,
    constantApi: [`${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails/sender-info`],
    setPagination,
  })

  const eventSelector = [
    {
      caption: t('dashboard.funnel.emails_registration_to_webinar'),
      id: EventTypeEnum.registration_to_webinar,
    },
    {
      caption: t('dashboard.funnel.emails_webinar_start'),
      id: EventTypeEnum.webinar_start,
    },
  ]

  useEffect(() => {
    setChosenSendingEmailTime(null)
  }, [selectedTimeBeforeAfter])

  useEffect(() => {
    setSelectedTimeBeforeAfter(TimeTypeState.after)
    setChosenSendingEmailTime(null)
    setChosenTimeDelay(0)
  }, [selectedCourse])

  useEffect(() => {
    if (infoSender) {
      setTempState(prev => ({
        ...prev,
        fromEmail: infoSender.senderAddress,
        fromName: infoSender.senderName,
      }))
    }
  }, [infoSender, opened])

  const renderTime = () => {
    return (
      <>
        <FormSelect
          withoutCloseIcon
          label={t('global.event_caption')}
          data={eventSelector}
          value={selectedCourse}
          onChange={data => setSelectedCourse(data)}
        />
        <TimeDelayPicker
          required
          selectedTimeType={selectedTimeType}
          selectedTimeBeforeAfter={selectedTimeBeforeAfter}
          selectedTimeValue={chosenTimeDelay}
          className={'pt-5'}
          classNameInputInteger={'lg:min-w-[40px]'}
          label={t('dashboard.funnel.emails_webinar.delay')}
          onChange={onChooseTimeDelay}
          error={errors.delay}
          withTimeAfterBeforeSelect={selectedCourse === EventTypeEnum.webinar_start}
        />
        {(selectedCourse === EventTypeEnum.registration_to_webinar ||
          selectedTimeBeforeAfter === TimeTypeState.after) && (
          <TimePicker
            className={'pt-5'}
            label={t('dashboard.funnel.emails_webinar.send_at_certain_time')}
            seconds={chosenSendingEmailTime}
            onChangeSeconds={setChosenSendingEmailTime}
          />
        )}
      </>
    )
  }

  const onCreate = async () => {
    try {
      if (tempState && funnelStepId) {
        setErrors(defaultErrors)
        if (!isValidEmail(tempState.fromEmail)) {
          return setErrors(prev => ({
            ...prev,
            fromEmail: t('dashboard.validation.email_not_valid'),
          }))
        }
        setIsFetching(true)
        const newData: FunnelEmailEditInterface = {
          mailing: { currentEmail: { ...tempState, body: getStyledBody(tempState.body) } },
          event: selectedCourse,
          delay: convertTimeValueToSeconds(
            chosenTimeDelay,
            selectedTimeType,
            selectedTimeBeforeAfter,
          ),
          certainTime: chosenSendingEmailTime,
        }
        const emailResponse = await createFunnelEmail(funnelStepId, newData)
        if (emailResponse.data) {
          await updateAttachments(emailResponse.data.mailing.currentEmail.id, attachmentsTempState)
        }
        await updateData(true, emails)
        await removeDbRecord()
        onClose()
        setIsFetching(false)
        toast.success(t('global.changes_saved'))
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const funnelEmailSettingsErrors = e.errors as unknown as FunnelEmailsErrorInterface
        if (funnelEmailSettingsErrors) {
          const fieldErrors = !Array.isArray(funnelEmailSettingsErrors.fields)
            ? funnelEmailSettingsErrors.fields
            : undefined
          if (fieldErrors) {
            setErrors(prev => ({
              ...prev,
              body: fieldErrors.mailing?.currentEmail?.body?.join(''),
              fromEmail: fieldErrors.mailing?.currentEmail?.fromEmail?.join(''),
              fromName: fieldErrors.mailing?.currentEmail?.fromName?.join(''),
              subject: fieldErrors.mailing?.currentEmail?.subject?.join(''),
              previewText: fieldErrors.mailing?.currentEmail?.previewText?.join(''),
              delay: fieldErrors.delay?.join(''),
            }))
          }
        }

        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const onChooseTimeDelay = useCallback<TimeDelayPickerProps['onChange']>(
    (timeDelay, timeType, timeBeforeAfter) => {
      setChosenTimeDelay(timeDelay)
      setSelectedTimeType(timeType)
      setSelectedTimeBeforeAfter(timeBeforeAfter)
    },
    [],
  )

  const onClose = () => {
    setOpened(false)
    setTempState(defaultValues)
    setChosenSendingEmailTime(null)
    setChosenTimeDelay(0)
    setErrors(defaultErrors)
    setIsFetching(false)
    setSelectedTimeBeforeAfter(TimeTypeState.after)
    setSelectedCourse(EventTypeEnum.registration_to_webinar)
  }

  const { setCkeditorRef, CkeditorUnsavedEmail, removeDbRecord, setNeedToShowModal } =
    useUnsavedEmail({
      type: UnsavedEmailsEnum.create_funnel_email,
      currentBody: tempState?.body,
      initialBody: '',
    })

  const onChangeEmail = (value: EmailBaseInterface) => setTempState(value)
  return (
    <>
      <div className={'px-6 py-4 flex justify-center'}>
        <PrimaryButton onClick={() => setOpened(true)}>+ {t('global.create')}</PrimaryButton>
      </div>
      <Modal
        opened={opened}
        size={ModalSizeEnum.big}
        onClose={onClose}
        afterEnter={() => setNeedToShowModal(true)}
        title={t('dashboard.funnel.emails_edit.send.email')}
        isFetching={isFetching}
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <DashboardMailEditor
            sidebarItemBlock={{
              title: t('global.settings'),
              Component: renderTime(),
            }}
            inModal={true}
            isPreFetching={false}
            tempState={tempState}
            onChangeEmail={onChangeEmail}
            attachmentsTempState={attachmentsTempState}
            setAttachmentsTempState={setAttachmentsTempState}
            errors={errors}
            setCkeditorRef={setCkeditorRef}
            additionalSubstitutes={[
              {
                name: t('dashboard.funnel.emails_edit.send.email_webinar.replay.link'),
                slug: 'webinar_show_link',
              },
              {
                name: t('dashboard.funnel.emails_edit.send.email_webinar.replay.link'),
                slug: 'webinar_replay_link',
              },
            ]}
          />
          <div className="flex justify-center gap-4">
            <SecondaryButton
              width="large"
              onClick={e => {
                e.preventDefault()
                onClose()
              }}
              type="submit"
            >
              {t('confirmation_modal.cancel')}
            </SecondaryButton>
            <PrimaryButton
              width="large"
              onClick={async e => {
                e.preventDefault()
                await onCreate()
              }}
              isFetching={isFetching}
              disabled={isFetching}
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
        </form>
        {CkeditorUnsavedEmail}
      </Modal>
    </>
  )
}

export default FunnelEmailCreate
