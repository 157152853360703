import React, { createContext, useContext } from 'react'

interface DeviceModeProviderProps {
  children?: React.ReactNode
  resetSelectedMobileTab?: () => void
  isMobile: boolean
}
type DeviceModeContextType = {
  isMobile: boolean
  resetSelectedMobileTab?: () => void
}

const DeviceModeContext = createContext<DeviceModeContextType>({
  isMobile: false,
})

export const useDeviceMode = () => useContext(DeviceModeContext)

function DeviceModeProvider({
  children,
  resetSelectedMobileTab,
  isMobile,
}: DeviceModeProviderProps) {
  return (
    <DeviceModeContext.Provider value={{ isMobile, resetSelectedMobileTab }}>
      {children}
    </DeviceModeContext.Provider>
  )
}

export default DeviceModeProvider
