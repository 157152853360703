import { FUNNEL_STEP_API } from 'modules/funnels/funnel/api/funnel-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'
import { AutomationRuleApiInterface } from '../../../../../automation-rules/types/automation-rule-interface'

export const useFunnelStepRules = (funnelStepId?: FunnelStepId) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    AutomationRuleApiInterface
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (!user || !funnelStepId) return
    return `${FUNNEL_STEP_API}/${funnelStepId}/automation-rules`
  }, fetcher)
}
