import React from 'react'

function PopupFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_607_37)">
        <path
          d="M18.307 5.76884C17.4271 5.76884 16.6797 5.18304 16.4362 4.38268C16.429 4.36339 16.4266 4.34893 16.4218 4.33205C16.3736 4.16571 16.3495 3.99214 16.3495 3.81134V0H0.993214C0.445982 0 0 0.443571 0 0.993214V21.1106C0 21.6337 0.421875 22.058 0.942589 22.058H21.101C21.6241 22.058 22.0436 21.6337 22.0436 21.1106V5.76884H18.307ZM3.40152 7.05134C3.40152 6.77411 3.6233 6.55232 3.90054 6.55232H10.9977C11.2749 6.55232 11.4967 6.77411 11.4967 7.05134V11.1086C11.4967 11.3858 11.2749 11.6076 10.9977 11.6076H3.90054C3.6233 11.6076 3.40152 11.3858 3.40152 11.1086V7.05134ZM18.6421 17.3788C18.6421 17.6561 18.4203 17.8779 18.143 17.8779H3.90054C3.6233 17.8779 3.40152 17.6561 3.40152 17.3788V17.2607C3.40152 16.9859 3.6233 16.7617 3.90054 16.7617H18.143C18.4203 16.7617 18.6421 16.9859 18.6421 17.2607V17.3788ZM18.6421 14.2449C18.6421 14.5197 18.4203 14.7439 18.143 14.7439H3.90054C3.6233 14.7439 3.40152 14.5197 3.40152 14.2449V14.1268C3.40152 13.852 3.6233 13.6278 3.90054 13.6278H18.143C18.4203 13.6278 18.6421 13.852 18.6421 14.1268V14.2449Z"
          fill="#CBD5E1"
        />
        <path
          d="M17.3523 3.72938V0L22.0435 4.69125H18.3166C17.7838 4.69125 17.3547 4.25973 17.3547 3.72938H17.3523Z"
          fill="#CBD5E1"
        />
        <path
          d="M27.0001 12.6562V26.0767C27.0001 26.5878 26.5854 27 26.0767 27H13.0541C12.5406 27 12.1235 26.5854 12.1235 26.0719V23.1863H21.101C22.2437 23.1863 23.1718 22.2557 23.1718 21.1106V12.6562H27.0001Z"
          fill="#CBD5E1"
        />
        <path
          d="M26.4504 9.34863H23.1719V11.728H27.0001V9.89828C27.0001 9.59453 26.7542 9.34863 26.4504 9.34863ZM25.0836 11.1229C24.7629 11.1229 24.5002 10.8602 24.5002 10.5395C24.5002 10.2189 24.7629 9.95613 25.0836 9.95613C25.4042 9.95613 25.6694 10.2165 25.6694 10.5395C25.6694 10.8626 25.4066 11.1229 25.0836 11.1229Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_37">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PopupFunnelStepIcon
