import { useSWRConfig } from 'swr'
import { FunnelId } from '../../types/funnel-interface'
import { FunnelStepId } from '../types/funnel-step-interface'

export type clearFunnelCacheFunctionType = (
  funnel?: FunnelId,
  funnelStepId?: FunnelStepId,
) => Promise<void>

export const useClearFunnelCache = () => {
  const { cache, mutate } = useSWRConfig()
  const clearFunnelCache: clearFunnelCacheFunctionType = async (funnelId, funnelStepId) => {
    const funnelApisToUpdate = [
      `/api/dashboard/customer/funnels/${funnelId}`,
      `/api/dashboard/customer/funnels/${funnelId}/steps/list`,
      `/api/dashboard/customer/funnels/step/${funnelStepId}/offer-form`,
      `/api/dashboard/customer/funnels/${funnelId}/stats`,
      `/api/dashboard/customer/funnels/${funnelId}/leads/list`,
      `/api/dashboard/customer/funnels/${funnelId}/sales`,
      `/api/dashboard/customer/funnels/${funnelId}/deadline`,
    ]

    const cacheArr = Array.from(cache as Map<string, {}>)

    const apiToClear = cacheArr.filter((el: [string, {}]) => {
      return funnelApisToUpdate.includes(el[0])
    })

    if (apiToClear.length > 0) {
      await Promise.all(
        apiToClear.map(async api => {
          await mutate(api[0], undefined, true)
        }),
      )
    }
  }

  return clearFunnelCache
}
