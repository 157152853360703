import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export interface FunnelStepDataInterface {
  bumpsCount: number
}

export function useFunnelStepData() {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelStepDataInterface
  >({ method: RequestMethodsEnum.get })

  const { data: funnelStepData, mutate: mutateFunnelStepData } = useSWR(() => {
    if (user) {
      return `${FUNNELS_API}/step/data`
    }
    return null
  }, fetcher)

  return { funnelStepData, mutateFunnelStepData }
}
