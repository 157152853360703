import { CouponApiInterface, CouponInterface } from 'modules/coupons/types/coupon-interface'
import { CouponCreateInterface } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/types/add-coupon-data-interface'
import { CouponUpdateInterface } from 'pages/coupons/[couponId]'
import httpClient from 'shared/utils/http-client'

export const COUPONS_API = '/api/dashboard/customer/coupons'

export const removeCoupon = (id: number) => httpClient.delete(`${COUPONS_API}/${id}`)

export const activateCoupon = (update: 'deactivate' | 'activate', id: number) =>
  httpClient.post<CouponApiInterface>(`${COUPONS_API}/${update}/${id}`)

export const createCoupon = (data: CouponCreateInterface) =>
  httpClient.post<CouponInterface>(COUPONS_API, data)

export const updateCoupon = (id: number, data: CouponUpdateInterface) =>
  httpClient.put<CouponInterface>(`${COUPONS_API}/${id}`, data)
