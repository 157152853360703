import { useSaleLimit } from 'modules/funnels/funnel/components/step-configuration-tab/sale-limit/hooks/use-sale-limit'
import {
  SaleLimitEditInterface,
  SaleLimitErrorInterface,
  SaleLimitViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/sale-limit/types/sale-limit-interface'
import RemoveIcon from 'modules/funnels/funnel/icons/remove-icon'
import TrashIcon from 'modules/funnels/funnel/icons/trash-icon'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelSettingsFieldStateStatusIcon } from 'modules/funnels/funnel/utils/get-funnel-settings-field-status-icon'
import { ListingDataInterface } from 'modules/funnels/funnel/utils/offer-configuration-utils'
import React, { useEffect, useState } from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import FormInput, { handleNumberInput } from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'

interface SaleLimitProps extends SaleLimitViewInterface {
  saleLimitFunnelStepData: ListingDataInterface[]
  funnelStepId: FunnelStepId
  onSave: (data: SaleLimitEditInterface) => Promise<void>
}

const defaultValues: SaleLimitEditInterface = {
  saleLimit: null,
  saleLimitFunnelStepRedirect: null,
}

const defaultErrors: SaleLimitErrorInterface = { saleLimitFunnelStepRedirect: '', saleLimit: '' }
function SaleLimit({
  saleLimit,
  saleLimitFunnelStepRedirect,
  saleLimitFunnelStepData,
  funnelStepId,
  onSave,
}: SaleLimitProps) {
  const { t } = useLocoTranslation()
  const [tempState, setTempState] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)
  const [isOpen, setIsOpen] = useState(false)
  const { saleLimitData } = useSaleLimit(funnelStepId, !!saleLimit)
  const [modalOpened, setModalOpened] = useState(false)
  const [isSaleLimitFetching, setIsSaleLimitFetching] = useState(false)

  useEffect(() => {
    if (saleLimit || saleLimitFunnelStepRedirect) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    setTempState({
      saleLimit,
      saleLimitFunnelStepRedirect: saleLimitFunnelStepRedirect?.id,
    })
  }, [saleLimit, saleLimitFunnelStepRedirect])

  const onSaleLimitSave = async (data: SaleLimitEditInterface) => {
    try {
      setErrors(defaultErrors)
      await onSave(data)
    } catch (error) {
      if (error instanceof BadRequest) {
        if (error.errors.fields) {
          const errors = error.errors.fields as unknown as any
          setErrors({
            saleLimit: errors.saleLimit?.join(', '),
            saleLimitFunnelStepRedirect: errors.saleLimitFunnelStepRedirect?.join(', '),
          })
        }
      }
    }
  }

  const onChangeSaleLimitFunnelStep = async (data?: number) => {
    const funnelStep = saleLimitFunnelStepData.find(item => item.id === data)
    const newData = { ...tempState, saleLimitFunnelStepRedirect: funnelStep?.id || null }
    setTempState(newData)
    await onSaleLimitSave(newData)
  }

  const onRemove = async () => {
    const newData = { ...tempState, saleLimit: null, saleLimitFunnelStepRedirect: null }
    await onSaleLimitSave(newData)
    setIsOpen(false)
    setTempState(newData)
  }

  return (
    <>
      <div className="flex items-center">
        {isOpen ? (
          <RemoveIcon
            className={`fill-blue w-[18px] h-[18px] cursor-pointer mr-3`}
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <AddIcon
            className={`fill-blue w-[18px] h-[18px] cursor-pointer mr-3`}
            onClick={() => setIsOpen(true)}
          />
        )}
        <span className={'lg:text-[15px] text-sm text-darkblue font-normal'}>
          <Hint label={t('dashboard.funnel.configuration.offer.sales_limit_popover')}>
            {t('dashboard.funnel.configuration.offer.sales_limit')}
          </Hint>
        </span>
      </div>
      {isOpen && (
        <div className="flex xl:gap-5 xl:flex-row flex-col  gap-4">
          <div className="flex flex-col gap-1 justify-center">
            <FormInput
              label={t('dashboard.funnel.configuration.offer.sales_limit')}
              labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
              required
              value={tempState.saleLimit === null ? 0 : tempState.saleLimit}
              onChange={e =>
                setTempState(prev => ({ ...prev, saleLimit: handleNumberInput(e.target.value) }))
              }
              onBlur={async () => {
                setIsSaleLimitFetching(true)
                await onSaleLimitSave(tempState)
                setIsSaleLimitFetching(false)
              }}
              maxLength={256}
              statusIcon={getFunnelSettingsFieldStateStatusIcon(isSaleLimitFetching)}
              error={errors.saleLimit}
            />
            {!!saleLimit && (
              <>
                {saleLimitData ? (
                  <div className="flex justify-center">
                    {t('dashboard.funnel.configuration.offer.sales_limit_sold', {
                      salesCount: saleLimitData.salesCount,
                      salesLimit: saleLimit,
                    })}
                  </div>
                ) : (
                  <div className={'text-transparent animate-pulse bg-gray-600 mx-4 rounded-lg'}>
                    .
                  </div>
                )}
              </>
            )}
          </div>
          <FormSelect
            label={t('dashboard.funnel.configuration.offer.redirect_funnel_step')}
            labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
            value={tempState.saleLimitFunnelStepRedirect || undefined}
            data={saleLimitFunnelStepData}
            onChange={onChangeSaleLimitFunnelStep}
            required
            error={errors.saleLimitFunnelStepRedirect}
          />
          <TrashIcon
            className="cursor-pointer w-[17px] h-[18px]"
            onClick={() => setModalOpened(true)}
          />
        </div>
      )}
      <ConfirmationModal
        confirmationText={t('dashboard.funnel.configuration.offer.remove_offer_sale_limit')}
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        onConfirm={onRemove}
      />
    </>
  )
}

export default SaleLimit
