import { Loader } from 'shared/components/loader'
import CheckIcon from 'shared/icons/check-icon'

export const getFunnelSettingsFieldStateStatusIcon = (isLoading: boolean, isSuccess?: boolean) => {
  if (isLoading) {
    return <Loader small />
  }
  if (isSuccess) {
    return <CheckIcon className="w-5 h-5 stroke-green" />
  }
}
