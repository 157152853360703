import FunnelStepActions from 'modules/funnels/funnel/components/funnel-actions'
import TestsIcon from 'modules/funnels/funnel/icons/tests-icon'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelStepTypeIcon } from 'modules/funnels/funnel/utils/get-funnel-step-type-icon'
import React from 'react'
import { FunnelStepTypeTitlesEnum } from 'shared/enums/funnel-step-type-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CheckIcon from 'shared/icons/check-icon'

interface OverlayFunnelStepProps {
  funnelStep?: FunnelStepInterface
}

function OverlayFunnelStep({ funnelStep }: OverlayFunnelStepProps) {
  const { t } = useLocoTranslation()

  if (!funnelStep) {
    return null
  }

  return (
    <li className="flex px-8 py-7 justify-between border-b-[1px] last:border-b-transparent border-[#142D6326] border-opacity-20 hover:bg-blue-06 cursor-pointer">
      <div className="flex gap-3">
        {getFunnelStepTypeIcon(funnelStep.type)}
        <div className="flex flex-col">
          <span className={`font-bold text-base`}>{funnelStep.name}</span>
          <span className="font-normal text-opacity-60 text-sm">
            {t(FunnelStepTypeTitlesEnum[funnelStep.type])}
          </span>
        </div>
      </div>
      {funnelStep.goalFunnelStepOfAnySplitTest && (
        <div className="absolute top-3 left-3">
          <CheckIcon className="stroke-[#CBD5E1] h-4 w-4" />
        </div>
      )}
      {funnelStep.inRunningSplitTest && (
        <div className="absolute top-3 left-3">
          <TestsIcon className="stroke-[#CBD5E1] h-4 w-4" active={false} />
        </div>
      )}
      <FunnelStepActions funnelStep={funnelStep} />
    </li>
  )
}

export default OverlayFunnelStep
