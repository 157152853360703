import {
  CourseBundleAccessTypeEnum,
  getCourseBundleAccessTypeDataOptions,
} from 'modules/courses/bundles/course-bundle/enums/access-type-enum'
import {
  AccessTypeEnum,
  getCourseAccessTypeDataOptions,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import {
  DigitalResourcesTypeEnum,
  DigitalResourcesTypeTitles,
} from 'modules/digital-product/enums/digital-resources-type-enum'
import DigitalResourceWrapper from 'modules/digital-product/resources/components/digital-resource-wrapper'
import { DigitalResourceType } from 'modules/digital-product/resources/types/digital-resource-interface'
import { getGrantCourseAccessUrl } from 'modules/digital-product/resources/utils/get-grant-course-access-url'
import { getStaticData } from 'modules/digital-product/resources/utils/get-static-data'
import React from 'react'
import { Checkbox } from 'shared/components/checkbox'
import CheckBoxSwitcher from 'shared/components/checkbox-switcher'
import DatePicker from 'shared/components/date-picker/date-picker'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { Hint } from 'shared/components/hint'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'

export const useDigitalResourceContent = (
  data: DigitalResourceType[],
  onDelete?: (id: number) => void,
): JSX.Element => {
  const { t } = useLocoTranslation()
  const { user } = useUser()

  return (
    <>
      {data.map(resource => {
        switch (resource.type) {
          case DigitalResourcesTypeEnum.membership_course: {
            return (
              <DigitalResourceWrapper
                resource={resource}
                onDelete={onDelete ? () => onDelete(resource.id) : undefined}
                key={resource.id}
              >
                {data => (
                  <>
                    <FormSelect
                      key={`resource-selector-${resource.id}`}
                      data={[
                        {
                          id: DigitalResourcesTypeEnum.membership_course,
                          caption: t(
                            DigitalResourcesTypeTitles[DigitalResourcesTypeEnum.membership_course],
                          ),
                        },
                      ]}
                      disabled
                      value={resource.type}
                      onChange={() => {}}
                    />
                    <div className="flex flex-col gap-3 ml-4">
                      <FormSelect
                        key={`course-selector-${resource.id}`}
                        value={resource.course}
                        staticData={getStaticData({ id: resource.course, data: data.coursesData })}
                        isPreFetching={!data.coursesData}
                        data={undefined}
                        onChange={() => {}}
                        disabled
                      />
                      <FormSelect
                        key={`access-type-selector-${resource.id}`}
                        value={resource.enrollmentAccessType}
                        staticData={getCourseAccessTypeDataOptions(t)}
                        onChange={() => {}}
                        data={undefined}
                        disabled
                      />
                      {resource.enrollmentAccessType === AccessTypeEnum.partialAccess && (
                        <div
                          key={`modules-selector-${resource.id}`}
                          className={`text-sm font-medium flex-col gap-1 ${
                            resource.modules && resource.modules.length === 0 ? 'hidden' : 'flex'
                          }`}
                        >
                          <ul className={'flex flex-wrap flex-row gap-5'}>
                            {resource.modules?.map(module => {
                              const label =
                                data.courseModulesData?.find(el => el.id === module)?.caption ?? ''
                              return (
                                <li key={module}>
                                  <Checkbox
                                    value={true}
                                    label={
                                      <div
                                        className={`flex gap-1.5 items-center [&>svg]:fill-blue [&>svg]:main-transition-colors`}
                                      >
                                        {label}
                                      </div>
                                    }
                                    onChange={() => {}}
                                    disabled
                                  />
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )}
                      {resource.enrollmentAccessType && !!resource.accessFrom && (
                        <>
                          <div
                            className="flex gap-5 items-center min-h-[42px]"
                            key={`access-from-selector-${resource.id}`}
                          >
                            <CheckBoxSwitcher
                              label={
                                <Hint
                                  label={
                                    <TransLoco
                                      t={t}
                                      i18nKey={
                                        'dashboard.funnel.configuration.common.offer_resources.course_start_access_on_day_description'
                                      }
                                      components={{
                                        a: (
                                          <a
                                            target={'_blank'}
                                            className={'primary-link'}
                                            href={getGrantCourseAccessUrl(user?.dashboardLocale)}
                                            rel="noreferrer"
                                          />
                                        ),
                                      }}
                                    />
                                  }
                                >
                                  {t(
                                    'dashboard.funnel.configuration.common.offer_resources.course_start_access_on_day',
                                  )}
                                </Hint>
                              }
                              selected={resource.accessFrom !== undefined}
                              onChange={() => {}}
                              disabled
                            />
                            <DatePicker value={resource.accessFrom} onChange={() => {}} disabled />
                          </div>
                        </>
                      )}

                      {(resource.enrollmentAccessType === AccessTypeEnum.fullAccess ||
                        resource.enrollmentAccessType === AccessTypeEnum.partialAccess) &&
                        !!resource.expirationPeriod && (
                          <div
                            className="flex gap-5 items-center min-h-[42px]"
                            key={`enrollment-access-type-selector-${resource.id}`}
                          >
                            <CheckBoxSwitcher
                              label={
                                <Hint
                                  label={t(
                                    'dashboard.funnel.configuration.common.offer_resources.course_expiration_period_popover',
                                  )}
                                >
                                  {t(
                                    'dashboard.funnel.configuration.common.offer_resources.course_expiration_period',
                                  )}
                                </Hint>
                              }
                              selected={!!resource.expirationPeriod}
                              onChange={() => {}}
                              disabled
                            />
                            <FormInput
                              key={`expiration-period-selector-${resource.id}`}
                              value={resource.expirationPeriod}
                              onChange={() => {}}
                              disabled
                              type={'number'}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.membership_course_bundle: {
            return (
              <DigitalResourceWrapper
                resource={resource}
                onDelete={onDelete ? () => onDelete(resource.id) : undefined}
                key={resource.id}
              >
                {data => (
                  <>
                    <FormSelect
                      key={`resource-type-selector-${resource.id}`}
                      data={[
                        {
                          id: DigitalResourcesTypeEnum.membership_course_bundle,
                          caption: t(
                            DigitalResourcesTypeTitles[
                              DigitalResourcesTypeEnum.membership_course_bundle
                            ],
                          ),
                        },
                      ]}
                      disabled
                      value={resource.type}
                      onChange={() => {}}
                    />
                    <div className="flex flex-col gap-3 ml-4">
                      <FormSelect
                        key={`course-bundle-selector-${resource.courseBundle}`}
                        value={resource.courseBundle}
                        staticData={getStaticData({
                          id: resource.courseBundle,
                          data: data.courseBundlesData,
                        })}
                        isPreFetching={!data.courseBundlesData}
                        data={undefined}
                        onChange={() => {}}
                        disabled
                      />
                      <>
                        {resource.courseBundle && (
                          <FormSelect
                            key={`course-bundle-access-type-selector-${resource.id}`}
                            value={resource.enrollmentAccessType}
                            staticData={getCourseBundleAccessTypeDataOptions(t)}
                            onChange={() => {}}
                            data={undefined}
                            disabled
                          />
                        )}
                        {resource.courseBundle &&
                          resource.enrollmentAccessType ===
                            CourseBundleAccessTypeEnum.dripping_content && (
                            <>
                              {resource.enrollmentDrippingAccessCourse ? (
                                <FormSelect
                                  key={`enrollment-dripping-course-selector-${resource.id}`}
                                  value={resource.enrollmentDrippingAccessCourse}
                                  staticData={getStaticData({
                                    id: resource.enrollmentDrippingAccessCourse,
                                    data: data.courseBundleCoursesData,
                                  })}
                                  isPreFetching={!data.courseBundleCoursesData}
                                  data={undefined}
                                  onChange={() => {}}
                                  disabled
                                />
                              ) : (
                                <FormInput
                                  key={`enrollment-dripping-course-selector-${resource.id}`}
                                  value={t(
                                    'dashboard.funnel.configuration.common.offer_resources.enrollment_dripping_access.first_course',
                                  )}
                                  onChange={() => {}}
                                  disabled
                                />
                              )}
                            </>
                          )}
                      </>
                    </div>
                  </>
                )}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.systemeio_tag: {
            return (
              <DigitalResourceWrapper
                resource={resource}
                onDelete={onDelete ? () => onDelete(resource.id) : undefined}
                key={resource.id}
              >
                {data => (
                  <>
                    <FormSelect
                      data={[
                        {
                          id: DigitalResourcesTypeEnum.systemeio_tag,
                          caption: t(
                            DigitalResourcesTypeTitles[DigitalResourcesTypeEnum.systemeio_tag],
                          ),
                        },
                      ]}
                      disabled
                      value={resource.type}
                      onChange={() => {}}
                    />
                    <FormSelect
                      wrapperClassName="ml-4"
                      value={resource.value}
                      data={getStaticData({ data: data.tagsData, id: resource.value })}
                      isPreFetching={!data.tagsData}
                      onChange={() => {}}
                      disabled
                    />
                  </>
                )}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.community_access: {
            return (
              <DigitalResourceWrapper
                resource={resource}
                onDelete={onDelete ? () => onDelete(resource.id) : undefined}
                key={resource.id}
              >
                {data => (
                  <>
                    <FormSelect
                      key={`resource-type-selector-${resource.id}`}
                      data={[
                        {
                          id: DigitalResourcesTypeEnum.community_access,
                          caption: t(
                            DigitalResourcesTypeTitles[DigitalResourcesTypeEnum.community_access],
                          ),
                        },
                      ]}
                      disabled
                      value={resource.type}
                      onChange={() => {}}
                    />
                    <FormSelect
                      key={`campaign-position-input-${resource.id}`}
                      wrapperClassName="ml-4"
                      value={resource.value}
                      isPreFetching={!data.communitiesData}
                      staticData={getStaticData({ data: data.communitiesData, id: resource.value })}
                      data={undefined}
                      onChange={() => {}}
                      disabled
                    />
                  </>
                )}
              </DigitalResourceWrapper>
            )
          }
          case DigitalResourcesTypeEnum.systemeio_plan: {
            return (
              <DigitalResourceWrapper
                resource={resource}
                onDelete={onDelete ? () => onDelete(resource.id) : undefined}
                key={resource.id}
              >
                {data => {
                  return (
                    <>
                      <FormSelect
                        key={`resource-type-selector-${resource.id}`}
                        data={[
                          {
                            id: DigitalResourcesTypeEnum.systemeio_plan,
                            caption: t(
                              DigitalResourcesTypeTitles[DigitalResourcesTypeEnum.systemeio_plan],
                            ),
                          },
                        ]}
                        disabled
                        value={resource.type}
                        onChange={() => {}}
                      />
                      <FormSelect
                        key={`systemeio-plan-selector-${resource.id}`}
                        wrapperClassName="ml-4"
                        value={resource.value}
                        isPreFetching={!data.plansData}
                        staticData={getStaticData({ data: data.plansData, id: resource.value })}
                        data={undefined}
                        onChange={() => {}}
                        disabled
                      />
                    </>
                  )
                }}
              </DigitalResourceWrapper>
            )
          }
          default: {
            return <></>
          }
        }
      })}
    </>
  )
}
