import {
  FUNNEL_EMAIL_API,
  deleteFunnelEmail,
} from 'modules/funnels/funnel/components/email-tab/api/funnel-email-api'
import FunnelEmailUpdate from 'modules/funnels/funnel/components/email-tab/funnel-email-update'
import {
  FunnelEmailsApiInterface,
  FunnelEmailsInterface,
} from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import React from 'react'
import toast from 'react-hot-toast'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { convertSecondsToTimeValue } from 'shared/components/date-picker/time-delay-picker/utils'
import { transformSecondsToHoursAndMinutes } from 'shared/components/date-picker/time-picker/utils'
import Popover from 'shared/components/popover'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { LimitType, PaginationInterface } from 'shared/types/pagination-interface'
import { KeyedMutator } from 'swr'

interface FunnelEmailTableProps {
  emails?: FunnelEmailsApiInterface
  funnelStepId?: FunnelStepId
  setPagination: React.Dispatch<React.SetStateAction<PaginationInterface>>
  setLimit: React.Dispatch<React.SetStateAction<LimitType>>
  limit: LimitType
  pagination: PaginationInterface
  mutateEmails: KeyedMutator<FunnelEmailsApiInterface>
}

const FunnelEmailTable = ({
  emails,
  funnelStepId,
  setPagination,
  setLimit,
  limit,
  pagination,
  mutateEmails,
}: FunnelEmailTableProps) => {
  const { t } = useLocoTranslation()

  const updateData = useUpdateData({
    api: `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails`,
    setPagination,
  })

  const deleteEmail = async (email: FunnelEmailsInterface) => {
    await deleteFunnelEmail(email.webinarNotification.id)
    await updateData(false, emails)
  }

  const columns: ColumnDefinitionInterface<FunnelEmailsInterface>[] = [
    {
      key: 'mailingStats',
      header: t('global.event_caption'),
      widthPercent: 73,
      onRender: email => {
        const time = transformSecondsToHoursAndMinutes(email.webinarNotification.certainTime)
        const delay = convertSecondsToTimeValue(email.webinarNotification.delay)
        return (
          <div className={'flex gap-2'}>
            <Popover
              label={
                <div className={'flex flex-col items-start'}>
                  {delay && (
                    <span>
                      {t('dashboard.funnel.emails_webinar.delay')}:{' '}
                      {`${delay.timeValue} ${t(delay.name)}`}
                    </span>
                  )}
                  {email.webinarNotification.certainTime && (
                    <span className="pb-2">
                      {t('dashboard.funnel.emails_webinar.certain_time')}: {time.hours}:
                      {time.minutes}
                    </span>
                  )}
                  <span>
                    {t('dashboard.funnel.emails_webinar.number_of_sent_emails')}:{' '}
                    {email.mailingStats.sentAmount}
                  </span>
                  <span>
                    {t('dashboard.funnel.emails_webinar.opened_emails')}:{' '}
                    {email.mailingStats.openedAmount}
                  </span>
                  <span>
                    {t('dashboard.funnel.emails_webinar.count_of_click')}:{' '}
                    {email.mailingStats.clickedAmount}
                  </span>
                </div>
              }
            >
              {show => (
                <QuestionMarkIcon
                  className={`${
                    show ? 'fill-blue' : 'fill-gray-100'
                  } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                />
              )}
            </Popover>
            <div className="truncate">
              <span className="w-full">
                {email.webinarNotification.event === 'webinar_start'
                  ? email.webinarNotification.delay < 0
                    ? `${t('dashboard.funnel.emails_webinar_start_before')} `
                    : `${t('dashboard.funnel.emails_webinar_start_after')} `
                  : ''}
                {t(`dashboard.funnel.emails_${email.webinarNotification.event}`)}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      key: 'webinarNotification',
      header: t('global.title_caption'),
      widthPercent: 73,
      onRender: email => (
        <div className="truncate">
          <span className="w-full">{email.webinarNotification.mailing.currentEmail.subject}</span>
        </div>
      ),
    },
  ]
  return (
    <Table
      data={emails}
      nestedId={'webinarNotification'}
      columns={columns}
      caption={t('dashboard.funnel.title')}
      limiter={{ limit, setLimit }}
      paginator={{ pagination, setPagination }}
      testAttributePostfix="emails"
      actions={{
        custom: [
          {
            name: 'edit-send-email',
            caption: t('dashboard.funnel.emails_webinar.edit_send_email'),
            onActionRender: (opened, onClose, instance) => (
              <FunnelEmailUpdate
                opened={opened}
                onClose={onClose}
                instance={instance}
                funnelStepId={funnelStepId}
                mutateEmails={mutateEmails}
              />
            ),
          },
          {
            name: 'delete-email',
            caption: t('dashboard.funnel.emails_webinar.remove_email'),
            onActionRender: (opened, onClose, instance, key) => (
              <ConfirmationModal
                key={key}
                toastCaption={t('dashboard.funnel.emails_webinar.email_delete_success')}
                confirmationText={t('dashboard.funnel.emails_webinar.modal_delete_confirm')}
                opened={opened}
                onClose={onClose}
                onConfirm={() => deleteEmail(instance)}
              />
            ),
          },
        ],
      }}
    />
  )
}

export default FunnelEmailTable
