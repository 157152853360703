import DigitalProductEdit from 'modules/digital-product/components/digital-product-edit'
import DigitalProductSelect from 'modules/digital-product/components/digital-product-select'
import { DigitalProductSourceEnum } from 'modules/digital-product/enums/digital-product-source-enum'
import { DigitalProductTabEnum } from 'modules/digital-product/enums/digital-product-tab-enum'
import { useDigitalProductsList } from 'modules/digital-product/hooks/use-digital-product-list'
import { DigitalProductInterface } from 'modules/digital-product/types/digital-product-interface'
import { DigitalOfferEditInterface } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/digital-offer-interface'
import React, { FC, useState } from 'react'
import { Loader } from 'shared/components/loader'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { PaymentMethodEnum } from 'shared/enums/payment-method-enum'
import {
  DigitalProductDeleteModal,
  useDigitalProductDeleteModal,
} from './components/digital-product-delete'

export interface DigitalProductProps {
  product: DigitalProductInterface | null
  onSave: (data: DigitalOfferEditInterface['digitalProduct']) => Promise<void>
  currency: CurrencyEnum
  paymentMethods: PaymentMethodEnum[]
  isBump?: boolean
  source: DigitalProductSourceEnum
}

const DigitalProduct: FC<DigitalProductProps> = ({
  product,
  paymentMethods,
  onSave,
  currency,
  isBump,
  source,
}) => {
  const [tab, setTab] = useState<DigitalProductTabEnum>(DigitalProductTabEnum.select)
  const deleteModal = useDigitalProductDeleteModal()

  const { digitalProductList, mutate } = useDigitalProductsList(currency, source)

  if (!digitalProductList) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  const digitalProduct = digitalProductList.find(el => el.id === product?.id) ?? null

  return (
    <div className="flex flex-col gap-5">
      {tab === DigitalProductTabEnum.select && (
        <DigitalProductSelect
          digitalProductId={digitalProduct?.id}
          onSave={onSave}
          isBump={isBump}
          setTab={setTab}
          digitalProductList={digitalProductList}
          onDelete={deleteModal.open}
        />
      )}
      {tab === DigitalProductTabEnum.create && (
        <DigitalProductEdit
          onSave={onSave}
          paymentMethods={paymentMethods}
          currency={currency}
          setTab={setTab}
          isBump={isBump}
          source={source}
        />
      )}
      {tab === DigitalProductTabEnum.edit && digitalProduct && (
        <DigitalProductEdit
          digitalProduct={digitalProduct}
          paymentMethods={paymentMethods}
          currency={currency}
          setTab={setTab}
          isBump={isBump}
          source={source}
        />
      )}

      <DigitalProductDeleteModal
        opened={deleteModal.opened}
        digitalProductName={
          digitalProductList.find(el => el.id === deleteModal.digitalProductId)?.name
        }
        digitalProductLinkedOffersCount={deleteModal.digitalProductLinkedOffersCount}
        onClose={deleteModal.close}
        afterLeave={deleteModal.afterLeave}
        onConfirm={async () => {
          await deleteModal.deleteDigitalProduct()
          await mutate(data => {
            return data?.filter(e => e.id !== deleteModal.digitalProductId)
          }, false)

          if (digitalProduct?.id === deleteModal.digitalProductId) {
            onSave(null)
          }
        }}
      />
    </div>
  )
}

export default DigitalProduct
