import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import FormTextarea from 'shared/components/form-textarea'
import Modal from 'shared/components/modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { copyTextToClipboard } from 'shared/utils/copy-to-clipboard'
import { FunnelStepId } from '../../../types/funnel-step-interface'
import { getScriptLinkHtmlCode } from '../../../utils/remote-form-utils'

interface CreateRemotePopupLinkModalProps {
  opened: boolean
  onClose: () => void
  domainName: string
  pageHash: string
  funnelStepId: FunnelStepId
}

const CreateRemotePopupLinkModal = ({
  opened,
  onClose,
  domainName,
  pageHash,
  funnelStepId,
}: CreateRemotePopupLinkModalProps) => {
  const { t } = useLocoTranslation()
  const defaultAnchorName = t('dashboard.funnel.configuration.remote.anchor_name_placeholder')
  const [tempAnchorName, setTempAnchorName] = useState(defaultAnchorName)
  const [tempLink, setTempLink] = useState(
    getScriptLinkHtmlCode(domainName, pageHash, funnelStepId, tempAnchorName),
  )

  useEffect(() => {
    setTempLink(getScriptLinkHtmlCode(domainName, pageHash, funnelStepId, tempAnchorName))
  }, [tempAnchorName])

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose()
        setTempAnchorName(defaultAnchorName)
      }}
      title={t('dashboard.funnel.configuration.remote.modal_link_header')}
    >
      <form className="flex flex-col gap-6 lg:gap-10">
        <FormInput
          label={t('dashboard.funnel.configuration.remote.anchor')}
          value={tempAnchorName}
          onChange={e => setTempAnchorName(e.target.value)}
        />
        <div className="flex justify-center flex-col gap-3">
          <FormTextarea value={tempLink} disabled />
        </div>
        <div className="flex justify-center gap-4">
          <PrimaryButton
            onClick={e => {
              e.preventDefault()
              copyTextToClipboard(tempLink)
              toast.success(t('global.copy_clipboard'))
            }}
          >
            {t('global.copy_to_clipboard_caption')}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  )
}

export default CreateRemotePopupLinkModal
