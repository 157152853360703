import React from 'react'

const FunnelStepsListSkeleton = () => (
  <div
    className={`h-fit w-full inset-0 flex flex-col animate-pulse rounded-md lg:w-[330px] bg-white overflow-auto last:border-b-transparent`}
  >
    <div className="px-8 py-7 border-b border-b-gray/10 ">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
    <div className="px-8 py-7 border-b border-b-gray/10">
      <div className="bg-gray-600 rounded text-transparent my-0.5 h-[40px]">.</div>
    </div>
  </div>
)

export default FunnelStepsListSkeleton
