import PricePlanBase from 'modules/digital-product/price-plans/components/price-plan-base'
import PricePlanTooltip from 'modules/digital-product/price-plans/components/price-plan-tooltip'
import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import React, { FC } from 'react'

interface Props {
  pricePlans: PricePlanType[]
}

export const PricePlansReadOnly: FC<Props> = ({ pricePlans }) => {
  return (
    <PricePlanBase>
      {pricePlans.map(pricePlan => (
        <div
          key={pricePlan.id}
          className="flex justify-between items-center rounded-lg border-gray/30 border p-2 w-full"
        >
          <div className="flex flex-col gap-3 bo-color-[#F8FAFC]">
            <PricePlanTooltip
              pricePlan={pricePlan}
              label={
                <div className="flex flex-row gap-1 lg:text-[15px] text-sm text-blue items-center">
                  <span>{pricePlan.innerName || 'test.price.plan.name'}</span>
                </div>
              }
            />
          </div>
        </div>
      ))}
    </PricePlanBase>
  )
}
