import React from 'react'

interface LeadsIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function LeadsIcon({ active }: LeadsIconProps) {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill={active ? '#00A0FF' : '#CBD5E1'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.902344 3.62012C0.902344 1.96327 2.24549 0.620117 3.90234 0.620117H22.9629C24.6197 0.620117 25.9629 1.96326 25.9629 3.62012V16.8706C25.9629 18.5274 24.6197 19.8706 22.9629 19.8706H3.90234C2.24549 19.8706 0.902344 18.5274 0.902344 16.8706V3.62012Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9629 14.2299C20.9629 14.5837 20.6564 14.8706 20.2783 14.8706H12.0635C11.6854 14.8706 11.3789 14.5837 11.3789 14.2299C11.3789 12.107 13.2179 10.386 15.4863 10.386H16.8555C19.1239 10.386 20.9629 12.107 20.9629 14.2299ZM16.1709 4.62012C14.6586 4.62012 13.4326 5.76744 13.4326 7.18273C13.4326 8.59802 14.6586 9.74534 16.1709 9.74534C17.6832 9.74534 18.9092 8.59802 18.9092 7.18273C18.9092 5.76744 17.6832 4.62012 16.1709 4.62012ZM10.0098 4.62012C8.49745 4.62012 7.27148 5.76744 7.27148 7.18273C7.27148 8.59802 8.49745 9.74534 10.0098 9.74534C11.5221 9.74534 12.748 8.59802 12.748 7.18273C12.748 5.76744 11.5221 4.62012 10.0098 4.62012ZM10.0098 14.2299C10.0077 12.8707 10.587 11.5672 11.6185 10.6102C11.2006 10.4626 10.7577 10.3866 10.311 10.386H9.70855C7.608 10.3895 5.90611 11.9822 5.90234 13.948V14.2299C5.90234 14.5837 6.20883 14.8706 6.58691 14.8706H10.133C10.0533 14.6651 10.0116 14.4485 10.0098 14.2299Z"
        fill={active ? 'white' : '#64748B'}
      />
    </svg>
  )
}

export default LeadsIcon
