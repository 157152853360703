import React from 'react'

function LinkInBioIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_331_2)">
        <path d="M27 0H0V27H27V0Z" fill="white" />
        <path
          d="M25.3231 0H6.60767C7.12974 0.442969 7.48306 1.04941 7.60962 1.72441C7.81528 2.81074 8.55884 2.8793 8.72231 2.8793H8.74341H27V1.67695C27 0.754102 26.2512 0 25.3231 0Z"
          fill="#CBD5E1"
        />
        <path
          d="M6.96094 1.8457C6.76582 0.82793 6.00117 0.279492 5.4 0H1.67695C0.754102 0 0 0.754102 0 1.67695V25.3178C0 26.2459 0.754102 27 1.67695 27H25.3178C26.2459 27 26.9947 26.2512 26.9947 25.323V3.5332H8.73809C8.73809 3.5332 7.29316 3.58594 6.96094 1.8457ZM11.86 8.76973L12.7934 7.83633C14.9502 5.69004 18.4412 5.69531 20.5875 7.85215C22.7285 10.0037 22.7285 13.4789 20.5875 15.6305L19.6541 16.5639C19.2006 17.0332 18.4465 17.049 17.9771 16.5955C17.5078 16.142 17.492 15.3879 17.9455 14.9186C17.9561 14.908 17.9666 14.8975 17.9771 14.8869L18.9105 13.9535C20.134 12.7248 20.134 10.7367 18.9053 9.51328C18.8736 9.48164 18.8367 9.44473 18.7998 9.41309C17.5553 8.2793 15.6305 8.3584 14.4387 9.5502L13.5369 10.452C13.0729 10.916 12.324 10.916 11.86 10.452C11.3959 9.98789 11.3959 9.23379 11.86 8.76973ZM10.8158 15.9311L14.5072 12.2396C14.9713 11.7756 15.7201 11.7756 16.1842 12.2396C16.6482 12.7037 16.6482 13.4525 16.1842 13.9166L12.4928 17.608C12.0287 18.0721 11.2799 18.0721 10.8158 17.608C10.3518 17.1439 10.3518 16.3951 10.8158 15.9311ZM9.02285 14.9607L8.08945 15.8941C6.86602 17.1229 6.86602 19.1109 8.08945 20.3344C8.12637 20.3713 8.15801 20.4029 8.19492 20.4346C9.43945 21.5684 11.3643 21.4893 12.5561 20.2975L13.4578 19.401C13.9113 18.9316 14.6654 18.9158 15.1348 19.3693C15.6041 19.8229 15.6199 20.577 15.1664 21.0463C15.1559 21.0568 15.1453 21.0674 15.1348 21.0779L14.2014 22.0113C12.0445 24.1576 8.55352 24.1523 6.40723 21.9955C4.26621 19.8439 4.26621 16.3688 6.40723 14.2172L7.34062 13.2838C7.80469 12.8197 8.55352 12.8197 9.01758 13.2838C9.48691 13.7479 9.48691 14.4967 9.02285 14.9607Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_2">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkInBioIcon
