import { ListingPhysicalProductInterface } from 'modules/funnels/funnel/components/step-configuration-tab/physical-product-selector/types/listing-physical-product-interface'
import { PRODUCTS_API } from 'modules/products/api/product-api'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

export function usePhysicalProductsList(currency: CurrencyEnum) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ListingPhysicalProductInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: physicalProductsList } = useSWR(() => {
    return `${PRODUCTS_API}/list-by-currency/${currency}`
  }, fetcher)

  return { physicalProductsList }
}
