import React from 'react'

interface ConfigurationIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function ConfigurationIcon({ active }: ConfigurationIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1971 0H5.8129C2.17109 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5859 17.2501C13.1759 17.2501 12.8359 16.9101 12.8359 16.5001V12.6001C12.8359 12.1901 13.1759 11.8501 13.5859 11.8501C13.9959 11.8501 14.3359 12.1901 14.3359 12.6001V16.5001C14.3359 16.9101 13.9959 17.2501 13.5859 17.2501Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5859 6.2C13.1759 6.2 12.8359 5.86 12.8359 5.45V3.5C12.8359 3.09 13.1759 2.75 13.5859 2.75C13.9959 2.75 14.3359 3.09 14.3359 3.5V5.45C14.3359 5.86 13.9959 6.2 13.5859 6.2Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42188 17.25C6.01188 17.25 5.67188 16.91 5.67188 16.5V14.55C5.67188 14.14 6.01188 13.8 6.42188 13.8C6.83187 13.8 7.17188 14.14 7.17188 14.55V16.5C7.17188 16.91 6.84187 17.25 6.42188 17.25Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42188 8.15C6.01188 8.15 5.67188 7.81 5.67188 7.4V3.5C5.67188 3.09 6.01188 2.75 6.42188 2.75C6.83187 2.75 7.17188 3.09 7.17188 3.5V7.4C7.17188 7.81 6.84187 8.15 6.42188 8.15Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5855 5.33008C12.0855 5.33008 10.8555 6.55008 10.8555 8.05008C10.8555 9.55008 12.0755 10.7801 13.5855 10.7801C15.0855 10.7801 16.3055 9.56008 16.3055 8.05008C16.3055 6.54008 15.0855 5.33008 13.5855 5.33008Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42313 9.22998C4.92313 9.22998 3.70312 10.45 3.70312 11.96C3.70312 13.47 4.92313 14.68 6.42313 14.68C7.92313 14.68 9.15312 13.46 9.15312 11.96C9.15312 10.46 7.93313 9.22998 6.42313 9.22998Z"
        fill={active ? 'white' : '#64748B'}
      />
    </svg>
  )
}

export default ConfigurationIcon
