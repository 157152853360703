import React from 'react'

function EditIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_301_38)">
        <path d="M18.9999 12.0461C18.447 12.0461 18 12.4942 18 13.0461V21.0461C18 21.5971 17.5519 22.0461 17.0001 22.0461H3C2.44794 22.0461 2.00006 21.5971 2.00006 21.0461V7.04608C2.00006 6.49512 2.44794 6.04614 3 6.04614H11.0001C11.553 6.04614 12 5.59808 12 5.0462C12 4.49414 11.553 4.04608 11.0001 4.04608H3C1.34601 4.04608 0 5.39209 0 7.04608V21.0461C0 22.7001 1.34601 24.0461 3 24.0461H17.0001C18.6541 24.0461 20.0001 22.7001 20.0001 21.0461V13.0461C20.0001 12.4931 19.5529 12.0461 18.9999 12.0461Z" />
        <path d="M9.37594 11.1352C9.306 11.2051 9.25894 11.2941 9.23898 11.3901L8.53201 14.9262C8.49905 15.0901 8.55105 15.2591 8.66897 15.3781C8.76401 15.4731 8.892 15.524 9.0231 15.524C9.05496 15.524 9.0881 15.5211 9.12106 15.5142L12.6561 14.8072C12.7541 14.787 12.843 14.7402 12.9121 14.67L20.8241 6.75806L17.289 3.22321L9.37594 11.1352Z" />
        <path d="M23.2679 0.778198C22.2931 -0.196838 20.707 -0.196838 19.7329 0.778198L18.349 2.16211L21.884 5.69714L23.2679 4.31305C23.74 3.8421 24 3.21405 24 2.54608C24 1.87811 23.74 1.25006 23.2679 0.778198Z" />
      </g>
      <defs>
        <clipPath id="clip0_301_38">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditIcon
