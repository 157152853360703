import React from 'react'

function TrashIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72701 21.6817H13.5141C14.348 21.6817 15.0264 21.0033 15.0264 20.1693V8.21875H2.21484V20.1693C2.21484 21.0033 2.89325 21.6817 3.7272 21.6817H3.72701ZM11.0777 11.3471C11.0777 11.0687 11.3035 10.8429 11.5818 10.8429C11.8602 10.8429 12.086 11.0687 12.086 11.3471V17.5519C12.086 17.8303 11.8602 18.0561 11.5818 18.0561C11.3035 18.0561 11.0777 17.8303 11.0777 17.5519V11.3471ZM5.43703 11.3471C5.43703 11.0687 5.6628 10.8429 5.94115 10.8429C6.2195 10.8429 6.4453 11.0687 6.4453 11.3471V17.5519C6.4453 17.8303 6.21953 18.0561 5.94115 18.0561C5.6628 18.0561 5.43703 17.8303 5.43703 17.5519V11.3471Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9983 4.99281C16.9983 4.43686 16.546 3.98457 15.9901 3.98457H12.0838V2.92861C12.0838 1.81666 11.1792 0.912109 10.0673 0.912109H7.16928C6.05731 0.912109 5.1528 1.81666 5.1528 2.92861V3.98457H1.24652C0.690581 3.98457 0.238281 4.43684 0.238281 4.99281V7.21097H16.9985L16.9983 4.99281ZM11.0755 3.98457H6.16125V2.92861C6.16125 2.37266 6.61352 1.92035 7.16949 1.92035H10.0675C10.6235 1.92035 11.0758 2.37263 11.0758 2.92861L11.0755 3.98457Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default TrashIcon
