import RuleSave from 'modules/automation-rules/components/rule-save'
import { useRuleState } from 'modules/automation-rules/hooks/use-rule-state'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRuleApiInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { FunnelStepRule } from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule'
import { FunnelTriggerDataInterface } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'
import React, { useRef, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { KeyedMutator } from 'swr'

export interface FunnelStepRuleCreateProps {
  mutateRules: KeyedMutator<AutomationRuleApiInterface>
  mutateAvailableTriggers: KeyedMutator<AutomationRuleTriggersEnum[]>
  availableTriggers?: AutomationRuleTriggersEnum[]
  funnelTriggerData: FunnelTriggerDataInterface
}

const FunnelStepRuleCreate = ({
  mutateRules,
  mutateAvailableTriggers,
  availableTriggers,
  funnelTriggerData,
}: FunnelStepRuleCreateProps) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)

  const { tempState, setTempState, errors, setErrors, clearState } = useRuleState()

  const maxTriggerId = useRef(0)
  const maxActionId = useRef(0)

  const [isFetching, setIsFetching] = useState(false)

  const afterSave = async () => {
    await mutateRules()
    await mutateAvailableTriggers()
    setOpened(false)
  }

  return (
    <div className={'lg:px-8 px-6 py-4 flex justify-center'}>
      <PrimaryButton
        onClick={() => setOpened(true)}
        disabled={!availableTriggers || availableTriggers.length === 0}
      >
        {t('dashboard.funnel.automation_rules.add_rule')}
      </PrimaryButton>
      <Modal
        afterLeave={clearState}
        size={ModalSizeEnum.big}
        isFetching={isFetching}
        title={t('dashboard.automation_rule.edit.create_title')}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <FunnelStepRule
          isFetching={isFetching}
          tempState={tempState}
          setTempState={setTempState}
          errors={errors}
          maxActionId={maxActionId}
          maxTriggerId={maxTriggerId}
          funnelTriggerData={funnelTriggerData}
          availableTriggers={availableTriggers}
        />
        <div className={'mt-5 flex justify-center'}>
          <RuleSave
            setErrors={setErrors}
            afterSave={afterSave}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            rule={tempState}
          />
        </div>
      </Modal>
    </div>
  )
}

export default FunnelStepRuleCreate
