import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import {
  FunnelStepId,
  ListingFunnelStepsInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useGoalTestFunnelSteps(funnelStepId?: FunnelStepId, shouldFetch?: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ListingFunnelStepsInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: goalFunnelStepsData } = useSWR(() => {
    if (user && funnelStepId && shouldFetch) {
      return `${FUNNELS_API}/steps/${funnelStepId}/goal-step`
    }
    return null
  }, fetcher)

  return { goalFunnelStepsData }
}
