import { MARKET_PLACE_API } from 'modules/funnels/api/market-place-api'
import { OfferCategoryInterface } from 'modules/funnels/funnel/types/marketplace-offer-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useMarketplaceOfferCategories(shouldFetch?: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    OfferCategoryInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: marketPlaceOfferCategories } = useSWR(() => {
    if (user && shouldFetch) {
      return `${MARKET_PLACE_API}/offer-categories`
    }
    return null
  }, fetcher)

  return { marketPlaceOfferCategories }
}
