import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import SplitTestActions from 'modules/funnels/funnel/components/split-tests-tab/split-test-actions'
import SplitTestHistory from 'modules/funnels/funnel/components/split-tests-tab/split-test-history'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { useFunnelStepTests } from 'modules/funnels/funnel/hooks/use-funnel-step-tests'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Loader } from 'shared/components/loader'

interface SplitTestsTabProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface | null
}

function SplitTestsTab({ funnelId, funnelStep }: SplitTestsTabProps) {
  const { splitTestData } = useFunnelStepTests(funnelStep?.id)

  const router = useRouter()

  useEffect(() => {
    // the timeout adds a small delay to reduce the errors logged
    const timeout = setTimeout(() => {
      router.replace(
        {
          pathname: router.asPath.split('#step')[0],
        },
        undefined,
        {
          shallow: true,
        },
      )
    }, 0)

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [])

  if (!splitTestData || !funnelStep)
    return <Loader className={'flex justify-center mb-10 mt-10 scale-150'} />

  return (
    <div className="flex flex-col mb-10 gap-8 lg:px-0 px-4">
      <MobileSettingsHeader type={FunnelStepSettingTabsEnum.tests} />
      <SplitTestActions funnelId={funnelId} funnelStep={funnelStep} data={splitTestData} />
      <SplitTestHistory funnelStep={funnelStep} />
    </div>
  )
}

export default SplitTestsTab
