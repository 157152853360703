import React, { FC, ReactNode } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BoxIcon from 'shared/icons/box-icon'

type Props = {
  additionalChild?: ReactNode
}

export const DigitalProductBase: FC<Props> = ({ additionalChild }) => {
  const { t } = useLocoTranslation()
  return (
    <div className={'flex flex-col gap-3'}>
      <div className={'flex items-center justify-between'}>
        <div className="flex flex-row gap-1 lg:text-[15px] text-sm text-blue items-center">
          <BoxIcon />
          <span>{t('dashboard.funnel.configuration.offer.digital_product')}</span>
        </div>
        {additionalChild}
      </div>
      <span className="text-sm font-normal">
        {t('dashboard.funnel.configuration.offer.digital_product_description')}
      </span>
    </div>
  )
}
