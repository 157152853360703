const CourseModulesSkeleton = () => (
  <div className={'flex gap-7 animate-pulse'}>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
  </div>
)

export default CourseModulesSkeleton
