import React from 'react'

function DeliveredBoxIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11300_42181)">
        <path
          d="M5.17578 3.29688L3 7.02539V21.2969H12.2949C12.1979 20.9729 12.1232 20.6389 12.0742 20.2969H12.0801C12.0331 19.9699 12 19.6369 12 19.2969C12 15.4309 15.134 12.2969 19 12.2969C19.34 12.2969 19.673 12.33 20 12.377V12.3711C20.342 12.4211 20.676 12.4958 21 12.5918V7.02539L18.8242 3.29688H5.17578ZM6.32422 5.29688H17.6758L18.8418 7.29688H5.1582L6.32422 5.29688ZM9 9.29688H15V11.2969H9V9.29688ZM19 14.2969C16.239 14.2969 14 16.5359 14 19.2969C14 22.0579 16.239 24.2969 19 24.2969C21.761 24.2969 24 22.0579 24 19.2969C24 16.5359 21.761 14.2969 19 14.2969ZM21.0508 16.9473L22.4492 18.3477L18.5 22.2969L15.5508 19.3477L16.9492 17.9473L18.5 19.4961L21.0508 16.9473Z"
          fill="#00A0FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_11300_42181">
          <rect width="24" height="24" fill="white" transform="translate(0 0.296875)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeliveredBoxIcon
