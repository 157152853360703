import {
  funnelSettingTabsList,
  funnelSettingTabsListWithEmails,
} from 'modules/funnels/funnel/utils/funnel-setting-tabs'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'

export const getFunnelSettingsTabList = (type?: FunnelStepTypeEnum) => {
  switch (type) {
    case FunnelStepTypeEnum.WebinarRegistration: {
      return funnelSettingTabsListWithEmails
    }
    default: {
      return funnelSettingTabsList
    }
  }
}
