import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { SplitTestHistoryItemInterface } from 'modules/funnels/funnel/types/funnel-tests-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnelStepTestsHistory(funnelStepId: FunnelStepId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SplitTestHistoryItemInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: testsHistoryData, mutate: mutateHistoryData } = useSWR(() => {
    if (user && funnelStepId) {
      return `${FUNNELS_API}/steps/${funnelStepId}/split-test-history`
    }
    return null
  }, fetcher)

  return { testsHistoryData, mutateHistoryData }
}
