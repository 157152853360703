import { DigitalResourcesTypeEnum } from 'modules/digital-product/enums/digital-resources-type-enum'
import { DigitalResourceEditType } from 'modules/digital-product/resources/types/digital-resource-edit-interface'

const defaultResourceValues: Record<DigitalResourcesTypeEnum, DigitalResourceEditType> = {
  [DigitalResourcesTypeEnum.systemeio_tag]: {
    type: DigitalResourcesTypeEnum.systemeio_tag,
    value: undefined,
  },
  [DigitalResourcesTypeEnum.community_access]: {
    type: DigitalResourcesTypeEnum.community_access,
    value: undefined,
  },
  [DigitalResourcesTypeEnum.systemeio_plan]: {
    type: DigitalResourcesTypeEnum.systemeio_plan,
    value: undefined,
  },
  [DigitalResourcesTypeEnum.membership_course]: {
    type: DigitalResourcesTypeEnum.membership_course,
    course: undefined,
    enrollmentAccessType: null,
  },
  [DigitalResourcesTypeEnum.membership_course_bundle]: {
    type: DigitalResourcesTypeEnum.membership_course_bundle,
    courseBundle: undefined,
    enrollmentAccessType: null,
  },
  [DigitalResourcesTypeEnum.downloadable_file]: {
    type: DigitalResourcesTypeEnum.downloadable_file,
    dataFile: undefined,
  },
}

export const getDefaultResourceValue = (slug: DigitalResourcesTypeEnum) => {
  return defaultResourceValues[slug]
}
