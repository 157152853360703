import { CouponInterface } from 'modules/coupons/types/coupon-interface'
import { useCustomerCoupons } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/hooks/use-coupons'
import { useEffect, useState } from 'react'
import { CurrencyEnum } from 'shared/enums/currency-enum'

interface useAvailableCouponsProps {
  currency: CurrencyEnum
  shouldFetch?: boolean
  initialData?: CouponInterface[]
}

export function useAvailableCoupons({
  currency,
  shouldFetch,
  initialData,
}: useAvailableCouponsProps) {
  const [availableCoupons, setAvailableCoupons] = useState<CouponInterface[] | undefined>(undefined)
  const { coupons } = useCustomerCoupons(currency, shouldFetch)

  useEffect(() => {
    if (coupons) {
      if (initialData) {
        const initialDataIds = initialData.map(coupon => coupon.id)
        const filteredCoupons = coupons.filter(coupon => !initialDataIds.includes(coupon.id))
        setAvailableCoupons(filteredCoupons)
      } else {
        setAvailableCoupons(coupons)
      }
    }
  }, [coupons, initialData])

  return { availableCoupons, setAvailableCoupons }
}
