import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

type DeadlineSettingsExpirationInputProps = FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    value: number
    onChange: (value: number) => void
    isFetching: boolean
    wrapperClassName?: string
  }

function DeadlineSettingsExpirationInput({
  onChange,
  value,
  isFetching,
  label,
  labelClassName,
  required = false,
  wrapperClassName,
  error,
}: DeadlineSettingsExpirationInputProps) {
  const { t } = useLocoTranslation()

  return (
    <div className={`text-sm font-medium flex flex-col gap-1 ${wrapperClassName || ''}`}>
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      <div
        className={`w-fit focus-within:border-blue flex main-transition-colors rounded-lg ${
          error ? 'border-danger' : 'border-gray/30'
        } border border-gray/30 ${isFetching && 'animate-pulse bg-gray-600/40'}`}
      >
        <input
          value={value}
          disabled={isFetching}
          type={'number'}
          onChange={e => {
            const newValue = e.target.valueAsNumber
            if (newValue > 100) {
              // check with backend
              onChange(100)
            } else if (newValue < 0) {
              onChange(0)
            }
            onChange(newValue)
          }}
          className={`peer w-[66px] rounded-lg py-2.5 px-4 main-transition-colors text-darkblue focus:border-blue focus:outline-none placeholder:text-gray-300/70`}
        />
        <div
          className={
            'w-fit py-2.5 px-4 rounded-r-lg w-fit flex items-center justify-center bg-lightblue'
          }
        >
          {value > 1
            ? t('dashboard.transactions.sub_interval.days')
            : t('dashboard.transactions.sub_interval.day')}
        </div>
      </div>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

export default DeadlineSettingsExpirationInput
