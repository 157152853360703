import { AUTOMATION_RULES_API } from 'modules/automation-rules/api/automation-rules-api'
import {
  AutomationRuleViewInterface,
  RuleId,
} from 'modules/automation-rules/types/automation-rule-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useAutomationRule(id: RuleId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    AutomationRuleViewInterface
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (user) {
      return `${AUTOMATION_RULES_API}/${id}`
    }
  }, fetcher)
}
