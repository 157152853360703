export enum FunnelPageTypeEnum {
  squeeze = 'squeeze',
  sales_page = 'sales_page',
  info_page = 'info_page',
  inline = 'inline',
  popup = 'popup',
  opt_in_thank_you = 'opt_in_thank_you',
  webinar_thank_you_page = 'webinar_thank_you_page',
  webinar_registration = 'webinar_registration',
  webinar_session = 'webinar_session',
  offer_thank_you_page = 'offer_thank_you_page',
  offer_page = 'offer_page',
  offer_inner = 'offer_inner',
  offer_upsell_page = 'offer_upsell_page',
  inactive_funnel_page = 'inactive_funnel_page',
  funnel_contact_us = 'funnel_contact_us',
  link_in_bio = 'link_in_bio',
}
