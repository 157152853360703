import FunnelEmailCreate from 'modules/funnels/funnel/components/email-tab/funnel-email-create'
import FunnelEmailTable from 'modules/funnels/funnel/components/email-tab/funnel-email-table'
import { useFunnelEmails } from 'modules/funnels/funnel/components/email-tab/hooks/use-funnel-emails'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { usePagination } from 'shared/route-query/hooks/use-pagination'

interface FunnelEmailTabProps {
  funnelStepId?: FunnelStepId
}

function EmailTab({ funnelStepId }: FunnelEmailTabProps) {
  const { setPagination, pagination, setLimit, limit } = usePagination()
  const { data: emails, mutate: mutateEmails } = useFunnelEmails({
    limit,
    pagination,
    funnelStepId,
  })

  return (
    <>
      <FunnelEmailTable
        emails={emails}
        funnelStepId={funnelStepId}
        limit={limit}
        setLimit={setLimit}
        setPagination={setPagination}
        pagination={pagination}
        mutateEmails={mutateEmails}
      />
      <FunnelEmailCreate
        funnelStepId={funnelStepId}
        emails={emails}
        setPagination={setPagination}
      />
    </>
  )
}

export default EmailTab
