import { FunnelStepPageId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { ABSOLUTE_PATH_PREFIX } from 'shared/constants/base-path-url'

const NO_SPLIT_TEST_QUERY = '?no_split_test=1'

export const getInactivePageEditorUrl = (id: number) => {
  return `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${process.env.NEXT_PUBLIC_PAGE_EDITOR_HOSTNAME}/page/${id}/edit`
}

export const getFunnelStepPageUrl = (domainName: string, pagePath: string) => {
  return `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/${pagePath}`
}

export const getSplitTestFunnelStepPreviewUrl = (
  domainName: string,
  pagePath: string,
  inRunningSplitTest: boolean,
) => {
  return `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/${pagePath}${
    inRunningSplitTest ? NO_SPLIT_TEST_QUERY : ''
  }`
}

export const getFunnelStepPreviewUrl = (domainName: string, pagePath: string) => {
  return `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/${pagePath}?preview=actual`
}

export const getFunnelStepPageEditorUrl = (pageId: FunnelStepPageId) => {
  return `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${process.env.NEXT_PUBLIC_PAGE_EDITOR_HOSTNAME}/page/${pageId}/edit`
}

export const getRemotePagePreviewUrl = (pageId: FunnelStepPageId) => {
  return `${ABSOLUTE_PATH_PREFIX}/dashboard/page/${pageId}/show?remote-preview=current`
}
