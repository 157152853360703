import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { useEffect, useRef, useState } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { FunnelId } from '../../types/funnel-interface'
import { useFunnelStepsList } from './use-funnel-steps-list'

interface BaseTempStateInterface {
  name: string
}

export function useUpdateBaseConfigurationTab<R>({
  funnelStepId,
  funnelId,
  updateUrl,
}: {
  funnelStepId: FunnelStepId
  funnelId: FunnelId
  updateUrl: string
}) {
  const abortController = useRef<AbortController | null>(null)
  const { fetcher: updateBaseConfigurationFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    R
  >({
    method: RequestMethodsEnum.put,
  })
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const [isFetching, setIsFetching] = useState(false)
  const cancelRequest = () => {
    if (abortController.current) {
      abortController.current.abort()
    }
  }

  return {
    updateFetcher: async <T extends BaseTempStateInterface>(
      updatedState: T,
      mutationAfterUpdate?: () => void | Promise<void>,
    ) => {
      try {
        setIsFetching(true)
        cancelRequest()
        abortController.current = new AbortController()
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(dataFunnelStep => {
              if (dataFunnelStep.id === funnelStepId) {
                return {
                  ...dataFunnelStep,
                  name: updatedState.name,
                }
              }
              return dataFunnelStep
            })
          }
        }, false)
        const responseData = await updateBaseConfigurationFetcher(updateUrl, updatedState, {
          signal: abortController.current?.signal,
        })
        if (responseData) {
          if (mutationAfterUpdate) mutationAfterUpdate()
          setIsFetching(false)
        }
      } catch (e) {
        setIsFetching(false)
        throw e
      }
    },
    isFetching,
  }
}

interface CommonFunnelStepConfigurationEditInterface {
  name: string
  page?: {
    path: string
  }
  webinar?: {
    duration: number
  }
}

interface useBaseConfigurationTabFieldStateProps {
  savedState: CommonFunnelStepConfigurationEditInterface
  currentState: CommonFunnelStepConfigurationEditInterface
}

export const useBaseConfigurationTabFieldState = ({
  savedState,
  currentState,
}: useBaseConfigurationTabFieldStateProps) => {
  const [isNameChanged, setIsNameChanged] = useState(false)
  const [isPagePathChanged, setIsPagePathChanged] = useState(false)
  const [isWebinarDurationChanged, setIsWebinarDurationChanged] = useState(false)

  const [successState, setSuccessState] = useState({
    name: false,
    path: false,
    webinarDuration: false,
  })

  useEffect(() => {
    if (currentState.name !== savedState.name) {
      setIsNameChanged(true)
      setSuccessState(prev => {
        return {
          ...prev,
          name: false,
        }
      })
    } else setIsNameChanged(false)
  }, [currentState, savedState])

  useEffect(() => {
    if (currentState.page && savedState.page) {
      if (currentState.page.path !== savedState.page.path) {
        setIsPagePathChanged(true)
        setSuccessState(prev => {
          return {
            ...prev,
            path: false,
          }
        })
      } else setIsPagePathChanged(false)
    }
  }, [currentState, savedState])

  useEffect(() => {
    if (currentState.webinar && savedState.webinar) {
      if (currentState.webinar.duration !== savedState.webinar.duration) {
        setIsWebinarDurationChanged(true)
        setSuccessState(prev => {
          return {
            ...prev,
            webinarDuration: false,
          }
        })
      } else setIsWebinarDurationChanged(false)
    }
  }, [currentState, savedState])

  useEffect(() => {
    const cleanUpSuccessState = setTimeout(() => {
      if (successState.name || successState.path || successState.webinarDuration) {
        setSuccessState(prev => {
          return { ...prev, name: false, path: false, webinarDuration: false }
        })
      }
    }, 3000)
    return () => clearTimeout(cleanUpSuccessState)
  }, [successState])

  return {
    isNameChanged,
    isPagePathChanged,
    isWebinarDurationChanged,
    successState,
    setSuccessState,
  }
}
