import React from 'react'

function InlineFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_607_113)">
        <path
          d="M1.11526 2.88574V24.1146C0.499188 24.1146 0 23.613 0 22.9969V4.001C0 3.38493 0.499188 2.88574 1.11526 2.88574Z"
          fill="#CBD5E1"
        />
        <path
          d="M22.4246 7.05925C21.1023 7.05925 20.0285 5.98539 20.0285 4.66315V0H3.39942C2.72978 0 2.18433 0.543019 2.18433 1.2151V25.8433C2.18433 26.4813 2.70056 27 3.33855 27H25.8459C26.4838 27 27.0001 26.4813 27.0001 25.8433V7.05925H22.4246ZM23.4911 21.6867C23.4911 21.9326 23.2915 22.1323 23.0455 22.1323H6.13887C5.89293 22.1323 5.69325 21.9326 5.69325 21.6867V21.4602C5.69325 21.2143 5.89293 21.017 6.13887 21.017H23.0455C23.2915 21.017 23.4911 21.2143 23.4911 21.4602V21.6867ZM23.4911 16.3685C23.4911 16.6144 23.2915 16.8141 23.0455 16.8141H6.13887C5.89293 16.8141 5.69325 16.6144 5.69325 16.3685V16.142C5.69325 15.8961 5.89293 15.6964 6.13887 15.6964H23.0455C23.2915 15.6964 23.4911 15.8961 23.4911 16.142V16.3685ZM23.4911 11.0503C23.4911 11.2963 23.2915 11.4959 23.0455 11.4959H6.13887C5.89293 11.4959 5.69325 11.2963 5.69325 11.0503V10.8239C5.69325 10.5779 5.89293 10.3782 6.13887 10.3782H23.0455C23.2915 10.3782 23.4911 10.5779 23.4911 10.8239V11.0503Z"
          fill="#CBD5E1"
        />
        <path
          d="M21.2581 4.56331V0L26.9999 5.74188H22.4366C21.784 5.74188 21.2581 5.21347 21.2581 4.56331Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_113">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InlineFunnelStepIcon
