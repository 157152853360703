import React from 'react'

function OrderFormFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_607_105)">
        <path
          d="M9.25561 23.586C9.90334 25.4878 8.35464 27.2459 6.49181 26.9732C5.37655 26.8101 4.49019 25.8896 4.36113 24.7695C4.18581 23.2378 5.37899 21.9375 6.87412 21.9375C7.93337 21.9375 8.9147 22.5828 9.25561 23.586Z"
          fill="#CBD5E1"
        />
        <path
          d="M26.2791 24.47C26.2791 25.9627 24.986 27.1534 23.4593 26.9854C22.3026 26.8563 21.3602 25.9164 21.2312 24.7573C21.0632 23.2305 22.2539 21.9375 23.7466 21.9375C25.1443 21.9375 26.2766 23.0698 26.2766 24.4675L26.2791 24.47Z"
          fill="#CBD5E1"
        />
        <path
          d="M10.1274 17.9976C8.40341 17.9976 8.36688 17.1721 9.82062 16.7557H9.81818L26.4205 12.0146C26.7541 11.9026 26.983 11.5982 26.9976 11.25V3H7.26623C6.56737 3 5.99757 2.43263 5.99757 1.73133V0.599026C5.99757 0.267857 5.72971 0.00243507 5.40097 0H1.16153C0.521104 0 0 0.521104 0 1.16153V1.83847C0 2.48133 0.521104 3 1.16153 3H1.98945C2.57873 3 3.08766 3.41153 3.20942 3.98864L5.8612 16.4343L5.99757 17.849V20.3231C6 20.6542 6.26786 20.9221 6.59659 20.9221H26.3985C26.7297 20.9196 26.9976 20.6542 27 20.3231V19.7825C27 18.7963 26.2013 17.9976 25.2151 17.9976H10.1274ZM11.0942 4.92127H20.7151C20.9927 4.92127 21.2167 5.14529 21.2167 5.42289V5.53734C21.2167 5.81494 20.9927 6.03896 20.7151 6.03896H11.0942C10.8166 6.03896 10.5925 5.81494 10.5925 5.53734V5.42289C10.5925 5.14529 10.8166 4.92127 11.0942 4.92127ZM11.0674 7.69968H20.7443C21.0049 7.69968 21.2192 7.91153 21.2192 8.17451V8.34253C21.2192 8.60308 21.0073 8.81737 20.7443 8.81737H11.0674C10.8068 8.81737 10.5925 8.60552 10.5925 8.34253V8.17451C10.5925 7.91396 10.8044 7.69968 11.0674 7.69968ZM11.0674 10.6096H20.7468C21.0073 10.6096 21.2192 10.8214 21.2192 11.082V11.2524C21.2192 11.513 21.0073 11.7248 20.7468 11.7248H11.0649C10.8044 11.7248 10.5925 11.513 10.5925 11.2524V11.082C10.5925 10.8214 10.8044 10.6096 11.0649 10.6096H11.0674Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_105">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OrderFormFunnelStepIcon
