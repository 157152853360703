import {
  FunnelEmailApiInterface,
  FunnelEmailEditInterface,
  FunnelEmailId,
} from 'modules/funnels/funnel/components/email-tab/types/funnel-emails-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import httpClient from 'shared/utils/http-client'

export const FUNNEL_EMAIL_API = '/api/dashboard/customer/funnels/steps'

export const createFunnelEmail = (funnelStepId: FunnelStepId, data: FunnelEmailEditInterface) =>
  httpClient.post<FunnelEmailApiInterface>(
    `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails`,
    data,
  )

export const updateFunnelEmail = (
  funnelStepId: FunnelStepId,
  emailId: FunnelEmailId,
  data: FunnelEmailEditInterface,
) =>
  httpClient.put<FunnelEmailApiInterface>(
    `${FUNNEL_EMAIL_API}/${funnelStepId}/webinar/emails/${emailId}`,
    data,
  )

export const deleteFunnelEmail = (funnelStepId: FunnelStepId) =>
  httpClient.delete(`${FUNNEL_EMAIL_API}/webinar/emails/${funnelStepId}`)
