import React from 'react'

interface ClockIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function ClockIcon({ active, ...props }: ClockIconProps) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3894 19.8554C16.5836 19.8554 20.7944 15.6446 20.7944 10.4504C20.7944 5.25617 16.5836 1.04541 11.3894 1.04541C6.19514 1.04541 1.98438 5.25617 1.98438 10.4504C1.98438 15.6446 6.19514 19.8554 11.3894 19.8554Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3875 0C5.62955 0 0.9375 4.69205 0.9375 10.45C0.9375 16.208 5.62955 20.9 11.3875 20.9C17.1455 20.9 21.8375 16.208 21.8375 10.45C21.8375 4.69205 17.1455 0 11.3875 0ZM15.9333 14.1806C15.8276 14.3605 15.6542 14.4906 15.4519 14.5416C15.2496 14.5927 15.0353 14.5605 14.8569 14.4523L11.6174 12.5191C10.8127 12.0384 10.2171 10.983 10.2171 10.0529V5.7684C10.2171 5.33995 10.5724 4.98465 11.0008 4.98465C11.4293 4.98465 11.7846 5.33995 11.7846 5.7684V10.0529C11.7846 10.4291 12.0981 10.9829 12.4221 11.171L15.6616 13.1043C16.0378 13.3237 16.1631 13.8045 15.9333 14.1806Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
    </svg>
  )
}

export default ClockIcon
