import { removeSplitTest } from 'modules/funnels/funnel/api/funnel-api'
import TabTable from 'modules/funnels/funnel/components/tab-table'
import { useFunnelStepTestsHistory } from 'modules/funnels/funnel/hooks/use-funnel-step-tests-history'
import { useSplitTestTableColumns } from 'modules/funnels/funnel/hooks/use-split-test-table-columns'
import TrashIcon from 'modules/funnels/funnel/icons/trash-icon'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getHistoryTableData } from 'modules/funnels/funnel/utils/split-tests-utils'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import Confirmation from 'shared/components/confirmation-modal'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface SplitTestHistoryProps {
  funnelStep: FunnelStepInterface
}

function SplitTestHistory({ funnelStep }: SplitTestHistoryProps) {
  const { t } = useLocoTranslation()
  const { testsHistoryData, mutateHistoryData } = useFunnelStepTestsHistory(funnelStep.id)
  const [isFetching, setIsFetching] = useState(false)
  const [isDeleteHistoryItemOpen, setIsDeleteHistoryItemOpen] = useState(false)
  const [historyItemIdToDelete, setHistoryItemIdToDelete] = useState<null | number>(null)

  const handleDeleteHistoryItem = async () => {
    if (!historyItemIdToDelete) {
      setIsDeleteHistoryItemOpen(false)
      return
    }
    setIsFetching(true)
    const response = await removeSplitTest(funnelStep?.id, historyItemIdToDelete)
    await mutateHistoryData(response.data, false)
    setHistoryItemIdToDelete(null)
    setIsFetching(false)
  }

  const columns = useSplitTestTableColumns({
    withEndDate: true,
    trashIconRenderer: data => (
      <TrashIcon
        onClick={() => {
          setHistoryItemIdToDelete(data.splitTest.id)
          setIsDeleteHistoryItemOpen(true)
        }}
        className="hover:fill-red hover:border-red main-transition-colors cursor-pointer ml-2"
      />
    ),
  })

  if (!testsHistoryData || isFetching)
    return <Loader className={'flex justify-center mb-10 mt-10'} />

  const tableData = getHistoryTableData(testsHistoryData)

  return (
    <>
      {tableData.length > 0 && (
        <div className="flex flex-col gap-5 lg:px-8">
          <div className="font-bold text-darkblue lg:text-xl text-lg font-inter text-center">
            {t('dashboard.funnel.split_tests.history_header')}
          </div>
          <div className="flex flex-col lg:gap-20 gap-4">
            {tableData.map((historyItem, index) => (
              <TabTable columns={columns} data={historyItem} key={index} passDataToHeader />
            ))}
          </div>
        </div>
      )}
      <Confirmation
        confirmationText={t('dashboard.funnel.split_tests.remove_action_history_confirmation_text')}
        opened={isDeleteHistoryItemOpen}
        onClose={() => setIsDeleteHistoryItemOpen(false)}
        onConfirm={handleDeleteHistoryItem}
        toastCaption={t('dashboard.funnel.split_tests.remove_action_history_toast')}
      />
    </>
  )
}
export default SplitTestHistory
