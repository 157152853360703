import { ListingFunnelStepsInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  NotStartedSplitTestInterface,
  SplitTestHistoryItemInterface,
  SplitTestHistoryTableInterface,
  SplitTestInterface,
  SplitTestType,
} from 'modules/funnels/funnel/types/funnel-tests-interface'

interface FunnelStepDataInterface {
  id: number
  caption: string
}

export const getAllowedStepsAsVariant = (funnelsList?: ListingFunnelStepsInterface[]) => {
  return funnelsList?.map(funnel => {
    return {
      id: funnel.id,
      caption: funnel.name,
    }
  }, [] as FunnelStepDataInterface[])
}

export const getAllowedStepsAsGoal = (funnelsList?: ListingFunnelStepsInterface[]) => {
  return funnelsList?.map(funnel => {
    return {
      id: funnel.id,
      caption: funnel.name,
    }
  }, [] as FunnelStepDataInterface[])
}

export const getHistoryTableData = (
  testsHistoryData: SplitTestHistoryItemInterface[],
): SplitTestHistoryTableInterface[] => {
  return testsHistoryData.map(el => {
    return {
      splitTest: el.splitTest,
      items: [
        { ...el.funnelStepAStat, id: el.splitTest.id + 1 }, // not sure if it's right, maybe ask back to add id, but it's funnelStepInfo - so id could be the same | maybe we should generate random id for this
        { ...el.funnelStepBStat, id: el.splitTest.id + 2 },
      ],
    }
  })
}

export const getRunningTestTableData = (
  testData: SplitTestInterface,
): SplitTestHistoryTableInterface => {
  return {
    splitTest: {
      id: testData.splitTest.id,
      startedAt: testData.splitTest.startedAt || '',
      finishedAt: '',
    },
    items: [
      { ...testData.funnelStepAStat, id: testData.splitTest.id + 1 }, // not sure if it's right, maybe ask back to add id, but it's funnelStepInfo - so id could be the same | maybe we should generate random id for this
      { ...testData.funnelStepBStat, id: testData.splitTest.id + 2 },
    ],
  }
}

export const isSplitTestIsEmptyArray = (data: SplitTestType) => Array.isArray(data) && !data.length

const isTypeNotStartedSplitTest = (props: SplitTestType): props is NotStartedSplitTestInterface =>
  props.hasOwnProperty('finished')

const isTypeSplitTestInterface = (props: SplitTestType): props is SplitTestInterface =>
  props.hasOwnProperty('splitTest')

export const isSplitTestIsNotStarted = (data: SplitTestType) => {
  if (isTypeNotStartedSplitTest(data)) {
    return data.finished === false && data.running === false
  }
  return false
}

export const isSplitTestIsStarted = (data: SplitTestType) => {
  if (isTypeSplitTestInterface(data)) {
    return data.splitTest.running && !data.splitTest.finished
  }
  return false
}
