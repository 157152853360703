import Confirmation, { ConfirmationProps } from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useModal } from 'shared/hooks/use-modal'
import { useDigitalProductActions } from '../hooks/use-digital-product-actions'
import { useOfferDigitalProductsCount } from '../hooks/use-offer-digital-products-count'
import { DigitalProductId, DigitalProductInterface } from '../types/digital-product-interface'
import { DeleteConfirmationLoader } from './delete-confirmation-loader'

export function useDigitalProductDeleteModal() {
  const { opened, data, open, close, cleanData } = useModal<DigitalProductId>(false, false)

  const digitalProductId = data.current

  const { onDelete } = useDigitalProductActions()

  const { offerDigitalProductsCount } = useOfferDigitalProductsCount(digitalProductId)

  const deleteDigitalProduct = async () => {
    if (!digitalProductId) return
    await onDelete(digitalProductId)
  }

  return {
    opened,
    digitalProductId,
    digitalProductLinkedOffersCount: offerDigitalProductsCount?.count,
    open,
    close,
    afterLeave: cleanData,
    deleteDigitalProduct,
  }
}

export function DigitalProductDeleteModal({
  opened,
  digitalProductName,
  digitalProductLinkedOffersCount,
  onClose,
  onConfirm,
  afterLeave,
}: {
  digitalProductName?: DigitalProductInterface['name']
  digitalProductLinkedOffersCount?: number
} & Pick<ConfirmationProps, 'opened' | 'onClose' | 'onConfirm' | 'afterLeave'>) {
  const { t, tICU } = useLocoTranslation()

  return (
    <Confirmation
      opened={opened}
      onClose={onClose}
      onConfirm={onConfirm}
      afterLeave={afterLeave}
      toastCaption={t('dashboard.actions.removed', {
        module: `${digitalProductName ? `"${digitalProductName}"` : ''} ${t(
          'dashboard.funnel.configuration.offer.digital_product',
        )}`,
      })}
      confirmationContent={
        digitalProductName && typeof digitalProductLinkedOffersCount === 'number' ? (
          <div className="text-darkblue text-base">
            {tICU('dashboard.offer.digital_product.remove_description_with_count', {
              digitalProductName,
              numberOfOffers: digitalProductLinkedOffersCount,
            })}
          </div>
        ) : (
          <DeleteConfirmationLoader />
        )
      }
    />
  )
}
