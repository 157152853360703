import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { ListingFunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnels(funnelId: FunnelId, shouldFetch: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ListingFunnelInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: funnelsList } = useSWR(() => {
    if (user && shouldFetch) {
      return `${FUNNELS_API}/${funnelId}/list`
    }
    return null
  }, fetcher)

  return { funnelsList }
}
