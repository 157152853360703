import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { FunnelStatisticsFilterInterface } from 'modules/funnels/funnel/types/funnel-statistics-interface'
import React from 'react'
import DateRangePicker from 'shared/components/date-picker/date-range-picker'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { currencyFormat } from 'shared/utils/currency-format'

interface StatisticsTabTableLayoutProps {
  setFilter: React.Dispatch<React.SetStateAction<FunnelStatisticsFilterInterface>>
  filter: FunnelStatisticsFilterInterface
  currency: CurrencyEnum
  totalSales: number | null
  averageCartValue: number | null
  children: JSX.Element
}

function StatisticsTabTableLayout({
  filter,
  setFilter,
  currency,
  totalSales,
  averageCartValue,
  children,
}: StatisticsTabTableLayoutProps) {
  const { user } = useUser()
  const { t } = useLocoTranslation()

  return (
    <div className="flex flex-col mb-5 gap-8 lg:px-[15px] px-[15px]">
      <MobileSettingsHeader type={FunnelStepSettingTabsEnum.stats} />
      <div className="flex lg:flex-row lg:flex-wrap flex-col lg:justify-between gap-4 lg:items-center">
        <div className="flex lg:flex-row flex-col lg:gap-20 gap-4">
          <div className="flex flex-col gap-2">
            <div className="font-inter lg:text-lg text-base text-darkblue">
              {t('dashboard.funnel.statistics.gross')}
            </div>
            <div className="font-inter lg:text-3xl text-xl font-bold text-blue">
              {currencyFormat((totalSales || 0) / 100, currency, user?.dashboardLocale)}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-inter lg:text-lg text-base text-darkblue">
              {t('dashboard.funnel.statistics.average_cart_value')}
            </div>
            <div className="font-inter lg:text-3xl text-xl font-bold text-blue">
              {currencyFormat((averageCartValue || 0) / 100, currency, user?.dashboardLocale)}
            </div>
          </div>
        </div>
        <DateRangePicker
          date={filter.date}
          onChange={date => {
            setFilter({ date: date })
          }}
        />
      </div>
      {children}
    </div>
  )
}

export default StatisticsTabTableLayout
