import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import {
  FunnelSalesApiInterface,
  FunnelSalesSwrInterface,
} from 'modules/funnels/funnel/components/sales-tab/types/funnel-sales-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnelSales({ limit, pagination, filter, funnelId }: FunnelSalesSwrInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelSalesApiInterface
  >({ method: RequestMethodsEnum.get })

  const filterApi = `${filter.date[0] ? `&filter[dateSince]=${filter.date[0]}` : ''}${
    filter.date[1] ? `&filter[dateTill]=${filter.date[1]}` : ''
  }`

  const { data: sales } = useSWR(() => {
    if (user && pagination.startFromId === undefined && limit) {
      return `${FUNNELS_API}/${funnelId}/sales?pagination[limit]=${limit}${filterApi}`
    } else if (user && pagination.startFromId && limit) {
      return `${FUNNELS_API}/${funnelId}/sales?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}${filterApi}`
    }
    return null
  }, fetcher)

  return { sales }
}
