import { TLocoType } from 'shared/hooks/use-loco-translation'

export enum CourseBundleAccessTypeEnum {
  full_access = 'full_access',
  dripping_content = 'dripping_content',
}

export const CourseBundleAccessTypeCaptions = {
  [CourseBundleAccessTypeEnum.full_access]: 'dashboard.course.access_type.full_access',
  [CourseBundleAccessTypeEnum.dripping_content]: 'dashboard.course.access_type.drip_content',
}

export function getCourseBundleAccessTypeDataOptions(t: TLocoType) {
  return Object.values(CourseBundleAccessTypeEnum).map(accessType => {
    return {
      id: accessType,
      caption: t(CourseBundleAccessTypeCaptions[accessType]),
    }
  })
}
