import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'

interface useResetFunnelStepPageTemplateProps {
  funnelId: FunnelId
  funnelStepId: FunnelStepId
}
export function useResetFunnelStepPageTemplate({
  funnelId,
  funnelStepId,
}: useResetFunnelStepPageTemplateProps) {
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)

  const resetFunction = async () => {
    await mutateFunnelStepsList(data => {
      if (data) {
        return data.map(dataFunnelStep => {
          if (dataFunnelStep.id === funnelStepId) {
            return {
              ...dataFunnelStep,
              page: null,
            }
          }
          return dataFunnelStep
        })
      }
    }, false)
  }

  return { resetFunction }
}
