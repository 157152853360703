import { PricePlanTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-type-enum'
import SubscriptionDescription from 'modules/funnels/funnel/components/sales-tab/components/subscription-description'
import {
  FunnelSalesInterface,
  SubscriptionPlanInterface,
} from 'modules/funnels/funnel/components/sales-tab/types/funnel-sales-interface'
import { UserInterface } from 'shared/types/user-interface'
import { currencyFormat } from 'shared/utils/currency-format'

export const getSalePriceDescription = (
  sale: FunnelSalesInterface,
  user: UserInterface | undefined,
) => {
  if (sale.productOrderItem) {
    return (
      <div className="flex flex-col gap-1">
        <div>
          {currencyFormat(
            sale.calculatedPrice / 100,
            sale.productOrderItem.product.currency,
            user?.dashboardLocale,
          )}
        </div>
        <div>{sale.productOrderItem.product.name}</div>
      </div>
    )
  } else {
    switch (sale.offerPricePlan.type) {
      case PricePlanTypeEnum.one_shot:
        return (
          <div>
            {currencyFormat(
              sale.calculatedPrice / 100,
              sale.offerPricePlan.currency,
              user?.dashboardLocale,
            )}
          </div>
        )
      case PricePlanTypeEnum.subscription:
      case PricePlanTypeEnum.limited_subscription: {
        const castedSubscriptionPlan = sale.offerPricePlan
          .subscriptionPlan as SubscriptionPlanInterface
        return (
          <SubscriptionDescription
            amountWithCurrency={currencyFormat(
              sale.calculatedPrice / 100,
              sale.offerPricePlan.currency,
              user?.dashboardLocale,
            )}
            quantity={sale.quantity}
            pricePlanName={sale.offerPricePlan.innerName}
            coupon={sale.coupon}
            subscriptionPlan={castedSubscriptionPlan}
          />
        )
      }
      default:
        return <></>
    }
  }
}
