import { getOfferTypeData } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/utils/get-offer-type-data'
import { OfferTypeEnum } from 'modules/funnels/funnel/enums/offer-type-enum'
import React from 'react'
import RadioGroup from 'shared/components/radio-group'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface OfferProductTypeSelectorProps {
  selected: OfferTypeEnum | undefined
  className?: string
  onChange: (value: OfferTypeEnum) => void
}

const OfferProductTypeSelector = ({ selected, onChange }: OfferProductTypeSelectorProps) => {
  const { t } = useLocoTranslation()
  const offerProductTypeData = getOfferTypeData(t)

  return (
    <RadioGroup
      labelClassName={'lg:text-[15px] text-sm font-normal text-darkblue pb-4'}
      label={t('dashboard.funnel.configuration.offer.choose_offer_type')}
      optionsWrapperClassName={'gap-0'}
      optionWrapperClassName={`py-4 lg:px-7 px-3`}
      selectedOptionWrapperClassName={'bg-[#F8FAFC] rounded-t-md'}
      selectedOptionLabelClassName={'bg-[#F8FAFC]'}
      optionClassName={'lg:text-[15px] text-sm font-normal text-darkblue'}
      options={offerProductTypeData}
      selected={selected}
      onChange={onChange}
    />
  )
}

export default OfferProductTypeSelector
