import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelPageTemplateId } from 'modules/funnels/funnel/types/funnel-page-template-interface'
import {
  FunnelStepId,
  FunnelStepInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import httpClient from 'shared/utils/http-client'

export const PAGE_TEMPLATES_API = '/api/dashboard/customer/page-templates'

export const selectPageTemplate = (id: FunnelStepId, pageTemplateId: FunnelPageTemplateId) =>
  httpClient.post<FunnelStepInterface>(
    `${FUNNELS_API}/steps/${id}/templates/${pageTemplateId}/choose`,
  )
