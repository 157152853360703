import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { WebinarRegistrationInterface } from 'modules/funnels/funnel/types/webinar-registration-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

interface OptionsInterface {
  shouldFetch?: boolean
}

export function useWebinarRegistrationSettings(
  id?: number,
  options: OptionsInterface = { shouldFetch: true },
) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    WebinarRegistrationInterface
  >({ method: RequestMethodsEnum.get })

  const { data: webinarRegistrationSettings, mutate: mutateWebinarRegistrationSettings } = useSWR(
    () => {
      if (!id) return
      if (!options.shouldFetch) return
      return `${FUNNELS_API}/steps/${id}/webinar-registration`
    },
    fetcher,
  )

  return { webinarRegistrationSettings, mutateWebinarRegistrationSettings }
}
