import React from 'react'

function ChangeIconNew(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_301_33)">
        <path d="M0 14.767C0 7.90669 5.56139 2.34535 12.4217 2.34535H15V0.704488C15 0.0957225 15.721 -0.225371 16.1735 0.181879L20.861 4.40063C21.1713 4.67996 21.1713 5.16657 20.861 5.44589L16.1735 9.66464C15.721 10.0719 15 9.75075 15 9.14204V7.5016H12.602C7.67245 7.5016 3.23025 10.4765 1.35328 15.0347C1.06003 15.7469 0 15.5372 0 14.767Z" />
        <path d="M24 9.23297C24 16.0933 18.4386 21.6547 11.5783 21.6547H8.99999V23.2955C8.99999 23.9042 8.27896 24.2253 7.82652 23.8181L3.13902 19.5993C2.82866 19.32 2.82866 18.8334 3.13902 18.5541L7.82652 14.3353C8.279 13.9281 8.99999 14.2492 8.99999 14.8579V16.4984H11.398C16.3275 16.4984 20.7697 13.5234 22.6467 8.96522C22.94 8.2531 24 8.46277 24 9.23297Z" />
      </g>
      <defs>
        <clipPath id="clip0_301_33">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ChangeIconNew
