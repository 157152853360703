import ContactInfoTooltip from 'modules/contacts/components/contact-info-tooltip'
import MobileSettingsHeader from 'modules/funnels/funnel/components/mobile-settings-header'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import { useFunnelLeads } from 'modules/funnels/funnel/hooks/use-funnel-leads'
import { FunnelLeadsInterface } from 'modules/funnels/funnel/types/funnel-leads-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React from 'react'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import ListingLayout from 'shared/components/listing-layout'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { dateTimeFormat } from 'shared/utils/date-time-format'

interface LeadsTabProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface | null
}

function LeadsTab({ funnelId }: LeadsTabProps) {
  const { setPagination, pagination, setLimit, limit } = usePagination()
  const { t } = useLocoTranslation()
  const { user, isWsAssistant } = useUser()

  const { leads, counter } = useFunnelLeads({ funnelId, limit, pagination })

  const columns: ColumnDefinitionInterface<FunnelLeadsInterface>[] = [
    {
      key: 'createdAt',
      header: t('dashboard.funnel.leads_opt_in_date'),
      widthPercent: 30,
      onRender: lead => (
        <span>
          {dateTimeFormat(lead.date, {
            timeFormat: user?.timeFormat,
            dateFormat: user?.dateFormat,
            timeZone: user?.timezone,
          })}
        </span>
      ),
    },
    {
      key: 'email',
      header: t('dashboard.funnel.leads_email'),
      widthPercent: 30,
      onRender: lead => (
        <>
          {!isWsAssistant && lead.customer ? (
            <ContactInfoTooltip
              fields={lead.customer.fieldValues}
              referredByEmail={lead.customer?.referredBy?.email}
              type={lead.customer.type}
              contactId={lead.customer?.referredBy?.connectedUser?.id}
            >
              <div className="truncate">
                <LinkWithoutPrefetch
                  className="primary-link w-full"
                  href={`/contacts/${lead.contact.id}`}
                >
                  {lead.contact.email}
                </LinkWithoutPrefetch>
              </div>
            </ContactInfoTooltip>
          ) : (
            <div className="truncate">
              <LinkWithoutPrefetch
                className="primary-link w-full"
                href={`/contacts/${lead.contact.id}`}
              >
                {lead.contact.email}
              </LinkWithoutPrefetch>
            </div>
          )}
        </>
      ),
    },
    {
      key: 'funnelStepName',
      header: t('dashboard.funnel.leads_funnel_step_name'),
      widthPercent: 30,
      onRender: lead => <div className="truncate">{lead.page.funnelStep.name}</div>,
    },
  ]

  return (
    <div className="flex flex-col mb-5 lg:px-8 ">
      <div className="px-4">
        <MobileSettingsHeader type={FunnelStepSettingTabsEnum.leads} />
      </div>
      <ListingLayout
        counter={counter}
        data={leads}
        title={
          <Hint label={t('dashboard.funnel.leads_title_hint')}>{t('dashboard.funnel.leads')}</Hint>
        }
        titleClassName={'px-1 lg:text-lg text-base font-inter font-semibold text-darkblue'}
        type={'compact'}
      >
        <Table
          data={leads}
          columns={columns}
          limiter={{ limit, setLimit }}
          paginator={{ pagination, setPagination }}
          withBorders
          actions={{}}
        />
      </ListingLayout>
    </div>
  )
}

export default LeadsTab
