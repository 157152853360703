import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import BaseConfiguration from 'modules/funnels/funnel/components/step-configuration-tab/base-configuration-tab/components/base-configuration'
import SplitTestPreviewStepModal from 'modules/funnels/funnel/components/step-configuration-tab/split-test-preview-step-modal'
import StepConfigurationTabLayout from 'modules/funnels/funnel/components/step-configuration-tab/step-configuration-tab-layout'
import { useBaseFunnelStepConfigurationSettings } from 'modules/funnels/funnel/hooks/use-base-funnel-step-configuration-settings'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useResetFunnelStepPageTemplate } from 'modules/funnels/funnel/hooks/use-reset-funnel-step-page-template'
import { useUpdateBaseConfigurationTab } from 'modules/funnels/funnel/hooks/use-update-base-configuration-tab'
import {
  BaseFunnelStepConfigurationEditInterface,
  BaseFunnelStepConfigurationInterface,
} from 'modules/funnels/funnel/types/base-funnel-step-configuration-settings-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelStepPreviewData } from 'modules/funnels/funnel/utils/get-funnel-step-split-test-preview-data'
import {
  getFunnelStepPageEditorUrl,
  getFunnelStepPageUrl,
  getFunnelStepPreviewUrl,
} from 'modules/funnels/funnel/utils/page-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface BaseConfigurationTabProps {
  funnelStep: FunnelStepInterface
  funnelId: FunnelId
  showPreview?: boolean
}

function BaseConfigurationTab({ funnelStep, funnelId, showPreview }: BaseConfigurationTabProps) {
  const { funnelStepConfigurationSettings, mutateFunnelStepConfigurationSettings } =
    useBaseFunnelStepConfigurationSettings(funnelStep.id)
  const { t } = useLocoTranslation()
  const { funnel } = useFunnel(funnelId)
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { resetFunction } = useResetFunnelStepPageTemplate({
    funnelId,
    funnelStepId: funnelStep.id,
  })
  const [splitTestPreviewOpen, setSplitTestPreviewOpen] = useState(false)
  const { updateFetcher } = useUpdateBaseConfigurationTab<BaseFunnelStepConfigurationInterface>({
    funnelStepId: funnelStep.id,
    funnelId: funnelId,
    updateUrl: `${FUNNELS_API}/steps/${funnelStep.id}`,
  })

  const handleSave = async (baseData: BaseFunnelStepConfigurationEditInterface) => {
    try {
      await updateFetcher<BaseFunnelStepConfigurationEditInterface>(baseData, async () => {
        await mutateFunnelStepConfigurationSettings(data => {
          if (data) {
            return {
              ...data,
              name: baseData.name,
              page: {
                ...data.page,
                path: baseData.page.path,
              },
            }
          }
        }, false)
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(dataFunnelStep => {
              if (dataFunnelStep.id === funnelStep.id) {
                return {
                  ...dataFunnelStep,
                  name: baseData.name,
                }
              }
              return dataFunnelStep
            })
          }
        }, false)
      })
    } catch (e) {
      throw e
    }
  }

  if (!funnelStepConfigurationSettings || !funnel || !funnelStep.page) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  const viewLinkUrl = showPreview
    ? getFunnelStepPreviewUrl(funnel.domain.domainName, funnelStepConfigurationSettings.page.path)
    : getFunnelStepPageUrl(funnel.domain.domainName, funnelStepConfigurationSettings.page.path)

  const { customViewButton, funnelStepAPreviewLink, funnelStepBPreviewLink, isSplitTestPreview } =
    getFunnelStepPreviewData({
      funnelStep,
      setOpen: setSplitTestPreviewOpen,
      funnel,
      t,
      showPreview,
      previewLinkWithQuery: !showPreview,
    })

  return (
    <>
      <StepConfigurationTabLayout
        viewLinkUrl={viewLinkUrl}
        editLinkUrl={getFunnelStepPageEditorUrl(funnelStep.page.id)}
        customViewButton={customViewButton}
        onChangeTemplate={resetFunction}
      >
        <BaseConfiguration
          onSave={handleSave}
          domainName={funnel?.domain.domainName}
          name={funnelStepConfigurationSettings.name}
          path={funnelStepConfigurationSettings.page.path}
          pathDisabled={funnelStep.inRunningSplitTest}
        />
      </StepConfigurationTabLayout>
      {isSplitTestPreview && (
        <SplitTestPreviewStepModal
          open={splitTestPreviewOpen}
          setOpen={setSplitTestPreviewOpen}
          stepAPreviewLink={funnelStepAPreviewLink}
          stepBPreviewLink={funnelStepBPreviewLink}
        />
      )}
    </>
  )
}

export default BaseConfigurationTab
