import StatisticsTabTableLayout from 'modules/funnels/funnel/components/statistics-tab/statistics-tab-table-layout'
import TabTable, { TabColumnDefinitionInterface } from 'modules/funnels/funnel/components/tab-table'
import { useFunnelStatistics } from 'modules/funnels/funnel/hooks/use-funnel-statistics'
import {
  FunnelStatisticsFilterInterface,
  FunnelStatisticsInterface,
  FunnelStepStatisticsInterface,
} from 'modules/funnels/funnel/types/funnel-statistics-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Hint } from 'shared/components/hint'
import { Loader } from 'shared/components/loader'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { currencyFormat } from 'shared/utils/currency-format'

interface SalesTabProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface | null
}

const defaultFilters: FunnelStatisticsFilterInterface = { date: [null, null] }

function StatisticsTab({ funnelId }: SalesTabProps) {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const [filter, setFilter] = useState<FunnelStatisticsFilterInterface>(defaultFilters)
  const { statistics } = useFunnelStatistics({ funnelId, filter })
  const router = useRouter()

  useEffect(() => {
    // the timeout adds a small delay to reduce the errors logged
    const timeout = setTimeout(() => {
      router.replace(
        {
          pathname: router.asPath.split('#step')[0],
        },
        undefined,
        {
          shallow: true,
        },
      )
    }, 0)

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [])

  if (!statistics) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  const columns: TabColumnDefinitionInterface<
    FunnelStepStatisticsInterface,
    FunnelStatisticsInterface
  >[] = [
    {
      key: 'funnelStepName',
      header: ' ',
      widthPercent: 15,
      subHeaders: [
        {
          widthPercent: 100,
          title: ' ',
          onRender: funnelStep => (
            <span className="truncate group:justify-start">{funnelStep.name}</span>
          ),
          onRenderSmall: funnelStep => (
            <span className="truncate font-semibold font-inter text-base text-darkblue text-opacity-100">
              {funnelStep.name}
            </span>
          ),
          align: 'justify-start',
        },
      ],
    },
    {
      key: 'funnelStepViews',
      header: t('dashboard.funnel.statistics.page_views'),
      widthPercent: 7,
      subHeaders: [
        {
          widthPercent: 100,
          title: ' ',
          onRender: funnelStep => <span className="truncate">{funnelStep.pageViewsUnique}</span>,
        },
      ],
    },
    {
      key: 'optIns',
      header: (
        <Hint label={t('dashboard.funnel.statistics.opt_in_title_hint')}>
          {t('dashboard.funnel.statistics.opt_in_title')}
        </Hint>
      ),
      widthPercent: 20,
      subHeaders: [
        {
          widthPercent: 30,
          title: t('dashboard.funnel.statistics.all'),
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.optInAll}</span>
          ),
        },
        {
          widthPercent: 70,
          title: t('dashboard.funnel.statistics.conversion_rate'),
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.optInRate}%</span>
          ),
        },
      ],
    },
    {
      key: 'sales',
      header: t('dashboard.funnel.statistics.sales'),
      widthPercent: 40,
      subHeaders: [
        {
          widthPercent: 20,
          title: t('dashboard.funnel.statistics.total'),
          onRender: funnelStep => (
            <span className="truncate font-normal">{funnelStep.salesCount || '-'}</span>
          ),
        },
        {
          widthPercent: 30,
          title: t('dashboard.funnel.statistics.conversion_rate'),
          onRender: funnelStep => (
            <span className="truncate font-normal">
              {funnelStep.salesRate ? `${funnelStep.salesRate}%` : '-'}
            </span>
          ),
        },
        {
          widthPercent: 50,
          title: t('dashboard.funnel.statistics.revenue'),
          onRender: funnelStep => (
            <Tooltip
              mode="overflow"
              label={
                <>
                  {funnelStep.salesGrossSum
                    ? currencyFormat(
                        funnelStep.salesGrossSum / 100,
                        statistics.currency,
                        user?.dashboardLocale,
                      )
                    : '-'}
                </>
              }
              wrapperClassName="flex w-full h-full justify-center align-center"
              className="flex"
            >
              <span className="truncate font-normal">
                {funnelStep.salesGrossSum
                  ? currencyFormat(
                      funnelStep.salesGrossSum / 100,
                      statistics.currency,
                      user?.dashboardLocale,
                    )
                  : '-'}
              </span>
            </Tooltip>
          ),
        },
      ],
    },
    {
      key: 'earning/preview',
      header: t('dashboard.funnel.statistics.earning_pageview_title'),
      widthPercent: 12,
      subHeaders: [
        {
          widthPercent: 100,
          title: ' ',
          onRender: funnelStep => (
            <Tooltip
              mode="overflow"
              label={
                <>
                  {funnelStep.earningPerViewUnique
                    ? currencyFormat(
                        funnelStep.earningPerViewUnique / 100,
                        statistics.currency,
                        user?.dashboardLocale,
                      )
                    : '-'}
                </>
              }
              wrapperClassName="flex w-full h-full justify-center align-center"
              className="flex"
            >
              <span className="truncate font-normal">
                {funnelStep.earningPerViewUnique
                  ? currencyFormat(
                      funnelStep.earningPerViewUnique / 100,
                      statistics.currency,
                      user?.dashboardLocale,
                    )
                  : '-'}
              </span>
            </Tooltip>
          ),
        },
      ],
    },
  ]

  return (
    <StatisticsTabTableLayout
      filter={filter}
      setFilter={setFilter}
      currency={statistics.currency}
      totalSales={statistics.commonGross}
      averageCartValue={statistics.commonAverageInOrder}
    >
      <TabTable columns={columns} data={statistics} />
    </StatisticsTabTableLayout>
  )
}

export default StatisticsTab
