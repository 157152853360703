import AddActionModal from 'modules/automation-rules/actions/components/add-action-modal'
import EditActions from 'modules/automation-rules/actions/components/edit-actions'
import { getDefaultActionValue } from 'modules/automation-rules/actions/utils/get-default-action-value'
import AddTriggerModal from 'modules/automation-rules/triggers/components/add-trigger-modal'
import EditTriggers from 'modules/automation-rules/triggers/components/edit-triggers'
import { getDefaultTriggerValue } from 'modules/automation-rules/triggers/utils/get-default-trigger-value'
import {
  AutomationRuleErrorInterface,
  AutomationRuleViewInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import { FunnelStepRuleCreateProps } from 'modules/funnels/funnel/components/funnel-rules/components/funnel-step-rule-create'
import { fillTriggerWithFunnelData } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'
import { splitRelatedAndUnrelatedTriggers } from 'modules/funnels/funnel/components/funnel-rules/utils/split-related-and-unrelated-triggers'
import React, { Dispatch, SetStateAction } from 'react'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { Loader } from 'shared/components/loader'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AutomationRuleActionIcon from 'shared/icons/automation-rule-action-icon'
import AutomationRuleTriggerIcon from 'shared/icons/automation-rule-trigger-icon'
import { twJoin } from 'tailwind-merge'

interface FunnelStepRuleProps
  extends Pick<FunnelStepRuleCreateProps, 'availableTriggers' | 'funnelTriggerData'> {
  tempState: AutomationRuleViewInterface
  setTempState: Dispatch<SetStateAction<AutomationRuleViewInterface>>
  errors: AutomationRuleErrorInterface
  maxTriggerId: React.MutableRefObject<number>
  maxActionId: React.MutableRefObject<number>
  isPreFetching?: boolean
  isFetching: boolean
  savedTriggers?: AutomationRuleViewInterface['triggers']
  ruleId?: number
}

export function FunnelStepRule({
  funnelTriggerData,
  tempState,
  setTempState,
  maxTriggerId,
  maxActionId,
  errors,
  isPreFetching,
  isFetching,
  savedTriggers = [],
  availableTriggers = [],
  ruleId,
}: FunnelStepRuleProps) {
  const { t } = useLocoTranslation()
  const canAdd = !isPreFetching && !isFetching

  const { relatedTriggers: relatedSavedTriggers, unrelatedTriggers } =
    splitRelatedAndUnrelatedTriggers(savedTriggers, funnelTriggerData.funnelStepId)

  const { relatedTriggers: relatedTempTriggers } = splitRelatedAndUnrelatedTriggers(
    tempState.triggers,
    funnelTriggerData.funnelStepId,
  )

  const allowedTriggers = relatedSavedTriggers
    .map(trigger => trigger.type)
    .concat(availableTriggers)
    .filter(trigger => !relatedTempTriggers.find(el => el.type === trigger))

  return (
    <div className={'flex flex-col lg:flex-row gap-5 lg:gap-0'}>
      <div className={'flex flex-col w-full lg:w-1/2 lg:border-r border-gray/30'}>
        <div className={'flex gap-5 justify-between p-5 pt-0 border-b border-gray/30'}>
          <div className={'flex gap-3 items-center'}>
            <AutomationRuleTriggerIcon className={'shrink-0'} />
            <div className={'flex flex-col gap-1'}>
              <Hint
                showHint={!!ruleId && unrelatedTriggers.length !== 0}
                label={
                  <TransLoco
                    t={t}
                    components={{
                      a: (
                        <LinkWithoutPrefetch
                          href={`/automation-rules/${ruleId}`}
                          className={'primary-link'}
                        />
                      ),
                    }}
                    i18nKey={'dashboard.automation_rule.trigger.has_non_related'}
                  />
                }
              >
                <span className={'font-bold text-darkblue text-[17px]'}>
                  {t('dashboard.automation_rule.trigger.title')}
                </span>
              </Hint>
              <span className={'text-darkblue'}>
                {t('dashboard.automation_rule.edit.when_happens')}
              </span>
            </div>
          </div>
          <AddTriggerModal
            allowedTriggers={allowedTriggers}
            isReady={canAdd}
            onAddTrigger={trigger =>
              setTempState(prev => {
                return {
                  ...prev,
                  triggers: [
                    ...prev.triggers,
                    fillTriggerWithFunnelData(
                      getDefaultTriggerValue(trigger, maxTriggerId),
                      funnelTriggerData,
                    ),
                  ],
                }
              })
            }
          />
        </div>
        <div
          className={twJoin(
            `min-h-[200px] lg:min-h-[500px] relative p-5 px-10 flex flex-col gap-10`,
            isPreFetching && 'justify-center',
          )}
        >
          {isPreFetching ? (
            <div className={'h-full flex items-center justify-center'}>
              <Loader className={'scale-150'} />
            </div>
          ) : (
            <EditTriggers
              isStatic={true}
              errors={errors}
              setRules={setTempState}
              triggers={tempState.triggers}
              hideTriggers={unrelatedTriggers.map(el => el.id)}
            />
          )}
          <div
            className={
              'absolute hidden lg:inline inset-x-full inset-y-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white border-r border-t border-gray/30 h-[30px] w-[30px]'
            }
          ></div>
        </div>
      </div>
      <div className={'flex flex-col w-full lg:w-1/2'}>
        <div className={'flex gap-5 justify-between p-5 pt-0 border-b border-gray/30'}>
          <div className={'flex gap-3 items-center'}>
            <AutomationRuleActionIcon className={'shrink-0'} />
            <div className={'flex flex-col gap-1'}>
              <span className={'font-bold text-darkblue text-[17px]'}>
                {t('dashboard.automation_rule.action.title')}
              </span>
              <span className={'text-darkblue'}>
                {t('dashboard.automation_rule.edit.then_happens')}
              </span>
            </div>
          </div>
          <AddActionModal
            isReady={canAdd}
            onAddAction={action =>
              setTempState(prev => {
                return {
                  ...prev,
                  actions: [
                    ...prev.actions,
                    {
                      ...getDefaultActionValue(action, maxActionId),
                    },
                  ],
                }
              })
            }
          />
        </div>
        <div
          className={twJoin(
            `min-h-[200px] lg:min-h-[500px] relative p-5 px-10 flex flex-col gap-10`,
            isPreFetching && 'justify-center',
          )}
        >
          {isPreFetching ? (
            <div className={'h-full flex items-center justify-center'}>
              <Loader className={'scale-150'} />
            </div>
          ) : (
            <EditActions errors={errors} setRules={setTempState} actions={tempState.actions} />
          )}
        </div>
      </div>
    </div>
  )
}
