import React from 'react'

function WebinarRegistrationFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_607_31)">
        <path
          d="M22.4244 7.06098C21.3468 7.06098 20.4332 6.345 20.1342 5.36384C20.127 5.34214 20.1222 5.32286 20.1174 5.30116C20.0571 5.09866 20.0282 4.88652 20.0282 4.66473V0H1.2294C0.559226 0 0.0144043 0.542411 0.0144043 1.215V25.8429C0.0144043 26.4817 0.530297 27 1.16914 27H25.8452C26.484 27 26.9999 26.4817 26.9999 25.8429V7.06098H22.4244ZM2.60833 1.72607H2.61074C3.12181 1.72607 3.53405 2.1383 3.53405 2.64937C3.53405 3.16045 3.1194 3.57268 2.61074 3.57268H2.60833C2.09726 3.57268 1.68503 3.16045 1.68503 2.64937C1.68503 2.1383 2.09967 1.72607 2.60833 1.72607ZM22.9499 9.56812C23.2103 9.56812 23.42 9.77786 23.42 10.0382V15.0236C23.42 15.2839 23.2103 15.4937 22.9499 15.4937H11.8944C11.634 15.4937 11.4243 15.2839 11.4243 15.0236V10.0382C11.4243 9.77786 11.634 9.56812 11.8944 9.56812H22.9499ZM9.15824 10.7518C9.32458 10.9181 9.32458 11.1857 9.15824 11.3521L9.01842 11.4919C8.85208 11.6582 8.58449 11.6582 8.41815 11.4919L7.53583 10.6096C7.36949 10.4432 7.36949 10.1756 7.53583 10.0093L7.67807 9.86705C7.8444 9.70071 8.11199 9.70071 8.27833 9.86705H8.27592L9.15824 10.7518ZM3.53164 14.4088C3.53164 14.1557 3.63289 13.9146 3.81128 13.7338L6.4944 11.0507L7.97458 12.5309L5.29146 15.214C5.11307 15.3924 4.86958 15.4937 4.61646 15.4937H3.53164V14.4088ZM23.4658 21.3782C23.4658 21.6313 23.2609 21.8387 23.0054 21.8387H3.99449C3.74137 21.8387 3.53405 21.6338 3.53405 21.3782V21.1854C3.53405 20.9322 3.73896 20.7249 3.99449 20.7249H23.0054C23.2585 20.7249 23.4658 20.9298 23.4658 21.1854V21.3782ZM23.4658 18.3769C23.4658 18.63 23.2609 18.8373 23.0054 18.8373H4.00896C3.74619 18.8373 3.53405 18.6252 3.53405 18.3624V18.1961C3.53405 17.9333 3.74619 17.7212 4.00896 17.7212H23.0054C23.2585 17.7212 23.4658 17.9261 23.4658 18.1816V18.3769Z"
          fill="#CBD5E1"
        />
        <path
          d="M21.2576 4.56348V0L26.9999 5.74232H22.4364C21.7831 5.74232 21.2576 5.21438 21.2576 4.56348Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_31">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WebinarRegistrationFunnelStepIcon
