import React from 'react'

function TestIconWithoutBg(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2611 2.83994C15.2211 2.92994 15.1711 3.00994 15.101 3.07994L13.3001 4.87994C13.1501 5.02994 12.96 5.09994 12.7699 5.09994C12.5798 5.09994 12.3897 5.02994 12.2396 4.87994C11.9492 4.58622 11.9492 4.11366 12.2396 3.81994L12.7599 3.29994H12.6098C10.5088 3.29994 8.78791 5.00994 8.78791 7.10994V11.2399C8.78791 11.6499 8.44774 11.9899 8.03754 11.9899C7.62734 11.9899 7.28717 11.6499 7.28717 11.2399V7.10994C7.28717 5.00994 5.57632 3.29994 3.47527 3.29994H3.05506L3.57532 3.81994C3.86546 4.10994 3.86546 4.58994 3.57532 4.87994C3.42525 5.02994 3.23515 5.09994 3.04506 5.09994C2.85497 5.09994 2.66487 5.02994 2.5148 4.87994L0.713901 3.07994C0.423491 2.78622 0.423491 2.31366 0.713901 2.01994L2.5148 0.219941C2.80494 -0.0700586 3.28518 -0.0700586 3.57533 0.219941C3.86547 0.509941 3.86547 0.989941 3.57533 1.27994L3.05507 1.79994H3.48528C5.43625 1.79994 7.12709 2.86994 8.04755 4.43994C8.96801 2.86994 10.6589 1.79994 12.6198 1.79994H12.7699L12.2496 1.27994C11.9592 0.986221 11.9592 0.513661 12.2496 0.219941C12.5398 -0.0700586 13.02 -0.0700586 13.3102 0.219941L15.1111 2.01994C15.1811 2.08994 15.2311 2.16994 15.2711 2.25994C15.3412 2.44994 15.3412 2.65994 15.2611 2.83994H15.2611Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default TestIconWithoutBg
