import React from 'react'

function InfoFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_607_87)">
        <path
          d="M7.50928 10.3588L10.3226 9.36795C10.419 9.3342 10.5227 9.3342 10.6167 9.36795L13.4348 10.3588V6.05322H7.50928V10.3612V10.3588Z"
          fill="#CBD5E1"
        />
        <path
          d="M20.949 6.05322V9.23295C19.4206 8.78215 18.2176 7.5792 17.7668 6.05322H20.949Z"
          fill="#CBD5E1"
        />
        <path
          d="M16.7786 6.05322H14.3606V11.0217C14.3606 11.1663 14.2907 11.3013 14.1726 11.3857C14.0979 11.4412 14.0063 11.4701 13.9122 11.4701C13.8592 11.4701 13.8086 11.4605 13.7579 11.4436L10.4746 10.2913L7.19357 11.4436C6.95732 11.528 6.69938 11.4074 6.615 11.1712C6.59813 11.1205 6.58848 11.0651 6.58848 11.0097V6.05322H1.27286C0.568929 6.05322 0 6.62215 0 7.32608V25.7271C0 26.431 0.568929 26.9999 1.27286 26.9999H19.6763C20.3802 26.9999 20.9491 26.431 20.9491 25.7271V10.2213C18.8952 9.72233 17.2776 8.10474 16.7786 6.05322ZM17.0896 21.8072C17.0896 22.0483 16.8943 22.2436 16.6532 22.2436H4.2983C4.05482 22.2436 3.85955 22.0483 3.85955 21.8072V21.5662C3.85955 21.3251 4.05482 21.1274 4.2983 21.1274H16.6508C16.8919 21.1274 17.0896 21.3251 17.0896 21.5662V21.8072ZM17.0896 18.613C17.0896 18.8541 16.8943 19.0494 16.6532 19.0494H4.2983C4.05482 19.0494 3.85955 18.8541 3.85955 18.613V18.372C3.85955 18.1309 4.05482 17.9332 4.2983 17.9332H16.6532C16.8943 17.9332 17.0896 18.1285 17.0896 18.3696V18.613ZM17.0896 15.4188C17.0896 15.6599 16.8943 15.8552 16.6532 15.8552H4.2983C4.05482 15.8552 3.85955 15.6599 3.85955 15.4188V15.1778C3.85955 14.9367 4.05482 14.739 4.2983 14.739H16.6532C16.8943 14.739 17.0896 14.9343 17.0896 15.1754V15.4188Z"
          fill="#CBD5E1"
        />
        <path
          d="M21.6001 24.1528V10.3587C22.0943 10.3587 22.4945 10.7589 22.4945 11.2531V23.2585C22.4945 23.7527 22.0943 24.1528 21.6001 24.1528Z"
          fill="#CBD5E1"
        />
        <path
          d="M13.4397 5.36621L7.51178 5.36621C7.51178 4.87201 7.91196 4.47184 8.40616 4.47184L12.5478 4.47184C13.042 4.47184 13.4421 4.87201 13.4421 5.36621H13.4397Z"
          fill="#CBD5E1"
        />
        <path
          d="M22.2895 0C19.6836 0 17.5718 2.10937 17.5718 4.71295C17.5718 5.17821 17.6393 5.62902 17.767 6.0533C18.2178 7.57929 19.4208 8.78223 20.9492 9.23304C21.3735 9.3608 21.8243 9.4283 22.2895 9.4283C24.8931 9.4283 27.0001 7.31652 27.0001 4.71295C27.0001 2.10937 24.8931 0 22.2895 0ZM23.0128 6.48C22.7862 6.66321 22.5017 6.76446 22.2052 6.76688C22.1232 6.76688 22.0412 6.75723 21.9617 6.74277C21.7471 6.70661 21.5639 6.57161 21.4675 6.37875C21.3566 6.10875 21.3807 5.80259 21.5302 5.55188L21.7857 5.03839C21.9231 4.76598 21.8412 4.71054 21.8002 4.6792C21.7688 4.66232 21.7303 4.65509 21.6965 4.65991C21.6049 4.66232 21.5157 4.6792 21.4289 4.70571C21.3807 4.725 21.3253 4.71054 21.2915 4.67196C21.2457 4.61652 21.2505 4.53696 21.3036 4.48875C21.5326 4.30554 21.817 4.20429 22.1112 4.20188C22.1955 4.20188 22.2751 4.21152 22.357 4.22598C22.5692 4.26214 22.7524 4.39714 22.8488 4.59C22.9597 4.86 22.9356 5.16616 22.7886 5.41687L22.5306 5.92795C22.3932 6.20277 22.4728 6.25821 22.5162 6.28955C22.5475 6.30402 22.5861 6.31125 22.6198 6.30884C22.7114 6.30402 22.8006 6.28955 22.8874 6.26062C22.938 6.24375 22.9911 6.25821 23.0248 6.29679C23.073 6.34982 23.0658 6.43179 23.0128 6.48ZM22.6728 3.94393C22.3161 3.94393 22.0292 3.65705 22.0292 3.30268C22.0292 2.9483 22.3161 2.66143 22.6728 2.66143C23.0296 2.66143 23.3141 2.9483 23.3141 3.30268C23.3141 3.65705 23.0272 3.94393 22.6728 3.94393Z"
          fill="#CBD5E1"
        />
        <path
          d="M22.2895 0C19.6836 0 17.5718 2.10937 17.5718 4.71295C17.5718 5.17821 17.6393 5.62902 17.767 6.0533C18.2178 7.57929 19.4208 8.78223 20.9492 9.23304C21.3735 9.3608 21.8243 9.4283 22.2895 9.4283C24.8931 9.4283 27.0001 7.31652 27.0001 4.71295C27.0001 2.10937 24.8931 0 22.2895 0ZM23.0128 6.48C22.7862 6.66321 22.5017 6.76446 22.2052 6.76688C22.1232 6.76688 22.0412 6.75723 21.9617 6.74277C21.7471 6.70661 21.5639 6.57161 21.4675 6.37875C21.3566 6.10875 21.3807 5.80259 21.5302 5.55188L21.7857 5.03839C21.9231 4.76598 21.8412 4.71054 21.8002 4.6792C21.7688 4.66232 21.7303 4.65509 21.6965 4.65991C21.6049 4.66232 21.5157 4.6792 21.4289 4.70571C21.3807 4.725 21.3253 4.71054 21.2915 4.67196C21.2457 4.61652 21.2505 4.53696 21.3036 4.48875C21.5326 4.30554 21.817 4.20429 22.1112 4.20188C22.1955 4.20188 22.2751 4.21152 22.357 4.22598C22.5692 4.26214 22.7524 4.39714 22.8488 4.59C22.9597 4.86 22.9356 5.16616 22.7886 5.41687L22.5306 5.92795C22.3932 6.20277 22.4728 6.25821 22.5162 6.28955C22.5475 6.30402 22.5861 6.31125 22.6198 6.30884C22.7114 6.30402 22.8006 6.28955 22.8874 6.26062C22.938 6.24375 22.9911 6.25821 23.0248 6.29679C23.073 6.34982 23.0658 6.43179 23.0128 6.48ZM22.6728 3.94393C22.3161 3.94393 22.0292 3.65705 22.0292 3.30268C22.0292 2.9483 22.3161 2.66143 22.6728 2.66143C23.0296 2.66143 23.3141 2.9483 23.3141 3.30268C23.3141 3.65705 23.0272 3.94393 22.6728 3.94393Z"
          fill="#CBD5E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_87">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InfoFunnelStepIcon
