import React from 'react'

function InactiveIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 0C6.49102 0 0 6.49102 0 14.5C0 22.509 6.49102 29 14.5 29C22.509 29 29 22.509 29 14.5C29 6.49102 22.509 0 14.5 0ZM14.5 25.375C8.50176 25.375 3.625 20.4965 3.625 14.5C3.625 8.50346 8.50346 3.625 14.5 3.625C20.4965 3.625 25.375 8.50346 25.375 14.5C25.375 20.4965 20.4982 25.375 14.5 25.375Z"
        fill="#64748B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20972 5.646L23.3554 20.7917C22.6495 21.7825 21.7833 22.6488 20.7924 23.3547L5.64844 8.2073C6.35192 7.21609 7.21852 6.34948 8.20973 5.64601L8.20972 5.646Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default InactiveIcon
