import { CouponErrorsInterface } from 'modules/coupons/types/coupon-interface'
import { getDiscountTypeData } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/utils/coupons-utils'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import ButtonLikeLink from 'shared/components/button-like-link'
import DateTimePicker from 'shared/components/date-picker/date-time-picker'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import CloseIcon from 'shared/icons/close-icon'
import SaveIcon from 'shared/icons/save-icon'
import { filterPassedTime, setDateWithTimezone } from 'shared/utils/date-time-format'
import { CouponCreateInterface } from '../types/add-coupon-data-interface'

interface CreateCouponFormProps {
  onCreateCoupon: (value: CouponCreateInterface) => Promise<void>
  openAddForm: () => void
  closeForm: () => void
  createFieldErrors: CouponErrorsInterface
}

const defaultValues: CouponCreateInterface = {
  innerName: '',
  code: '',
  discountType: undefined as unknown as CouponCreateInterface['discountType'],
  discountAmount: undefined,
  currency: undefined as unknown as CouponCreateInterface['currency'],
  expirationDate: null,
  limitOfUse: NaN,
}

function CreateCouponForm({
  onCreateCoupon,
  openAddForm,
  closeForm,
  createFieldErrors,
}: CreateCouponFormProps) {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const [tempState, setTempState] = useState(defaultValues)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState('')

  const handleConfirm = async () => {
    try {
      setError('')
      setIsFetching(true)
      await onCreateCoupon(tempState)
      setIsFetching(false)
      closeForm()
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  return (
    <div className="flex flex-col gap-5 relative rounded-lg border-gray/30 border py-3 px-5">
      <CloseIcon
        className={`cursor-pointer ${
          isFetching && 'fill-gray pointer-events-none'
        } absolute lg:top-2 top-1 lg:right-2 right-1`}
        onClick={() => closeForm()}
      />
      <FormInput
        required
        label={t('dashboard.coupon.coupon_name')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        placeholder={t('global.name')}
        value={tempState.innerName}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, innerName: e.target.value }
          })
        }
        maxLength={256}
        error={createFieldErrors.innerName}
      />
      <div className="flex gap-5">
        <FormInput
          required
          label={t('dashboard.coupon.coupon_code')}
          labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
          placeholder={t('dashboard.coupon.coupon_code')}
          value={tempState.code}
          onChange={e =>
            setTempState(prev => {
              return { ...prev, code: e.target.value }
            })
          }
          maxLength={256}
          error={createFieldErrors.code}
        />
        <FormSelect
          label={t('dashboard.funnel.configuration.offer.coupon_discount_type')}
          labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
          data={getDiscountTypeData(t)}
          onChange={data =>
            setTempState(prev => {
              return { ...prev, discountType: data }
            })
          }
          value={tempState.discountType}
          required
          error={createFieldErrors.discountType}
        />
      </div>
      <FormInput
        required
        label={t('dashboard.coupon.discount_amount')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        type="number"
        onChange={e =>
          setTempState(prev => {
            return { ...prev, discountAmount: e.target.valueAsNumber }
          })
        }
        value={
          !tempState.discountAmount && tempState.discountAmount !== 0
            ? ''
            : tempState.discountAmount
        }
        min={0}
        maxLength={256}
        error={createFieldErrors.discountAmount}
      />
      <div className="flex gap-5">
        <DateTimePicker
          value={tempState.expirationDate}
          onChange={data =>
            setTempState(prev => {
              return { ...prev, expirationDate: data }
            })
          }
          label={t('dashboard.coupon.expiration_date')}
          customProps={{
            minDate: setDateWithTimezone('', user?.timezone),
            filterTime: time => filterPassedTime(time, user?.timezone),
          }}
          key="coupon-expirationDate-create"
          labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
          error={createFieldErrors.expirationDate}
        />
        <FormInput
          label={t('dashboard.coupon.limit_of_use')}
          type="number"
          onChange={e =>
            setTempState(prev => {
              return { ...prev, limitOfUse: e.target.valueAsNumber }
            })
          }
          value={!tempState.limitOfUse && tempState.limitOfUse !== 0 ? '' : tempState.limitOfUse}
          labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
          min={0}
          maxLength={256}
          error={createFieldErrors.discountAmount}
        />
      </div>
      <div
        className={
          'flex w-full lg:flex-row flex-col lg:text-[15px] text-sm text-darkblue font-medium gap-2'
        }
      >
        <span>{t('dashboard.funnel.configuration.offer.create_coupon_footer')}</span>
        <ButtonLikeLink
          onClick={() => {
            openAddForm()
          }}
        >
          {t('dashboard.funnel.configuration.offer.create_coupon_footer_link')}
        </ButtonLikeLink>
      </div>
      <div className="flex">
        <PrimaryButton
          width="large"
          onClick={e => {
            e.preventDefault()
            handleConfirm()
          }}
          isFetching={isFetching}
          type="submit"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      </div>
      <FieldErrorAndDescription error={error} errorClassName={'text-center mt-2'} />
    </div>
  )
}

export default CreateCouponForm
