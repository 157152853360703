import axios from 'axios'
import { DigitalProductBase } from 'modules/digital-product/components/digital-product-base'
import { DigitalProductSave } from 'modules/digital-product/components/digital-product-save'
import {
  defaultDigitalProductErrors,
  defaultDigitalProductValues,
} from 'modules/digital-product/constants/default-values'
import { DigitalProductTabEnum } from 'modules/digital-product/enums/digital-product-tab-enum'
import { useDigitalProductActions } from 'modules/digital-product/hooks/use-digital-product-actions'
import { useDigitalProductsList } from 'modules/digital-product/hooks/use-digital-product-list'
import PricePlans from 'modules/digital-product/price-plans'
import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import { handleOfferPricePlansErrors } from 'modules/digital-product/price-plans/utils/handle-offer-price-plans-errors'
import Resources from 'modules/digital-product/resources'
import {
  DigitalProductChangeInterface,
  DigitalProductErrorInterface,
  DigitalProductInterface,
} from 'modules/digital-product/types/digital-product-interface'
import { getDigitalProductApiData } from 'modules/digital-product/utils/get-digital-product-api-data'
import { DigitalOfferEditInterface } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/digital-offer-interface'
import React, { FC, useState } from 'react'
import toast from 'react-hot-toast'
import FormInput from 'shared/components/form-input'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import { DigitalProductProps } from '../index'

interface BaseProps extends Omit<DigitalProductProps, 'product' | 'onSave'> {
  setTab: (tab: DigitalProductTabEnum) => void
}

type DigitalProductEditProps = BaseProps & {
  onSave?: never
  digitalProduct: DigitalProductInterface
}

type DigitalProductCreateProps = BaseProps & {
  onSave: (data: DigitalOfferEditInterface['digitalProduct']) => Promise<void>
  digitalProduct?: never
}

type Props = DigitalProductEditProps | DigitalProductCreateProps

const inCreate = (props: Props): props is DigitalProductCreateProps => !!props.onSave

const DigitalProductEdit: FC<Props> = props => {
  const isCreate = inCreate(props)

  const { mutate } = useDigitalProductsList(props.currency, props.source)
  const { t } = useLocoTranslation()
  const [isFetching, setIsFetching] = useState(false)

  const [tempState, setTempState] = useState<DigitalProductChangeInterface>(
    isCreate ? defaultDigitalProductValues : props.digitalProduct,
  )

  const [errors, setErrors] = useState<DigitalProductErrorInterface>(defaultDigitalProductErrors)

  const { onCreate, onEdit } = useDigitalProductActions()

  const mutateDigitalProductItemFromList = async (digitalProduct: DigitalProductInterface) =>
    mutate(
      data => data && data.map(el => (el.id === digitalProduct.id ? digitalProduct : el)),
      false,
    )

  const onSaveDigitalProduct = async () => {
    try {
      setErrors(defaultDigitalProductErrors)
      setIsFetching(true)
      if (isCreate) {
        const digitalProduct = await onCreate(getDigitalProductApiData(tempState, props.source))
        await mutate(data => data && [...data, digitalProduct], false)
        await props.onSave(digitalProduct.id)
      } else {
        const responseData = await onEdit(
          props.digitalProduct.id,
          getDigitalProductApiData(tempState, props.source),
        )
        await mutateDigitalProductItemFromList(responseData)
      }

      toast.success(t('global.changes_saved'))
      props.setTab(DigitalProductTabEnum.select)
      setIsFetching(false)
    } catch (e) {
      if (axios.isCancel(e)) return
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.fields) {
          const errors = e.errors.fields as DigitalProductErrorInterface
          setErrors(prev => ({ ...prev, ...errors }))
          toast.error(t('global.error'))
        }
        handleOfferPricePlansErrors(e.errors)
      }
    }
  }

  const isReadyToSave = !!tempState.name && tempState.pricePlans.length > 0

  const mutatePricePlans = !isCreate
    ? async (pricePlans: PricePlanType[]) => {
        await mutateDigitalProductItemFromList({ ...props.digitalProduct, pricePlans })
      }
    : undefined

  return (
    <>
      <DigitalProductBase
        additionalChild={
          <button
            onClick={() => props.setTab(DigitalProductTabEnum.select)}
            className={'outline-none'}
          >
            <CloseIcon />
          </button>
        }
      />
      <FormInput
        className={'sm:min-w-0'}
        value={tempState.name}
        onChange={e => setTempState(prev => ({ ...prev, name: e.target.value }))}
        label={t('dashboard.funnel.configuration.offer.digital_product_name')}
        error={errors.name}
      />
      <div className={'flex flex-col gap-5 ml-5'}>
        <Resources
          resources={tempState.resources}
          errors={errors.resources}
          onChangeResources={resources => setTempState(prev => ({ ...prev, resources }))}
        />
        <hr className={'border-t-1 border-solid border-gray/30'} />
        <PricePlans
          errors={errors.pricePlans}
          maxPricePlans={props.isBump ? 1 : Infinity}
          paymentMethods={props.paymentMethods}
          currency={props.currency}
          pricePlans={tempState.pricePlans}
          onChanePricePlans={pricePlans => setTempState(prev => ({ ...prev, pricePlans }))}
          mutatePricePlans={mutatePricePlans}
        />
      </div>
      <DigitalProductSave
        isFetching={isFetching}
        onSave={onSaveDigitalProduct}
        isCreate={isCreate}
        disabled={!isReadyToSave}
      />
    </>
  )
}

export default DigitalProductEdit
