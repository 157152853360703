import { COUPONS_API, createCoupon } from 'modules/coupons/api/coupon-api'
import { CouponErrorsInterface, CouponInterface } from 'modules/coupons/types/coupon-interface'
import { AddItemIcon } from 'modules/funnels/funnel/components/offer-settings/add-item-icon'
import AddCouponForm from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/components/add-coupon-form'
import CreateCouponForm from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/components/create-coupon-form'
import { couponDefaultErrors } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/constants/default-error'
import { useAvailableCoupons } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/hooks/use-available-coupons'
import {
  AddCouponDataInterface,
  CouponCreateInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/types/add-coupon-data-interface'
import { CouponDefaultErrorsInterface } from 'modules/funnels/funnel/components/step-configuration-tab/coupon-selector/types/default-errors-interface'
import { OfferFormEditType } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'
import CouponIcon from 'modules/funnels/funnel/icons/coupon-icon'
import TrashIcon from 'modules/funnels/funnel/icons/trash-icon'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import Confirmation from 'shared/components/confirmation-modal'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import useUser from 'shared/hooks/use-user'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { fetchWithErrors, fetchWithFieldAndCommonErrors } from 'shared/utils/fetch-with-errors'

const defaultCreateFieldErrors: CouponErrorsInterface = {
  innerName: '',
  code: '',
  discountType: '',
  discountAmount: '',
  currency: '',
  expirationDate: '',
  limitOfUse: '',
}

interface CouponSelectorProps {
  currency: CurrencyEnum
  data: CouponInterface[]
  onSave: (data: OfferFormEditType['coupons']) => Promise<void>
}

function CouponSelector({ currency, data, onSave }: CouponSelectorProps) {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false)
  const [createFieldErrors, setCreateFieldErrors] =
    useState<CouponErrorsInterface>(defaultCreateFieldErrors)
  const [couponErrors, setCouponErrors] =
    useState<CouponDefaultErrorsInterface>(couponDefaultErrors)

  const [couponToDelete, setCouponToDelete] = useState<null | CouponInterface>(null)
  const [shouldFetchCoupons, setShouldFetchCoupons] = useState(false)
  const { availableCoupons, setAvailableCoupons } = useAvailableCoupons({
    currency,
    shouldFetch: shouldFetchCoupons,
    initialData: data,
  })
  const createdCouponId = useRef<number | undefined>()

  const updateData = useUpdateData({
    api: COUPONS_API,
    constantApi: [`${COUPONS_API}/list`],
  })

  const handleCreateCoupon = async (createCouponData: CouponCreateInterface) => {
    const apiData = {
      ...createCouponData,
      ...(createCouponData.discountType === 'fixed' ? { currency: currency } : {}),
    }
    await fetchWithErrors(async () => {
      const response = await createCoupon(apiData)
      await updateData(true, {})
      setAvailableCoupons(prev => {
        return [...(prev || []), response.data]
      })
      openAddForm(response.data.id)
    }, setCreateFieldErrors)
  }

  const handleAddCoupon = async (addCouponData: AddCouponDataInterface) => {
    const fullCoupon = availableCoupons?.find(coupon => coupon.id === addCouponData.couponId)
    if (fullCoupon) {
      await fetchWithFieldAndCommonErrors(async () => {
        const updatedCoupons = data ? [...data, fullCoupon] : [fullCoupon]
        await onSave(updatedCoupons.map(coupon => coupon.id))
        setAvailableCoupons(prev => {
          if (prev) {
            return prev.filter(coupon => coupon.id !== fullCoupon.id)
          }
        })
        closeAddForm()
        toast.success(t('global.save'))
      }, setCouponErrors)
    }
  }

  const handleRemoveCoupon = async () => {
    if (couponToDelete) {
      const updatedCoupons = data?.filter(coupon => coupon.id !== couponToDelete.id) || []
      try {
        await onSave(updatedCoupons.map(coupon => coupon.id))
        setAvailableCoupons(prev => {
          return [...(prev || []), couponToDelete]
        })
      } catch (error) {}
    }
  }

  const openCreateForm = () => {
    setIsAddFormOpen(false)
    setIsCreateFormOpen(true)
  }

  const openAddForm = (value?: number) => {
    setIsAddFormOpen(true)
    setIsCreateFormOpen(false)
    if (value) createdCouponId.current = value
  }

  const closeAddForm = () => {
    setIsAddFormOpen(false)
  }

  const closeCreateForm = () => {
    setIsCreateFormOpen(false)
  }

  return (
    <>
      <div className="flex flex-col lg:gap-2 gap-3 px-4 lg:px-0">
        <div className="flex lg:flex-row flex-col justify-between lg:items-center lg:gap-0 gap-3">
          <div className="flex flex-col gap-3 bo-color-[#F8FAFC]">
            <div className="flex flex-row gap-1 font-normal lg:text-[15px] text-sm text-blue items-center">
              <CouponIcon />
              <span>{t('dashboard.funnel.configuration.offer.coupons')}</span>
            </div>
            <span className="text-sm font-normal">
              {t('dashboard.funnel.configuration.offer.coupons_description')}
            </span>
          </div>
          <AddItemIcon
            onClick={() => {
              setIsAddFormOpen(true)
              setShouldFetchCoupons(true)
            }}
            caption={t('dashboard.funnel.configuration.offer.add_coupon')}
          />
        </div>
        {isAddFormOpen && (
          <AddCouponForm
            coupons={availableCoupons}
            onAddCoupon={handleAddCoupon}
            openCreateForm={openCreateForm}
            closeForm={closeAddForm}
            createdCouponIdRef={createdCouponId}
            couponFieldErrors={couponErrors}
          />
        )}
        {isCreateFormOpen && (
          <CreateCouponForm
            onCreateCoupon={handleCreateCoupon}
            openAddForm={openAddForm}
            closeForm={closeCreateForm}
            createFieldErrors={createFieldErrors}
          />
        )}
        {data?.map(coupon => {
          return (
            <div
              className="flex lg:flex-row relative flex-col lg:items-center rounded-lg border-gray/30 border p-2"
              key={coupon.id}
            >
              <div className="flex flex-col gap-1 bo-color-[#F8FAFC] lg:w-1/3">
                <div className="flex flex-row gap-1 lg:text-[15px] text-sm text-blue items-center">
                  <span>{coupon.innerName}</span>
                </div>
                <span className="lg:text-[15px] text-sm">
                  {t('dashboard.coupon.coupon_code')} : {coupon.code}
                </span>
              </div>
              <div className="flex lg:flex-row flex-col lg:gap-3 lg:w-2/3 lg:text-[15px] text-sm">
                <span>
                  {t('dashboard.funnel.configuration.common.coupon.expiration_date')} :{' '}
                  {coupon.expirationDate === null
                    ? t('dashboard.coupon.expiration_date_never')
                    : dateTimeFormat(coupon.expirationDate, {
                        timeFormat: user?.timeFormat,
                        dateFormat: user?.dateFormat,
                        timeZone: user?.timezone,
                      })}
                </span>
                <span>
                  {t('dashboard.funnel.configuration.common.coupon.limit_of_use_title')} :{' '}
                  {coupon.limitOfUse === null
                    ? t('global.unlimited')
                    : t('dashboard.funnel.configuration.common.coupon.limit_of_use', {
                        count_used: coupon.numberOfUse,
                        count_available: coupon.limitOfUse,
                      })}
                </span>
              </div>
              <TrashIcon
                className="cursor-pointer ml-auto w-[17px] h-[18px] absolute top-2 right-2"
                onClick={() => setCouponToDelete(coupon)}
              />
            </div>
          )
        })}
      </div>
      <Confirmation
        opened={!!couponToDelete}
        onClose={() => setCouponToDelete(null)}
        onConfirm={handleRemoveCoupon}
        confirmationText={t('dashboard.funnel.configuration.common.coupon.remove_confirmation')}
      />
    </>
  )
}

export default CouponSelector
