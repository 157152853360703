import DigitalProduct from 'modules/digital-product'
import { DigitalProductSourceEnum } from 'modules/digital-product/enums/digital-product-source-enum'
import { useOfferActions } from 'modules/digital-product/hooks/use-offer-actions'
import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import ProductWrapper from 'modules/funnels/funnel/components/offer-settings/product-wrapper'
import AffiliateCommission from 'modules/funnels/funnel/components/step-configuration-tab/affiliate-commission/components/affiliate-commission'
import BaseConfiguration from 'modules/funnels/funnel/components/step-configuration-tab/base-configuration-tab/components/base-configuration'
import { useOfferFormSettings } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/hooks/use-offer-form-settings'
import {
  OfferFormEditType,
  OfferFormViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'
import { getOfferSettingsApiData } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/utils/get-offer-settings-api-data'
import PhysicalProductSelector from 'modules/funnels/funnel/components/step-configuration-tab/physical-product-selector/components/physical-product-selector'
import StepConfigurationTabLayout from 'modules/funnels/funnel/components/step-configuration-tab/step-configuration-tab-layout'
import { OfferTypeEnum } from 'modules/funnels/funnel/enums/offer-type-enum'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useResetFunnelStepPageTemplate } from 'modules/funnels/funnel/hooks/use-reset-funnel-step-page-template'
import { useUpdateBaseConfigurationTab } from 'modules/funnels/funnel/hooks/use-update-base-configuration-tab'
import EditIcon from 'modules/funnels/funnel/icons/edit-icon'
import {
  BaseFunnelStepConfigurationEditInterface,
  BaseFunnelStepConfigurationInterface,
} from 'modules/funnels/funnel/types/base-funnel-step-configuration-settings-interface'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  getFunnelStepPageEditorUrl,
  getFunnelStepPreviewUrl,
} from 'modules/funnels/funnel/utils/page-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { usePaymentMethods } from 'modules/payment-methods/hooks/use-payment-methods'
import React from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { Loader } from 'shared/components/loader'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface OfferFormConfigurationTabProps {
  funnelStep: FunnelStepInterface
  funnelId: FunnelId
}

function UpsellConfigurationTab({ funnelStep, funnelId }: OfferFormConfigurationTabProps) {
  const { funnel } = useFunnel(funnelId)
  const { data: paymentMethodsData } = usePaymentMethods(funnel?.currency)
  const { offerFormSettings, mutateOfferFormSettings } = useOfferFormSettings(funnelStep.id)
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { t } = useLocoTranslation()

  const { updateOffer } = useOfferActions()

  const { updateFetcher: updateBaseFetcher } =
    useUpdateBaseConfigurationTab<BaseFunnelStepConfigurationInterface>({
      funnelStepId: funnelStep.id,
      funnelId: funnelId,
      updateUrl: `${FUNNELS_API}/steps/${funnelStep.id}`,
    })

  const { resetFunction } = useResetFunnelStepPageTemplate({
    funnelId,
    funnelStepId: funnelStep.id,
  })

  const saveOffer = async (offer: OfferFormEditType) => {
    if (!offerFormSettings) return
    try {
      const data = await updateOffer(offerFormSettings?.offer.id, offer)
      await mutateOffer(data)
      toast.success(t('global.changes_saved'))
    } catch (e) {
      throw e
    }
  }

  const mutateOffer = async (offer: OfferFormViewInterface['offer']) => {
    await mutateOfferFormSettings(mutationData => {
      if (mutationData) {
        return {
          ...mutationData,
          offer: offer,
        }
      }
    }, false)
  }
  const handleSaveOffer = async (updateOffer: (offer: OfferFormEditType) => OfferFormEditType) => {
    if (!offerFormSettings) return
    await saveOffer(updateOffer(getOfferSettingsApiData(offerFormSettings.offer)))
  }
  const handleSaveBaseProperties = async (baseData: BaseFunnelStepConfigurationEditInterface) => {
    await updateBaseFetcher<BaseFunnelStepConfigurationEditInterface>(baseData, async () => {
      await mutateFunnelStepsList(data => {
        if (data) {
          return data.map(dataFunnelStep => {
            if (dataFunnelStep.id === funnelStep.id) {
              return {
                ...dataFunnelStep,
                name: baseData.name,
              }
            }
            return dataFunnelStep
          })
        }
      }, false)
      await mutateOfferFormSettings(data => {
        if (data) {
          return {
            ...data,
            name: baseData.name,
            page: {
              ...data.page,
              path: baseData.page.path,
            },
          }
        }
      }, false)
    })
  }

  if (!offerFormSettings || !funnel || !paymentMethodsData || !funnelStep.page)
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )

  const viewLinkUrl = getFunnelStepPreviewUrl(funnel.domain.domainName, offerFormSettings.page.path)

  return (
    <div className="flex flex-col gap-5">
      <StepConfigurationTabLayout
        viewLinkUrl={viewLinkUrl}
        editLinkUrl={getFunnelStepPageEditorUrl(funnelStep.page.id)}
        onChangeTemplate={resetFunction}
        customEditButton={
          <>
            {funnelStep.inRunningSplitTest ? (
              <Tooltip
                mode="hover"
                label={t('dashboard.funnel.configuration.common.in_split_test_not_available')}
                className={'overflow-auto'}
              >
                <PrimaryButton
                  className="!justify-start w-full"
                  disabled
                  data-test-element={'edit-page-funnel-step-button'}
                >
                  <EditIcon className={'w-[18px] h-[18px] fill-white'} />
                  <span className="text-start">
                    {t('dashboard.funnel.configuration.common.edit_page')}
                  </span>
                </PrimaryButton>
              </Tooltip>
            ) : (
              <PrimaryButton
                className="!justify-start"
                href={getFunnelStepPageEditorUrl(funnelStep.page.id)}
                target={'_self'}
                data-test-element={'edit-page-funnel-step-button'}
              >
                <span className="text-start">
                  {t('dashboard.funnel.configuration.common.edit_page')}
                </span>
              </PrimaryButton>
            )}
          </>
        }
      >
        <div className="flex flex-col gap-5">
          <BaseConfiguration
            path={offerFormSettings.page.path}
            name={offerFormSettings.name}
            domainName={funnel.domain.domainName}
            pathDisabled={funnelStep.inRunningSplitTest}
            onSave={handleSaveBaseProperties}
          />
          <hr className="border-solid border-darkblue border-opacity-[0.15] mt-7 mb-7" />
          <AffiliateCommission
            affiliateCommission={offerFormSettings.offer.affiliateCommission}
            payoutDelay={offerFormSettings.offer.payoutDelay}
            onSave={data => handleSaveOffer(prev => ({ ...prev, ...data }))}
          />
          <hr className="border-solid border-darkblue border-opacity-[0.15] mt-7" />
        </div>
      </StepConfigurationTabLayout>
      <div className="flex flex-col gap-5 lg:px-8 pb-10 w-full flex-grow ">
        <ProductWrapper
          digitalProduct={offerFormSettings.offer.digitalProduct}
          product={offerFormSettings.offer.product}
        >
          {type => (
            <>
              {type === OfferTypeEnum.physical && (
                <PhysicalProductSelector
                  product={offerFormSettings?.offer.product?.id || null}
                  onSave={data =>
                    handleSaveOffer(prev => ({ ...prev, digitalProduct: null, product: data }))
                  }
                  funnel={funnel}
                />
              )}
              {type === OfferTypeEnum.digital && (
                <DigitalProduct
                  source={DigitalProductSourceEnum.Funnel}
                  product={offerFormSettings.offer.digitalProduct}
                  onSave={data =>
                    handleSaveOffer(prev => ({ ...prev, product: null, digitalProduct: data }))
                  }
                  currency={funnel.currency}
                  paymentMethods={paymentMethodsData.paymentMethods}
                />
              )}
            </>
          )}
        </ProductWrapper>
      </div>
    </div>
  )
}

export default UpsellConfigurationTab
