import { DigitalResourcesTypeEnum } from 'modules/digital-product/enums/digital-resources-type-enum'
import BinIcon from 'shared/icons/bin-icon'
import { DigitalResourcesDataInterface, useDigitalResources } from '../hooks/use-digital-resources'
import { DigitalResourceEditType } from '../types/digital-resource-edit-interface'

interface DigitalResourceWrapperProps {
  resource: DigitalResourceEditType
  children: (data: DigitalResourcesDataInterface) => JSX.Element
  onDelete?: () => void
}

const DigitalResourceWrapper = ({ resource, children, onDelete }: DigitalResourceWrapperProps) => {
  const courseId =
    resource.type === DigitalResourcesTypeEnum.membership_course ? resource.course : undefined

  const courseBundleId =
    resource.type === DigitalResourcesTypeEnum.membership_course_bundle
      ? resource.courseBundle
      : undefined

  const data = useDigitalResources({
    resourceType: resource.type,
    courseId,
    courseBundleId,
  })

  return (
    <>
      <div className="flex gap-3 rounded-lg border-gray/30 border p-2 ">
        <div className={'flex flex-col gap-3 flex-1'}>{children(data)}</div>
        {onDelete && (
          <button onClick={onDelete} className={'outline-none h-fit group'}>
            <BinIcon className="cursor-pointer w-[17px] h-[18px]" />
          </button>
        )}
      </div>
    </>
  )
}

export default DigitalResourceWrapper
