import httpClient from 'shared/utils/http-client'
import {
  MarketPlaceOfferCreateApiInterface,
  MarketPlaceOfferId,
  MarketPlaceOfferInterface,
} from 'modules/funnels/funnel/types/marketplace-offer-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'

export const MARKET_PLACE_API = '/api/dashboard/marketplace'

export const updateMarketPlaceOffer = (
  id: MarketPlaceOfferId,
  data: MarketPlaceOfferCreateApiInterface,
) => httpClient.put<MarketPlaceOfferInterface>(`${MARKET_PLACE_API}/offer/${id}`, data)

export const createMarketPlaceOffer = (id: FunnelId, data: MarketPlaceOfferCreateApiInterface) =>
  httpClient.post<MarketPlaceOfferInterface>(`${MARKET_PLACE_API}/offer/${id}`, data)
