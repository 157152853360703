import React from 'react'

function UpsellFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="27" height="27" viewBox="0 0 111 112" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2625 0.390869C6.83326 0.390869 0 7.26137 0 15.7365V96.6501C0 105.125 6.83326 111.996 15.2625 111.996H95.7375C104.167 111.996 111 105.125 111 96.6501V15.7365C111 7.26137 104.167 0.390869 95.7375 0.390869H15.2625ZM63.6062 16.3089C63.6062 18.9364 65.6884 21.0664 68.2572 21.0664H86.7953L61.2746 47.1712C60.5437 47.9188 60.0917 48.3778 59.7524 48.6931C59.4132 48.3778 58.9611 47.9188 58.2302 47.1712L46.5903 35.2649L46.4887 35.161C45.7352 34.3899 44.9855 33.6229 44.2957 33.0239C43.5332 32.3619 42.5437 31.6305 41.2418 31.1977C39.4536 30.6034 37.5273 30.6034 35.7391 31.1977C34.4372 31.6305 33.4476 32.3619 32.6852 33.0239C31.9953 33.6229 31.2458 34.3899 30.4921 35.1611L30.3906 35.2649L9.68726 56.4421C7.87091 58.3 7.87091 61.3124 9.68726 63.1703C11.5036 65.0283 14.4485 65.0283 16.2648 63.1703L36.9681 41.993C37.6991 41.2454 38.1511 40.7865 38.4904 40.4713C38.8297 40.7865 39.2818 41.2454 40.0127 41.993L51.7542 54.0032C52.5077 54.7743 53.2574 55.5414 53.9473 56.1403C54.7095 56.8024 55.6991 57.5338 57.0011 57.9666C58.7892 58.5609 60.7155 58.5609 62.5037 57.9666C63.8057 57.5338 64.7953 56.8024 65.5576 56.1403C66.2474 55.5414 66.997 54.7744 67.7505 54.0034L93.3729 27.7946V46.757C93.3729 49.3844 95.4553 51.5146 98.024 51.5146C100.593 51.5146 102.675 49.3844 102.675 46.757V16.3089C102.675 13.6813 100.593 11.5514 98.024 11.5514H68.2572C65.6884 11.5514 63.6062 13.6813 63.6062 16.3089Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default UpsellFunnelStepIcon
