import React from 'react'

function SalesFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="28" height="28" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2988 9.59766C13.501 9.59766 9.59766 13.501 9.59766 18.2988C9.59766 23.0967 13.501 27 18.2988 27C23.0967 27 27 23.0967 27 18.2988C27 13.501 23.0967 9.59766 18.2988 9.59766ZM18.2988 17.5078C19.6074 17.5078 20.6719 18.5723 20.6719 19.8809C20.6719 20.9108 20.0086 21.7806 19.0898 22.1082V23.8359H17.5078V22.1082C16.5891 21.7806 15.9258 20.9108 15.9258 19.8809H17.5078C17.5078 20.3173 17.8624 20.6719 18.2988 20.6719C18.7353 20.6719 19.0898 20.3173 19.0898 19.8809C19.0898 19.4444 18.7353 19.0898 18.2988 19.0898C16.9903 19.0898 15.9258 18.0253 15.9258 16.7168C15.9258 15.6869 16.5891 14.8171 17.5078 14.4895V12.7617H19.0898V14.4895C20.0086 14.8171 20.6719 15.6869 20.6719 16.7168H19.0898C19.0898 16.2804 18.7353 15.9258 18.2988 15.9258C17.8624 15.9258 17.5078 16.2804 17.5078 16.7168C17.5078 17.1532 17.8624 17.5078 18.2988 17.5078Z"
        fill="#CBD5E1"
      />
      <path
        d="M8.80664 9.59766C13.6856 9.59766 17.5078 7.51275 17.5078 4.85156C17.5078 2.19037 13.6856 0 8.80664 0C3.92766 0 0 2.19037 0 4.85156C0 7.51275 3.92766 9.59766 8.80664 9.59766Z"
        fill="#CBD5E1"
      />
      <path
        d="M0 17.8009V19.0898C0 21.751 3.92766 23.8359 8.80664 23.8359C9.08339 23.8359 9.35154 23.8104 9.62402 23.7971C9.01726 22.843 8.56475 21.7845 8.29828 20.6528C4.77009 20.5554 1.70659 19.4712 0 17.8009Z"
        fill="#CBD5E1"
      />
      <path
        d="M8.05365 19.0495C8.03551 18.8007 8.01563 18.5522 8.01563 18.2988C8.01563 17.4742 8.12368 16.6761 8.30793 15.9071C4.77536 15.8114 1.70812 14.7266 0 13.0548V14.3437C0 16.8637 3.54702 18.8441 8.05365 19.0495Z"
        fill="#CBD5E1"
      />
      <path
        d="M8.80664 14.3437C8.80748 14.3437 8.80817 14.3436 8.80907 14.3436C9.33077 13.0967 10.0891 11.9728 11.0309 11.031C10.3165 11.1213 9.57825 11.1796 8.80664 11.1796C5.05106 11.1796 1.78907 10.0597 0 8.30872V9.5976C0 12.2588 3.92766 14.3437 8.80664 14.3437Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default SalesFunnelStepIcon
