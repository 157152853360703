import React from 'react'

interface TestsIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  active: boolean
}

function TestsIcon({ active, ...props }: TestsIconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87109 2.28027H19.6063V17.0467H2.87109V2.28027Z"
        fill={active ? 'white' : '#64748B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0994 0H6.71523C3.07342 0 0.902344 2.17 0.902344 5.81V14.18C0.902344 17.83 3.07342 20 6.71523 20H15.0894C18.7312 20 20.9023 17.83 20.9023 14.19V5.81001C20.9123 2.17001 18.7412 1e-05 15.0994 1e-05L15.0994 0ZM18.261 6.84C18.221 6.93 18.1709 7.01 18.1009 7.08L16.3 8.88C16.1499 9.03 15.9598 9.1 15.7697 9.1C15.5796 9.1 15.3896 9.03 15.2395 8.88C14.9491 8.58628 14.9491 8.11372 15.2395 7.82L15.7597 7.3H15.6097C13.5086 7.3 11.7878 9.01 11.7878 11.11V15.24C11.7878 15.65 11.4476 15.99 11.0374 15.99C10.6272 15.99 10.287 15.65 10.287 15.24V11.11C10.287 9.01 8.57618 7.3 6.47513 7.3H6.05492L6.57518 7.82C6.86532 8.11 6.86532 8.59 6.57518 8.88C6.42511 9.03 6.23501 9.1 6.04492 9.1C5.85483 9.1 5.66473 9.03 5.51466 8.88L3.71376 7.08C3.42335 6.78628 3.42335 6.31372 3.71376 6.02L5.51466 4.22C5.8048 3.93 6.28504 3.93 6.57519 4.22C6.86533 4.51 6.86533 4.99 6.57519 5.28L6.05493 5.8H6.48514C8.43611 5.8 10.127 6.87 11.0474 8.44C11.9679 6.87 13.6587 5.8 15.6197 5.8H15.7698L15.2495 5.28C14.9591 4.98628 14.9591 4.51372 15.2495 4.22C15.5396 3.93 16.0199 3.93 16.31 4.22L18.1109 6.02C18.181 6.09 18.231 6.17 18.271 6.26C18.341 6.45 18.341 6.66 18.261 6.84H18.261Z"
        fill={active ? '#00A0FF' : '#CBD5E1'}
      />
    </svg>
  )
}

export default TestsIcon
