import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import {
  SettingsTabInterface,
  funnelSettingTabsListWithEmails,
} from 'modules/funnels/funnel/utils/funnel-setting-tabs'

export const getFunnelStepSettingsProperties = (type: FunnelStepSettingTabsEnum) => {
  const { iconRenderer, caption } = funnelSettingTabsListWithEmails.find(
    el => el.type === type,
  ) as SettingsTabInterface
  return { iconRenderer, caption }
}
