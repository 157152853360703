import {
  CreateFunnelStepErrorsInterface,
  CreateFunnelStepInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelStepPageTypeDataOptions } from 'modules/funnels/funnel/utils/get-funnel-step-page-type-data-options'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useRouter } from 'next/router'
import React, { forwardRef, useState } from 'react'
import CreateModal from 'shared/components/create-modal'
import FormInput from 'shared/components/form-input'
import FormSelect from 'shared/components/form-select'
import { FunnelGroupEnum, funnelGroupTitleLocoKeys } from 'shared/enums/funnel-step-type-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'
import { addFunnelStep } from '../api/funnel-api'
import { useFunnelStepsList } from '../hooks/use-funnel-steps-list'
import { useUpdateFunnelStepHash } from '../hooks/use-update-funnel-step-hash'

const defaultValues: CreateFunnelStepInterface = {
  name: '',
  type: undefined,
}

const defaultErrors: CreateFunnelStepErrorsInterface = {
  fields: {
    name: '',
    type: '',
  },
}

function CreateFunnelStepModal() {
  const router = useRouter()
  const { t } = useLocoTranslation()
  const funnelId = parseInt(router.query.funnelId as string) as FunnelId
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const [errors, setErrors] = useState(defaultErrors)
  const [opened, setOpened] = useState(false)
  const { updateFunnelStepHash } = useUpdateFunnelStepHash()

  const onClose = () => setOpened(false)

  const afterLeave = () => {
    setErrors(defaultErrors)
  }

  const handleCreate = async (data: CreateFunnelStepInterface) => {
    await fetchWithFieldErrors(async () => {
      const response = await addFunnelStep(Number(funnelId), data)
      await mutateFunnelStepsList(data => {
        if (data) {
          return [...data, response.data]
        }
      }, false)
      updateFunnelStepHash(`#step${response.data.id}`)
      onClose()
    }, setErrors)
  }

  const funnelStepTypeGroupsData = Object.values(FunnelGroupEnum).map(key => {
    return {
      id: key,
      label: t(funnelGroupTitleLocoKeys[key]),
    }
  })

  return (
    <>
      <div
        className={
          'px-[15px] py-[15px] border-b-[1px] last:border-b-transparent border-[#142D6326] border-opacity-20 cursor-pointer shadow-lg ring-1 ring-black ring-opacity-5'
        }
        onClick={() => setOpened(true)}
        data-test-element={'create-funnel-step-button'}
      >
        <div className="flex justify-center items-center gap-[10px]">
          <AddIcon className={`fill-blue`} />
          <span className={'text-lg text-blue'}>{t('dashboard.funnel.create_funnel_step')}</span>
        </div>
      </div>
      <CreateModal
        opened={opened}
        onClose={onClose}
        afterLeave={afterLeave}
        defaultValues={defaultValues}
        caption={t('dashboard.funnel.funnel_step')}
        saveDataAttribute={'create-funnel-step-save-button'}
        creatable={[
          {
            field: 'name',
            onRender: (value, onChange) => (
              <FormInput
                key="funnel-step-name"
                label={t('global.name')}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errors.fields.name}
                data-test-element={'create-funnel-step-name-field'}
                required
              />
            ),
          },
          {
            field: 'type',
            onRender: (value, onChange) => (
              <FormSelect
                key="funnel-step-type"
                className={'sm:min-w-0'}
                data={getFunnelStepPageTypeDataOptions(t)}
                label={t('global.type')}
                value={value}
                onChange={value => onChange(value)}
                placeholder={t('dashboard.funnel.funnel_step_type_placeholder')}
                error={errors.fields.type}
                dataInputAttribute={'create-funnel-step-type-field'}
                getOptionDataAttribute={(value?: string) => {
                  if (value) {
                    return { ['data-test-element']: value }
                  }
                  return {}
                }}
                floatWrapper={Wrapper}
                groups={funnelStepTypeGroupsData}
                required
                withoutCloseIcon
              />
            ),
          },
        ]}
        onCreate={handleCreate}
        requiredFields={['name', 'type']}
      />
    </>
  )
}

const Wrapper = forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} {...props} data-test-element={'create-funnel-step-type-options'} />
))

Wrapper.displayName = 'Wrapper'

export default CreateFunnelStepModal
