import { DigitalProductInterface } from 'modules/digital-product/types/digital-product-interface'

export const getDigitalProductsData = (
  digitalProductList: DigitalProductInterface[],
  isBump?: boolean,
) => {
  return digitalProductList
    ?.filter(digitalProduct => (isBump ? digitalProduct.pricePlans.length <= 1 : true))
    .map(digitalProduct => {
      return {
        id: digitalProduct.id,
        caption: digitalProduct.name,
      }
    })
}
