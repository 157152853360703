import { ListingFunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'

interface FunnelStepDataInterface {
  id: number
  caption: string
}

export const getAvailableFunnelsData = (funnelsList?: ListingFunnelInterface[]) => {
  return funnelsList?.map(funnel => {
    return {
      id: funnel.id,
      caption: funnel.name,
    }
  }, [] as FunnelStepDataInterface[])
}
