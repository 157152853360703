export enum FunnelStepTypeEnum {
  SalesPage = 'sales_page',
  OfferForm = 'offer-form',
  Upsell = 'upsell',
  Downsell = 'downsell',
  OrderThankYouPage = 'order_thank_you_page',
  Squeeze = 'squeeze',
  InfoPage = 'info_page',
  OptInThankYouPage = 'opt_in_thank_you_page',
  Inline = 'inline',
  Popup = 'popup',
  WebinarRegistration = 'webinar_registration',
  WebinarThankYou = 'webinar_thank_you',
  WebinarSession = 'webinar_session',
  ContactUsPage = 'contact_us_page',
  LinkInBio = 'link_in_bio',
}

export enum FunnelStepTypeTitlesEnum {
  order_thank_you_page = 'dashboard.funnel.funnel_step_type.order.thank-you-page',
  'offer-form' = 'dashboard.funnel.funnel_step_type.offer-form',
  upsell = 'dashboard.funnel.funnel_step_type.upsell',
  downsell = 'dashboard.funnel.funnel_step_type.downsell',
  squeeze = 'dashboard.funnel.funnel_step_type.squeeze',
  sales_page = 'dashboard.funnel.funnel_step_type.sales_page',
  info_page = 'dashboard.funnel.funnel_step_type.info_page',
  opt_in_thank_you_page = 'dashboard.funnel.funnel_step_type.opt_in_thank_you_page',
  inline = 'dashboard.funnel.funnel_step_type.inline',
  popup = 'dashboard.funnel.funnel_step_type.popup',
  webinar_registration = 'dashboard.funnel.funnel_step_type.webinar_registration',
  webinar_thank_you = 'dashboard.funnel.funnel_step_type.webinar_thank_you',
  webinar_session = 'dashboard.funnel.funnel_step_type.webinar_session',
  contact_us_page = 'dashboard.funnel.funnel_step_type.contact_us_page',
  link_in_bio = 'dashboard.funnel.funnel_step_type.link_in_bio',
}

export enum FunnelGroupEnum {
  OptIn = 'opt_in',
  Sales = 'sales',
  Webinar = 'webinar',
  Misc = 'misc',
}

export const funnelGroupTitleLocoKeys = {
  [FunnelGroupEnum.Sales]: 'dashboard.funnel.funnel_step_type.group.sales',
  [FunnelGroupEnum.OptIn]: 'dashboard.funnel.funnel_step_type.group.opt-in',
  [FunnelGroupEnum.Webinar]: 'dashboard.funnel.funnel_step_type.group.webinar',
  [FunnelGroupEnum.Misc]: 'dashboard.funnel.funnel_step_type.group.misc',
}
