import { Tab } from '@headlessui/react'
import FunnelHeader from 'modules/funnels/funnel/components/funnel-header'
import FunnelStepsList from 'modules/funnels/funnel/components/funnel-steps-list'
import FunnelStepsListSkeleton from 'modules/funnels/funnel/components/funnel-steps-list-skeleton'
import { FunnelLoco } from 'modules/funnels/funnel/constants/funnel-loco'
import DeviceModeProvider from 'modules/funnels/funnel/context/device-mode-context'
import { FunnelStepSettingTabsEnum } from 'modules/funnels/funnel/enums/funnel-step-setting-tabs-enum'
import useContentScrollingSize from 'modules/funnels/funnel/hooks/use-content-scrolling-styles'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useUpdateFunnelStepHash } from 'modules/funnels/funnel/hooks/use-update-funnel-step-hash'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  getSelectedFunnelStepCookie,
  setSelectedFunnelStepCookie,
} from 'modules/funnels/funnel/utils/cookie-utils'
import { funnelSettingTabsList } from 'modules/funnels/funnel/utils/funnel-setting-tabs'
import { getFunnelSettingsTabList } from 'modules/funnels/funnel/utils/get-funnel-settings-tab-list'
import { getFunnelStepSettingTabContent } from 'modules/funnels/funnel/utils/get-funnel-step-setting-tab-content'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { getFunnelUrl } from 'modules/funnels/utils/get-funnel-url'
import { GetStaticPaths, NextPage } from 'next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useWindowSize from 'shared/hooks/use-window-size'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const Funnel: NextPage = () => {
  const router = useRouter()
  const { t } = useLocoTranslation()
  const funnelId = parseInt(router.query.funnelId as string) as FunnelId
  const { funnelStepsList, mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { updateFunnelStepHash } = useUpdateFunnelStepHash()
  const { width } = useWindowSize()
  const isMobile = width < 768
  const { contentRef, contentHeight, listHeight, listRef } = useContentScrollingSize(isMobile)
  const [selectedFunnelStep, setSelectedFunnelStep] = useState<FunnelStepInterface | null>(null)
  const [selectedTab, setSelectedTab] = useState<FunnelStepSettingTabsEnum>(
    FunnelStepSettingTabsEnum.stepConfiguration,
  )
  const [mobileSelectedTab, setMobileSelectedTab] = useState<
    FunnelStepSettingTabsEnum | undefined
  >()

  const selectedStepFromCookie = getSelectedFunnelStepCookie(funnelId)
  const selectedFunnelStepId = parseInt(router.asPath.split('#step')[1])
  const settingsTabsList = getFunnelSettingsTabList(selectedFunnelStep?.type)

  function selectFunnelStep(funnelStep: FunnelStepInterface) {
    setSelectedFunnelStep(funnelStep)
    updateFunnelStepHash(`#step${funnelStep.id}`)
    setSelectedTab(funnelSettingTabsList[0].type)
    setSelectedFunnelStepCookie(funnelId, funnelStep.id)
    if (selectedFunnelStep && funnelStep.id === selectedFunnelStep.id) {
      setMobileSelectedTab(funnelSettingTabsList[0].type)
    }
  }

  const isFunnelListIsEmpty = funnelStepsList && funnelStepsList.length === 0

  const resetSelectedMobileTab = () => {
    setMobileSelectedTab(undefined)
  }

  useEffect(() => {
    if (funnelStepsList && Number(selectedFunnelStepId) !== selectedFunnelStep?.id) {
      const selectedFunnelStepEntity = funnelStepsList.find(
        funnelStep => funnelStep.id === Number(selectedFunnelStepId),
      )
      if (selectedFunnelStepEntity) {
        setSelectedFunnelStep(selectedFunnelStepEntity)
        setSelectedFunnelStepCookie(funnelId, selectedFunnelStepId)
        setSelectedTab(FunnelStepSettingTabsEnum.stepConfiguration)
      }
    }
  }, [selectedFunnelStepId, funnelStepsList])

  useEffect(() => {
    if (funnelStepsList) {
      if (selectedFunnelStepId) {
        const selectedFunnelStepEntity = funnelStepsList.find(
          funnelStep => funnelStep.id === Number(selectedFunnelStepId),
        )
        if (selectedFunnelStepEntity) {
          setSelectedFunnelStep(selectedFunnelStepEntity)
          setSelectedFunnelStepCookie(funnelId, Number(selectedFunnelStepId))
        } else if (funnelStepsList[0]) {
          setSelectedFunnelStep(funnelStepsList[0])
          setSelectedFunnelStepCookie(funnelId, funnelStepsList[0]?.id)
          updateFunnelStepHash(`#step${funnelStepsList[0]?.id}`)
        } else {
          router.push(getFunnelUrl(funnelId))
        }
      } else if (selectedStepFromCookie) {
        const selectedFunnelStepEntity = funnelStepsList.find(
          funnelStep => funnelStep.id === Number(selectedStepFromCookie),
        )
        if (selectedFunnelStepEntity) {
          setSelectedFunnelStep(selectedFunnelStepEntity)
          updateFunnelStepHash(`#step${selectedStepFromCookie}`)
        }
      } else if (funnelStepsList.length > 0) {
        setSelectedFunnelStep(funnelStepsList[0])
        updateFunnelStepHash(`#step${funnelStepsList[0].id}`)
        setSelectedFunnelStepCookie(funnelId, funnelStepsList[0].id)
      }
    }
  }, [funnelStepsList])

  return (
    <DeviceModeProvider resetSelectedMobileTab={resetSelectedMobileTab} isMobile={isMobile}>
      <div className={`flex flex-col gap-5`}>
        <FunnelHeader funnelId={funnelId} selectedFunnelStep={selectedFunnelStep} />
        <div className="flex flex-col gap-3 lg:flex-row lg:gap-6">
          <div
            className={`relative w-full lg:w-[330px] lg:min-h-fit ${
              isMobile && mobileSelectedTab ? 'hidden' : ''
            }`}
          >
            {!funnelStepsList ? (
              <FunnelStepsListSkeleton />
            ) : (
              <FunnelStepsList
                funnelId={funnelId}
                funnelSteps={funnelStepsList}
                mutate={mutateFunnelStepsList}
                selectedFunnelStep={selectedFunnelStep}
                selectFunnelStep={selectFunnelStep}
                listHeight={listHeight}
                listRef={listRef}
              />
            )}
          </div>
          <div
            className={`flex flex-col w-full h-full rounded-md overflow-x-hidden gap-5 bg-white items-stretch min-h-[200px] customScroll`}
            style={
              contentHeight
                ? {
                    height: contentHeight,
                    overflowY: 'scroll',
                  }
                : {}
            }
          >
            <div className="flex flex-col gap-7 lg:gap-10" ref={contentRef}>
              <Tab.Group>
                <Tab.List
                  className={`flex lg:flex-row flex-col overflow-x-auto funnelCustomScroll gap-y-0 ${
                    isMobile && mobileSelectedTab ? 'hidden' : ''
                  }`}
                >
                  {settingsTabsList.map(tab => (
                    <Tab
                      onClick={() =>
                        isMobile ? setMobileSelectedTab(tab.type) : setSelectedTab(tab.type)
                      }
                      key={tab.type}
                      disabled={isFunnelListIsEmpty}
                      className={`${
                        tab.type === selectedTab
                          ? 'lg:text-darkblue lg:border-b-blue lg:border-b-[3px]'
                          : 'lg:border-b-darkblue lg:border-b-1 lg:border-opacity-[15%]'
                      } lg:pb-[22px] lg:pt-[27px] py-4 lg:main-transition-colors outline-none lg:w-fit w-full border-b-[1px] last:border-b-[0px] lg:last:border-b-[1px] border-[#142D6326] border-opacity-15 lg:first:pl-8 px-4`}
                    >
                      <div className="flex gap-2">
                        {tab.iconRenderer(
                          isMobile ? tab.type === mobileSelectedTab : tab.type === selectedTab,
                        )}
                        <span className="font-semibold font-inter sm:text-[15px] whitespace-nowrap">
                          {t(tab.caption)}
                        </span>
                      </div>
                    </Tab>
                  ))}
                </Tab.List>
              </Tab.Group>
              {getFunnelStepSettingTabContent(
                funnelId,
                selectedFunnelStep,
                isMobile ? mobileSelectedTab : selectedTab,
                isFunnelListIsEmpty,
              )}
            </div>
          </div>
        </div>
      </div>
    </DeviceModeProvider>
  )
}

export const getStaticProps = getBaseServerSideProps(FunnelLoco, 'header.top_menu.funnels')

export const getStaticPaths: GetStaticPaths<{ funnelId: string }> = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default Funnel
