import React from 'react'

function DownsellFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.997009C4.92487 0.997009 0 5.92188 0 11.997V69.997C0 76.0721 4.92487 80.997 11 80.997H69C75.0751 80.997 80 76.0721 80 69.997V11.997C80 5.92188 75.0751 0.997009 69 0.997009H11ZM45.8423 69.5868C45.8423 67.7033 47.343 66.1765 49.1944 66.1765H62.5552L44.1619 47.4642C43.6351 46.9283 43.3093 46.5993 43.0648 46.3733C42.8203 46.5993 42.4945 46.9283 41.9677 47.4642L33.5786 55.9988L33.5054 56.0733C32.9622 56.626 32.4219 57.1758 31.9248 57.6052C31.3753 58.0797 30.6621 58.604 29.7238 58.9142C28.435 59.3402 27.0467 59.3402 25.7579 58.9142C24.8196 58.604 24.1064 58.0797 23.5569 57.6052C23.0597 57.1758 22.5195 56.626 21.9763 56.0733L21.9031 55.9988L6.98181 40.8187C5.67273 39.4869 5.67273 37.3276 6.98181 35.9958C8.29089 34.6641 10.4133 34.6641 11.7224 35.9958L26.6437 51.176C27.1705 51.7119 27.4963 52.0409 27.7408 52.2669C27.9854 52.0409 28.3112 51.7119 28.838 51.176L37.3003 42.5669C37.8434 42.0142 38.3837 41.4644 38.8809 41.035C39.4303 40.5604 40.1435 40.0361 41.0819 39.726C42.3706 39.2999 43.7589 39.2999 45.0477 39.726C45.9861 40.0361 46.6993 40.5604 47.2487 41.035C47.7459 41.4644 48.2861 42.0141 48.8292 42.5668L67.2958 61.3537V47.7611C67.2958 45.8777 68.7966 44.3509 70.6479 44.3509C72.4992 44.3509 74 45.8777 74 47.7611V69.5868C74 71.4702 72.4992 72.997 70.6479 72.997H49.1944C47.343 72.997 45.8423 71.4702 45.8423 69.5868Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default DownsellFunnelStepIcon
