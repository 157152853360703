import { EMBEDDED_FORM_FIELDS_API } from 'modules/funnels/funnel/api/step-configuration-api'
import { FunnelStepPageId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { EmbeddedFormFieldsInterface } from 'modules/funnels/funnel/types/remote-form-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useEmbeddedFormFields(shouldFetch: boolean, pageId?: FunnelStepPageId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    EmbeddedFormFieldsInterface
  >({ method: RequestMethodsEnum.get })

  const { data: embeddedFormFields } = useSWR(() => {
    if (user && pageId && shouldFetch) {
      return `${EMBEDDED_FORM_FIELDS_API}/${pageId}/html`
    }
    return null
  }, fetcher)

  return { embeddedFormFields }
}
