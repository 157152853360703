import { DIGITAL_PRODUCT_API } from 'modules/digital-product/api'
import { DigitalProductSourceEnum } from 'modules/digital-product/enums/digital-product-source-enum'
import { DigitalProductInterface } from 'modules/digital-product/types/digital-product-interface'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useDigitalProductsList(currency: CurrencyEnum, source: DigitalProductSourceEnum) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    DigitalProductInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: digitalProductList, mutate } = useSWR(
    () => user && `${DIGITAL_PRODUCT_API}/list?currency=${currency}&source=${source}`,
    fetcher,
  )

  return { digitalProductList, mutate }
}
