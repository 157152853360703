import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { changeFunnelStepPosition } from 'modules/funnels/funnel/api/funnel-api'
import CreateFunnelStepModal from 'modules/funnels/funnel/components/create-funnel-step-modal'
import DraggableFunnelStep from 'modules/funnels/funnel/components/draggable-funnel-step'
import InactivePage from 'modules/funnels/funnel/components/inactive-page'
import OverlayFunnelStep from 'modules/funnels/funnel/components/overlay-funnel-step'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import { KeyedMutator } from 'swr'

interface FunnelStepsListProps {
  funnelSteps: FunnelStepInterface[]
  mutate: KeyedMutator<FunnelStepInterface[]>
  selectedFunnelStep: FunnelStepInterface | null
  selectFunnelStep: (funnelId: FunnelStepInterface) => void
  funnelId: FunnelId
  listRef: React.RefObject<HTMLUListElement>
  listHeight: string | number
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

function FunnelStepsList({
  funnelSteps,
  mutate,
  selectedFunnelStep,
  selectFunnelStep,
  funnelId,
  listRef,
  listHeight,
}: FunnelStepsListProps) {
  const [draggableId, setDraggableId] = useState<UniqueIdentifier | null>(null)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
  )

  const onDragCancel = async () => {
    setDraggableId(null)
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = funnelSteps.findIndex(({ id }) => id === active.id)
      const overIndex = funnelSteps.findIndex(({ id }) => id === over.id)
      const activeItem = funnelSteps.find(({ id }) => id === active.id)
      const overItem = funnelSteps.find(({ id }) => id === over.id)

      if (activeItem && overItem && activeIndex !== overIndex) {
        const clonedSteps = [...funnelSteps]
        await mutate(data => {
          if (data) {
            return arrayMove(funnelSteps, activeIndex, overIndex)
          }
        }, false)
        try {
          await changeFunnelStepPosition(Number(active.id), {
            newPosition: overIndex + 1,
          })
        } catch (error) {
          await mutate(data => {
            if (data) {
              return {
                ...data,
                funnelSteps: clonedSteps,
              }
            }
          }, false)
        }
      }
    }
    setDraggableId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setDraggableId(active.id)
      }}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={funnelSteps} strategy={verticalListSortingStrategy}>
        <div className="flex flex-col bg-white">
          <ul
            ref={listRef}
            className={`w-full lg:overflow-y-hidden lg:hover:overflow-y-auto overflow-y-auto funnelCustomScrollContainer customScroll inset-0 flex flex-col text-base rounded-md lg:w-[330px] last:border-b-transparent text-darkblue lg:h-[calc(100vh-15rem)] h-[calc(46vh)]`}
            style={
              listHeight
                ? {
                    height: listHeight,
                    minHeight: 'calc(100vh - 15rem)',
                  }
                : {}
            }
          >
            {funnelSteps.map(funnel => (
              <DraggableFunnelStep
                key={funnel.id}
                funnelStep={funnel}
                selectedFunnelStep={selectedFunnelStep}
                selectFunnelStep={selectFunnelStep}
              />
            ))}
            <InactivePage funnelId={funnelId} />
          </ul>
          <CreateFunnelStepModal />
        </div>
      </SortableContext>
      {draggableId && (
        <DragOverlay dropAnimation={dropAnimationConfig}>
          <OverlayFunnelStep funnelStep={funnelSteps.find(item => item.id === draggableId)} />
        </DragOverlay>
      )}
    </DndContext>
  )
}

export default FunnelStepsList
