import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import {
  BaseFunnelStepConfigurationEditInterface,
  BaseFunnelStepConfigurationInterface,
} from 'modules/funnels/funnel/types/base-funnel-step-configuration-settings-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  RemoteFormEditInterface,
  RemoteFormInterface,
} from 'modules/funnels/funnel/types/remote-form-interface'
import {
  WebinarRegistrationEditInterface,
  WebinarRegistrationInterface,
} from 'modules/funnels/funnel/types/webinar-registration-interface'
import httpClient from 'shared/utils/http-client'

export const EMBEDDED_FORM_FIELDS_API = '/api/dashboard/customer/embedded'

export const updateRemoteFormConfiguration = (id: FunnelStepId, data: RemoteFormEditInterface) =>
  httpClient.put<RemoteFormInterface>(`${FUNNELS_API}/step-remote-form/${id}`, data)

export const updateWebinarConfiguration = (
  id: FunnelStepId,
  data: WebinarRegistrationEditInterface,
) =>
  httpClient.put<WebinarRegistrationInterface>(
    `${FUNNELS_API}/steps/${id}/webinar-registration`,
    data,
  )

export const updateBaseFunnelStepConfiguration = (
  id: FunnelStepId,
  data: BaseFunnelStepConfigurationEditInterface,
) => httpClient.put<BaseFunnelStepConfigurationInterface>(`${FUNNELS_API}/steps/${id}`, data)
