import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import StepConfigurationTabLayout from 'modules/funnels/funnel/components/step-configuration-tab/step-configuration-tab-layout'
import { useEmbeddedFormFields } from 'modules/funnels/funnel/hooks/use-embedded-form-fields'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useRemoteFormSettings } from 'modules/funnels/funnel/hooks/use-remote-form-settings'
import { useResetFunnelStepPageTemplate } from 'modules/funnels/funnel/hooks/use-reset-funnel-step-page-template'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  RemoteFormEditInterface,
  RemoteFormFieldErrorResponseContentInterface,
  RemoteFormFieldErrorsInterface,
  RemoteFormInterface,
} from 'modules/funnels/funnel/types/remote-form-interface'
import {
  getFunnelStepPageEditorUrl,
  getRemotePagePreviewUrl,
} from 'modules/funnels/funnel/utils/page-utils'
import {
  getEmbeddedFormHtmlCode,
  getScriptTagHtmlCode,
} from 'modules/funnels/funnel/utils/remote-form-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import LinkShareModal from 'shared/components/link-share-modal'
import { Loader } from 'shared/components/loader'
import { FunnelStepTypeEnum } from 'shared/enums/funnel-step-type-enum'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import LinkIcon from 'shared/icons/link-icon'
import { useFunnelStepsList } from '../../../hooks/use-funnel-steps-list'
import {
  useBaseConfigurationTabFieldState,
  useUpdateBaseConfigurationTab,
} from '../../../hooks/use-update-base-configuration-tab'
import EmbeddedFormIcon from '../../../icons/embedded-form-icon'
import { getFunnelSettingsFieldStateStatusIcon } from '../../../utils/get-funnel-settings-field-status-icon'
import CreateRemotePopupLinkModal from './create-remote-popup-link-modal'

const defaultValues: RemoteFormEditInterface = {
  name: '',
}

const defaultErrors: RemoteFormFieldErrorsInterface = {
  name: '',
}

interface RemoteFormConfigurationTabProps {
  funnelStep: FunnelStepInterface
  funnelId: FunnelId
}

function RemoteFormConfigurationTab({ funnelStep, funnelId }: RemoteFormConfigurationTabProps) {
  const { remoteFormSettings, mutateRemoteFormSettings } = useRemoteFormSettings(funnelStep.id)
  const { t } = useLocoTranslation()
  const { funnel } = useFunnel(funnelId)
  const { resetFunction } = useResetFunnelStepPageTemplate({
    funnelId,
    funnelStepId: funnelStep.id,
  })
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)

  const [tempState, setTempState] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)
  const [openedScriptModal, setOpenedScriptModal] = useState(false)
  const [openedEmbeddedModal, setOpenedEmbeddedModal] = useState(false)
  const [openedLinkModal, setOpenedLinkModal] = useState(false)
  const [shouldFetchEmbeddedForm, setShouldFetchEmbeddedForm] = useState(false)

  const { embeddedFormFields } = useEmbeddedFormFields(shouldFetchEmbeddedForm, funnelStep.page?.id)
  const isScriptModalVisible = !!(
    funnel &&
    funnel.domain.domainName &&
    remoteFormSettings &&
    remoteFormSettings.page.hash
  )

  const { updateFetcher, isFetching } = useUpdateBaseConfigurationTab<RemoteFormInterface>({
    funnelStepId: funnelStep.id,
    funnelId: funnelId,
    updateUrl: `${FUNNELS_API}/step-remote-form/${funnelStep.id}`,
  })

  const isPopup = funnelStep.type === FunnelStepTypeEnum.Popup
  const [savedState, setSavedState] = useState(defaultValues)

  const { isNameChanged, successState, setSuccessState } = useBaseConfigurationTabFieldState({
    savedState: savedState,
    currentState: tempState,
  })

  const handleSave = async () => {
    try {
      setErrors(defaultErrors)
      await updateFetcher<RemoteFormEditInterface>(tempState, async () => {
        setSuccessState(prev => {
          return {
            ...prev,
            name: isNameChanged,
          }
        })
        setSavedState(prev => {
          return {
            ...prev,
            name: isNameChanged,
          }
        })
        await mutateRemoteFormSettings(data => {
          if (data) {
            return {
              ...data,
              name: tempState.name,
            }
          }
        }, false)
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(dataFunnelStep => {
              if (dataFunnelStep.id === funnelStep.id) {
                return {
                  ...dataFunnelStep,
                  name: tempState.name,
                }
              }
              return dataFunnelStep
            })
          }
        }, false)
      })
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.fields) {
          const errors = e.errors.fields as unknown as RemoteFormFieldErrorResponseContentInterface
          setErrors(prev => ({
            ...prev,
            name: errors.name?.join(''),
          }))
        }
      }
    }
  }

  useEffect(() => {
    if (remoteFormSettings) {
      setTempState(prev => {
        return {
          ...prev,
          name: remoteFormSettings.name,
        }
      })
      setSavedState(prev => {
        return {
          ...prev,
          name: remoteFormSettings.name,
        }
      })
    }
  }, [remoteFormSettings])

  if (!remoteFormSettings || !funnel || !funnelStep.page) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  return (
    <StepConfigurationTabLayout
      additionalAction={
        <PrimaryButton className="!justify-start" onClick={() => setOpenedEmbeddedModal(true)}>
          <EmbeddedFormIcon className={'w-[18px] h-[18px] fill-white'} />
          <span className="text-start font-inter font-normal">
            {t('dashboard.funnel.configuration.remote.embedded_form')}
          </span>
        </PrimaryButton>
      }
      viewLinkUrl={getRemotePagePreviewUrl(funnelStep.page.id)}
      editLinkUrl={getFunnelStepPageEditorUrl(funnelStep.page.id)}
      onChangeTemplate={resetFunction}
    >
      <div className="flex flex-col gap-5">
        <FormInput
          required
          label={t('global.name')}
          labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
          value={tempState.name}
          onChange={e =>
            setTempState(prev => {
              return { ...prev, name: e.target.value }
            })
          }
          onBlur={() => isNameChanged && handleSave()}
          maxLength={256}
          success={successState.name}
          statusIcon={getFunnelSettingsFieldStateStatusIcon(
            isFetching && isNameChanged,
            successState.name,
          )}
          error={errors.name}
        />
        <div className="flex gap-5">
          <PrimaryButton
            className="max-w-fit"
            onClick={() => setOpenedScriptModal(true)}
            disabled={!isScriptModalVisible}
          >
            <span>
              {`</>`} {t('dashboard.funnel.configuration.remote.script')}
            </span>
          </PrimaryButton>
          {isPopup && (
            <PrimaryButton className="max-w-fit" onClick={() => setOpenedLinkModal(true)}>
              <LinkIcon className="fill-white w-[18px]  h-[18px]" />
              <span>{t('dashboard.funnel.configuration.remote.create_link')}</span>
            </PrimaryButton>
          )}
        </div>
        {isScriptModalVisible && (
          <LinkShareModal
            link={getScriptTagHtmlCode(
              funnel.domain.domainName,
              remoteFormSettings.page.hash,
              funnelStep.id,
            )}
            label={''}
            title={t('dashboard.funnel.configuration.remote.script_tag')}
            opened={openedScriptModal}
            onClose={() => setOpenedScriptModal(false)}
          />
        )}
        {funnelStep.page !== null && (
          <LinkShareModal
            link={
              embeddedFormFields
                ? getEmbeddedFormHtmlCode(embeddedFormFields, funnelStep.page.id)
                : ''
            }
            isLoading={!embeddedFormFields}
            label={''}
            title={t('dashboard.funnel.configuration.remote.script_tag')}
            opened={openedEmbeddedModal}
            onClose={() => setOpenedEmbeddedModal(false)}
            onOpen={() => setShouldFetchEmbeddedForm(true)}
            showInTextArea
          />
        )}
        {isPopup && (
          <CreateRemotePopupLinkModal
            opened={openedLinkModal}
            onClose={() => setOpenedLinkModal(false)}
            domainName={funnel.domain.domainName}
            pageHash={remoteFormSettings.page.hash}
            funnelStepId={funnelStep.id}
          />
        )}
      </div>
    </StepConfigurationTabLayout>
  )
}

export default RemoteFormConfigurationTab
