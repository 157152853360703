import ResourcesBase from 'modules/digital-product/resources/components/resources-base'
import ResourcesSelect from 'modules/digital-product/resources/components/resources-select'
import { useDigitalResourceContent } from 'modules/digital-product/resources/hooks/use-digital-resource-content'
import { DigitalResourceEditType } from 'modules/digital-product/resources/types/digital-resource-edit-interface'
import { DigitalResourceType } from 'modules/digital-product/resources/types/digital-resource-interface'
import {
  DigitalProductChangeInterface,
  DigitalProductErrorInterface,
} from 'modules/digital-product/types/digital-product-interface'
import { AddItemIcon } from 'modules/funnels/funnel/components/offer-settings/add-item-icon'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

type Props = {
  resources: DigitalResourceEditType[]
  onChangeResources: (resources: DigitalProductChangeInterface['resources']) => void
  errors: DigitalProductErrorInterface['resources']
}

const isResourceExists = (
  resource: DigitalResourceEditType | DigitalResourceType,
): resource is DigitalResourceType => !!resource.id

function Resources({ resources, onChangeResources, errors }: Props) {
  const { t } = useLocoTranslation()

  const existingResources = resources.filter(isResourceExists)

  const newResources = resources.filter(r => !isResourceExists(r))

  const onDeleteExisting = (id: number) => onChangeResources(resources.filter(r => r.id !== id))

  const existingResourcesData = useDigitalResourceContent(existingResources, onDeleteExisting)

  return (
    <ResourcesBase
      additionalChild={
        <AddItemIcon
          onClick={() => onChangeResources([...resources, { type: undefined }])}
          caption={t('dashboard.funnel.configuration.offer.add_digital_resource')}
        />
      }
    >
      <ResourcesSelect
        errors={errors}
        resources={newResources}
        onChangeResources={newResources =>
          onChangeResources([...existingResources, ...newResources])
        }
        numberOfExistingResources={existingResources.length}
      />
      {existingResourcesData}
    </ResourcesBase>
  )
}

export default Resources
