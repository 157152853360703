import React from 'react'

function EmbeddedFormIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 7.75H1.25V19C1.25 21.071 2.929 22.75 5 22.75H19C21.071 22.75 22.75 21.071 22.75 19V7.75ZM12.488 11.163L10.088 18.363C9.958 18.756 10.17 19.181 10.563 19.312C10.956 19.442 11.381 19.23 11.512 18.837L13.912 11.637C14.042 11.244 13.83 10.819 13.437 10.688C13.044 10.558 12.619 10.77 12.488 11.163ZM14.57 13.13L16.439 15L14.57 16.87C14.277 17.162 14.277 17.638 14.57 17.93C14.862 18.223 15.338 18.223 15.63 17.93L18.03 15.53C18.323 15.237 18.323 14.763 18.03 14.47L15.63 12.07C15.338 11.777 14.862 11.777 14.57 12.07C14.277 12.362 14.277 12.838 14.57 13.13ZM8.37 12.07L5.97 14.47C5.677 14.763 5.677 15.237 5.97 15.53L8.37 17.93C8.662 18.223 9.138 18.223 9.43 17.93C9.723 17.638 9.723 17.162 9.43 16.87L7.561 15L9.43 13.13C9.723 12.838 9.723 12.362 9.43 12.07C9.138 11.777 8.662 11.777 8.37 12.07ZM1.25 6.25H22.75V5C22.75 2.929 21.071 1.25 19 1.25H5C2.929 1.25 1.25 2.929 1.25 5V6.25ZM12.75 3.25C13.164 3.25 13.5 3.586 13.5 4C13.5 4.414 13.164 4.75 12.75 4.75C12.336 4.75 12 4.414 12 4C12 3.586 12.336 3.25 12.75 3.25ZM15.5 3.25C15.914 3.25 16.25 3.586 16.25 4C16.25 4.414 15.914 4.75 15.5 4.75C15.086 4.75 14.75 4.414 14.75 4C14.75 3.586 15.086 3.25 15.5 3.25ZM18.25 3.25C18.664 3.25 19 3.586 19 4C19 4.414 18.664 4.75 18.25 4.75C17.836 4.75 17.5 4.414 17.5 4C17.5 3.586 17.836 3.25 18.25 3.25Z"
      />
    </svg>
  )
}

export default EmbeddedFormIcon
