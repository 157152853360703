import { PhysicalOfferEditInterface } from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/physical-offer-interface'
import { usePhysicalProductsList } from 'modules/funnels/funnel/components/step-configuration-tab/physical-product-selector/hooks/use-physical-products-list'
import { FunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'
import { getProductListData } from 'modules/funnels/funnel/utils/offer-configuration-utils'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import FormSelect from 'shared/components/form-select'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BoxIcon from 'shared/icons/box-icon'
import SaveIcon from 'shared/icons/save-icon'

interface PhysicalProductSelectorProps {
  funnel: FunnelInterface
  product: PhysicalOfferEditInterface['product']
  onSave: (data: PhysicalOfferEditInterface['product']) => Promise<void>
}

function PhysicalProductSelector({ funnel, product, onSave }: PhysicalProductSelectorProps) {
  const [tempState, setTempState] = useState(product)
  const { physicalProductsList } = usePhysicalProductsList(funnel.currency)
  const { t } = useLocoTranslation()
  const [hasChanged, setHasChanged] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    setTempState(product)
  }, [product])

  const handleSave = async () => {
    if (!physicalProductsList) return
    setIsFetching(true)
    await onSave(tempState)
    setHasChanged(false)
    setIsFetching(false)
  }

  if (!physicalProductsList)
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )

  return (
    <div className="flex flex-col gap-5 bo-color-[#F8FAFC]">
      <div className={'flex flex-col gap-3'}>
        <div className="flex flex-row gap-1 lg:text-[15px] text-sm text-blue items-center">
          <BoxIcon />
          <span>{t('dashboard.funnel.configuration.offer.physical_product')}</span>
        </div>
        <span className="text-sm font-normal">
          {t('dashboard.funnel.configuration.offer.physical_product_description')}
        </span>
      </div>
      <FormSelect
        data={getProductListData(physicalProductsList)}
        className={'sm:min-w-0'}
        value={tempState || undefined}
        onChange={data => {
          setTempState(data)
          if (data !== tempState) {
            setHasChanged(true)
          }
        }}
      />
      {hasChanged && (
        <PrimaryButton
          width="large"
          onClick={e => {
            e.preventDefault()
            handleSave()
          }}
          isFetching={isFetching}
          type="submit"
          className="mr-auto"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      )}
    </div>
  )
}

export default PhysicalProductSelector
