import {
  finishSplitTest,
  removeSplitTestVariant,
  startSplitTest,
} from 'modules/funnels/funnel/api/funnel-api'
import ChooseTestVariantModal from 'modules/funnels/funnel/components/split-tests-tab/choose-test-variant-modal'
import SplitTestItem from 'modules/funnels/funnel/components/split-tests-tab/split-test-item'
import TabTable from 'modules/funnels/funnel/components/tab-table'
import { SplitTestComponentTypeEnum } from 'modules/funnels/funnel/enums/split-tests-component-type-enum'
import { useFunnelStepTests } from 'modules/funnels/funnel/hooks/use-funnel-step-tests'
import { useFunnelStepTestsHistory } from 'modules/funnels/funnel/hooks/use-funnel-step-tests-history'
import { useFunnelStepsList } from 'modules/funnels/funnel/hooks/use-funnel-steps-list'
import { useSplitTestTableColumns } from 'modules/funnels/funnel/hooks/use-split-test-table-columns'
import { useUpdateFunnelStepHash } from 'modules/funnels/funnel/hooks/use-update-funnel-step-hash'
import PlayIcon from 'modules/funnels/funnel/icons/play-icon'
import StopIcon from 'modules/funnels/funnel/icons/stop-icon'
import TestsIcon from 'modules/funnels/funnel/icons/tests-icon'
import TrashIcon from 'modules/funnels/funnel/icons/trash-icon'
import {
  FunnelStepId,
  FunnelStepInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  NotStartedSplitTestInterface,
  SplitTestInterface,
  SplitTestType,
} from 'modules/funnels/funnel/types/funnel-tests-interface'
import {
  getRunningTestTableData,
  isSplitTestIsEmptyArray,
  isSplitTestIsNotStarted,
  isSplitTestIsStarted,
} from 'modules/funnels/funnel/utils/split-tests-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { NeutralButton } from 'shared/components/button'
import { PrimaryButton } from 'shared/components/button'
import Confirmation from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface SplitTestActionItemProps {
  data: SplitTestType
  funnelId: FunnelId
  funnelStep: FunnelStepInterface
}

function SplitTestActions({ data, funnelId, funnelStep }: SplitTestActionItemProps) {
  const { t } = useLocoTranslation()
  const [isDeleteVariantOpen, setIsDeleVariantOpen] = useState(false)
  const [isEndSplitTestOpen, setIsEndSplitTestOpen] = useState(false)
  const { mutateSplitTest } = useFunnelStepTests(funnelStep.id)
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)
  const { mutateHistoryData } = useFunnelStepTestsHistory(funnelStep.id)
  const columns = useSplitTestTableColumns({
    withEndDate: false,
  })
  const { updateFunnelStepHash } = useUpdateFunnelStepHash()

  const [isFetching, setIsFetching] = useState(false)

  const handleDeleteVariant = async () => {
    const castedData = data as NotStartedSplitTestInterface
    setIsFetching(true)
    await removeSplitTestVariant(funnelStep.id)
    await mutateFunnelStepsList(data => {
      if (data) {
        return data.map(funnelStep => {
          if (
            funnelStep.id === castedData.funnelStepA.id ||
            funnelStep.id === castedData.funnelStepB.id
          ) {
            return { ...funnelStep, inRunningSplitTest: false, currentSplitTest: null }
          }
          if (funnelStep.id === castedData.goalFunnelStep.id) {
            return { ...funnelStep, goalFunnelStepOfAnySplitTest: false, currentSplitTest: null }
          }
          return funnelStep
        })
      }
    }, false)
    await mutateSplitTest([], false)
    setIsFetching(false)
  }

  const handleEndSplitTest = async () => {
    setIsFetching(true)
    const castedData = data as SplitTestInterface
    const response = await finishSplitTest(funnelStep.id)
    await mutateHistoryData(response.data, false)
    await mutateFunnelStepsList(data => {
      if (data) {
        return data.map(funnelStep => {
          if (
            funnelStep.id === castedData.splitTest.funnelStepA.id ||
            funnelStep.id === castedData.splitTest.funnelStepB.id
          ) {
            return { ...funnelStep, inRunningSplitTest: false, currentSplitTest: null }
          }
          if (funnelStep.id === castedData.splitTest.goalFunnelStep.id) {
            return { ...funnelStep, goalFunnelStepOfAnySplitTest: false, currentSplitTest: null }
          }
          return funnelStep
        })
      }
    }, false)
    await mutateSplitTest([], false)
    setIsFetching(false)
  }

  const handleStartSplitTest = async () => {
    try {
      setIsFetching(true)
      const response = await startSplitTest(funnelStep.id)
      await mutateSplitTest(response.data, false)
      await mutateFunnelStepsList(data => {
        if (data) {
          return data.map(funnelStep => {
            if (
              funnelStep.id === response.data.splitTest.funnelStepA.id ||
              funnelStep.id === response.data.splitTest.funnelStepB.id
            ) {
              return {
                ...funnelStep,
                inRunningSplitTest: true,
                currentSplitTest: {
                  id: response.data.splitTest.id,
                  funnelStepA: {
                    id: response.data.splitTest.funnelStepA.id,
                    page: response.data.splitTest.funnelStepA.page,
                  },
                  funnelStepB: {
                    id: response.data.splitTest.funnelStepB.id,
                    page: response.data.splitTest.funnelStepB.page,
                  },
                },
              }
            }
            return funnelStep
          })
        }
      }, false)
    } catch (error) {
      toast.error(t('global.error'))
    } finally {
      setIsFetching(false)
    }
  }

  const handleChangeFunnelStepConfigurationTab = (id: FunnelStepId) => {
    updateFunnelStepHash(`#step${id}`)
  }

  if (isSplitTestIsEmptyArray(data)) {
    return (
      <div className="flex mx-auto lg:gap-10 gap-3 lg:px-0 px-4">
        <SplitTestItem type={SplitTestComponentTypeEnum.original} funnelStep={funnelStep} />
        <div className="flex flex-col gap-4 font-inter text-darkblue items-center justify-center flex-[33_1_0%] lg:text-lg text-[15px]">
          <TestsIcon active={false} className="lg:w-[121px] lg:h-[104px] w-[85px] h-[73px]" />
          <div className="font-bold">{t('dashboard.funnel.split_tests.start_test')}</div>
          <div className="opacity-60 text-center lg:text-base text-sm">
            {t('dashboard.funnel.split_tests.create_test_description')}
          </div>
          <ChooseTestVariantModal funnelId={funnelId} funnelStep={funnelStep} />
        </div>
      </div>
    )
  }

  if (isSplitTestIsNotStarted(data)) {
    const castedData = data as NotStartedSplitTestInterface
    return (
      <>
        <div className="flex flex-col gap-5">
          <div className="flex flex-wrap lg:mx-auto gap-10 ">
            <SplitTestItem
              type={SplitTestComponentTypeEnum.original}
              funnelStep={castedData.funnelStepA}
              className="order-1"
            />
            <div className="flex flex-col gap-4 font-inter text-darkblue lg:text-lg text-base items-center justify-center flex-[33_1_0%] xl:order-2 order-3 w-full">
              <div className="font-bold ">{t('dashboard.funnel.split_tests.start_test_title')}</div>
              <div className="opacity-60 text-center lg:text-base text-sm">
                {t('dashboard.funnel.split_tests.start_test_description')}
              </div>
              <div className="flex gap-2">
                <PrimaryButton onClick={handleStartSplitTest} isFetching={isFetching}>
                  <PlayIcon />
                  {t('dashboard.funnel.split_tests.start_test')}
                </PrimaryButton>
                <NeutralButton
                  className={
                    '[&_svg]:hover:fill-red hover:border-red [&_svg]:main-transition-colors'
                  }
                  onClick={() => setIsDeleVariantOpen(true)}
                >
                  <TrashIcon />
                </NeutralButton>
              </div>
            </div>
            <SplitTestItem
              type={SplitTestComponentTypeEnum.variant}
              funnelStep={castedData.funnelStepB}
              className="xl:order-3 order-2"
            />
          </div>
          <hr className={'color-darkblue opacity-10 w-full'} />
          <div className="flex flex-col gap-4 font-inter text-darkblue lg:text-base text-[15px] items-center justify-start">
            <div className="flex flex-col gap-1 justify-center items-center">
              <div className="opacity-60">{t('dashboard.funnel.split_tests.goal_funnel_step')}</div>
              <div
                className="font-bold cursor-pointer"
                onClick={() => handleChangeFunnelStepConfigurationTab(castedData.goalFunnelStep.id)}
              >
                {castedData.goalFunnelStep.name}
              </div>
            </div>
          </div>
        </div>
        <Confirmation
          confirmationText={t('dashboard.funnel.split_tests.remove_variant_confirmation')}
          opened={isDeleteVariantOpen}
          onClose={() => setIsDeleVariantOpen(false)}
          onConfirm={handleDeleteVariant}
          toastCaption={t('dashboard.funnel.split_tests.deleted_variant_toast')}
        />
      </>
    )
  }

  if (isSplitTestIsStarted(data)) {
    const castedData = data as SplitTestInterface
    const tableData = getRunningTestTableData(castedData)

    return (
      <>
        <div className="flex flex-col gap-5">
          <div className="flex flex-wrap lg:mx-auto gap-10 ">
            <SplitTestItem
              type={SplitTestComponentTypeEnum.original}
              funnelStep={castedData.splitTest.funnelStepA}
              className={'order-1'}
            />
            <div className="flex flex-col gap-4 min-w-max xl:order-2 order-3 lg:w-auto w-full justify-center items-center flex-1">
              <div className="flex flex-col gap-4 font-inter text-darkblue lg:text-lg text-base items-center justify-center flex-[33_1_0%]">
                <div className="font-bold text-center">
                  {t('dashboard.funnel.split_tests.started')}
                </div>
                <PrimaryButton onClick={() => setIsEndSplitTestOpen(true)}>
                  <StopIcon />
                  {t('dashboard.funnel.split_tests.end_test')}
                </PrimaryButton>
              </div>
            </div>
            <SplitTestItem
              type={SplitTestComponentTypeEnum.variant}
              funnelStep={castedData.splitTest.funnelStepB}
              className={'xl:order-3 order-2'}
            />
          </div>
          <hr className={'color-darkblue opacity-10 w-full'} />
          <div className="flex flex-col gap-4 font-inter text-darkblue lg:text-base text-[15px] items-center justify-start">
            <div className="flex flex-col gap-1 justify-center items-center">
              <div className="opacity-60">{t('dashboard.funnel.split_tests.goal_funnel_step')}</div>
              <div
                className="font-bold cursor-pointer"
                onClick={() =>
                  handleChangeFunnelStepConfigurationTab(castedData.splitTest.goalFunnelStep.id)
                }
              >
                {castedData.splitTest.goalFunnelStep.name}
              </div>
            </div>
          </div>
        </div>
        <Confirmation
          confirmationText={t('dashboard.funnel.split_tests.finish_test_confirmation')}
          opened={isEndSplitTestOpen}
          onClose={() => setIsEndSplitTestOpen(false)}
          onConfirm={handleEndSplitTest}
          toastCaption={t('dashboard.funnel.split_tests.finish_test_toast')}
        />
        <div className="lg:px-8">
          <TabTable columns={columns} data={tableData} passDataToHeader />
        </div>
      </>
    )
  }
  return <></>
}

export default SplitTestActions
