import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRulesTriggersEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { FunnelStepId } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'

export interface FunnelTriggerDataInterface {
  funnelId: FunnelId
  funnelName: string
  funnelStepId: FunnelStepId
  funnelStepName: string
  webinarId?: number
}
export const fillTriggerWithFunnelData = (
  trigger: AutomationRulesTriggersEditType,
  data: FunnelTriggerDataInterface,
): AutomationRulesTriggersEditType => {
  switch (trigger.type) {
    case AutomationRuleTriggersEnum.form_subscribed:
    case AutomationRuleTriggersEnum.new_sale:
    case AutomationRuleTriggersEnum.page_visited:
    case AutomationRuleTriggersEnum.sale_canceled:
    case AutomationRuleTriggersEnum.subscription_payment_failed:
      return {
        ...trigger,
        funnelStep: {
          id: data.funnelStepId,
          name: data.funnelStepName,
          funnel: { id: data.funnelId, name: data.funnelName },
        },
      }
    case AutomationRuleTriggersEnum.registered_to_webinar:
      return {
        ...trigger,
        webinar: {
          id: data.webinarId,
          registrationFunnelStep: {
            id: data.funnelStepId,
            name: data.funnelStepName,
            funnel: { id: data.funnelId, name: data.funnelName },
          },
        },
      }
    default:
      return trigger
  }
}
