import { PrimaryButton } from 'shared/components/button'
import { TLocoType } from 'shared/hooks/use-loco-translation'
import EyeIcon from 'shared/icons/eye-icon'
import { FunnelInterface } from '../types/funnel-interface'
import { FunnelStepInterface } from '../types/funnel-step-interface'
import {
  getFunnelStepPageUrl,
  getFunnelStepPreviewUrl,
  getSplitTestFunnelStepPreviewUrl,
} from './page-utils'

interface FunnelStepPreviewDataProps {
  funnelStep: FunnelStepInterface
  setOpen: (value: boolean) => void
  funnel: FunnelInterface
  t: TLocoType
  showPreview?: boolean
  previewLinkWithQuery?: boolean
}
export const getFunnelStepPreviewData = ({
  funnelStep,
  setOpen,
  funnel,
  t,
  showPreview,
  previewLinkWithQuery,
}: FunnelStepPreviewDataProps) => {
  let customViewButton
  let funnelStepAPreviewLink = ''
  let funnelStepBPreviewLink = ''
  const isSplitTestPreview = funnelStep.currentSplitTest?.id && funnelStep.inRunningSplitTest

  if (
    funnelStep.currentSplitTest &&
    funnelStep.currentSplitTest.id &&
    funnelStep.inRunningSplitTest
  ) {
    customViewButton = (
      <PrimaryButton
        className="!justify-start"
        data-test-element={'view-funnel-step-button'}
        onClick={() => setOpen(true)}
      >
        <EyeIcon className={'w-[20px] h-[20px] fill-white'} />
        <span className="text-start">
          {t('dashboard.funnel.configuration.common.view_funnel_step')}
        </span>
      </PrimaryButton>
    )

    if (previewLinkWithQuery) {
      funnelStepAPreviewLink = getSplitTestFunnelStepPreviewUrl(
        funnel.domain.domainName,
        funnelStep.currentSplitTest.funnelStepA.page.path,
        funnelStep.inRunningSplitTest,
      )
      funnelStepBPreviewLink = getSplitTestFunnelStepPreviewUrl(
        funnel.domain.domainName,
        funnelStep.currentSplitTest.funnelStepB.page.path,
        funnelStep.inRunningSplitTest,
      )
    } else {
      funnelStepAPreviewLink = showPreview
        ? getFunnelStepPreviewUrl(
            funnel.domain.domainName,
            funnelStep.currentSplitTest.funnelStepA.page.path,
          )
        : getFunnelStepPageUrl(
            funnel.domain.domainName,
            funnelStep.currentSplitTest.funnelStepA.page.path,
          )
      funnelStepBPreviewLink = showPreview
        ? getFunnelStepPreviewUrl(
            funnel.domain.domainName,
            funnelStep.currentSplitTest.funnelStepB.page.path,
          )
        : getFunnelStepPageUrl(
            funnel.domain.domainName,
            funnelStep.currentSplitTest.funnelStepB.page.path,
          )
    }
  }

  return { customViewButton, funnelStepAPreviewLink, funnelStepBPreviewLink, isSplitTestPreview }
}
