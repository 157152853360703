import React from 'react'

function WebinarBroadcastFunnelStepIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 7.49716V19.5025C27 19.8979 26.5636 20.1341 26.2334 19.9196L20.7828 16.371C20.6188 16.2649 20.5176 16.0793 20.5176 15.884V11.1181C20.5176 10.9204 20.6164 10.7372 20.7828 10.6311L26.2334 7.08252C26.5636 6.86797 27 7.10422 27 7.49957V7.49716Z"
        fill="#CBD5E1"
      />
      <path
        d="M16.8604 22.2003V21.8291H2.54077V22.2003C2.54077 22.6126 2.98193 22.9453 3.52434 22.9453H15.8793C16.4217 22.9453 16.8628 22.6126 16.8628 22.2003H16.8604Z"
        fill="#CBD5E1"
      />
      <path
        d="M16.8604 4.7996V5.17085H2.54077V4.7996C2.54077 4.38737 2.98193 4.05469 3.52434 4.05469H15.8793C16.4217 4.05469 16.8628 4.38737 16.8628 4.7996H16.8604Z"
        fill="#CBD5E1"
      />
      <path
        d="M18.1021 5.89404H1.29937C0.580982 5.89404 0 6.47744 0 7.19342V19.8063C0 20.5247 0.580982 21.1057 1.29937 21.1057H18.1021C18.818 21.1057 19.4014 20.5247 19.4014 19.8063V7.19342C19.4014 6.47744 18.818 5.89404 18.1021 5.89404ZM9.6983 17.2919C7.60821 17.2919 5.90866 15.5899 5.90866 13.4998C5.90866 11.4098 7.60821 9.7102 9.6983 9.7102C11.7884 9.7102 13.4879 11.4098 13.4904 13.4974C13.4904 15.5899 11.7908 17.2919 9.6983 17.2919Z"
        fill="#CBD5E1"
      />
      <path
        d="M9.69828 10.665C8.13132 10.665 6.86328 11.9331 6.86328 13.5C6.86328 15.067 8.13132 16.3374 9.69828 16.3374C11.2652 16.3374 12.5357 15.067 12.5357 13.5C12.5333 11.9331 11.2652 10.665 9.69828 10.665ZM9.13176 14.7753V12.2248L10.8337 13.5L9.13176 14.7753Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default WebinarBroadcastFunnelStepIcon
