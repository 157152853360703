import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { getFunnelStepConfigurationTabContent } from 'modules/funnels/funnel/utils/get-funnel-step-configuration-tab-content'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React from 'react'
import { Loader } from 'shared/components/loader'

interface StepConfigurationTabProps {
  funnelId: FunnelId
  funnelStep: FunnelStepInterface | null
  isMobile?: boolean
  exitMobileTab?: () => void
}

function StepConfigurationTab({ funnelId, funnelStep }: StepConfigurationTabProps) {
  if (!funnelStep) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  return <>{getFunnelStepConfigurationTabContent(funnelStep, funnelId)}</>
}

export default StepConfigurationTab
