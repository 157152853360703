import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import {
  DeadlineSettingsEditInterface,
  DeadlineSettingsInterface,
} from 'modules/funnels/funnel/types/deadline-settings-interface'
import {
  CreateFunnelStepInterface,
  FunnelStepId,
  FunnelStepInterface,
} from 'modules/funnels/funnel/types/funnel-step-interface'
import {
  ChooseSplitTestVariantInterface,
  NotStartedSplitTestInterface,
  SplitTestHistoryItemInterface,
  SplitTestInterface,
} from 'modules/funnels/funnel/types/funnel-tests-interface'
import { CreateInactivePageResponseInterface } from 'modules/funnels/funnel/types/inactive-page-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import httpClient from 'shared/utils/http-client'

export const FUNNEL_STEP_API = '/api/dashboard/customer/funnel-step'

export const changeFunnelStepPosition = (id: FunnelStepId, data: { newPosition: number }) =>
  httpClient.post<FunnelStepInterface>(`${FUNNELS_API}/steps/${id}/change-position`, data)

export const moveFunnelStep = (id: FunnelStepId, data: { funnel?: number }) =>
  httpClient.post<FunnelStepInterface>(`${FUNNELS_API}/steps/${id}/move`, data)

export const duplicateFunnelStep = (id: FunnelStepId) =>
  httpClient.post<FunnelStepInterface>(`${FUNNELS_API}/steps/${id}/duplicate`)

export const removeFunnelStep = (id: FunnelStepId) =>
  httpClient.delete<FunnelStepInterface>(`${FUNNELS_API}/steps/${id}`)

export const addFunnelStep = (id: FunnelId, data: CreateFunnelStepInterface) =>
  httpClient.post<FunnelStepInterface>(`${FUNNELS_API}/${id}/steps`, data)

export const chooseSplitTestVariant = (
  funnelStepId: FunnelStepId,
  data: ChooseSplitTestVariantInterface,
) =>
  httpClient.post<NotStartedSplitTestInterface>(
    `${FUNNELS_API}/steps/${funnelStepId}/split-test-variant`,
    data,
  )

export const removeSplitTestVariant = (funnelStepId: FunnelStepId) =>
  httpClient.delete<[]>(`${FUNNELS_API}/steps/${funnelStepId}/split-test-variant`)

export const removeSplitTest = (funnelStepId: FunnelStepId, historyId: number) =>
  httpClient.delete<SplitTestHistoryItemInterface[]>(
    `${FUNNELS_API}/steps/${funnelStepId}/split-test/${historyId}`,
  )

export const startSplitTest = (funnelStepId: FunnelStepId) =>
  httpClient.post<SplitTestInterface>(`${FUNNELS_API}/steps/${funnelStepId}/split-test/start`)

export const finishSplitTest = (funnelStepId: FunnelStepId) =>
  httpClient.post<SplitTestHistoryItemInterface[]>(
    `${FUNNELS_API}/steps/${funnelStepId}/split-test/finish`,
  )

export const updateFunnelDeadlineSettings = (id: FunnelId, data: DeadlineSettingsEditInterface) =>
  httpClient.post<DeadlineSettingsInterface>(`${FUNNELS_API}/${id}/deadline`, data)

export const createInactivePage = (id: FunnelId) =>
  httpClient.post<CreateInactivePageResponseInterface>(`${FUNNELS_API}/create-inactive-page/${id}`)
