import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import {
  FunnelStatisticsInterface,
  FunnelStatisticsSwrInterface,
} from 'modules/funnels/funnel/types/funnel-statistics-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnelStatistics({ filter, funnelId }: FunnelStatisticsSwrInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    FunnelStatisticsInterface
  >({ method: RequestMethodsEnum.get })

  const filterApi = `${filter.date[0] ? `&filter[dateSince]=${filter.date[0]}` : ''}${
    filter.date[1] ? `&filter[dateTill]=${filter.date[1]}` : ''
  }`

  const { data: statistics } = useSWR(() => {
    if (user) {
      return `${FUNNELS_API}/${funnelId}/stats?${filterApi}`
    }
    return null
  }, fetcher)

  return { statistics }
}
