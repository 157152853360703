import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useResetFunnelStepPageTemplate } from 'modules/funnels/funnel/hooks/use-reset-funnel-step-page-template'
import { useWebinarRegistrationSettings } from 'modules/funnels/funnel/hooks/use-webinar-registration-settings'
import { FunnelStepInterface } from 'modules/funnels/funnel/types/funnel-step-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import FormInput from 'shared/components/form-input'
import FormUrlPathInput from 'shared/components/form-url-path-input'
import { Hint } from 'shared/components/hint'
import { Loader } from 'shared/components/loader'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useFunnelStepsList } from '../../hooks/use-funnel-steps-list'
import {
  useBaseConfigurationTabFieldState,
  useUpdateBaseConfigurationTab,
} from '../../hooks/use-update-base-configuration-tab'
import {
  WebinarRegistrationEditErrorsInterface,
  WebinarRegistrationEditInterface,
  WebinarRegistrationFieldErrorResponseContentInterface,
  WebinarRegistrationInterface,
} from '../../types/webinar-registration-interface'
import { getFunnelSettingsFieldStateStatusIcon } from '../../utils/get-funnel-settings-field-status-icon'
import { getFunnelStepPreviewData } from '../../utils/get-funnel-step-split-test-preview-data'
import { getFunnelStepPageEditorUrl, getFunnelStepPageUrl } from '../../utils/page-utils'
import SplitTestPreviewStepModal from './split-test-preview-step-modal'
import StepConfigurationTabLayout from './step-configuration-tab-layout'

const defaultValues: WebinarRegistrationEditInterface = {
  name: '',
  page: {
    path: '',
  },
  webinar: {
    duration: 0,
  },
}

const defaultErrors: WebinarRegistrationEditErrorsInterface = {
  name: '',
  page: {
    path: '',
  },
  webinar: {
    duration: '',
  },
}

interface WebinarRegistrationConfigurationTabProps {
  funnelStep: FunnelStepInterface
  funnelId: FunnelId
}

function WebinarRegistrationConfigurationTab({
  funnelStep,
  funnelId,
}: WebinarRegistrationConfigurationTabProps) {
  const { webinarRegistrationSettings, mutateWebinarRegistrationSettings } =
    useWebinarRegistrationSettings(funnelStep.id)
  const { resetFunction } = useResetFunnelStepPageTemplate({
    funnelId,
    funnelStepId: funnelStep.id,
  })
  const { mutateFunnelStepsList } = useFunnelStepsList(funnelId)

  const { t } = useLocoTranslation()
  const { funnel } = useFunnel(funnelId)
  const [tempState, setTempState] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)
  const [splitTestPreviewOpen, setSplitTestPreviewOpen] = useState(false)
  const [savedState, setSavedState] = useState(defaultValues)

  const { updateFetcher, isFetching } = useUpdateBaseConfigurationTab<WebinarRegistrationInterface>(
    {
      funnelStepId: funnelStep.id,
      funnelId: funnelId,
      updateUrl: `${FUNNELS_API}/steps/${funnelStep.id}/webinar-registration`,
    },
  )

  const {
    isNameChanged,
    isPagePathChanged,
    isWebinarDurationChanged,
    successState,
    setSuccessState,
  } = useBaseConfigurationTabFieldState({ savedState: savedState, currentState: tempState })

  const handleSave = async () => {
    try {
      setErrors(defaultErrors)
      await updateFetcher(tempState, async () => {
        setSuccessState(prev => {
          return {
            ...prev,
            name: isNameChanged,
            path: isPagePathChanged,
            webinarDuration: isWebinarDurationChanged,
          }
        })
        setSavedState({ ...tempState })
        await mutateWebinarRegistrationSettings(data => {
          if (data) {
            return {
              ...data,
              name: tempState.name,
              page: {
                ...data.page,
                path: tempState.page.path,
              },
              webinar: {
                ...data.webinar,
                duration: tempState.webinar.duration,
              },
            }
          }
        }, false)
        await mutateFunnelStepsList(data => {
          if (data) {
            return data.map(dataFunnelStep => {
              if (dataFunnelStep.id === funnelStep.id) {
                return {
                  ...dataFunnelStep,
                  name: tempState.name,
                }
              }
              return dataFunnelStep
            })
          }
        }, false)
      })
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.common.length !== 0) {
          toast.error(e.errors.common.join(''))
        }
        if (e.errors.fields) {
          const errors = e.errors
            .fields as unknown as WebinarRegistrationFieldErrorResponseContentInterface
          setErrors(prev => ({
            ...prev,
            name: errors.name?.join(''),
            page: { path: errors.page?.path?.join('') },
            webinar: { duration: errors.webinar?.duration?.join('') },
          }))
        }
      }
    }
  }

  useEffect(() => {
    if (webinarRegistrationSettings) {
      setTempState(prev => {
        return {
          ...prev,
          name: webinarRegistrationSettings.name,
          page: {
            path: webinarRegistrationSettings.page.path,
          },
          webinar: {
            duration: webinarRegistrationSettings.webinar.duration,
          },
        }
      })
      setSavedState(prev => {
        return {
          ...prev,
          name: webinarRegistrationSettings.name,
          page: {
            path: webinarRegistrationSettings.page.path,
          },
          webinar: {
            duration: webinarRegistrationSettings.webinar.duration,
          },
        }
      })
    }
  }, [webinarRegistrationSettings])

  if (!webinarRegistrationSettings || !funnel || !funnelStep.page) {
    return (
      <div className={'h-full flex items-center justify-center py-12'}>
        <Loader className={'scale-150'} />
      </div>
    )
  }

  const { customViewButton, funnelStepAPreviewLink, funnelStepBPreviewLink, isSplitTestPreview } =
    getFunnelStepPreviewData({
      funnelStep,
      setOpen: setSplitTestPreviewOpen,
      funnel,
      t,
      previewLinkWithQuery: true,
    })

  return (
    <>
      <StepConfigurationTabLayout
        viewLinkUrl={getFunnelStepPageUrl(
          funnel.domain.domainName,
          webinarRegistrationSettings.page.path,
        )}
        editLinkUrl={getFunnelStepPageEditorUrl(funnelStep.page.id)}
        onChangeTemplate={resetFunction}
        customViewButton={customViewButton}
      >
        <div className="flex flex-col gap-5">
          <FormInput
            required
            label={t('global.name')}
            labelClassName={'lg:text-[15px] font-medium text-sm text-darkblue'}
            value={tempState.name}
            onChange={e =>
              setTempState(prev => {
                return { ...prev, name: e.target.value }
              })
            }
            onBlur={() => isNameChanged && handleSave()}
            maxLength={256}
            success={successState.name}
            statusIcon={getFunnelSettingsFieldStateStatusIcon(
              isFetching && isNameChanged,
              successState.name,
            )}
            error={errors.name}
          />
          <FormInput
            label={
              <Hint
                label={t('dashboard.funnel.configuration.webinar.duration_popover')}
                className={'lg:text-[15px] font-medium text-sm text-darkblue'}
              >
                {t('dashboard.funnel.configuration.webinar.duration')}
              </Hint>
            }
            type="number"
            value={tempState.webinar?.duration}
            onChange={e =>
              setTempState(prev => {
                return { ...prev, webinar: { duration: parseInt(e.target.value) || 0 } }
              })
            }
            onBlur={() => isWebinarDurationChanged && handleSave()}
            maxLength={256}
            success={successState.webinarDuration}
            statusIcon={getFunnelSettingsFieldStateStatusIcon(
              isFetching && isWebinarDurationChanged,
              successState.webinarDuration,
            )}
            error={errors.webinar.duration}
          />
          <FormUrlPathInput
            required
            copyButtonClassName={`border border-gray/30 hover:border-blue group !bg-white [&>*]:group-hover:!fill-blue ${
              successState.path && 'border-green'
            }`}
            copyIconClassName="fill-gray group-hover:fill-blue main-transition-colors"
            value={tempState.page?.path}
            domainName={funnel?.domain.domainName}
            label={
              <Hint
                label={t('dashboard.funnel.configuration.webinar.url_path_popover')}
                className={'lg:text-[15px] font-medium text-sm text-darkblue'}
              >
                {t('global.url_path')}
              </Hint>
            }
            onChange={e =>
              setTempState(prev => {
                return { ...prev, page: { path: e.target.value } }
              })
            }
            onBlur={() => isPagePathChanged && handleSave()}
            disabled={funnelStep.inRunningSplitTest}
            success={successState.path}
            statusIcon={getFunnelSettingsFieldStateStatusIcon(
              isFetching && isPagePathChanged,
              successState.path,
            )}
            error={errors.page.path}
          />
        </div>
      </StepConfigurationTabLayout>
      {isSplitTestPreview && (
        <SplitTestPreviewStepModal
          open={splitTestPreviewOpen}
          setOpen={setSplitTestPreviewOpen}
          stepAPreviewLink={funnelStepAPreviewLink}
          stepBPreviewLink={funnelStepBPreviewLink}
        />
      )}
    </>
  )
}

export default WebinarRegistrationConfigurationTab
