import { CouponInterface, DiscountTypeInterface } from 'modules/coupons/types/coupon-interface'
import { TLocoType } from 'shared/hooks/use-loco-translation'

interface CouponDataInterface {
  id: number
  caption: string
}

export const getAvailableCouponsData = (
  coupons?: CouponInterface[],
  type?: CouponInterface['discountType'],
) => {
  return coupons
    ?.filter(coupon => {
      if (!type) {
        return true
      }
      return coupon.discountType === type
    })
    .map(coupon => {
      return {
        id: coupon.id,
        caption: coupon.innerName,
      }
    }, [] as CouponDataInterface[])
}

export const getDiscountTypeData = (t: TLocoType): DiscountTypeInterface[] => [
  { caption: t('dashboard.coupon.discount.fixed'), id: 'fixed' },
  { caption: t('dashboard.coupon.discount.percent'), id: 'percent' },
]
