import { createInactivePage } from 'modules/funnels/funnel/api/funnel-api'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import InactiveIcon from 'modules/funnels/funnel/icons/inactive-icon'
import { getInactivePageEditorUrl } from 'modules/funnels/funnel/utils/page-utils'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface InactivePageProps {
  funnelId: FunnelId
}

function InactivePage({ funnelId }: InactivePageProps) {
  const { t } = useLocoTranslation()
  const { funnel, mutateFunnel } = useFunnel(funnelId)
  const [isFetching, setIsFetching] = useState(false)
  const handleClickInactivePage = async () => {
    if (funnel) {
      if (funnel.inactivePage) {
        window.location.assign(getInactivePageEditorUrl(funnel.inactivePage.id))
      } else {
        setIsFetching(true)
        const response = await createInactivePage(funnelId)
        const inactivePageId = response.data.inactivePage.id
        await mutateFunnel(data => {
          if (data) {
            return { ...data, inactivePage: { id: inactivePageId } }
          }
        }, false)
        setIsFetching(false)
        window.location.assign(getInactivePageEditorUrl(inactivePageId))
      }
    }
  }

  return (
    <li
      className={
        'px-[15px] py-[15px] border-b-[1px] last:border-b-transparent border-[#142D6326] border-opacity-20 flex gap-3 cursor-pointer hover:bg-blue-06'
      }
      onClick={handleClickInactivePage}
    >
      {isFetching ? (
        <div className={'flex justify-center w-full'}>
          <Loader />
        </div>
      ) : (
        <>
          <InactiveIcon className="flex-shrink-0" />
          <div className="flex flex-col flex-wrap">
            <span className="font-bold text-base">{t('dashboard.funnel.inactive_page')}</span>
            <span className="font-normal text-opacity-60 text-sm">
              {t('dashboard.funnel.inactive_page_description')}
            </span>
          </div>
        </>
      )}
    </li>
  )
}

export default InactivePage
