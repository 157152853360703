import { ListingBaseDataInterface } from 'modules/digital-product/resources/hooks/use-digital-resources'
import { DigitalResourceBaseDataInterface } from 'modules/digital-product/resources/types/digital-resource-edit-interface'

type GetStaticDataType = {
  data?: ListingBaseDataInterface[]
  id: number
}

export const getStaticData = ({
  id,
  data,
}: GetStaticDataType): DigitalResourceBaseDataInterface[] => {
  if (!data) return [{ id: 0, caption: '' }]
  const selectedData = data.find(el => el.id === Number(id))
  if (!selectedData) return [{ id: 0, caption: '' }]
  return [{ id: selectedData.id, caption: selectedData.caption }]
}
